<template>
  <div class="exam-view">
    <div>
      <div class="bg_top">
        <div style="padding-top:160px;">
          <div class="bg_top_border">
            <span class="testTitle">{{ test_name }}</span>
          </div>
        </div>
        <div class="bg_top_content">
          <span>{{ test_instruction }}</span>
        </div>
      </div>
    </div>
    <div style="display: flex;flex-direction: row;">
      <div class="content">
        <div class="content_list2" v-for="item in sections" :key="item._id.$id">
          <div class="content_list2_title">{{item.section_title}}</div>
          <exam
            :questions="item.questions_item"
            @submitAnswer="saveAnswer"
          >
          </exam>
        </div>
        <el-button v-if="type==='exam'" @click="finishTest" type="primary">提交试卷</el-button>
    </div>
    <div class="hintInfo" v-if="type==='exam'">
      <p>切屏次数总数 :{{visibleChangeCount}} </p>
      <p>{{countDownTimes}}</p>
    </div>
    </div>
    <div class="bg_bottom"></div>
  </div>
</template>
<script>
import exam from '../../components/exam'
import { getUserId } from '../../utils/store'
import { getExerciseTemplateByStudent, uploadAnswers, changeVisibleLog, getTestVisibleChangeCount, submitAnswer,getExerciseTemplateByStudentReview} from '../../api/assistant'
export default {
  components: {
    exam
  },
  data () {
    return {
      id: this.$route.query.id,
      type:this.$route.query.type,
      countDownTimes: '',
      questionList: [],
      answerList: [],
      test_name: "",
      test_instruction: "",
      countDownThread: undefined,
      sections:[],
      visibleChangeCount: 0
    };
  },
  beforeDestroy () {
    if (this.countDownThread && this.type==='exam') {
      window.clearInterval(this.countDownThread)
    }
  },
  mounted () {
    if(this.type==='exam'){
      document.addEventListener('visibilitychange', ()=>{
        document.hidden && this.handleVisiable()
      }
    )}
    this.fetchData()
  },
  methods: {
    handleVisiable (e) {
      this.visibleChangeCount++
      changeVisibleLog(getUserId(), this.id)
    },
    async fetchData () {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      if(this.type==='exam'){
        await this.getTestVisibleChangeCount() //获取倒计时
        await this.getExerciseTemplateByStudent() //获取题目
      }else{
        await this.getExerciseTemplateByStudentReview() //获取题目和题号
      }
      loading.close();
    },
    async getExerciseTemplateByStudentReview(){
      return new Promise((resolve,reject)=>{
        getExerciseTemplateByStudentReview(getUserId(),this.id).then(res=>{
          if(res.data.code===0){
            this.test_name = res.data.data.template_name;
            this.test_instruction = res.data.data.template_instruction;
            this.sections = res.data.data.sections_item
            resolve(true)
          }
        })
      })
    },
    async getExerciseTemplateByStudent(){
      return new Promise((resolve,reject)=>{
        getExerciseTemplateByStudent(this.id).then(res => {
          if (res.data.code === 0) {
            this.test_name = res.data.data.template_name;
            this.test_instruction = res.data.data.template_instruction;
            this.sections = res.data.data.sections_item
            this.startCountDown(res.data.data.test_duration)
            for(let item of this.sections){
              this.questionList = [...this.questionList,...item.questions_item];
            }
            this.questionList.map(item=>{
              item.choice = '未作答';
            })
            resolve(true)
          }
        })
      })
    },
    async getTestVisibleChangeCount(){
      return new Promise((resolve,reject)=>{
        getTestVisibleChangeCount(getUserId(), this.id).then(res => {
          this.visibleChangeCount = res.data.data
          resolve(true)
        })
      })
    },
    startCountDown (minute) {
      let totalTime = minute * 60
      this.countDownThread = window.setInterval(() => {
        this.countDownTimes = '答题时间：'+ this.formatSeconds(totalTime)
        totalTime--
        if (totalTime < 0) {
          window.clearInterval(this.countDownThread)
          this.$alert('时间到，确认交卷', '标题名称', {
            confirmButtonText: '确定',
            callback: action => {
              this.uploadAnswers()
            }
          });
        }
      }, 1000)
    },
    saveAnswer (e) {
      if(this.type==='exam'){
        let question_id = e.id
        let choice = e.choice
        submitAnswer(question_id,this.id,choice,getUserId());
        let question = this.questionList.find(item=>item._id.$id===e.id);
        question.choice = e.choice
      }
    },
    uploadAnswers () {
      const userId = getUserId()
      let answerList = this.questionList.map(item=>item.choice);
      uploadAnswers(answerList, userId, this.id).then(res => {
        if (res.data.code === 0) {
          this.$message({
            message: '上传成功',
            type: 'success'
          })
          this.$router.push('/examlist')
        }else{
          this.$message('您已提交过试卷');
          this.$router.push('/examlist')
        }
      })
    },
    formatSeconds (endTime) {
      let secondTime = parseInt(endTime)
      let min = 0
      let h = 0
      let result = ''
      if(secondTime>60){
        min=parseInt(secondTime/60)
        secondTime=parseInt(secondTime%60)
        if(min>60){
          h= parseInt(min / 60)
          min= parseInt(min%60)
        }
      }
      result =`${h.toString().padStart(2,'0')}:${min.toString().padStart(2,'0')}:${secondTime.toString().padStart(2,'0')}`
      return result
    },
    finishTest(){
      window.clearInterval(this.countDownThread)
      this.$confirm('是否确认交卷', '提示', {
        type: 'warning'
      }).then(() => {
        this.uploadAnswers()
      })
    }
  }
}
</script>

<style scoped>
.exam-view {
  width: 100%;
}
.hintInfo{
  margin-top: 20px;
  margin-right: 30px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #0085FF;
  border-radius: 8px;
  height: 80px;
  right: 28px;
  color: #0085FF;
  text-align: center;
}
.bg_top {
  background: url("../../assets/bgtop2x.png") no-repeat;
  background-size: 100%;
  width: 100%;
  height: 450px;
}
.bg_bottom {
  background: url("../../assets/bgbottom2x.png") no-repeat;
  width: 100%;
  background-size: 100%;
  height: 170px;
}
.content {
  width: 840px;
  /* height: 1313px; */
  margin: 70px auto;
}
.bg_top_border {
  height: 40px;
  padding: 20px;
  line-height: 40px;
  color: #ffffff;
  font-size: 24px;
  font-weight: bold;
}
.testTitle{
  border: 2px solid #f8fdff;
  background: rgba(248, 253, 255, 0.2);
  padding:20px;
  margin-left: 315px;
}

.bg_top_content {
  margin-left: 315px;
  width: 840px;
  height: 111px;
  font-size: 48px;
  font-weight: bold;
  line-height: 56px;
  color: #f8fdff;
  margin-top: 35px;
}
.content_list2{
  margin-top: 40px;
}

.content_list2_title {
  color: #0085ff;
  font-size: 22px;
  font-weight: bold;
}
.img_content {
  width: 677px;
  height: 87px;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  color: #919191;
  text-align: center;
}
</style>
