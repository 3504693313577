<template>
<div>
    <el-button @click="$router.back(-1)" style="margin:10px" size="medium">返回</el-button>
    <el-button @click="logout" style="float:right;margin:10px">退出</el-button>
    <div style="width:82%;margin:40px auto" >

            <p>标题</p>
            <el-input v-model="name" style="width:60%"></el-input>

            <p>标签</p>
            <vue-tags-input
                    v-model="tag"
                    :tags="tags"
                    @tags-changed="newTags => tags = newTags"
            />
            <div v-if="id!==''">
            <p >发送对象</p>
            <el-tag style="margin-left:6px;margin-bottom:6px" size="medium" v-for="(item,index) in toSendList" :key="index">{{item.user_lastName+item.user_givenName}}</el-tag>
            </div>
            <p>内容</p>
            <!-- <tinymce :height="200" v-model="content" style="width:80%"/> -->
            <el-input type="textarea" :rows="7" v-model="content" style="width:80%"></el-input>
            <div>
            <el-button @click="initSubmit" type="primary" style="margin-top:20px" >提交</el-button>
            </div>

        <el-dialog :visible.sync="showEmployee" :close-on-click-modal="false">
            <div>
                <span>需要发送的人：</span>
                <!-- <el-tag style="margin-left:6px;margin-bottom:6px" size="medium" closable  @close="removeEmail(index)" v-for="(item,index) in toSendList" :key="index" >{{item.user_lastName+item.user_givenName}}</el-tag> -->
            </div>
            <div style="margin-top:20px">
                <!-- <el-input v-model="searchEmail" placeholder="请输入要搜索的邮箱" style="width:60%"></el-input>
                <el-button @click="searchEmployee" style="margin-left:10px" type="primary" size="medium">搜索</el-button> -->

                <el-select v-model="toSendEmail" multiple style="width:100%" filterable>
                    <el-option v-for="item in users" :key="item._id.$id" :label="item.user_lastName+item.user_givenName+' '+item.user_email" :value="item.user_email"></el-option>
                </el-select>
            </div>
            <!-- <div>
                <el-table :data="searchResults" style="margin-top:10px">
                    <el-table-column label="姓名">
                        <template slot-scope="scope">
                            <span>{{scope.row.user_lastName+scope.row.user_givenName}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="user_email" label="邮箱"></el-table-column>

                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <el-button @click="add(scope.row)" type="primary" size="medium">添加</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div> -->
            <el-button :loading="typeLoading" @click="submit" style="margin-top:10px" type="primary" size="medium">提交</el-button>
        </el-dialog>
    </div>
</div>
</template>

<script>

import { findEmployeeByEmail, updateMeetingNote, getMyMeetingNote, getMeetingNote,getUserList ,getUserListWithType} from '../../api/assistant.js'
import VueTagsInput from '@johmun/vue-tags-input'
import Tinymce from '../../components/Tinymce'

import {
  getUserId,
  removeUserId,
  removeUserName,
  getUserName
} from '../../utils/store'
export default {

  components: {
    tinymce: Tinymce,
    VueTagsInput
  },
  data () {
    return {
      typeLoading: false,
      showEmployee: false,
      toSendList: [],
      toSendEmail: [],
      searchEmail: '',
      searchResults: [],
      id: '',
      name: '',
      tags: [],
      tag: '',
      content: '',
      sendObject: [],
      users:[]
    }
  },
  mounted () {
      this.getUserList()
    this.id = this.$route.query.id
    if (this.id !== '') {
      this.getMeetingNote()
    }
  },
  methods: {
      getUserList(){
        getUserListWithType('').then(res=>{
              this.users = res.data
          })
      },
    searchEmployee () {
      findEmployeeByEmail(this.searchEmail).then(res => {
        console.log(res)
        this.searchResults = res.data
      })
    },
    getMeetingNote () {
      getMeetingNote(this.id).then(res => {
        console.log(res.data)
        if (res.data.code === 0) {
          const result = res.data.data
          this.name = result.name
          const song_tags = result.tags
          for (var index in song_tags) {
            if (song_tags[index] !== null && song_tags[index] !== 'null') {
              const new_tag = { text: song_tags[index] }
              this.tags.push(new_tag)
            }
          }
          this.content = result.content
          this.toSendList = result.participant_emails_name
          this.toSendEmail = result.participant_emails
          console.log(this.toSendList)
        }
      })
    },
    initSubmit () {
      this.searchResults = []
      this.searchEmail = ''

      this.showEmployee = true
    },
    add (row) {
      this.toSendList.push(row)
      this.toSendEmail.push(row.user_email)
    },
    submit () {
      const tempTags = []
      for (const tag in this.tags) {
        tempTags.push(this.tags[tag].text)
      }


      var regex = /(<([^>]+)>)/ig
      var result = this.content.replace(regex, '')

      this.typeLoading = true;

      updateMeetingNote(this.id, this.name, tempTags, result, this.toSendEmail).then(res => {
        console.log(res)
        this.showEmployee = false
        this.$message({
          message: '提交成功！',
          type: 'success'
        })
        this.id = res.data.data._id.$id
        this.typeLoading = false;

      })
    },
    removeEmail (index) {
      console.log(index)
      this.toSendList.splice(index, 1)
    },
    logout () {
      removeUserId()
      removeUserName()
      location.reload()
    }
  }
}
</script>

<style>

</style>
