<template>
    <div>
        <p>Work shop列表</p>
        <el-table :data="workshops">
            <el-table-column label="名字" prop="name"></el-table-column>
            <el-table-column label="介绍" prop="intro"></el-table-column>
            <el-table-column label="模板名" prop="template.name"></el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button type="primary" @click="toEntry(scope.row._id.$id)">查看提交的作业</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import {getWorkshops} from '../../api/assistant'
export default {
    data(){
        return{
            workshops:[]
        }
    },
    mounted(){
        this.fetchData()
    },
    methods:{
        fetchData(){
            getWorkshops().then(res=>{
                console.log(res)
                if(res.data.code===0){
                    this.workshops = res.data.data
                }
            })
        },
        toEntry(id){
            this.$router.push({
                path:'workShopEntryList',
                query:{
                    id:id
                }
            })
        }
    }
}
</script>

<style>

</style>