<template>
  <div class="chat-container">
    <div class="message-box">
      <div class="message-list">
        <div class="user-info">
          <div @click="changeAvatar">
            <el-avatar
              shape="square"
              class="avatar"
              :size="48"
              :src="avatarUrl"
              fit="contain"
            ></el-avatar>
          </div>
          <div style="flex: 1;">
            <div class="tip">我自己</div>
            <div class="user-name">{{ userName }}</div>
          </div>
          <img
            src="../../assets/chat.png"
            class="chat-icon"
            @click="showAddGroup = true"
          />
        </div>
        <div class="message-container">
          <div
            v-for="(chat, index) in chatList"
            :key="index"
            :class="chat._id.$id === currentChat ? 'active' : ''"
            @click="currentChat = chat._id.$id"
          >
            <ChatGroup :info="chat" />
          </div>
        </div>
      </div>
      <div class="message-content">
        <div v-if="currentChat" style="width: 100%; height: 100%">
          <div class="content-container">
            <MessageContent
              :info="currentChatInfo"
              ref="messageDetail"
              @show="showGroupInfo = true"
            />
          </div>
          <div class="sender">
            <textarea ref="text" v-model="content" @keydown="handleKeydown" />
            <el-button type="primary" class="send-btn" @click="handleSubmit"
              >发送</el-button
            >
          </div>
        </div>
        <div v-else class="empty">您还未选中或发起聊天</div>
      </div>
    </div>
    <GroupInfo
      :visible="showGroupInfo"
      :info="currentChatInfo"
      v-if="currentChatInfo"
      @success="onSuccess"
      @close="showGroupInfo = false"
    />
    <el-dialog :visible.sync="showAddGroup" title="新建群聊" width="600px">
      <el-form :model="form" label-position="top" :rules="rules" ref="chatForm">
        <el-form-item label="老师邮箱" prop="contact">
          <el-select
            v-model="form.contact"
            multiple
            filterable
            remote
            reserve-keyword
            placeholder="请输入老师邮箱"
            style="width: 100%"
            :remote-method="queryContactAsync"
            :loading="contactLoading"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="项目名称" prop="code">
          <el-select
            v-model="form.code"
            placeholder="请输入项目名称"
            style="width: 100%"
          >
            <el-option
              v-for="item in projectList"
              :key="item.label"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showAddGroup = false">取 消</el-button>
        <el-button type="primary" @click="onCreate">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="修改头像" :visible.sync="editAvatarDialog" width="40%">
      <el-row type="flex" justify="center">
        <div class="cropper">
          <vueCropper
            ref="cropper"
            :img="option.img"
            :outputSize="option.size"
            :outputType="option.outputType"
            :info="option.info"
            :full="option.full"
            :canMove="option.canMove"
            :canMoveBox="option.canMoveBox"
            :original="option.original"
            :autoCrop="option.autoCrop"
            :autoCropWidth="option.autoCropWidth"
            :autoCropHeight="option.autoCropHeight"
            :fixedBox="option.fixedBox"
            @realTime="realTime"
          ></vueCropper>
        </div>
        <div class="previewBox">
          <div :style="previews.div" class="preview">
            <img :src="previews.url" :style="previews.img" />
          </div>
          <el-row type="flex" justify="center">
            <el-upload
              action=""
              :show-file-list="false"
              :auto-upload="false"
              :on-change="uploadImg"
            >
              <el-button size="mini" type="primary"> 更换头像 </el-button>
            </el-upload>
          </el-row>
          <br />
          <el-row>
            <el-button
              icon="el-icon-plus"
              circle
              size="mini"
              @click="changeScale(1)"
            ></el-button>
            <el-button
              icon="el-icon-minus"
              circle
              size="mini"
              @click="changeScale(-1)"
            ></el-button>
            <el-button
              icon="el-icon-download"
              circle
              size="mini"
              @click="down('blob')"
            ></el-button>
            <el-button
              icon="el-icon-refresh-left"
              circle
              size="mini"
              @click="rotateLeft"
            ></el-button>
            <el-button
              icon="el-icon-refresh-right"
              circle
              size="mini"
              @click="rotateRight"
            ></el-button>
          </el-row>
        </div>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editAvatarDialog = false">取 消</el-button>
        <el-button type="primary" @click="saveEditAvatar">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ChatGroup from './Message.vue'
import MessageContent from './MessageContent.vue'
import GroupInfo from './GroupInfo.vue'
import { VueCropper } from 'vue-cropper'
import {
  getChatList,
  sendMessage,
  createChatGroup,
  getContactWithEmail,
  getReachChatProjects,
  uploadPic,
  updateUserHeadPic
} from '../../api/assistant'
import { getAvatar, getUserId, getUserName, setAvatar } from '../../utils/store'

export default {
  name: 'Chat',
  components: {
    ChatGroup,
    MessageContent,
    GroupInfo,
    VueCropper
  },
  data () {
    return {
      avatarUrl:
        getAvatar() ||
        'https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png',
      squareUrl:
        'https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png',
      content: '',
      userName: '',
      chatList: [],
      showAddGroup: false,
      showGroupInfo: false,
      form: {},
      options: [],
      projectList: [],
      contactLoading: false,
      currentChat: '',
      rules: {
        contact: [
          { required: true, message: '请输入教师名称', trigger: 'blur' }
        ],
        code: [{ required: true, message: '请选择活动名称', trigger: 'change' }]
      },
      editAvatarDialog: false,
      previews: {},
      option: {
        img: '', // 裁剪图片的地址
        info: true, // 裁剪框的大小信息
        outputSize: 1, // 剪切后的图片质量（0.1-1）
        full: true, // 输出原图比例截图 props名full
        outputType: 'png', // 裁剪生成额图片的格式
        canMove: true, // 能否拖动图片
        original: false, // 上传图片是否显示原始宽高
        canMoveBox: true, // 能否拖动截图框
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 150,
        autoCropHeight: 150,
        fixedBox: true // 截图框固定大小
      }
    }
  },
  async mounted () {
    await this.getProjects()
    await this.getChatList()
    this.$ws.wsMessage(res => this.$refs.messageDetail.getChatDetail())
    this.userName = getUserName()
  },
  computed: {
    currentChatInfo () {
      return this.chatList.find(item => item._id.$id === this.currentChat)
    }
  },
  methods: {
    changeAvatar () {
      this.editAvatarDialog = true
    },
    handleKeydown (e) {
      if (e.keyCode === 13 && (e.ctrlKey || e.shiftKey)) {
      } else if (e.keyCode === 13) {
        this.handleSubmit()
        e.preventDefault()
      }
    },
    async getChatList () {
      try {
        const res = await getChatList({
          user_id: getUserId()
        })
        this.chatList = res.data.data.map(item => {
          const projectInfo = this.projectList.find(
            i =>
              i.value ===
              (Object.keys(item.project_info).length > 0
                ? item.project_info._id.$id
                : '')
          )
          return {
            ...item,
            logo: Object.keys(projectInfo || {}).includes('logo')
              ? projectInfo.logo
              : this.squareUrl
          }
        })
      } catch (error) {
        console.log(error)
      }
    },
    async getProjects () {
      try {
        const res = await getReachChatProjects({})
        this.projectList = res.data.data.map(item => ({
          ...item,
          label: item.brand,
          value: item._id.$id
        }))
      } catch (error) {
        console.log(error)
      }
    },
    onSuccess () {
      this.getChatList()
    },
    async queryContactAsync (query) {
      if (query !== '') {
        this.contactLoading = true
        const res = await getContactWithEmail({
          contact_email: query
        })
        this.contactLoading = false
        this.options = res.data.data.map(item => ({
          ...item,
          label: `${item.contact_lastName}${item.contact_givenName}: ${item.contact_email}`,
          value: item._id.$id
        }))
      } else {
        this.options = []
      }
    },
    async onCreate () {
      this.$refs.chatForm.validate(async valid => {
        if (valid) {
          const res = await createChatGroup({
            account_id: this.options.find(
              item => item.value === this.form.contact[0]
            ).account_id,
            user_id: getUserId(),
            contact_ids: this.form.contact,
            brand_id: this.form.code,
            create_type: 'web'
          })
          if (res.data.code === -1) {
            this.$message.error(res.data.msg || 'error')
          } else {
            this.form = {
              contact: [],
              code: ''
            }
            this.showAddGroup = false
            this.getChatList()
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    async handleSubmit () {
      if (!this.content) {
        this.$message.warning('请输入内容')
        return
      }
      try {
        this.$ws.wsSend({
          groupId: this.currentChatInfo._id.$id,
          senderId: getUserId(),
          messageContent: this.content
        })
        this.$ws.wsMessage(() => {}, async (disConnectUserIds) => {
          const res = await sendMessage({
            sender_id: getUserId(),
            account_id: this.currentChatInfo.account_id,
            group_id: this.currentChatInfo._id.$id,
            message_content: this.content,
            message_type: 'web',
            off_line_contact_ids: disConnectUserIds
          })
          if (res.data.code === 0) {
            this.content = ''
            this.$refs.messageDetail.getChatDetail()
          }
        })
      } catch (error) {
        console.log(error)
      }
    },
    // 保存头像修改
    saveEditAvatar () {
      this.editAvatarDialog = false
      this.finish('blob')
    },
    // 放大/缩小
    changeScale (num) {
      num = num || 1
      this.$refs.cropper.changeScale(num)
    },
    // 左旋转
    rotateLeft () {
      this.$refs.cropper.rotateLeft()
    },
    // 右旋转
    rotateRight () {
      this.$refs.cropper.rotateRight()
    },
    // 保存上传图片
    finish (type) {
      if (type === 'blob') {
        this.$refs.cropper.getCropBlob(data => {
          const file = new File([data], Math.random() * Date.now(), {
            type: 'application/json',
            lastModified: Date.now()
          })
          const param = new FormData()
          param.append('file', file)
          param.append('function', 'uploadPic')
          uploadPic(param).then(async res => {
            await updateUserHeadPic(getUserId(), res.data.data)
            this.avatarUrl = res.data.data
            setAvatar(res.data.data)
            this.$message.success('修改成功')
          })
          // 访问接口保存到数据库写这儿!
        })
      } else {
        this.$refs.cropper.getCropData(data => {
          console.log(data)
        })
      }
    },
    // 实时预览函数
    realTime (data) {
      this.previews = data
    },
    // 下载图片
    down (type) {
      var aLink = document.createElement('a')
      aLink.download = 'author-img'
      if (type === 'blob') {
        this.$refs.cropper.getCropBlob(data => {
          aLink.href = window.URL.createObjectURL(data)
          aLink.click()
        })
      } else {
        this.$refs.cropper.getCropData(data => {
          aLink.href = data
          aLink.click()
        })
      }
    },
    // 更换头像--上传本地图片
    uploadImg (file) {
      var _this = this
      var reader = new FileReader()
      reader.onload = e => {
        let data
        if (typeof e.target.result === 'object') {
          // 把Array Buffer转化为blob 如果是base64不需要
          data = window.URL.createObjectURL(new Blob([e.target.result]))
        } else {
          data = e.target.result
        }
        _this.option.img = data
      }
      // 转化为base64
      // reader.readAsDataURL(file.raw)
      // 转化为blob
      reader.readAsArrayBuffer(file.raw)
    }
  }
}
</script>

<style lang="scss" scoped>
.chat-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  background: #f8f8f8;

  .previewBox {
    text-align: center;
    margin-left: 60px;
  }

  .preview {
    width: 150px;
    height: 150px;
    margin: 0px auto 20px auto;
    border-radius: 10px;
    border: 1px solid #ccc;
    background-color: #ccc;
    overflow: hidden;
  }

  .cropper {
    width: 260px;
    height: 260px;
  }
  .avatar {
    margin-right: 18px;
  }

  .message-box {
    width: 80%;
    display: flex;

    .message-list {
      width: 300px;
      height: 100%;
      background: #fff;

      .user-info {
        padding: 18px;
        display: flex;
        align-items: center;

        .chat-icon {
          width: 20px;
          height: 20px;
          cursor: pointer;
        }

        .user-name {
          font-size: 16px;
          color: #333333;
          flex: 1;
        }
        .tip {
          font-size: 12px;
          color: #999;
          margin-bottom: 12px;
        }
      }

      .message-container {
        height: calc(100% - 88px);
        overflow-y: scroll;
        .active {
          background-color: #e3edff;
        }
      }
    }
  }

  .message-content {
    flex: 1;
    margin-left: 8px;
    background: #fff;

    .empty {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #999999;
    }

    .content-container {
      height: calc(100% - 260px);
    }

    .sender {
      height: 200px;
      border-top: 1px solid #d9d9d9;
      padding-bottom: 59px;
      position: relative;

      textarea {
        box-sizing: border-box;
        padding: 24px;
        height: 100%;
        width: 100%;
        border: none;
        outline: none;
        resize: none;
      }

      .send-btn {
        position: absolute;
        bottom: 18px;
        right: 18px;
      }
    }
  }
}
</style>
