<template>
    <div>
        <el-form>
            <el-form-item label="问题题干">
                <el-input v-model="quiz.question_title"></el-input>
            </el-form-item>
            <el-form-item label="问题选项">
                <el-input v-model="a" style="width:20%"></el-input>
                <el-input v-model="b" style="width:20%"></el-input>
                <el-input v-model="c" style="width:20%"></el-input>
                <el-input v-model="d" style="width:20%"></el-input>
            </el-form-item>
            <el-form-item label="问题答案">
                <el-input v-model="quiz.question_answer"></el-input>
            </el-form-item>
            <el-form-item label="测验位置">
                <el-input v-model="quiz.quiz_position"></el-input>
            </el-form-item>
            <el-form-item label="知识点">
                <el-input v-model="quiz.question_test_point"></el-input>
            </el-form-item>
            <el-form-item label="解析">
                <el-input v-model="quiz.question_solution"></el-input>
            </el-form-item>

        </el-form>
        <el-button type="primary" @click="update">提交</el-button>
    </div>
</template>

<script>
import {getQuiz,updateContentQuiz} from '../api/assistant'
export default {
    data(){
        return{
            id:'',
            quiz:{

            },
            a:'',
            b:'',
            c:'',
            d:''
        }
    },
    mounted(){
        this.id = this.$route.query.id
        this.fetchData()
    },
    methods:{
        fetchData(){
            getQuiz(this.id).then(res=>{
                console.log(res)
                this.quiz = res.data
                this.a = res.data.question_options[0]
                this.b = res.data.question_options[1]
                this.c = res.data.question_options[2]
                this.d = res.data.question_options[3]
            })
        },
        update(){
            let options = [];
            options.push(this.a)
            options.push(this.b)
            options.push(this.c)
            options.push(this.d)
            updateContentQuiz(this.quiz._id.$id,this.quiz.content_id,this.quiz.question_title,this.quiz.question_answer,this.quiz.quiz_position,options,this.quiz.question_solution,this.quiz.question_test_point).then(res=>{
                this.$message('更新成功')
                this.fetchData()
            })
        }
    }
}
</script>

<style>

</style>