<template>
    <div class="container">
        <el-button type="primary" @click="addMonth"> 添加员工月度日志空缺</el-button>
        <div class="table">
            <el-select v-model="year" @change="fetchData">
                <el-option value="2024" label="2024"></el-option>
                <el-option value="2023" label="2023"></el-option>
                <el-option value="2022" label="2022"></el-option>
                <el-option value="2021" label="2021"></el-option>
                <el-option value="2020" label="2020"></el-option>
                <el-option value="2019" label="2019"></el-option>
            </el-select>
            <div class="tableHead" style="margin-top:20px">
                <div class="headItem">姓名</div>
                <div :class="{'headItem':true,'border':index==11}" v-for="(item,index) in Array(12)" :key="item">{{index+1}}月</div>
            </div>
            <div class="tableContent" v-for="(item,index) in employees" :key="item._id.$id">
                <div :class="{'headItem':true,'border-bottom':index===(employees.length-1)}">{{item.user_lastName+item.user_givenName}}</div>
                <div v-for="(x,i) in item.sheets" :key="i" :class="{'content':x.sheet,'headItem':true,'border-bottom':index===(employees.length-1),'border':i==11}" @click="toForm(x)">
                    {{x.sheet?'已完成':''}}
                </div>
            </div>
        </div>



         <el-dialog :visible.sync="showAddMonth" title="添加空缺" width="80%">
            <el-form label-width="200px">
                <el-form-item label="选择员工">
                    <el-select v-model="employeeId">
                        <el-option v-for="item in employees" :label="item.user_lastName+item.user_givenName" :value="item._id.$id" :key="item._id.$id"></el-option>
                        
                    </el-select>
                </el-form-item>
                <el-form-item label="年">
                    <el-select v-model="addyear">
                        <el-option :label="2021" :value="2021"></el-option>
                        <el-option :label="2022" :value="2022"></el-option>
                        <el-option :label="2023" :value="2023"></el-option>

                    </el-select>
                </el-form-item>
                <el-form-item label="月">
                    <el-select v-model="addmonth">
                        <el-option v-for="item in 12" :label="item + 1" :value="item + 1" :key="item"></el-option>
                        
                    </el-select>
                </el-form-item>
                
                <el-form-item>
                    <el-button @click="updateAdd" type="primary">确认</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>

    </div>
    
</template>

<script>
import {getMonthWorkingTable,addUserMonth} from '../../api/assistant'
export default {
    data(){
        return{
            year:'2022',
            employees:[],
            showAddMonth:false,
            employeeId:'',
            addmonth:'',
            addyear:''
        }
    },
    mounted(){
        this.fetchData()
    },
    methods:{
        fetchData(){
            getMonthWorkingTable(this.year).then(res=>{
                console.log(res)
                if(res.data){
                    this.employees = res.data
                }
            })
        },
        addMonth(){

            this.employeeId = ''
            this.addmonth = ''
            this.addyear = ''
            this.showAddMonth = true
        },
        updateAdd(){
            console.log(111,this.employeeId,this.addyear,this.addmonth)

            addUserMonth(this.addyear,this.addmonth,this.employeeId).then(res=>{
                // console.log(res)
                this.$message(res.data.msg);
                this.fetchData()

                this.showAddMonth = false

            })
            
        },
        toForm(month){
            if(month.sheet){
                this.$router.push({
                    path:'/adminmanage/monthLogForm',
                    query:{
                        month:month.sheet.month+'月',
                        year:month.sheet.year,
                        sheet_id:month.sheet._id.$id
                    }
                })
            }
        }
    }
}
</script>

<style scoped>
.container{
    width: 100%;
    color: #909399;
}
.table{
    width:100%;
    margin-top:20px
}


.tableHead{
    text-align: center;
    display: flex;
    height: 46px;
    line-height: 46px;
    font-weight: 600;
    font-size: 96%;
}
.headItem{
    border-top: 0.5px solid #E4E7ED;
    border-left: 0.5px solid #E4E7ED;
    width: 11%;
    font-size: 15px;
}
.tableContent{
    height: 50px;
    display: flex;
    line-height: 50px;
    text-align: center;
}
.border{
    border-right: 0.5px solid #E4E7ED;
}
.border-bottom{
    border-bottom: 0.5px solid #E4E7ED;
}
.content{
    cursor: pointer;
    background: #DCDFE6;
}
.content:hover{
    background: #67C23A;
}
</style>