<template>
    <div>
        <el-button @click="$router.back(-1)" size="medium">返回</el-button>
        <el-form>
            <el-form-item label="课程名称" >
                <el-input v-model="class_info.class_info.class_name"></el-input>
            </el-form-item>
            <el-form-item label="课程所属BU">
                <el-input v-model="class_info.class_info.class_BU"></el-input>
            </el-form-item>
            <el-form-item label="课程介绍">
                <el-input v-model="class_info.class_info.class_description" type="textarea" :rows="6"></el-input>
            </el-form-item>
            <el-form-item label="课程头像链接">
                <el-input v-model="class_info.class_info.class_avatar_url"></el-input>
            </el-form-item>
            <el-form-item label="是否要求完成前一节才能进入下一节？">
                <div style="width:100%">
                    <el-select v-model="class_info.class_info.session_completion_required" style="width:80px">
                        <el-option value="是" label="是"></el-option>
                        <el-option value="否" label="否"></el-option>
                    </el-select>
                </div>
            </el-form-item>
        </el-form>
        <el-button type="primary" @click="submit">提交</el-button>
        <section style="margin-top:50px">
            <el-button @click="initAdd" type="primary">添加</el-button>
            <el-button @click="startOrder" type="primary">排序</el-button>
            <el-table :data="session_list" style="margin-top:20px">
                <el-table-column label="编号" width="200px" align="center">
                    <template slot-scope="scope">
                        {{scope.$index}}
                    </template>
                </el-table-column>
                <el-table-column label="课时名称" prop="session_info.session_name" align="center"></el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button type="primary" size="small" @click="viewSession(scope.row)">查看</el-button>
                        <el-button @click="removeSession(scope.row._id.$id)" type="warning" size="small">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </section>
        <el-dialog :visible.sync="showAdd">
            <span>课时名称</span>
            <el-input v-model="session_name" type="text"> </el-input>
            <el-button style="margin-top:20px" @click="addConfirm">确定</el-button>
        </el-dialog>


        <el-dialog
          title="更改排序"
          :visible.sync="ordershow"
          :close-on-click-modal="false"
          width="80%"
          @open="openOrder"
          @close="orderClose"
        >
          <p class="orderP">注：自由拖拽可进行排序</p>

           <el-table   row-key="id" class="orderTable" :data="orderData" style="margin-top:20px">
                <el-table-column label="编号" width="200px" align="center">
                    <template slot-scope="scope">
                        {{scope.$index}}
                    </template>
                </el-table-column>
                <el-table-column label="课时名称" prop="session_info.session_name" align="center"></el-table-column>
               
            </el-table>
          <span slot="footer" class="dialog-footer">
            <el-button @click="orderClose">取 消</el-button>
            <el-button type="primary" @click="orderTask">确 定</el-button>
          </span>
        </el-dialog>
    </div>
</template>

<script>
import { getClass,updateClass,updateSession,addSessionToClass,removeSession,updateSessionOrderInClass } from '../api/assistant'
import Sortable from "sortablejs";
export default {
    data(){
        return{
            id:'',
            class_info:{
                class_info:{

                }
            },
            showAdd:false,
            session_list:[],
            session_name:'',
            ordershow:false,
            orderLoading: false,
            orderData:[],

        }
    },
    mounted(){
        this.id = this.$route.query.id
        this.fetchData()
    },
    methods:{
        fetchData(){
            getClass(this.id).then(res=>{
                console.log(res)
                if(res.data){
                    this.class_info = res.data
                    this.session_list = res.data.sessions
                    this.orderData = res.data.sessions.map(m=>{
                        m.id=m._id.$id;
                        return m;
                    })
                }  
            })
        },
        submit(){
            updateClass(this.id,this.class_info.class_info.class_name,this.class_info.class_info.class_BU,this.class_info.class_info.class_description,this.class_info.class_info.class_avatar_url,this.class_info.class_info.session_completion_required).then(res=>{
                this.$message({
                    message:'更新成功',
                    type:'success'
                })
            })
        },
        viewSession(row){
            this.$router.push({
                path:'sessioninfo',
                query:{
                    id:row._id.$id,
                    class_id:this.id
                }
            })
        },
        initAdd(){
            this.showAdd=true
            this.session_name = ''
            
        },
        addConfirm(){
            updateSession('',this.session_name,'',this.id).then(res=>{
                console.log(res)
                addSessionToClass(res.data._id.$id,this.id).then(res=>{
                    console.log(res)
                    this.$message({
                        message:'更新成功',
                        type:'success'
                    })
                    this.fetchData()
                    this.showAdd=false
                })
            })
        },
        removeSession(id){
            removeSession(id,this.id).then(res=>{
                this.$message('删除成功')
            })
            this.fetchData()
        },
        orderTask() {
          //确定排序
          let child_ids = [];
          this.orderData.forEach((f) => {
            child_ids.push(f._id.$id);
          });
          this.orderLoading = true;
          updateSessionOrderInClass(this.id, child_ids).then((res) => {
            this.orderLoading = false;
            if (res.data.code == 0) {
              this.$message.success("更新成功！");
              this.orderClose();
            }
          });
        },
        //行拖拽
        rowDrop() {
          const tbody = document.querySelector(
            ".orderTable .el-table__body-wrapper tbody"
          );
          console.log(tbody);
          const _this = this;
          Sortable.create(tbody, {
            onEnd({ newIndex, oldIndex }) {
              const currRow = _this.orderData.splice(oldIndex, 1)[0];
              _this.orderData.splice(newIndex, 0, currRow);
              console.log(_this.orderData);
            },
          });
        },
        // taskOrder(item) {
        //   this.orderData = item.children.map((m) => {
        //     if (m.children && m.children.length > 0) {
        //       delete m.children;
        //     }
        //     return m;
        //   });
        //   console.log(item);
        //   this.item_id = item.id;
        //   this.ordershow = true;
        // },
        openOrder() {
          this.$nextTick(() => {
            // 阻止默认行为
            document.body.ondrop = function (event) {
              event.preventDefault();
              event.stopPropagation();
            };
            this.rowDrop();
          });
        },
        orderClose() {
          this.ordershow = false;
          this.fetchData();
        },
        startOrder(){
            this.ordershow = true;
        }

    }
}
</script>

<style>

</style>