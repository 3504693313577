<template>
  <div>
    <div>品牌策略列表</div>
    <el-button @click="$router.back()" type="primary" style="margin-top:30px"
      >返回</el-button>
    <el-button @click="initAdd" type="primary" style="margin-top:30px"
      >新增品牌策略</el-button>
      

    <el-table :data="userList" style="margin-top:30px" v-loading="loading">
      <el-table-column label="编号" width="120px">
        <template slot-scope="scope">
          {{scope.$index+1}}
        </template>
      </el-table-column>

      <el-table-column label="品牌策略内容">
        <template slot-scope="scope">
          {{scope.row.content}}
        </template>
      </el-table-column>
      <el-table-column label="修改时间">
        <template slot-scope="scope">
          {{formateTime(scope.row.update_time)}}
        </template>
      </el-table-column>      

      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            @click="view(scope.row)"
            type="primary"
            size="small"
            >修改</el-button
          >
          <el-button
            @click="remove(scope.row._id.$id)"
            type="warning"
            size="small"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="新增品牌策略" :visible.sync="showAdd">
      <el-form>
        
        <el-form-item label="品牌策略内容">
          <el-input  type="textarea" :rows="2" placeholder="请输入内容" v-model="okrContent"></el-input>
        </el-form-item>
      </el-form>
      <el-button @click="updateBrandPolicy" type="primary" style="margin-top:20px"
        >更新</el-button
      >
    </el-dialog>
    
  </div>
</template>

<script>
import { getBrandPolicys, updateBrandPolicy,removeBrandPolicy} from "../../api/assistant";
export default {
  data() {
    return {
      employee_id:'',
      userList: [],
      okrContent: '',
      okr_id:'',
      showAdd: false,      
      loading:false,
      month:0
    };
  },
  mounted() {
    this.employee_id = this.$route.query.id;
    this.fetchData();
     
  },
  methods: {
    fetchData() {
      this.loading = true
      getBrandPolicys(this.employee_id).then(res => {
        if (res.data) {
          this.userList = res.data;
          this.loading = false
        }
      });
    },
    view(row) {
       this.showAdd = true;
       this.okr_id = row._id.$id; 
       this.okrContent = row.content;
    },
    initAdd() {
       this.showAdd = true;
       this.okr_id = ''; 
       this.okrContent = '';
    },
  
    updateBrandPolicy() {

      updateBrandPolicy(
        this.okr_id,
        this.employee_id,
        this.okrContent
        
      ).then(res => {
        this.$message({
          message: "更新成功",
          type: "success"
        });
        this.showAdd = false;
        this.fetchData();

      });

      
      

    },
    remove(id) {
      this.$confirm("是否确认删除", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        removeBrandPolicy(id).then(res => {
          this.$message("删除成功");
          this.fetchData();
        });
      });
    },
    formateTime(date){
      let time = new Date(date*1000);
      return `${time.getFullYear()}-${time.getMonth()+1}-${time.getDate()}`;
    },
    formateDay(date){
      let time = new Date(date*1000);
      return `${time.getDate()}`;
    },
    formateMonth(date){
      let time = new Date(date*1000);
      return `${time.getMonth()+1}`;
    },
    formateYear(date){
      let time = new Date(date*1000);
      return `${time.getFullYear()}`;
    },
  }
};
</script>

<style></style>
