<template>
  <div>
    <el-button @click="addSample" type="primary" style="margin-left:20px"
      >添加例子</el-button
    >
    <el-table :data="samples" v-loading="loading">
      <el-table-column
        prop="domain"
        label="领域"
        width="100px"
      ></el-table-column>
      <el-table-column prop="problem" label="原句"></el-table-column>
      <el-table-column prop="answer" label="纠正"></el-table-column>
      <el-table-column
        prop="type"
        label="问题类型"
        width="100px"
      ></el-table-column>
      <el-table-column
        prop="expression_type"
        label="表达类型"
        width="100px"
      ></el-table-column>
      <el-table-column label="详细" width="250">
        <template slot-scope="scope">
          <el-button type="primary" size="small" @click="view(scope.row)"
            >编辑</el-button
          >
          <el-button @click="remove(scope.row)" size="small" type="danger"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :visible.sync="showEdit" title="添加例子" width="80%">
      <el-form label-width="200px">
        <el-form-item label="表达类型">
          <el-select
            v-model="form.expression_type"
            @change="getCorrectionSampleTypes"
          >
            <el-option value="文字表达" label="文字表达"></el-option>
            <el-option value="视觉表达" label="视觉表达"></el-option>
            <el-option value="B端表达" label="B端表达"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="训练方法">
          <el-select v-model="form.train_mode">
            <el-option value="问答" label="问答"></el-option>
            <el-option value="笔记" label="笔记"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="领域">
          <el-select v-model="form.domain">
            <el-option
              v-for="item in domains"
              :label="item"
              :value="item"
              :key="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="原句/案例">
          <el-input
            type="textarea"
            :rows="5"
            v-model="form.problem"
            style="width:80%"
          ></el-input>
        </el-form-item>
        <el-form-item label="原句/案例(音频)">
          <el-upload
            class="upload-demo"
            action=""
            :on-remove="
              (file, fileList) => {
                handleRemove(file, fileList, 'currentProblemAudioList');
              }
            "
            :before-upload="
              file => {
                beforeUpload(file, 'currentProblemAudioList');
              }
            "
            :file-list="currentProblemAudioList"
          >
            <el-button size="small" type="primary" :loading="audioLoading"
              >点击上传音频</el-button
            >
          </el-upload>
        </el-form-item>
        <el-form-item label="纠正/应对">
          <el-input
            type="textarea"
            :rows="5"
            v-model="form.answer"
            style="width:80%"
          ></el-input>
        </el-form-item>
        <el-form-item label="纠正/应对(音频)">
          <el-upload
            class="upload-demo"
            action=""
            :before-upload="
              file => {
                beforeUpload(file, 'currentAnswerAudioList');
              }
            "
            :on-remove="
              (file, fileList) => {
                handleRemove(file, fileList, 'currentAnswerAudioList');
              }
            "
            :file-list="currentAnswerAudioList"
          >
            <el-button size="small" type="primary" :loading="audioLoading"
              >点击上传音频</el-button
            >
          </el-upload>
        </el-form-item>
        <el-form-item label="类型">
          <el-select v-model="form.type" multiple>
            <el-option
              v-for="item in types"
              :label="item"
              :value="item"
              :key="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="解释/指导">
          <el-input
            type="textarea"
            :rows="5"
            v-model="form.note"
            style="width:80%"
          ></el-input>
        </el-form-item>
        <el-form-item label="解释/指导(音频)">
          <el-upload
            class="upload-demo"
            action=""
            :before-upload="
              file => {
                beforeUpload(file, 'currentNoteAudioList');
              }
            "
            :on-remove="
              (file, fileList) => {
                handleRemove(file, fileList, 'currentNoteAudioList');
              }
            "
            :file-list="currentNoteAudioList"
          >
            <el-button size="small" type="primary" :loading="audioLoading"
              >点击上传音频</el-button
            >
          </el-upload>
        </el-form-item>
        <el-form-item label="图片">
          <el-upload
            class="avatar-uploader"
            action="111"
            :before-upload="beforeAvatarUpload"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>

        <el-form-item>
          <el-button @click="updateCorrectionSample" type="primary"
            >确认</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  getCorrectionSampleTypes,
  getUser,
  updateCorrectionSample,
  getCorrectionSamples,
  getCorrectionDomains,
  removeCorrectionSample,
  uploadPic,
  getQiniuToken
} from "../../api/assistant";
import { getUserId } from "../../utils/store";
import * as qiniu from "qiniu-js";
export default {
  data() {
    return {
      samples: [],
      types: [],
      domains: [],
      form: {},
      loading: false,
      showEdit: false,
      sample_id: "",
      imageUrl: "",
      currentProblemAudioList: [],
      currentAnswerAudioList: [],
      currentNoteAudioList: [],
      audioLoading: false
    };
  },
  mounted() {
    // this.getCorrectionSampleTypes()
    this.getCorrectionDomains();
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.loading = true;
      getCorrectionSamples().then(res => {
        this.samples = res.data;
        this.loading = false;
      });
    },
    addSample() {
      this.form = {};
      this.imageUrl = "";
      this.sample_id = "";
      this.showEdit = true;
    },

    updateCorrectionSample() {
      updateCorrectionSample(
        this.sample_id,
        this.form.problem,
        this.form.answer,
        this.form.type,
        this.form.note,
        this.form.domain,
        this.imageUrl,
        this.form.expression_type,
        this.currentProblemAudioList,
        this.currentAnswerAudioList,
        this.currentNoteAudioList,
        this.form.train_mode
      ).then(res => {
        this.fetchData();
        this.showEdit = false;
      });
    },
    view(data) {
      this.form = JSON.parse(JSON.stringify(data));
      this.getCorrectionSampleTypes();

      if (data.pic_url) {
        this.imageUrl = data.pic_url;
      } else {
        this.imageUrl = "";
      }

      if (data.problem_audio_url_list) {
        this.currentProblemAudioList = data.problem_audio_url_list;
      } else {
        this.currentProblemAudioList = [];
      }

      if (data.answer_audio_url_list) {
        this.currentAnswerAudioList = data.answer_audio_url_list;
      } else {
        this.currentAnswerAudioList = [];
      }

      if (data.note_audio_url_list) {
        this.currentNoteAudioList = data.note_audio_url_list;
      } else {
        this.currentNoteAudioList = [];
      }

      this.sample_id = data._id.$id;
      this.showEdit = true;
    },
    getCorrectionSampleTypes() {
      getCorrectionSampleTypes(this.form.expression_type).then(res => {
        this.types = res.data;
      });
    },
    getCorrectionDomains() {
      getCorrectionDomains().then(res => {
        this.domains = res.data;
      });
    },
    remove(row) {
      this.$confirm("是否确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(res => {
        removeCorrectionSample(row._id.$id).then(res => {
          this.fetchData();
        });
      });
    },
    beforeAvatarUpload(file) {
      const param = new FormData();
      param.append("file", file);
      param.append("function", "uploadPic");

      uploadPic(param).then(res => {
        if (res.data.code === 0) {
          this.imageUrl = res.data.data;
        }
      });
    },
    async beforeUpload(file, type) {
      this.audioLoading = true;
      const param = new FormData();
      param.append("file", file);
      param.append("function", "uploadAudio");

      const result = await this.getQiniuToken();
      if (result.data.code === 0) {
        const token = result.data.data;
        this.observable = qiniu.upload(
          file,
          new Date().getTime() + file.name,
          token
        );
        const observer = {
          complete: res => {
            this[type].push({
              address: "http://mainbucket.reachable-edu.com/" + res.key,
              name: file.name
            });
            this.audioLoading = false;
          }
        };
        this.observable.subscribe(observer);
      } else {
      }
    },
    handleRemove(file, fileList, type) {
      this[type] = fileList.map(item => {
        return { name: item.name, address: item.address };
      });
    },
    async getQiniuToken() {
      return new Promise(resolve => {
        getQiniuToken().then(res => {
          resolve(res);
        });
      });
    }
  }
};
</script>

<style></style>
