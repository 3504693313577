<template>
    <div style="width: 100vw;height: 100vh;background-color: white;">
        <div>
            <div class="topContainer">
                <p style="font-weight: bold;font-size: 22px;color: #4a8af4;margin: 0px;">Reachable Email System</p>
                <div style="display: flex;justify-content: center;align-items: center;">
                    <el-input v-model="search_key" placeholder="Search email by email subject or email address"
                        @input="handleSearchEmailListener" style="width: 400px;"></el-input>
                    <el-button type="danger" style="margin-left: 20px;" size="small"
                        @click="fetchData">Refresh</el-button>
                    <el-button type="success" style="margin-left: 20px;" size="small" @click="createNewEmail">New
                        Email</el-button>
                </div>
            </div>
        </div>
        <div style="display: flex;width: 100%;">
            <el-tabs v-model="currentTab" tab-position="left" @tab-click="handleTabClick" style="width: 100px;">
                <el-tab-pane label="InBox" name="first">
                </el-tab-pane>
                <el-tab-pane label="OutBox" name="second">
                </el-tab-pane>

                <el-tab-pane label="Settings" name="third">
                </el-tab-pane>

                <el-tab-pane label="Stats" name="fourth">
                </el-tab-pane>
                <el-tab-pane label="MailSign" name="five">
                </el-tab-pane>
            </el-tabs>
            <EmailNav :type="'in'" :operators="employees" v-if="currentTab == 'first'" :emails="inList" ref="inNav"
                @handleInSelectMail="handleInSelectMail" class="content-height" style="width: 320px;">
            </EmailNav>
            <EmailNav :type="'out'" :operators="employees" v-if="currentTab == 'second'" :emails="outList" ref="outNav"
                @handleOutSelectMail="handleOutSelectMail" class="content-height" style="width: 320px;">
            </EmailNav>
            <EmailEditor v-if="currentTab == 'first' || currentTab == 'second'" :allProjects="allProjects"
                :emailSignList="emailSignList" :mail_id="select_mail_id" :emails="emails" ref="emailEditor"
                class="email-editor-width content-height" @showReplyUserDialog="doShowReplyUserDialog"
                @clientDatasCallback="showClientDatas" @refreshNavSingleEmail="refreshNavSingleEmail"
                @refreshEmails="refreshOutBox" @emailDeleted="handleEmailDeleted">
            </EmailEditor>
            <ClientDatasView v-if="showClientDataView == true" style="width: 400px;" :clientData="clientDatas">
            </ClientDatasView>
            <div v-if="currentTab == 'third'" style="flex: 1;">
                <EmailSettings @handleEmailsChanged="requestSenderEmails"></EmailSettings>
            </div>
            <div v-if="currentTab == 'five'" style="flex: 1;">
                <EmailSign @handleEmailSignChanged="requestEmailSigns"></EmailSign>
            </div>
        </div>

        <el-dialog :visible.sync="showReplyUserDialog" title="Assign Employee to Reply Email" width="45%">
            <div>
                <el-form>
                    <el-form-item label="Select Employee">
                        <el-select v-model="assignEmployeeInfo.employee_id" placeholder="Please select"
                            style="width: 400px;">
                            <el-option value="-1" label="Not Assigned"></el-option>
                            <el-option v-for="(user, index) in employees" :key="index"
                                :label="user.user_lastName + user.user_givenName" :value="user._id.$id"></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer" style="margin-top: 20px;">
                    <el-button @click="showReplyUserDialog = false">Cancel</el-button>
                    <el-button type="primary" @click="confirmSelectEmployee">Confirm</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import {
    getEmailSigns,
    assignMailToEmployeeReply,
    getFullTimeEmployees,
    getCompanyEmailsV1,
    getAllProjects,
    getEmailsByType
} from '../../api/assistant'
import EmailNav from '../../components/Email/nav.vue'
import EmailEditor from '../../components/Email/edit.vue'
import EmailSettings from '../../components/Email/settings.vue'
import EmailSign from '../../components/Email/sign.vue'
import ClientDatasView from '../../components/Email/client_datas.vue'
import { Loading } from 'element-ui';
import {
    getUserId
} from '../../utils/store'
export default ({
    name: 'index',
    components: {
        ClientDatasView,
        EmailNav, EmailEditor, EmailSettings, EmailSign
    },
    data() {
        return {
            emailSignList: [],
            allProjects: [],
            showClientDataView: false,
            clientDatas: {},
            search_key: '',
            select_mail_id: '',
            inList: [],
            outList: [],
            currentTab: 'first',
            employees: [],
            emails: [],
            showReplyUserDialog: false,
            assignEmployeeInfo: {
                mail_id: '',
                employee_id: ''
            }
        }
    },
    mounted() {
        this.fetchData()
        this.initDatas()
    },
    methods: {

        refreshNavSingleEmail(mail) {
            if (this.currentTab == 'first' || this.currentTab == 'second') {
                if (this.currentTab == 'first') {
                    if (this.$refs.inNav) {
                        this.$refs.inNav.refreshSingleMail(mail)
                    }
                } else {
                    if (this.$refs.outNav) {
                        this.$refs.outNav.refreshSingleMail(mail)
                    }
                }
            }
        },

        showClientDatas(clientDatas) {
            this.showClientDataView = true
            this.clientDatas = clientDatas
        },

        handleSearchEmailListener() {
            this.showClientDataView = false
            this.fetchData()
        },

        confirmSelectEmployee() {
            if (this.assignEmployeeInfo.employee_id == '') {
                this.$message.warning('Please select')
                return
            }
            this.showClientDataView = false
            assignMailToEmployeeReply(this.assignEmployeeInfo.mail_id, this.assignEmployeeInfo.employee_id).then((res) => {
                this.$message.success('Assigned success~')
                this.showReplyUserDialog = false
                this.select_mail_id = this.assignEmployeeInfo.mail_id
                this.$refs.inNav.saveFilterParams()
                this.fetchData()
            })
        },

        doShowReplyUserDialog(mail_id) {
            this.assignEmployeeInfo = {
                mail_id: mail_id,
                employee_id: ''
            }
            this.showReplyUserDialog = true
        },

        async requestEmailSigns() {
            let emailSignListResult = await getEmailSigns()
            this.emailSignList = emailSignListResult.data
        },
        async requestSenderEmails() {
            let companyEmailResult = await getCompanyEmailsV1()
            this.emails = companyEmailResult.data
        },

        async initDatas() {
            let employeeResult = await getFullTimeEmployees()
            this.employees = employeeResult.data.data

            let projectResult = await getAllProjects()
            this.allProjects = projectResult.data.data

            this.requestSenderEmails()
            this.requestEmailSigns()

        },

        handleEmailDeleted() {
            this.showClientDataView = false
            this.select_mail_id = ''
            this.fetchData()
        },

        refreshOutBox() {
            this.showClientDataView = false
            this.currentTab = 'second'
            this.$nextTick(() => {
                this.fetchData()
            })
        },

        handleTabClick(tab, event) {
            this.showClientDataView = false
            if (this.currentTab == 'first' || this.currentTab == 'second') {
                this.select_mail_id = ''
                if (this.currentTab == 'first') {
                    if (this.$refs.outNav) {
                        this.$refs.outNav.resetSelectMailIndex()
                    }
                } else {
                    if (this.$refs.inNav) {
                        this.$refs.inNav.resetSelectMailIndex()
                    }
                }
                this.fetchData()
            }
        },

        createNewEmail() {
            this.showClientDataView = false
            this.currentTab = 'second'
            this.fetchData(false)
            this.$nextTick(() => {
                this.$refs.emailEditor.createNewEmail()
            })
        },

        handleInSelectMail(mail) {
            // this.showClientDataView = false
            this.select_mail_id = mail._id.$id
            this.$nextTick(() => {
                this.$refs.emailEditor.showContentView()
            })
        },
        handleOutSelectMail(mail) {
            // this.showClientDataView = false
            this.select_mail_id = mail._id.$id
            this.$nextTick(() => {
                this.$refs.emailEditor.showContentView()
            })
        },
        fetchData(showLoading = true) {

            let type = 'out'
            if (this.currentTab == 'first') {
                type = 'in'
            }
            let loadingInstance = undefined
            if (showLoading) {
                loadingInstance = Loading.service({
                    text: 'Searching',
                    fullscreen: true
                })
            }
            console.log('loading  starting-----')

            getEmailsByType(type, getUserId(), this.search_key).then((res) => {

                if (loadingInstance) {
                    loadingInstance.close()
                }

                if (type == 'in') {
                    this.inList = res.data
                } else {
                    this.outList = res.data
                }
                console.log('loading  finished-----')

            })
        }
    }
})
</script>
<style scoped lang="scss">
::-webkit-scrollbar {
    display: none;
}

.email-editor-width {
    width: calc(100vw - 720px);
}

.content-height {
    height: calc(100vh - 60px);
}

.topContainer {
    justify-content: space-between;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f5f5f5;
    box-sizing: border-box;
    padding: 0px 30px;
    height: 60px;
    width: 100%;
}
</style>
