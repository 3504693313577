<template>
    <div>
        <el-button @click="$router.back(-1)" size="medium">返回</el-button>
        <el-form>
            <el-form-item label="内容名称">
                <el-input :readonly="isShow" v-model="content.content_info.content_name"></el-input>
            </el-form-item>
            <el-form-item label="内容级别">
                <!-- <el-input v-model="content.content_info.content_level"></el-input> -->
                <el-select :disabled="isShow" v-model="content.content_info.content_level">
                    <el-option value="1" label="1"></el-option>
                    <el-option value="2" label="2"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="内容类型">
                <!-- <el-input v-model="content.content_info.content_type"></el-input> -->
                <el-select :disabled="isShow" v-model="content.content_info.content_type">
                    <el-option value="HTML" label="HTML"></el-option>
                    <el-option value="VIDEO" label="VIDEO"></el-option>
                    <el-option value="AUDIO" label="AUDIO"></el-option>
                    <el-option value="SLIDES" label="SLIDES"></el-option>
                    <el-option value="PDF" label="PDF"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="内容提示">
                <el-input :readonly="isShow" v-model="content.content_info.content_instruction" type="textarea" :rows="6"></el-input>
            </el-form-item>
            <el-form-item label="内容长度">
                <el-input :readonly="isShow" v-model="content.content_info.content_length"></el-input>
            </el-form-item>
            <el-form-item label="音频地址">
                <el-input :readonly="isShow" v-model="content.content_info.audio_url"></el-input>
            </el-form-item>
            <el-form-item label="视频地址">
                <el-input :readonly="isShow" v-model="content.content_info.video_url"></el-input>
            </el-form-item>
            <el-form-item label="PDF地址">
                <el-input :readonly="isShow" v-model="content.content_info.pdf_url"></el-input>
            </el-form-item>
            <tinymce :height="200" v-model="content.content_info.html_text"/>

        </el-form>
        <!-- <el-button @click="submit" type="primary" style="margin-top:30px;margin-bottom:30px">提交</el-button> -->
    </div>
</template>

<script>
import Tinymce from '../components/Tinymce' 
import { getContent,updateContent } from '../api/assistant'
export default {
    components: {
        tinymce: Tinymce
    },
    data(){
        return{
            id:'',
            isShow: true,
            content:{
                content_info:{
                    
                }
            }
        }
    },
    mounted(){
        this.id = this.$route.query.id
        this.fetchData()
    },
    methods:{
        fetchData(){
            getContent(this.id).then(res=>{
                if(res.data){
                    this.content = res.data
                }
            })
        },
        submit(){
            updateContent(this.id,this.content.content_info.content_name,this.content.content_info.content_level,this.content.content_info.content_type,this.content.content_info.content_instruction,this.content.content_info.content_length,this.content.content_info.audio_url,this.content.content_info.video_url,this.content.content_info.pdf_url,this.content.content_info.html_text).then(res=>{
                this.$message({
                    message:'更新成功',
                    type:'success'
                })
            })
        }
    }
}
</script>

<style>

</style>