<template>
    <div>
        <div class="title" style="font-size:18px;line-height:40px;">查看下属</div>
        <el-table :data="checkTaskList">
            <el-table-column prop="creater" label="姓名" >
                <template slot-scope="scope">
                    {{scope.row.user_lastName}}{{scope.row.user_givenName}}
                </template>
            </el-table-column>
            <el-table-column label="详情">
                <template slot-scope="scope">
                    <el-button @click="handleEdit(scope.row)" type="primary" size="small">
                        新添加评语
                    </el-button>
                    <el-button @click="handleList(scope.row)" type="primary" size="small">
                        评语列表
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <!-- 新增弹框 -->
        <el-dialog :title="title" :visible.sync="editFormVisible" width="50%" @click="closeDialog">
            <el-form :model="editForm"  ref="editForm">
                <el-form-item label="下属名称" >
                  <div>{{currentUser.user_lastName+currentUser.user_givenName}}</div>
                </el-form-item>
                <el-form-item label="评价类型">
                  <el-select v-model="editForm.type">
                    <el-option label="需要提升" value="需要提升"></el-option>
                    <el-option label="做得不错" value="做得不错"></el-option>
                    <el-option label="一般批评" value="一般批评"></el-option>
                    <el-option label="严厉批评" value="严厉批评"></el-option>
                    <el-option label="轻微消极工作行为" value="轻微消极工作行为"></el-option>
                    <el-option label="严重消极工作行为" value="严重消极工作行为"></el-option>

                  </el-select>                
                </el-form-item>
                <el-form-item label="评价内容（不要输入英文引号，不然邮件发不出去）">
                  <el-input type="textarea" :rows="6" v-model="editForm.content" auto-complete="off" ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <!-- <el-button size="small" @click="closeDialog">取消</el-button> -->
                <el-button size="small" type="primary"  class="title" @click="submitForm">提交</el-button>
            </div>
        </el-dialog>
        <!-- 列表弹框 -->
        <el-dialog title="评语列表" :visible.sync="listFormVisible" width="60%" @click="closeDialogList">
            <el-table :data="feedbacksList" v-loading="loading">
              <el-table-column prop="type" label="评语类型" width="150"></el-table-column>
              <el-table-column prop="reviewer_name" label="评论人" width="150"></el-table-column>
              <el-table-column prop="content" label="评论内容"></el-table-column>
          </el-table>
        </el-dialog>
    </div>
</template>

<script>
import {getTeammembers, updateFeedback, getFeedbacks} from '../../api/assistant.js'
import { getUserId } from '../../utils/store'
export default {
  data () {
    return {
      checkTaskList: [],
      name: '',
      note: '',
      id: '',
      type: 'my',
      editFormVisible: false,
      title: '工作反馈信息',
      editForm: {},
      listFormVisible:false,
      istForm: [],
      currentUser:{},
      feedbacksList:[],
      loading:false
    }
  },
  mounted () {
    this.$nextTick(()=>{
      this.getTeammembers()
    })
  },
  methods: {
    getTeammembers () {
      const my_id = getUserId()
      getTeammembers(my_id).then(res => {
        console.log(res)
        if (res.data.code === 0) {
          this.checkTaskList = res.data.data
        }
      })
    },
    //显示新增界面
    handleEdit(data) {
        this.currentUser = data
        this.editForm = {}
        this.editFormVisible = true
        this.title = '工作反馈信息'
    },
    //显示列表界面
    handleList(data){
      this.listFormVisible = true
      this.loading=true
      getFeedbacks(data._id.$id).then(res=>{
        if(res.data.code===0){
          this.feedbacksList = res.data.data
          this.loading=false
        }
      })

    },
    // 编辑、增加页面保存方法
    submitForm() {
      const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
      updateFeedback('',
          this.currentUser._id.$id,
          getUserId(),
          this.editForm.type,
          this.editForm.content)
      .then(res=>{
        console.log(res)
        this.editFormVisible = false   
        loading.close() 
        this.$message({
            message:'评论成功',
            type:'success'
        })
        this.getTeammembers()
      })
    },
    // 关闭编辑、增加弹出框
    closeDialog() {
    this.editFormVisible = false
    },
    // 关闭编辑、列表出框
    closeDialogList() {
    this.listFormVisible = false
    },
  }
}
</script>

<style scoped>
.line{
  margin: 0 6px;
}
</style>
