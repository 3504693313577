<template>
    <div>
        <div style="margin:40px 0">
            <el-table :data="classlist">
                <el-table-column label="编号">
                    <template slot-scope="scope"> 
                        {{scope.$index+1}}
                    </template>
                </el-table-column>
                <el-table-column label="所属BU" prop="class_info.class_BU"></el-table-column>
                <el-table-column label="课程名称" prop="class_info.class_name"></el-table-column>
                <el-table-column label="编辑者" prop="class_info.class_editor_names"></el-table-column>
                <el-table-column label="查看" width="180">
                    <template slot-scope="scope">
                        <el-button @click="viewClass(scope.row)" type="primary">查看</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
import {
  getEmail,
  getUserId,
  removeUserId,
  removeUserName,
  getUserName
} from '../utils/store'
import {getMyassignedClasses} from '../api/assistant'
export default {
    data(){
        return{
            classlist:[]
        }
    },
    mounted(){
        this.getUserList()
    },
    methods:{
        getUserList(){
            const userId = getUserId()
            console.log('this.getUserId---'+userId)
            getMyassignedClasses(userId).then(res=>{
                this.classlist = res.data.data
            })
        },
        viewClass(row){
            this.$router.push({
                path:'classinfouser',
                query:{
                    id:row._id.$id
                }
            })
        }
    }
}
</script>

<style scoped>
.add-subject{
    margin: 20px 0;
}
</style>
