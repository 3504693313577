<template>
    <div class="container">
        <div style="padding-left:10px">
            <p>模板列表</p>
            <el-button type="primary" @click="add" size="small">添加</el-button>
            <el-table :data="workshopTemplates" v-loading="loading">
                <el-table-column label="名字" prop="name"></el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button type="primary" size="small" @click="view(scope.row._id.$id)">查看</el-button>
                        <el-button type="danger" size="small" @click="removeWorkshopTemplate(scope.row._id.$id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>


<script>
import {getWorkshopTemplates,removeWorkshopTemplate} from '../../api/assistant'
export default {
    data(){
        return{
            workshopTemplates:[],
            loading:false,
        }
    },
    mounted(){
        this.fetchData()
    },
    methods:{
        fetchData(){
            this.loading = true
            getWorkshopTemplates().then(res=>{
                console.log(res)
                if(res.data.code===0){
                    this.workshopTemplates=res.data.data
                    this.loading=false
                }
            })
        },
        add(){
            this.$router.push({
                path:'workshopTemplateInfo',
                query:{
                    id:''
                }
            })
        },
        view(id){
            this.$router.push({
                path:'workshopTemplateInfo',
                query:{
                    id:id
                }
            })
        },
        removeWorkshopTemplate(id){
            this.$confirm('是否确认删除?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(res=>{
                removeWorkshopTemplate(id).then(res=>{
                    this.fetchData()
                })
            })
        }
    }
}
</script>

<style>
.container{
    width: 100%;
}
</style>