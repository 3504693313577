import Cookies from 'js-cookie'

const USER_ID = 'user_id'
const USER_NAME = 'user_name'

export function setUserId (user_id) {
  Cookies.set(USER_ID, user_id)
}
export function getUserId () {
  return Cookies.get(USER_ID)
}
export function removeUserId () {
  Cookies.remove(USER_ID)
}
export function setEmail (user_email) {
  Cookies.set('user_email', user_email)
}
export function getEmail () {
  return Cookies.get('user_email')
}
export function removeEmail () {
  Cookies.remove('user_email')
}
export function setUserName (user_name) {
  Cookies.set(USER_NAME, user_name)
}
export function getUserName () {
  return Cookies.get(USER_NAME)
}
export function removeUserName () {
  Cookies.remove(USER_NAME)
}
export function setBlock (block) {
  Cookies.set('block', block)
}
export function getBlock () {
  return Cookies.get('block')
}
export function removeBlock () {
  Cookies.remove('block')
}
export function setAvatar (avatar) {
  return Cookies.set('avatar', avatar)
}
export function getAvatar () {
  return Cookies.get('avatar')
}
export function removeAvatar () {
  Cookies.remove('avatar')
}
