<template>
    <div>
        <div class="title" style="font-size:18px;line-height:40px;">工作提问</div>
        <el-button @click="addCheckTask" type="primary" size="small">添加提问</el-button>
        <el-table :data="checkTaskList">
            <el-table-column prop="task" width="120" label="时间">
                <template slot-scope="scope">
                    {{formate(scope.row.create_time)}}
                </template>
            </el-table-column>
            <el-table-column prop="project_code" label="项目" width="160"></el-table-column>
            <el-table-column prop="title" label="标题"></el-table-column>
            <el-table-column prop="reply" label="回复"></el-table-column>
            <el-table-column prop="sender_name" label="提出人" width="100"></el-table-column>
            <el-table-column label="操作" width="100">
                <template slot-scope="scope">
                    <el-button :disabled="!(!scope.row.reply)" @click="getReply(scope.row)" type="primary" size="mini">回复</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-dialog :visible.sync="showEdit" title="添加提问">
            <p>项目</p>
            <el-input v-model="editadd.project_code"></el-input>
            <p>标题</p>
            <el-input v-model="editadd.title"></el-input>
            <div style="margin-top:20px">
                <el-button size="medium" type="primary" @click="add">确定</el-button>
            </div>
        </el-dialog>
        <el-dialog :visible.sync="replyShow" title="回复">
            <el-input type="textarea" v-model="replyVal"></el-input>

            <div style="margin-top:20px">
                <el-button size="medium" type="primary" @click="subReply">确定</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>
import { getIssues, updateIssue, replyIssue } from '../../api/assistant.js'
import { getUserId } from '../../utils/store'
import { formate } from '../../utils/date'
// import { formatDate } from '../utils/date'
export default {
  data () {
    return {
      checkTaskList: [],
      review_status: 'pending',
      newList: [],
      showEdit: false,
      date1: '',
      replyValId: '',
      replyVal: '',
      replyShow: false,
      editadd: {
        id: '',
        sender_id: '',
        project_code: '',
        title: ''
      }
    }
  },
  mounted () {
    this.getIssues()
  },
  methods: {
    getIssues () {
      getIssues().then(res => {
        console.log(res)
        if (res.data.code === 0) {
          this.checkTaskList = res.data.data
        }
      })
    },
    addCheckTask () {
      this.editadd.id = ''
      this.editadd.sender_id = getUserId()
      this.editadd.project_code = ''
      this.editadd.title = ''
      this.showEdit = true
    },
    add () {
      console.log(this.editadd)
      updateIssue(this.editadd.id, this.editadd.sender_id, this.editadd.project_code, this.editadd.title).then(res => {
        console.log(res)
        if (res.data.code === 0) {
          this.showEdit = false
          this.$message({
            message: '添加成功',
            type: 'success'
          })
          this.getIssues()
        }
      })
    },
    getReply (row) {
      console.log(row)
      this.replyValId = row._id.$id
      this.replyVal = ''
      this.replyShow = true
    },
    subReply () {
      console.log(this.replyVal)
      if (!this.replyVal) {
        this.$message({
          message: '请填写回复内容',
          type: 'errors'
        })
        return false
      }
      replyIssue(this.replyValId, this.replyVal).then(res => {
        console.log(res.data.code)
        if (res.data.code === 0) {
          this.replyShow = false
          this.$message({
            message: '回复成功',
            type: 'success'
          })
          this.getIssues()
        }
      })
    },
    toDetail (row) {
      this.$router.push({
        path: '/adminmanageuser/reviewdetail',
        query: {
          id: row._id.$id,
          type: 'reviewer'
        }
      })
    },
    formate (timestamp) {
      var date = new Date(timestamp * 1000)
      var y = date.getFullYear()
      var m = date.getMonth() + 1
      m = m < 10 ? '0' + m : m
      var d = date.getDate()
      d = d < 10 ? '0' + d : d
      var h = date.getHours()
      h = h < 10 ? '0' + h : h
      var minute = date.getMinutes()
      var second = date.getSeconds()
      minute = minute < 10 ? '0' + minute : minute
      second = second < 10 ? '0' + second : second
      var timeStr =
                y + '-' + m + '-' + d
      return timeStr
    },
    changeReviewStatus (row, data) {
      changeReviewStatus(row._id.$id, data).then(res => {
        console.log(res)
        if (res.data.code === 0) {
          this.$message({
            message: '更新成功！',
            type: 'success'
          })
          this.getMyCheckTasksToReview()
        }
      })
    }
  }
}
</script>

<style>

</style>
