<template>
    <div>
        <div style="display:flex;align-items:center">
            <div v-for="i in 4" :key="i" @mouseenter="disabled?'':curScore=i" @mouseleave="disabled?'':curScore=''" @click.stop="disabled?'':setScore(i)" style="display:flex;justify-content:center;align-items:center">
                <img :src="require('../../assets/'+getClass(i)+'.png')" style="width:25px;height:25px;margin-left:3px;cursor:pointer" />
            </div>
            <span v-if="showText" class="text">{{curScore||score}}分</span>
        </div>
    </div>
</template>

<script>
export default {
    name:'MyRate',
    props: {
        score: {
            type: Number,
            default: 0,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        showText: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            curScore: '',
            width:'',
        }
    },
    created() {
    //   this.getDecimal();
    },
    methods: {
        getClass(i) {
            if (this.curScore === '') {
                return i <= this.score ? 'award-on' : 'award-off'
            } else {
                return i <= this.curScore ? 'award-on' : 'award-off'
            }
        },
        getDecimal() {
            this.width=Number(this.score * 100 - Math.floor(this.score) * 100)+'%';
        },
        setScore(i){
            this.$emit('mark',i);//使用`.sync`修饰符，对score 进行“双向绑定
        },
        markGuide(e){
            this.todoInfo.level = e
            updateTodoLevel(this.todoInfo._id.$id,e).then(res=>{
                this.refreshTODOList('finishTaskChanged')
            })
        }

    }
}
</script>
<style scoped>
.text{
    color: #444444;
    margin-left: 8px;
}
</style>