<template>
  <div class="dataContainer">
    <el-button @click="add" type="primary" size="medium">新增</el-button>
    <el-table :data="tableData" style="margin-top: 20px">
      <el-table-column label="业务类型" prop="type"></el-table-column>
      <el-table-column label="法规名称" prop="title"></el-table-column>
      <el-table-column label="所属国家" prop="country"></el-table-column>
      <el-table-column label="操作" width="200">
        <template slot-scope="scope">
          <el-button @click="edit(scope.row)" type="primary" size="small"
            >编辑</el-button
          >
          <el-button @click="remove(scope.row)" type="danger" size="small"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      title="编辑法规"
      :visible.sync="showAdd"
      :close-on-click-modal="false"
    >
      <el-form class="myEditForm">
        <el-form-item label="业务类型">
          <el-select v-model="form.type">
            <el-option
              v-for="item in typeList"
              :key="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="法规名称">
          <el-input v-model="form.title"></el-input>
        </el-form-item>
        <el-form-item label="法规内容">
          <quill-editor
            class="editor"
            style="margin-top: 40px; margin-bottom: 20px"
            ref="myTextEditor"
            v-model="form.content"
            :options="editorOption"
          >
          </quill-editor>
        </el-form-item>
        <el-form-item label="所属国家">
          <el-select v-model="form.country">
            <el-option
              v-for="item in countryList"
              :key="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template slot="footer">
        <el-button
          type="primary"
          size="medium"
          @click="confirm"
          v-loading="loading"
          >确认</el-button
        >
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { getLaws, updateLaw, removeLaw } from "../api/assistant";
export default {
  data() {
    return {
      tableData: [],
      form: {},
      typeList: ["餐饮", "游戏", "人力资源服务", "教育", "文化", "电商"],
      countryList: ["中国", "新加坡", "美国"],
      showAdd: false,
      id: "",
      loading: false,
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
            ["blockquote", "code-block"], // 引用  代码块
            [{ header: 1 }, { header: 2 }], // 1、2 级标题
            [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
            [{ script: "sub" }, { script: "super" }], // 上标/下标
            [{ indent: "-1" }, { indent: "+1" }], // 缩进
            // [{'direction': 'rtl'}],                         // 文本方向
            [{ size: ["small", false, "large", "huge"] }], // 字体大小
            [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
            [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
            [{ font: [] }], // 字体种类
            [{ align: [] }], // 对齐方式
            ["clean"], // 清除文本格式
            ["link", "image", "video"], // 链接、图片、视频
          ], // 工具菜单栏配置
        },
        placeholder: "", // 提示
        readyOnly: false, // 是否只读
        theme: "snow", // 主题 snow/bubble
        syntax: true, // 语法检测,
      },
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      getLaws().then((res) => {
        if (res.data.code === 0) {
          this.tableData = res.data.data;
        }
      });
    },
    add() {
      this.showAdd = true;
      this.form = {
        title: "",
        type: "",
        country: "",
        content: "",
      };
      this.id = "";
    },
    edit(data) {
      this.form = {
        title: data.title ? data.title : "",
        type: data.type ? data.type : "",
        country: data.country ? data.country : "",
        content: data.content ? data.content : "",
      };
      this.id = data._id.$id;
      this.showAdd = true;
    },
    confirm() {
      this.loading = true;
      updateLaw({
        id: this.id,
        ...this.form,
      })
        .then((res) => {
          if (res.data.code === 0) {
            this.$message.success("更新成功");
            this.showAdd = false;
            this.fetchData();
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    remove(data) {
      this.$confirm("是否确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        removeLaw({ id: data._id.$id }).then((res) => {
          if (res.data.code === 0) {
            this.$message.success("删除成功");
            this.fetchData();
          }
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.dataContainer {
  padding: 30px;
}
</style>
<style>
.ql-container {
  height: 180px !important;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 140px;
  height: 140px;
  line-height: 140px !important;
  text-align: center;
}
.avatar {
  width: 140px;
  height: 140px;
  display: block;
}
</style>
