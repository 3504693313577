<template>
  <div style="padding: 0px 20px;box-sizing: border-box">

    <div style="text-align: right">
      <el-button type="primary" size="small" @click="doShowTimePointInfo(null)">添加时间点</el-button>
    </div>
    <div style="">
      <p>请选择筛选条件进行数据展示～</p>
      <div style="display: flex;flex-direction: row;align-items: center">
        <el-select placeholder="选择项目进行筛选" v-model="select_project_code" style="width: 300px"
                   @change="handleSelectProjectChanged">
          <el-option v-for="(project,index) in projects" :value="project.project_code" :lable="project.project_name"
                     :key="index"></el-option>
        </el-select>

        <p :class="point_time_type=='future_time'?'btn-normal btn-selected':'btn-normal'" @click="requestFutureTime">
          未来时间点</p>
        <p :class="point_time_type=='past_time'?'btn-normal btn-selected':'btn-normal'" @click="requestPastTime">
          过去时间点</p>

        <p :class="(point_time_type=='' && select_project_code=='')?'btn-normal btn-selected':'btn-normal'"
           @click="requestAll">全部</p>
      </div>

    </div>

    <el-table :data="dataList" style="margin-top: 20px">
      <el-table-column type="index" prop="index" label="序号"></el-table-column>
      <el-table-column prop="project_code" label="ProjectCode"></el-table-column>
      <el-table-column prop="project_name" label="项目名称"></el-table-column>
      <el-table-column prop="" label="日期" width="140px">
        <template slot-scope="scope">
          <div>
            {{ formatShowDate(scope.row.time_point_date) }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="content" label="内容"></el-table-column>
      <el-table-column prop="persist_days" label="持续天数" width="80px"></el-table-column>
      <el-table-column prop="" label="操作" width="120px">
        <template slot-scope="scope">
<!--          <el-button type="danger" size="small" @click="removeTimePoint(scope.row)">删除</el-button>-->
          <el-button type="primary" size="small" @click="doShowTimePointInfo(scope.row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog :visible.sync="showTimePointDialog" :title="timePointInfo.id==''?'添加时间点':'编辑时间点'">
      <div>
        <el-form>
          <el-form-item label="项目">
            <el-select v-model="timePointInfo.project_code" style="width: 400px">
              <el-option v-for="(project,index) in projects" :value="project.project_code" :lable="project.project_name"
                         :key="index"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="内容">
            <el-input v-model="timePointInfo.content" type="textarea" :rows="5" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="日期">
            <el-date-picker
                v-model="timePointInfo.time_point_date"
                type="date"
                value-format="timestamp"
                placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="持续天数">
            <el-input-number v-model="timePointInfo.persist_days" placeholder="请输入"></el-input-number>
          </el-form-item>
        </el-form>
        <div style="margin-top: 20px">
          <el-button type="primary" size="small" @click="saveInfo">保存</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {formatDate} from '../../utils/date'
import {Loading} from "element-ui"
import {
  getProjectTimePoints,
  getProjectsByProjectYear,
  updateProjectTimePoint,
  removeTimePoint
} from '../../api/assistant'
import {getUserId} from "../../utils/store";

export default {
  name: 'ProjectTimePoint',
  data() {
    return {
      select_project_code: '',
      point_time_type: '',
      projects: [],
      dataList: [],
      timePointInfo: {
        id: '',
        project_code: '',
        content: '',
        time_point_date: '',
        operator_user_id: getUserId(),
        persist_days: 0
      },
      showTimePointDialog: false
    }
  },
  mounted() {
    this.requestProjects()
    this.requestData()
  },
  methods: {

    removeTimePoint(row) {
      this.$alert('您确定要删除次项目时间点吗？', '提示', {
        confirmButtonText: '确定',
        callback: action => {
          if (action == 'confirm') {
            removeTimePoint(row._id.$id).then((res) => {
              this.$message.success('删除成功')
              this.requestData()
            })
          }
        }
      })
    },

    saveInfo() {
      if (this.timePointInfo.project_code == '') {
        this.$message.warning('请选择项目～')
        return
      }
      if (this.timePointInfo.content == '') {
        this.$message.warning('请输入内容～')
        return
      }
      if (this.timePointInfo.time_point_date == '') {
        this.$message.warning('请选择日期～')
        return
      }
      if (this.timePointInfo.persist_days <= 0) {
        this.$message.warning('请设置持续天数')
        return
      }
      let params = {
        id: this.timePointInfo.id,
        project_code: this.timePointInfo.project_code,
        content: this.timePointInfo.content,
        operator_user_id: this.timePointInfo.operator_user_id,
        time_point_date: this.timePointInfo.time_point_date / 1000,
        persist_days: this.timePointInfo.persist_days
      }
      updateProjectTimePoint(params).then((res) => {
        this.$message.success('保存成功')
        this.showTimePointDialog = false
        this.requestData()
      })
    },

    doShowTimePointInfo(row) {
      if (row) {
        this.timePointInfo.id = row._id.$id
        this.timePointInfo.project_code = row.project_code
        this.timePointInfo.content = row.content
        this.timePointInfo.time_point_date = row.time_point_date * 1000
        this.timePointInfo.persist_days = row.persist_days
      } else {
        this.timePointInfo.id = ''
        this.timePointInfo.project_code = ''
        this.timePointInfo.content = ''
        this.timePointInfo.time_point_date = ''
        this.timePointInfo.persist_days = 0
      }
      this.showTimePointDialog = true
    },

    requestFutureTime() {
      this.point_time_type = 'future_time'
      this.requestData()
    },
    requestPastTime() {
      this.point_time_type = 'past_time'
      this.requestData()
    },
    requestAll() {
      this.select_project_code = ''
      this.point_time_type = ''
      this.requestData()
    },

    requestProjects() {
      console.log('111')
      // 默认写死，后面可以修改
      getProjectsByProjectYear('22-23').then((res) => {
        this.projects = res.data
        let all = {
          project_code: '全部',
          project_name: '全部'
        }
        this.projects.unshift(all)
      })
    },

    formatShowDate(date) {
      return formatDate(new Date(date * 1000), 'yyyy年MM月dd日')
    },
    handleSelectProjectChanged(e) {
      if (this.select_project_code=='全部') {
        this.requestAll()
      } else {
        this.requestData()
      }
    },
    requestData() {
      const loadingInstance = Loading.service({
        fullscreen: true,
        text: 'Loading...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.8)'
      })
      getProjectTimePoints(this.select_project_code, this.point_time_type).then((res) => {
        loadingInstance.close()
        this.dataList = res.data
      })
    }
  },

}
</script>

<style scoped>

.btn-normal {
  cursor: pointer;
  padding: 0px;
  margin-left: 20px;
  text-align: center;
  width: 120px;
  height: 40px;
  line-height: 40px;
  color: #409EFF;
  background-color: white;
  border: 1px solid #409EFF;
  border-radius: 5px;
}

.btn-selected {
  cursor: pointer;
  color: white;
  background-color: #409EFF;
}

</style>
