<template>
    <div class="container">
        <span>模板名</span><el-input v-model="template.template_name" style="margin-left:20px"></el-input>
        <p>注：修改参数后请点击提交!</p>
        <div style="margin-top:20px">
            <el-button type="primary" size="small" @click="addParam">添加参数</el-button>
        </div>
        <el-table :data="template_parameters_array" size="medium" v-loading="loading">
            <el-table-column prop="key" label="参数中文"></el-table-column>
            <el-table-column prop="value" label="参数英文"></el-table-column>
            <el-table-column prop="required" label="是否必填"></el-table-column>
            <el-table-column prop="requiredNum" label="必填字数"></el-table-column>

            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button @click="editParam(scope.row,scope.$index)" type="primary" size="small">修改</el-button>
                    <el-button type="primary" size="small" @click="remove(scope.$index)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div style="margin-top:20px">
            <el-button @click="submit" type="primary">提交修改</el-button>
        </div>
        <el-dialog :visible.sync="showEdit">
            <el-form :model="param" :rules="rules">
                <el-form-item label="参数中文" prop="key">
                    <el-input v-model="param.key"></el-input>
                </el-form-item>
                <el-form-item label="参数英文(不要加入特殊符号)" prop="value">
                    <el-input v-model="param.value"></el-input>
                </el-form-item>
                <el-form-item label="是否必填" prop="value">
                    <el-select v-model="param.required">
                        <el-option value="YES" label="是"></el-option>
                        <el-option value="NO" label="否"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="必填字数" prop="value">
                    <el-input type="number" v-model="param.requiredNum"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button @click="edit" type="primary">确定</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import { getWorkLogTemplate, updateWorkLogTemplate } from '../../api/assistant'
export default {
  data () {
    return {
      id: this.$route.query.id,
      template: {

      },
      template_parameters_array: [],
      param: {
        key: '',
        value: '',
        required: '',
        requiredNum: ''
      },
      showEdit: false,
      rules: {
        key: [
          { required: true, message: '请输入参数中文', trigger: 'change' }
        ],
        value: [
          { required: true, message: '请输入参数英文', trigger: 'change' }
        ]
      },
      loading: false,
      index: 999
    }
  },
  created () {
    if (this.id) {
      this.fetchData()
    }
  },
  methods: {
    fetchData () {
      this.loading = true
      getWorkLogTemplate(this.id).then(res => {
        const template = res.data
        this.template = template
        const template_parameters_array = []
        for (const i in template.template_parameters_array) {
          const item = {}
          item.key = i
          item.value = template.template_parameters_array[i]

          if (template.template_required_array) {
            item.required = template.template_required_array[i].required
            item.requiredNum = template.template_required_array[i].requiredNum
          }

          template_parameters_array.push(item)
        }
        this.template_parameters_array = template_parameters_array
        this.loading = false
      })
    },
    addParam () {
      this.showEdit = true
      this.index = 999
      this.param = {
        key: '',
        value: ''
      }
    },
    editParam (row, index) {
      this.showEdit = true
      this.index = index
      this.param = JSON.parse(JSON.stringify(row))
    },
    submit () {
      console.log(this.template_parameters_array)
      const template_parameters_array = {}
      for (const item of this.template_parameters_array) {
        template_parameters_array[item.key] = item.value
      }

      const template_required_array = {}
      for (const item of this.template_parameters_array) {
        template_required_array[item.key] = item
      }

      console.log(template_parameters_array)
      console.log(template_required_array)

      updateWorkLogTemplate(this.id, this.template.template_name, template_parameters_array, template_required_array).then(res => {
        if (res.data.code === 0) {
          this.$message({
            message: '修改成功',
            type: 'success'
          })
          if (!this.id) {
            this.$router.back(-1)
          } else {
            this.fetchData()
          }
        }
      })
    },
    remove (index) {
      this.template_parameters_array.splice(index, 1)
    },
    edit () {
      if (this.param.key === '' || this.param.value === '') {
        this.$message.error('请完善表单')
        return
      }
      if (this.index !== 999) {
        this.$set(this.template_parameters_array, this.index, this.param)
      } else {
        this.template_parameters_array.push(this.param)
      }
      this.showEdit = false
    }
  }
}
</script>

<style scoped>
.container{
    width: 100%;
    color: #606266;
}
.el-input{
    width: 80%;
}
</style>
