<template>
<div>
    <div>
        <el-button @click="toUpdateMeetingNote" type="primary" style="margin-left:20px;margin-top:20px">添加会议记录</el-button>
        <div style="margin:20px">我的会议记录</div>
        <el-input v-model="searchTxt" placeholder="请输入要搜索的内容" @change="getMyMeetingNote"></el-input>
        <el-table :data="meetingList">
            <el-table-column prop="name" label="标题"></el-table-column>
            <el-table-column label="标签">
                <template slot-scope="scope">
                    <el-tag v-for="(item,index) in scope.row.tags" style="margin-left:6px;margin-bottom:6px" :key="index">{{item}}</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="发送对象">
                <template slot-scope="scope">
                    <el-tag v-for="(item,index) in scope.row.participant_emails_name" style="margin-left:6px;margin-bottom:6px" :key="index">
                        {{item?item.user_lastName+item.user_givenName:''}}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column label="创建时间">
                <template slot-scope="scope">
                    {{formate(scope.row.create_time)}}
                </template>
            </el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button @click="toDetail(scope.row)" type="primary" size="medium">
                        查看
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <div  style="margin-top:20px;margin-bottom: 20px; text-align: left;">
          <el-pagination
            @size-change="changeSize"
            @current-change="getMyMeetingNote"
            :current-page.sync="pageNum"
            :page-sizes="[10, 20, 50, 100]"
            :page-size.sync="pageSize"
            layout="sizes, prev, pager, next"
            :total="totalNum">
          </el-pagination>
        </div>
    </div>
</div>
</template>

<script>
import {
  getEmail,
  getUserId,
  removeUserId,
  removeUserName,
  getUserName
} from '../../utils/store'
import { getMyMeetingNote } from '../../api/assistant.js'

export default {
  data () {
    return {
      meetingList: [],
      pageSize: 10,
      pageNum: 1,
      totalNum: 0,
      searchTxt: ''
    }
  },
  mounted () {
    this.getMyMeetingNote()
  },
  methods: {
    toUpdateMeetingNote () {
      this.$router.push({
        path: '/updatemeetingnote',
        query: {
          id: ''
        }
      })
    },
    toDetail (row) {
      this.$router.push({
        path: '/updatemeetingnote',
        query: {
          id: row._id.$id
        }
      })
    },
    changeSize (e) {
      this.pageSize = e
      this.getMyMeetingNote()
    },
    getMyMeetingNote () {
      const email = getEmail()
      console.log(email)
      getMyMeetingNote({ my_email: email, pageSize: this.pageSize, pageNum: this.pageNum, searchTxt: this.searchTxt }).then(res => {
        console.log(res)
        if (res.data.code === 0) {
          this.meetingList = res.data.data.list
          this.totalNum = res.data.data.total
        }
      })
    },
    formate (timestamp) {
      var date = new Date(timestamp * 1000)
      var y = date.getFullYear()
      var m = date.getMonth() + 1
      m = m < 10 ? '0' + m : m
      var d = date.getDate()
      d = d < 10 ? '0' + d : d
      var h = date.getHours()
      h = h < 10 ? '0' + h : h
      var minute = date.getMinutes()
      var second = date.getSeconds()
      minute = minute < 10 ? '0' + minute : minute
      second = second < 10 ? '0' + second : second
      var timeStr =
                y + '-' + m + '-' + d + '　' + h + ':' + minute + ':' + second
      return timeStr
    },
    logout () {
      removeUserId()
      removeUserName()
      location.reload()
    }
  }
}
</script>

<style>

</style>
