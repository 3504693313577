<template>
  <div style="width:100%">
    <div style="padding:20px">
      <el-button type="primary" @click="initAdd">添加模板</el-button>
      
      <el-table :data="templates">
        <el-table-column label="模板名" prop="template_name" width="300"></el-table-column>
        <el-table-column label="主题" prop="test_subject"></el-table-column>
        <el-table-column label="所属业务" prop="test_BU"></el-table-column>
        <el-table-column label="类型" prop="test_type"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="primary" @click="edit(scope.row._id.$id)" size="small">编辑</el-button>
            <el-button type="danger" @click="remove(scope.row._id.$id)" size="small">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      title="编辑模板"
      :close-on-click-modal="false"
      width="60%"
      @open="openDialog"
      @close="closeDialog"
      :visible.sync="showTemplate"
    >
      <el-form
        class="block-form"
        :model="diaForm"
        ref="innerdialogForm"
        :rules="rules"
        status-icon
        label-width="100px"
        size="medium"
      >
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="模板名" prop="template_name">
              <el-input placeholder="请输入模板名" type="text" v-model="diaForm.template_name"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="试卷所属业务" prop="test_BU">
              <el-select class="w140" v-model="diaForm.test_BU" placeholder="请选择试卷所属业务" clearable>
                <el-option
                  v-for="item in BUOptions"
                  :key="item.name"
                  :label="item.name"
                  :value="item.name"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="试卷类型" prop="test_type">
              <el-select class="w140" v-model="diaForm.test_type" placeholder="请选择试卷类型" clearable>
                <el-option
                  v-for="item in typeOptions"
                  :key="item.name"
                  :label="item.name"
                  :value="item.name"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="主题" prop="test_subject">
              <el-input placeholder="请填写主题" type="text" v-model="diaForm.test_subject"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="介绍" prop="template_instruction">
              <el-input placeholder="请填写介绍" type="text" v-model="diaForm.template_instruction"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="时长" prop="test_duration">
              <el-input placeholder="请填写时长" type="text" v-model="diaForm.test_duration"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="考试时间" prop="test_time">
              <el-input placeholder="请填写时间" type="text" v-model="diaForm.test_time"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="参考资料" prop="test_refer">
              <el-input type="textarea" :rows="6" placeholder="请填写资料" v-model="diaForm.test_refer"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="createExerciseTemplate('diaForm')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  createExerciseTemplate,
  removeExerciseTemplate,
  getExerciseTemplates
} from "../../api/assistant";
export default {
  data() {
    return {
      templates: [],
      diaForm: {},
      rules:{},
      showTemplate: false,
      BUOptions: [
        {
          name: "ETA"
        },
        {
          name: "FBLA"
        }
      ],
      typeOptions: [
        {
          name: " formal_test"
        },
        {
          name: "mock_test"
        },
        {
          name: "sample_test"
        },
        {
          name: "special_exercise"
        }
      ]
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      getExerciseTemplates().then(res => {
        if (res.data.code === 0) {
          this.templates = res.data.data;
        }
      });
    },
    initAdd() {
      this.name = "";
      this.subject = "";
      this.showTemplate = true;
    },
    createExerciseTemplate() {
      createExerciseTemplate('',this.diaForm.template_name,this.diaForm.test_subject,this.diaForm.template_instruction,this.diaForm.test_duration,this.diaForm.test_type,this.diaForm.test_BU,this.diaForm.test_time,this.diaForm.test_refer).then(res => {
        if (res.data.code === 0) {
          this.$message({
            message: "创建成功",
            type: "success"
          });
          this.showTemplate = false;
          this.fetchData();
        }
      });
    },
    edit(id) {
      this.$router.push({
        path: "/templateInfo",
        query: {
          template_id: id
        }
      });
    },
    openDialog() {
      //打开弹框

      this.$nextTick(() => {
        if (this.$refs.diaForm) {
          this.$refs.diaForm.clearValidate();
        }
      });
    },
    closeDialog() {
      this.diaForm = {};
    },
    remove(id) {
      this.$confirm("是否确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(res => {
        removeExerciseTemplate(id).then(res => {
          if (res.data.code === 0) {
            this.$message("删除成功");
            this.fetchData();
          }
        });
      });
    }
  }
};
</script>

<style scoped>

</style>