<template>
  <el-dialog :visible.sync="visible" :title="`群成员(${info.contact_ids.length + info.object_ids.length})`" width="600px" @close="onClose">
    <div>
      <div>
        <p style="margin-left: 16px">My Team({{ groupInfo.contact_info_arr.length }})</p>
        <div class="service-list">
          <div v-for="(user, index) in groupInfo.contact_info_arr" :key="index" class="item">
            <el-avatar shape="square" :size="48" :src="user.headPicUrl || squareUrl"></el-avatar>
            <div class="name">{{ user.contact_enName || (user.contact_lastName + user.contact_givenName) }}</div>
          </div>
        </div>
      </div>
      <div>
        <p style="margin-left: 16px">Service Representatives({{ currentStaff.length }})</p>
        <div class="service-list">
          <div v-for="(user, index) in currentStaff" :key="index" class="item">
            <el-avatar shape="square" :size="48" :src="user.headPicUrl || squareUrl"></el-avatar>
            <div class="name">{{ user.user_lastName + user.user_givenName }}</div>
          </div>
          <div class="item" @click="innerVisible = true">
            <img src="../../assets/addUser.png" style="width: 48px; height: 48px" />
            <div class="name">添加成员</div>
          </div>
          <div class="item" @click="removeStaffVisible = true">
            <img src="../../assets/removeUser.png" style="width: 48px; height: 48px" />
            <div class="name">移除成员</div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      width="600px"
      title="成员信息"
      :visible.sync="innerVisible"
      @close="onStaffClose"
      append-to-body>
      <el-form ref="staffForm" :rules="staffRules" :model="staffForm" label-width="200px">
        <el-form-item label="成员信息" prop="user_id">
          <el-select v-model="staffForm.user_id" filterable placeholder="请选择">
            <el-option
              v-for="item in staffList"
              :key="item._id.$id"
              :label="item.user_lastName + item.user_givenName"
              :value="item._id.$id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onAdd">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      width="600px"
      title="成员信息"
      :visible.sync="removeStaffVisible"
      @close="onStaffClose"
      append-to-body>
      <el-form ref="staffForm" :rules="staffRules" :model="staffForm" label-width="200px">
        <el-form-item label="成员信息" prop="user_id">
          <el-select v-model="staffForm.user_id" filterable placeholder="请选择">
            <el-option
              v-for="item in currentStaff"
              :key="item._id.$id"
              :label="item.user_lastName + item.user_givenName"
              :value="item._id.$id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onRemove">确 定</el-button>
      </div>
    </el-dialog>
  </el-dialog>
</template>

<script>
import { getReachChatObjects, joinReachGroup, removeContactReachGroup, getContactReachGroupDetail } from '../../api/assistant'
export default {
  name: 'GroupInfo',
  props: {
    visible: Boolean,
    info: Object
  },
  mounted () {
    this.getChatObjects()
    this.getGroupDetail()
  },
  watch: {
    info () {
      this.getGroupDetail()
    }
  },
  data () {
    return {
      staffList: [],
      squareUrl: 'http://mainbucket.reachable-edu.com/hEaIyvBOHDTV0cd6468c61f7992cd9ef0aa688424c55.jpeg',
      innerVisible: false,
      removeStaffVisible: false,
      staffForm: {
        user_id: ''
      },
      staffRules: {
        user_id: [
          { required: true, message: '请选择员工', trigger: 'change' }
        ]
      },
      groupInfo: {}
    }
  },
  computed: {
    currentStaff () {
      return this.staffList.filter(item => this.info.object_ids.includes(item._id.$id))
    }
  },
  methods: {
    async getChatObjects () {
      try {
        const res = await getReachChatObjects({})
        this.staffList = res.data.data
      } catch (error) {
        console.log(error)
      }
    },
    async getGroupDetail () {
      try {
        const res = await getContactReachGroupDetail({
          group_id: this.info._id.$id
        })
        this.groupInfo = res.data.data
      } catch (error) {
        console.log(error)
      }
    },
    onStaffClose () {
      this.staffForm = {
        user_id: ''
      }
    },
    onClose () {
      this.$emit('close')
    },
    onAdd () {
      this.$refs.staffForm.validate(async (valid) => {
        if (valid) {
          const res = await joinReachGroup({
            user_id: this.staffForm.user_id,
            group_id: this.info._id.$id,
            join_type: 'web'
          })
          if (res.data.code === 0) {
            this.staffForm = {
              user_id: ''
            }
            this.innerVisible = false
            this.$message.success('添加成功')
            this.$emit('success')
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    onRemove () {
      this.$refs.staffForm.validate(async (valid) => {
        if (valid) {
          const res = await removeContactReachGroup({
            user_id: this.staffForm.user_id,
            group_id: this.info._id.$id,
            join_type: 'web'
          })
          if (res.data.code === 0) {
            this.staffForm = {
              user_id: ''
            }
            this.removeStaffVisible = false
            this.$message.success('移除成功')
            this.$emit('success')
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.service-list {
  display: flex;
  flex-wrap: wrap;
  .icon {
    cursor: pointer;
  }
  .item {
    margin: 10px 16px;
    cursor: pointer;
    .name {
      text-align: center;
      color: #666;
      font-size: 12px;
      margin-top: 8px;
    }
  }
}
</style>
