<template>
    <div>
        <el-button @click="addType" type="primary" size="medium">添加类别</el-button>
        <div style="margin-top:20px;margin-bottom:20px;font-family:'Microsoft YaHei'">审核类型</div>
        <el-table :data="typeList">
            <el-table-column prop="name" label="标题"></el-table-column>
            <el-table-column label="标签">
                <template slot-scope="scope">
                    <el-tag v-for="(item,index) in scope.row.tags" :key="index" style="margin-right:10px">{{item}}</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button @click="updateType(scope.row)" type="primary" size="mini">修改</el-button>
                    <el-button @click="view(scope.row)" type="primary" size="mini" >查看</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-dialog @close="closeDialog" :visible.sync="showEdit"  title="修改类别信息" width="600px">
            <p>标题</p>
            <el-input v-model="name"></el-input>
            <p>标签</p>
            <!-- <vue-tags-input
                    v-model="tag"
                    :tags="tags"
                    @tags-changed="newTags => tags = newTags"
            /> -->
            <el-tag
  :key="tag"
  v-for="tag in dynamicTags"
  closable
  :disable-transitions="false"
  @close="handleClose(tag)">
  {{tag}}
</el-tag>
<el-input
  class="input-new-tag"
  v-if="inputVisible"
  v-model="inputValue"
  ref="saveTagInput"
  size="small"
  @keyup.enter.native="handleInputConfirm"
  @blur="handleInputConfirm"
>
</el-input>
<el-button v-else class="button-new-tag" size="small" @click="showInput">+ New Tag</el-button>
<div>
  <el-button size="medium" type="primary" style="margin-top:20px" @click="updateCheckType">确定</el-button>
</div>

        </el-dialog>
    </div>
</template>

<script>
import VueTagsInput from '@johmun/vue-tags-input'
import { updateCheckType, getCheckTypes } from '../api/assistant.js'
export default {
  components: {
    VueTagsInput
  },
  data () {
    return {
      inputVisible: false,
      typeList: [],
      tag: '',
      dynamicTags: [],
      currentRow: '',
      showEdit: false,
      name: '',
      inputValue: '',
      id: ''
    }
  },
  mounted () {
    this.getCheckTypes()
  },
  methods: {
    closeDialog () {
      this.dynamicTags = []
      this.name = ''
    },
    handleClose (tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1)
    },

    showInput () {
      this.inputVisible = true
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus()
      })
    },

    handleInputConfirm () {
      const inputValue = this.inputValue
      if (inputValue) {
        this.dynamicTags.push(inputValue)
      }
      this.inputVisible = false
      this.inputValue = ''
    },
    getCheckTypes () {
      getCheckTypes().then(res => {
        if (res.data.code === 0) {
          this.typeList = res.data.data
        }
      })
    },
    updateCheckType () {
      const tempTags = []
      for (const tag in this.dynamicTags) {
        tempTags.push(this.dynamicTags[tag])
      }
      updateCheckType(this.id, this.name, tempTags).then(res => {
        console.log(res)
        if (res.data.code === 0) {
          this.$message({
            message: '更新成功',
            type: 'success'
          })
          this.showEdit = false
          this.getCheckTypes()
        }
      })
    },
    addType () {
      this.name = ''
      this.dynamicTags = []
      this.tag = ''
      this.id = ''
      this.showEdit = true
    },
    updateType (row) {
      this.currentRow = row
      this.name = row.name
      this.id = row._id.$id
      const song_tags = row.tags
      for (var index in song_tags) {
        if (song_tags[index] !== null && song_tags[index] !== 'null') {
          this.dynamicTags.push(song_tags[index])
        }
      }
      this.showEdit = true
    },
    view (row) {
      this.$router.push({
        path: '/adminmanageuser/checklist',
        query: {
          type_id: row._id.$id
        }
      })
    }
  }
}
</script>

<style>

</style>
