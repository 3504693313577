<template>
    <div>
        <div style="margin-top:20px;margin-bottom:20px;font-family:'Microsoft YaHei'">我的待审核列表</div>
        <!-- <p>审核状态</p> -->
        <!-- <el-tag style="margin-left:10px;cursor:pointer" :effect="review_status==='pending'?'dark':'light'"  @click="changeStatus('pending')">审核中</el-tag>
        <el-tag style="margin-left:10px;cursor:pointer" :effect="review_status==='approved'?'dark':'light'"  @click="changeStatus('approved')">已通过</el-tag>
        <el-tag style="margin-left:10px;cursor:pointer" :effect="review_status==='reject'?'dark':'light'"  @click="changeStatus('reject')">已拒绝</el-tag> -->
        <el-table :data="reviewsList">
            <!-- <el-table-column  label="审查状态">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.review_status==='approved'" effect="dark" type="success" size="medium">通过</el-tag>
                    <el-tag v-if="scope.row.review_status==='pending'" effect="dark" type="warning" size="medium">审核中</el-tag>
                    <el-tag v-if="scope.row.review_status==='reject'" effect="dark" type="danger" size="medium">拒绝</el-tag>
                </template>
            </el-table-column> -->

            <el-table-column prop="name" label="任务名称"></el-table-column>
            <el-table-column prop="note" label="笔记"></el-table-column>
            <!-- <el-table-column prop="checklist" label="审核清单"></el-table-column>
            <el-table-column prop="creater" label="创建者"></el-table-column> -->
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button @click="toDetail(scope.row)" type="text" size="mini">查看</el-button>
                    <el-button @click="changeReviewerStatus(scope.row,'approved')" type="text" size="mini">通过</el-button>
                    <el-button @click="changeReviewerStatus(scope.row,'reject')" type="text" size="mini">拒绝</el-button>
                </template>
            </el-table-column>
        </el-table>

    </div>
</template>

<script>
import { changeReviewStatus, changeReviewerStatus, getMyCheckTasksToReview } from '../api/assistant'
import { getUserId } from '../utils/store'
export default {
  data () {
    return {
      reviewsList: [],
      review_status: 'pending',
      newList: []
    }
  },
  mounted () {
    this.getMyCheckTasksToReview()
  },
  methods: {
    getMyCheckTasksToReview () {
      const my_id = getUserId()
      getMyCheckTasksToReview(my_id, 'false').then(res => {
        if (res.data.code === 0) {
          this.reviewsList = res.data.data
        }
      })
    },
    toDetail (row) {
      this.$router.push({
        path: '/adminmanageuser/reviewdetail',
        query: {
          task_id: row._id.$id,
          type_id: row.type_id,
          type: 'reviewer'
        }
      })
    },
    changeReviewerStatus (row, data) {
      const param = {
        reviewer_id: getUserId(),
        task_id: row._id.$id,
        status: data
      }
      changeReviewerStatus(param).then(res => {
        console.log(res)
        if (res.data.code === 0) {
          this.$message({
            message: '更新成功！',
            type: 'success'
          })
          this.getMyCheckTasksToReview()
        }
      })
    }

  }
}
</script>

<style>

</style>
