<template>
    <div class="container">
        <div style="position:absolute;cursor:pointer;font-size:18px" @click="$router.back(-1)"><i class="el-icon-arrow-left"></i></div>
        <div style="padding:20px">
            <p>名字</p>
            <el-input v-model="editForm.name"></el-input>
            <p>介绍</p>
            <el-input v-model="editForm.intro"></el-input>
            <p>模板名</p>
            <el-select v-model="editForm.template_id">
                <el-option :label="item.name" :value="item._id.$id" v-for="(item,index) in templates" :key="index"></el-option>
            </el-select>
            <div style="margin-top:10px">
                <el-button type="primary" @click="submit">提交</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import {getWorkshop,updateWorkshop,getWorkshopTemplates} from '../../api/assistant'
export default {
    data(){
        return{
            id:'',
            editForm:{
                name:'',
                intro:'',
                template_name:''
            },
            templates:[]
        }
    },
    mounted(){
        this.id = this.$route.query.id
        this.getWorkshopTemplates()
        if(this.id){
            this.fetchData()
        }
    },
    methods:{
        fetchData(){
            getWorkshop(this.id).then(res=>{
                if(res.data.code===0){
                    this.editForm=res.data.data
                }
            })
        },
        getWorkshopTemplates(){
            getWorkshopTemplates().then(res=>{
                if(res.data.code===0){
                    this.templates = res.data.data
                }
            })
        },
        submit(){
            updateWorkshop(this.id,this.editForm.name,this.editForm.intro,this.editForm.template_id).then(res=>{
                if(res.data.code===0){
                    this.$message({
                        message:'更新成功',
                        type:'success'
                    })
                    this.$router.back(-1)
                }
            })
        }
    }
}
</script>

<style>
.container{
    width: 100%;
}
</style>