<template>
    <div class="container">
        <el-date-picker
            v-model="start_time"
            type="date"
            placeholder="选择开始日期">
        </el-date-picker>
        <el-date-picker
            style="margin-left:10px"
            v-model="end_time"
            type="date"
            placeholder="选择结束日期">
        </el-date-picker>
        <el-button type="primary" @click="search" style="margin-left:10px">搜索</el-button>
        <el-table :data="employees">
            <el-table-column label="姓名">
                <template slot-scope="scope">
                    {{scope.row.user_lastName+scope.row.user_givenName}}
                </template>
            </el-table-column>
            <el-table-column prop="phone_num" label="电话"></el-table-column>
            <el-table-column prop="wechat_num" label="微信"></el-table-column>
            <el-table-column prop="email_num" label="邮件"></el-table-column>
            <el-table-column prop="sum_num" label="总和"></el-table-column>
        </el-table>
    </div>
</template>

<script>
import {userPerformanceStatistic} from '../../api/assistant'
export default {
    data(){
        return{
            start_time:'',
            end_time:'',
            employees:[]
        }
    },
    mounted(){

    },
    methods:{
        userPerformanceStatistic(start_time,end_time){
            userPerformanceStatistic(start_time,end_time).then(res=>{
                console.log(res)
                if(res.data){
                    this.employees = res.data
                }
            })
        },
        search(){
            if(!this.start_time || !this.end_time){
                this.$message.error('请选择时间')
                return
            }
            let start_time = new Date(this.start_time).getTime()/1000
            let end_time = new Date(this.end_time).getTime()/1000
            if(start_time>end_time){
                this.$message.error('开始时间不得大于结束时间')
                return
            }
            this.userPerformanceStatistic(start_time,end_time)
        }
    }
}
</script>

<style>

</style>