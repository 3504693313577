<template>
  <div>
    <div>员工信息列表</div>
    <el-button @click="initAdd" type="primary" style="margin-top: 30px"
      >新增员工</el-button
    >
    <el-button @click="initWorkTimeList" type="primary" style="margin-top: 30px"
      >员工入职日期视图</el-button
    >
    <el-select
      v-model="fulltime_status"
      @change="fetchData"
      style="margin-left: 10px"
    >
      <el-option value="全职"></el-option>
      <el-option value="兼职"></el-option>
    </el-select>

    <el-table :data="userList" style="margin-top: 30px" v-loading="loading">
      <el-table-column label="编号" width="120px">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
        prop="user_lastName"
        label="姓"
        width="120px"
      ></el-table-column>
      <el-table-column
        prop="user_givenName"
        label="名"
        width="140px"
      ></el-table-column>
      <el-table-column
        prop="user_employment"
        label="工作性质"
        width="150px"
      ></el-table-column>
      <el-table-column
        prop="employee_active_status"
        label="是否在职"
        width="150px"
      ></el-table-column>
      <el-table-column prop="wechat_work_id" label="企业微信">
      </el-table-column>
      <el-table-column prop="user_email" label="邮箱"></el-table-column>
      <el-table-column
        prop="comp"
        :label="month + '月加班工资'"
        width="140"
      ></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            @click="view(scope.row._id.$id)"
            type="primary"
            size="small"
            >查看</el-button
          >
          <el-button
            @click="viewTodo(scope.row._id.$id)"
            type="primary"
            size="small"
            >查看分配的Todo</el-button
          >
          <el-button
            @click="remove(scope.row._id.$id)"
            type="warning"
            size="small"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      title="新增员工"
      :visible.sync="showAdd"
      :close-on-click-modal="false"
    >
      <el-form>
        <el-form-item label="工作性质">
          <el-select v-model="userInfo.user_employment" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="企业微信">
          <el-input v-model="userInfo.wechat_work_id"></el-input>
        </el-form-item>
        <el-form-item label="邮箱">
          <el-input v-model="userInfo.user_email"></el-input>
        </el-form-item>
        <!-- <el-form-item label="密码">
          <el-input v-model="userInfo.user_pwd"></el-input>
        </el-form-item> -->
        <el-form-item label="姓">
          <el-input v-model="userInfo.user_lastName"></el-input>
        </el-form-item>
        <el-form-item label="名">
          <el-input v-model="userInfo.user_givenName"></el-input>
        </el-form-item>
      </el-form>
      <el-button @click="updateUser" type="primary" style="margin-top: 20px"
        >更新</el-button
      >
    </el-dialog>
    <el-dialog title="员工入职时间" :visible.sync="showWorkTime">
      <el-timeline>
        <el-timeline-item
          v-for="(activity, index) in workTimeList"
          :timestamp="activity.nowTime"
          placement="top"
        >
          <!-- <el-card>
            <el-button  @click="view(activity._id.$id)" type="primary" >{{activity.user_lastName+activity.user_givenName}}</el-button>
          </el-card> -->
        </el-timeline-item>
      </el-timeline>

      <el-button @click="closeList" type="primary" style="margin-top: 20px"
        >确认</el-button
      >
    </el-dialog>
    <el-dialog title="员工Todo" :visible.sync="showTodo">
      <el-table :data="todolist">
        <el-table-column label="编号" width="120px">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column prop="todo_content" label="内容"></el-table-column>
        <el-table-column label="状态">
          <template slot-scope="scope">
            {{ scope.row.finish_status ? "已完成" : "未完成" }}
          </template>
        </el-table-column>
        <el-table-column label="创建时间">
          <template slot-scope="scope">
            {{
              $dayjs(scope.row.create_time * 1000).format("YYYY-MM-DD HH:mm:ss")
            }}
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import {
  getUserList,
  updateUser,
  removeUser,
  getLastMonth,
  getUserWorkStartTimeList,
  sendAllEmployeeMessage,
  getUserAssignedTODOs,
} from "../../api/assistant";
import { getUserId } from "../../utils/store";
export default {
  data() {
    return {
      userList: [],
      userInfo: {},
      showAdd: false,
      showWorkTime: false,
      workTimeList: [],
      options: [
        {
          value: "fulltime",
          label: "全职",
        },
        {
          value: "parttime",
          label: "兼职",
        },
      ],
      loading: false,
      month: 0,
      fulltime_status: "全职",
      showTodo: false,
      todolist: [],
    };
  },
  mounted() {
    this.$nextTick(() => {
      getLastMonth().then((res) => {
        this.month = res.data;
        this.fetchData();
      });
    });
  },
  methods: {
    fetchData() {
      this.loading = true;
      getUserList({
        user_id: getUserId(),
      }).then((res) => {
        if (res.data) {
          this.userList = res.data;
          for (let i = 0; i < this.userList.length; i++) {
            if (this.userList[i].user_employment === "fulltime") {
              this.userList[i].user_employment = "全职";
            }
            if (this.userList[i].user_employment === "parttime") {
              this.userList[i].user_employment = "兼职";
            } else if (this.userList[i].user_employment == null) {
              this.userList[i].user_employment = "该字段未填写";
            }
          }
          this.userList = this.userList.filter((item) => {
            return item.user_employment === this.fulltime_status;
          });
          this.loading = false;
        }
      });
    },
    view(id) {
      this.$router.push({
        path: "userinfo",
        query: {
          id: id,
        },
      });
    },
    initAdd() {
      this.showAdd = true;
      this.userInfo = {};
    },
    initWorkTimeList() {
      this.getUserWorkStartTimeList();
    },
    closeList() {
      this.showWorkTime = false;
    },
    getUserWorkStartTimeList() {
      getUserWorkStartTimeList().then((res) => {
        // var flag = 0;
        // var data = [];
        // for (var i = 0; i < res.data.length ; i++) {
        //     var az = '';
        //     for (var j = 0 ; j < data.length ; j++){
        //       if(data[j][0].work_start_time == res.data[i].work_start_time){
        //         flag = 1;
        //         az = j;
        //         break;
        //       }
        //     }
        //     if (flag == 1){
        //       data[az].push(res.data[i]);
        //       flag =0;
        //     }else if (flag == 0){
        //       var wdy = new Array();
        //       wdy.push(res.data[i]);
        //       data.push(wdy);
        //     }
        // }

        // this.workTimeList = data;

        var nowDate = new Date();
        var nowMonth = nowDate.getMonth() + 1;
        var nowDay = nowDate.getDate();
        var nowYear = nowDate.getFullYear();

        this.workTimeList = res.data;

        var halfTime = JSON.stringify(this.workTimeList);

        halfTime = JSON.parse(halfTime);

        // console.log(33333,this.workTimeList)

        for (var i = 0; i < this.workTimeList.length; i++) {
          this.workTimeList[i].month = this.formateMonth(
            this.workTimeList[i].work_start_time
          );
          this.workTimeList[i].day = this.formateDay(
            this.workTimeList[i].work_start_time
          );
          if (this.workTimeList[i].month >= nowMonth) {
            if (this.workTimeList[i].day < nowDay) {
              this.workTimeList[i].year = Number(nowYear) + 1;
            } else {
              this.workTimeList[i].year = Number(nowYear);
            }
          } else {
            this.workTimeList[i].year = Number(nowYear) + 1;
          }
        }

        // var halfTime1 = JSON.stringify(this.workTimeList)

        // halfTime1 = JSON.parse(halfTime1)

        // console.log(2222,halfTime1)

        for (var i = 0; i < halfTime.length; i++) {
          var half_month = Number(
            this.formateMonth(halfTime[i].work_start_time)
          );
          var half_day = this.formateDay(halfTime[i].work_start_time);
          var half_year = Number(nowYear);
          var needAddOne = false;
          if (half_month < 7) {
            needAddOne = true;
            half_month = half_month + 6;
            if (half_day == 31) {
              if (half_month == 9 || half_month == 11) {
                half_day = 30;
              }
            }
            if (half_month < nowMonth) {
              half_year = Number(half_year) + 1;
              needAddOne = true;
            } else if (half_month == nowMonth && half_day < nowDay) {
              half_year = Number(half_year) + 1;
              needAddOne = true;
            }
          } else {
            half_month = half_month + 6 - 12;
            half_year = Number(nowYear) + 1;
            if (half_month == 2) {
              if (half_day > 28) {
                half_day = 28;
              }
            }
            if (half_day == 31) {
              if (half_month == 4 || half_month == 6) {
                half_day = 30;
              }
            }
          }
          halfTime[i].needAddOne = needAddOne;
          halfTime[i].half = true;
          halfTime[i].year = half_year;
          halfTime[i].month = half_month;
          halfTime[i].day = half_day;
        }

        this.workTimeList.push(...halfTime);

        this.workTimeList.sort(function (a, b) {
          if (a.year > b.year) {
            return 1;
          }
          if (a.year < b.year) {
            return -1;
          }
          if (a.month == b.month) {
            return a.day - b.day;
          }
          return a.month - b.month;
        });

        var index = 0;
        for (var i = 0; i < this.workTimeList.length; i++) {
          var info = this.workTimeList[i];
          if (info.month == nowMonth) {
            if (info.day >= nowDay && info.year <= nowYear) {
              index = i;
              break;
            }
          }
        }

        // var left = this.workTimeList.slice(0,index);
        for (var i = 0; i < this.workTimeList.length; i++) {
          // left[i]['year'] = nowYear+1;
          this.workTimeList[i].nowTime =
            this.workTimeList[i].year +
            "-" +
            this.workTimeList[i].month +
            "-" +
            this.workTimeList[i].day;
          var work_year = this.formateYear(
            this.workTimeList[i].work_start_time
          );
          var work_month = this.formateMonth(
            this.workTimeList[i].work_start_time
          );

          var yearCha = this.workTimeList[i].year - work_year;
          var allName =
            this.workTimeList[i].user_lastName +
            this.workTimeList[i].user_givenName;

          if (work_month != this.workTimeList[i].month) {
            if (yearCha != 0) {
              if (this.workTimeList[i].needAddOne == false) {
                yearCha = yearCha - 1;
              }
            }
          }

          if (this.workTimeList[i].half == true) {
            yearCha = yearCha + 0.5;
            this.workTimeList[i].nowTime =
              this.workTimeList[i].nowTime +
              " " +
              allName +
              " 入职 " +
              yearCha +
              "周年";
          } else {
            this.workTimeList[i].nowTime =
              this.workTimeList[i].nowTime +
              " " +
              allName +
              " 入职 " +
              yearCha +
              "周年";
          }
        }

        // console.log(11111,this.workTimeList)

        // var right = this.workTimeList.slice(index);
        // for (var i = 0; i < right.length ; i++) {
        //     right[i]['nowTime'] = right[i]['year'] + '-' + right[i]['month'] + '-' + right[i]['day']

        //     var work_year = this.formateYear(right[i].work_start_time)
        //     var work_month = this.formateMonth(right[i].work_start_time);
        //     var yearCha = right[i]['year'] - work_year;
        //     var allName = right[i].user_lastName+right[i].user_givenName;

        //     if (work_month != right[i]['month']){
        //       if (yearCha != 0){
        //           if (right[i]['needAddOne'] ==false){
        //           yearCha = yearCha - 1;
        //         }
        //       }
        //     }
        //     if (right[i]['half'] == true){
        //       yearCha = yearCha + 0.5;
        //       right[i]['nowTime'] = right[i]['nowTime'] + ' ' + allName + ' 入职 ' + yearCha +'周年';
        //     }else{

        //       right[i]['nowTime'] = right[i]['nowTime'] + ' ' + allName + ' 入职 ' + yearCha +'周年';
        //     }

        // }

        // right.push(...left)

        // // console.log(2222,right)

        // this.workTimeList = right;

        this.showWorkTime = true;
      });
    },
    updateUser() {
      updateUser(
        getUserId(),
        this.id,
        this.userInfo.user_email,
        this.userInfo.user_pwd,
        this.userInfo.user_lastName,
        this.userInfo.user_givenName,
        this.userInfo.user_employment,
        this.userInfo.wechat_work_id,
        "",
        "",
        "在职"
      ).then((res) => {
        this.$message({
          message: "更新成功",
          type: "success",
        });
        this.showAdd = false;
        this.fetchData();

        this.$confirm("是否向全员发送消息", "提示", {
          confirmButtonText: "发送",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            var message =
              "欢迎新同事" +
              " " +
              this.userInfo.user_lastName +
              this.userInfo.user_givenName +
              " " +
              "入职";
            sendAllEmployeeMessage(message).then((res) => {});
          })
          .catch(() => {});
      });
    },
    remove(id) {
      this.$confirm("是否确认删除", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        removeUser(id).then((res) => {
          this.$message("删除成功");
          this.fetchData();
        });
      });
    },
    formateTime(date) {
      const time = new Date(date * 1000);
      return `${time.getFullYear()}-${time.getMonth() + 1}-${time.getDate()}`;
    },
    formateDay(date) {
      const time = new Date(date * 1000);
      return `${time.getDate()}`;
    },
    formateMonth(date) {
      const time = new Date(date * 1000);
      return `${time.getMonth() + 1}`;
    },
    formateYear(date) {
      const time = new Date(date * 1000);
      return `${time.getFullYear()}`;
    },
    viewTodo(id) {
      this.showTodo = true;
      getUserAssignedTODOs({
        user_id: id,
      }).then((res) => {
        this.todolist = res.data;
      });
    },
  },
};
</script>

<style></style>
