<template>
  <div>
    <el-button
      size="small"
      type="primary"
      @click="handleClick"
      :disabled="disabled"
      >{{ currentBtn }}</el-button
    >
  </div>
</template>

<script>
let resultText = "";
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentBtn: "从头录音",
      APPID: "4bf6bfdc",
      API_KEY: "4980e6833e5b4d4201c8938819382ca0",
      btnStatus: "UNDEFINED",
      recorder: null,
      iatWS: null,
      countdownInterval: null,
      showResultText: "",
    };
  },
  watch: {
    resultText(e) {
      console.log("change", e);
    },
  },
  mounted() {
    this.recorder = new window.RecorderManager("/rtasr/dists");
    this.recorder.onFrameRecorded = ({ isLastFrame, frameBuffer }) => {
      if (this.iatWS.readyState === this.iatWS.OPEN) {
        this.iatWS.send(new Int8Array(frameBuffer));
        if (isLastFrame) {
          this.iatWS.send('{"end": true}');
          this.changeBtnStatus("CLOSING");
        }
      }
    };
    this.recorder.onStop = () => {
      clearInterval(this.countdownInterval);
    };
  },
  methods: {
    handleClick() {
      if (this.btnStatus === "UNDEFINED" || this.btnStatus === "CLOSED") {
        this.connectWebSocket();
      } else if (this.btnStatus === "CONNECTING" || this.btnStatus === "OPEN") {
        // 结束录音
        this.recorder.stop();
      }
    },
    getWebSocketUrl() {
      // 请求地址根据语种不同变化
      const url = "wss://rtasr.xfyun.cn/v1/ws";
      const appId = this.APPID;
      const secretKey = this.API_KEY;
      const ts = Math.floor(new Date().getTime() / 1000);
      const signa = window.hex_md5(appId + ts);
      const signatureSha = window.CryptoJSNew.HmacSHA1(signa, secretKey);
      let signature = window.CryptoJS.enc.Base64.stringify(signatureSha);
      signature = encodeURIComponent(signature);
      return `${url}?appid=${appId}&ts=${ts}&signa=${signature}`;
    },
    changeBtnStatus(status) {
      this.btnStatus = status;
      this.$emit("pending", true);
      if (status === "CONNECTING") {
        this.currentBtn = "建立连接中";
        resultText = "";
        this.showResultText = "";
      } else if (status === "OPEN") {
        this.currentBtn = "录音中";
      } else if (status === "CLOSING") {
        this.currentBtn = "关闭连接中";
      } else if (status === "CLOSED") {
        this.currentBtn = "从头录音";
        this.$emit("pending", false);
      }
    },
    renderResult(resultData) {
      const jsonData = JSON.parse(resultData);
      if (jsonData.action === "started") {
        // 握手成功
        console.log("握手成功");
      } else if (jsonData.action === "result") {
        const data = JSON.parse(jsonData.data);
        console.log(data);
        // 转写结果
        let resultTextTemp = "";
        data.cn.st.rt.forEach((j) => {
          j.ws.forEach((k) => {
            k.cw.forEach((l) => {
              resultTextTemp += l.w;
            });
          });
        });
        if (data.cn.st.type === "0") {
          // 【最终】识别结果：
          resultText += resultTextTemp;
          resultTextTemp = "";
        }

        this.showResultText = resultText + resultTextTemp;
        this.$emit("changeValue", this.showResultText);
      } else if (jsonData.action === "error") {
        // 连接发生错误
        console.log("出错了:", jsonData);
        this.$message.error(jsonData.desc);
      }
    },
    connectWebSocket() {
      const websocketUrl = this.getWebSocketUrl();
      this.iatWS = new WebSocket(websocketUrl);
      this.changeBtnStatus("CONNECTING");
      this.iatWS.onopen = (e) => {
        // 从头录音
        this.recorder.start({
          sampleRate: 16000,
          frameSize: 1280,
        });
        this.changeBtnStatus("OPEN");
      };
      this.iatWS.onmessage = (e) => {
        this.renderResult(e.data);
      };
      this.iatWS.onerror = (e) => {
        console.error(e);
        this.recorder.stop();
        this.changeBtnStatus("CLOSED");
      };
      this.iatWS.onclose = (e) => {
        this.recorder.stop();
        this.changeBtnStatus("CLOSED");
      };
    },
  },
};
</script>

<style>
</style>
