<template>
  <div class="chatContentContainer">
    <div class="left">
      <div
        v-for="(item, index) in chat_list"
        :key="index"
        class="conversationItem"
        @click="startChat(item)"
        :class="{ selected: currentConversationId === item._id.$id }"
      >
        <div class="name">{{ getConversationName(item) }}</div>
        <div class="currentMessage">
          {{ item.currentMessage }}
        </div>
        <div class="updatetime">{{ formateTimeLabel(item.update_time) }}</div>
        <div class="statuscircle" v-if="item[`no_read_${user_id}`]"></div>
      </div>
    </div>
    <div class="right">
      <div class="rightInfo">
        {{ getConversationName(currentConversation) }}
      </div>
      <div class="rightContent">
        <div
          v-for="(item, index) in messageList"
          :key="index"
          class="contentItem"
          :class="{ textRight: item.user_id === user_id }"
        >
          <div class="showtime">
            {{ $dayjs(item.create_time * 1000).format("YYYY-MM-DD HH:mm") }}
          </div>
          <div class="contentItem1">{{ item.content }}</div>
        </div>
      </div>
      <div class="inputContainer">
        <div class="toolInput">
          <div class="toolItem">
            <svg
              t="1719036738747"
              viewBox="0 0 1024 1024"
              width="30"
              height="30"
            >
              <path
                d="M512 896c-211.2 0-384-172.8-384-384s172.8-384 384-384 384 172.8 384 384S723.2 896 512 896zM512 185.6C332.8 185.6 185.6 332.8 185.6 512c0 179.2 147.2 326.4 326.4 326.4 179.2 0 326.4-147.2 326.4-326.4C838.4 332.8 691.2 185.6 512 185.6z"
                fill="#8a8a8a"
                p-id="4269"
              ></path>
              <path
                d="M396.8 480C396.8 499.2 377.6 512 364.8 512l0 0C345.6 512 332.8 499.2 332.8 480L332.8 422.4c0-19.2 12.8-32 32-32l0 0c19.2 0 32 12.8 32 32L396.8 480z"
                fill="#8a8a8a"
                p-id="4270"
              ></path>
              <path
                d="M691.2 480c0 19.2-12.8 32-32 32l0 0c-19.2 0-32-12.8-32-32L627.2 422.4c0-19.2 12.8-32 32-32l0 0c19.2 0 32 12.8 32 32L691.2 480z"
                fill="#8a8a8a"
                p-id="4271"
              ></path>
              <path
                d="M640 576c6.4 0 12.8 0 19.2 6.4 12.8 12.8 12.8 32 0 44.8-38.4 44.8-96 64-153.6 64-57.6 0-115.2-25.6-153.6-64-12.8-12.8-12.8-32 0-44.8C371.2 576 390.4 576 403.2 588.8c57.6 64 160 57.6 217.6 0C627.2 582.4 633.6 576 640 576z"
                fill="#8a8a8a"
                p-id="4272"
              ></path>
            </svg>
          </div>
        </div>
        <div class="inputContent">
          <el-input
            type="textarea"
            resize="none"
            placeholder="请输入消息"
            v-model="inputContent"
            @keyup.enter.native="sendMessage"
          ></el-input>
          <div
            class="sendBtn"
            @click="sendMessage"
            :class="{ disabled: !inputContent }"
          >
            发送
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getUserConversations,
  talkToSomeone,
  getChatUserList,
  chatSendMessage,
  getConversationMessages,
  updateChatConversation,
} from "../../api/assistant";
import { getUserId } from "../../utils/store";
export default {
  data() {
    return {
      user_id: getUserId(),
      chat_list: [],
      currentConversationId: "",
      currentConversation: {},
      userMap: {},
      inputContent: "",
      messageList: [],
      ws: null,
    };
  },
  mounted() {
    this.getChatList();
    this.getUserMap();
    this.initWebSocket();
  },
  beforeDestroy() {
    this.ws.close();
  },
  methods: {
    initWebSocket() {
      this.ws = new WebSocket("wss://team-chat.learningfirst.cn:9013");
      this.ws.onopen = () => {
        console.log("连接成功");
        this.ws.send(
          JSON.stringify({
            type: "login",
            userId: this.user_id,
          })
        );
      };
      this.ws.onmessage = (e) => {
        let data = JSON.parse(e.data);
        if (data.type === "登录成功") {
        } else if (data.type === "message") {
          let conversation = this.chat_list.find(
            (item) => item._id.$id === data.conversationId
          );
          if (conversation) {
            conversation.currentMessage = data.content;
            conversation.update_time = new Date().getTime() / 1000;
            conversation[`no_read_${this.user_id}`] = true;
            if (this.currentConversationId === data.conversationId) {
              conversation[`no_read_${this.user_id}`] = false;
              this.messageList.push({
                conversation_id: data.conversationId,
                content: data.content,
                user_id: conversation.group_user_ids.find(
                  (id) => id !== this.user_id
                ),
                create_time: new Date().getTime() / 1000,
              });
            }
          } else {
            this.getChatList();
          }
        }
      };
    },
    async getUserMap() {
      const res = await getChatUserList();
      let userMap = {};
      for (let item of res.data) {
        userMap[item._id.$id] =
          item.username || item.user_lastName + item.user_givenName;
      }
      this.userMap = userMap;
    },
    getChatList() {
      getUserConversations({
        user_id: this.user_id,
      }).then((res) => {
        if (res.data.code === 0) {
          this.chat_list = res.data.data;
          if (!this.currentConversationId && this.chat_list.length) {
            this.startChat(this.chat_list[0]);
          }
        }
      });
    },
    talkToSomeone(e) {
      let conversation = this.chat_list.find((item) => {
        return (
          item.type === "private" &&
          (item.group_user_ids[0] === e || item.group_user_ids[1] === e)
        );
      });
      if (conversation) {
        this.startChat(conversation);
      } else {
        talkToSomeone({
          user_id: this.user_id,
          to_id: e,
        }).then((res) => {
          if (res.data.code === 0) {
            this.chat_list.unshift(res.data.data);
            this.startChat(res.data.data);
          }
        });
      }
    },
    startChat(conversation) {
      this.currentConversationId = conversation._id.$id;
      this.currentConversation = conversation;
      getConversationMessages({
        conversation_id: this.currentConversationId,
        user_id: this.user_id,
      }).then((res) => {
        if (res.data.code === 0) {
          this.messageList = res.data.data;
          this.currentConversation[`no_read_${this.user_id}`] = false;
        }
      });
    },
    getUserName(id) {
      return this.userMap[id];
    },
    getConversationName(item) {
      if (item.type === "private") {
        let user_id = item.group_user_ids.find((id) => id !== this.user_id);
        return this.userMap[user_id];
      }
      return item.name;
    },
    sendMessage() {
      if (!this.inputContent) {
        return;
      }
      this.messageList.push({
        conversation_id: this.currentConversationId,
        content: this.inputContent,
        user_id: this.user_id,
        create_time: new Date().getTime() / 1000,
      });
      chatSendMessage({
        conversation_id: this.currentConversationId,
        content: this.inputContent,
        user_id: this.user_id,
      });
      this.ws.send(
        JSON.stringify({
          type: "sendToUser",
          conversationId: this.currentConversationId,
          message: this.inputContent,
          userId: this.user_id,
          toId: this.currentConversation.group_user_ids.find(
            (id) => id !== this.user_id
          ),
        })
      );
      this.currentConversation.currentMessage = this.inputContent;
      this.currentConversation.update_time = new Date().getTime() / 1000;
      updateChatConversation({
        conversation_id: this.currentConversationId,
        currentMessage: this.inputContent,
        to_id: this.currentConversation.group_user_ids.find(
          (id) => id !== this.user_id
        ),
      });
      this.inputContent = "";
    },
    formateTimeLabel(time) {
      let now = new Date().getTime();
      let diff = now - time * 1000;
      if (diff < 60 * 1000) {
        return "刚刚";
      } else if (diff < 60 * 60 * 1000) {
        return `${Math.floor(diff / (60 * 1000))}分钟前`;
      } else if (diff < 24 * 60 * 60 * 1000) {
        return `${Math.floor(diff / (60 * 60 * 1000))}小时前`;
      } else {
        return `${Math.floor(diff / (24 * 60 * 60 * 1000))}天前`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.chatContentContainer {
  display: flex;
  height: 100%;
  .left {
    background: #f9f9f9;
    width: 260px;
    height: 100%;
    border-right: 1px solid #ccc;
    position: relative;
    overflow-y: scroll;
    overflow-x: hidden;
    .conversationItem {
      padding: 16px;
      width: 250px;
      height: 74px;
      margin: 0 auto;
      box-sizing: border-box;
      cursor: pointer;
      border-bottom: 1px solid #eee;
      position: relative;
      &:hover {
        background: #f5f5f5;
      }
      .name {
        font-size: 15px;
        font-weight: bold;
        color: #333;
      }
      .currentMessage {
        font-size: 14px;
        color: #999;
        margin-top: 10px;
        width: 180px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .statuscircle {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: red;
        position: absolute;
        right: 14px;
        top: 14px;
      }
      .updatetime {
        font-size: 12px;
        color: #999;
        position: absolute;
        right: 14px;
        top: 44px;
      }
    }
    .conversationItem.selected {
      background: rgba(209, 222, 240, 0.4);
    }
  }
  .right {
    flex: 1;
    height: 100%;
    background: #fafafa;
    font-size: 16px;
    overflow: hidden;
    .rightInfo {
      width: 100%;
      height: 60px;
      border-bottom: 1px solid #eee;
      color: #333;
      padding: 20px;
      box-sizing: border-box;
      font-weight: bold;
    }
    .rightContent {
      height: calc(100% - 220px);
      overflow-y: scroll;
      padding: 20px;
      box-sizing: border-box;
      .contentItem {
        text-align: left;
        margin-top: 10px;
        width: 100%;
        .showtime {
          font-size: 14px;
          color: #999;
          margin-bottom: 4px;
        }
        .contentItem1 {
          background: rgb(232, 232, 233);
          color: #000;
          padding: 10px 16px;
          border-radius: 4px;
          display: inline-block;
          max-width: 70%;
          word-wrap: break-word;
        }
      }
      .contentItem.textRight {
        text-align: right;
        .contentItem1 {
          background: rgb(201, 231, 255);
          color: #000;
          padding: 10px 16px;
          border-radius: 4px;
          display: inline-block;
          max-width: 70%;
          word-wrap: break-word;
        }
      }
    }
    .inputContainer {
      background: #fff;
      width: 100%;
      height: 160px;
      border-top: 1px solid #eee;
      box-sizing: border-box;
      .toolInput {
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        padding: 4px;
        .toolItem {
          cursor: pointer;
          margin-right: 10px;
          width: 32px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
        }
        .toolItem:hover {
          background: #efefef;
        }
      }
      .inputContent {
        height: 120px;
        width: 100%;
        position: relative;
        .sendBtn {
          position: absolute;
          right: 30px;
          bottom: 20px;
          cursor: pointer;
          color: #666;
          font-size: 16px;
        }
        .sendBtn:hover {
          color: #999;
        }
        .sendBtn.disabled {
          color: #ddd !important;
          cursor: not-allowed;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.chatContentContainer {
  .el-textarea__inner {
    height: 116px !important;
    border: none;
  }
}
</style>