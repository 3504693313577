<template>
    <div>
        <el-button @click="addType" type="primary" style="margin-left:20px">添加类型</el-button>
        <el-table :data="types" v-loading="loading">
            <el-table-column prop="format" label="形式" width="100px"></el-table-column>
            <el-table-column prop="type" label="类型" width="100px"></el-table-column>
            <el-table-column prop="note" label="解释" ></el-table-column>
            <el-table-column label="详细" width="150">
                <template slot-scope="scope">
                    <el-button type="primary" size="small" @click="view(scope.row)">编辑</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-dialog :visible.sync="showEdit" title="添加类型" width="80%">
            <el-form label-width="200px">
                <el-form-item label="形式">
                    <el-select v-model="form.format">
                        <el-option v-for="item in formats" :label="item" :value="item"></el-option>
                        
                    </el-select>
                </el-form-item>
                <el-form-item label="类型">
                    <el-input v-model="form.type" style="width:80%"></el-input>
                </el-form-item>
                
                

              

                <el-form-item label="解释">
                    <el-input type="textarea" :rows="5" v-model="form.note" style="width:80%"></el-input>
                </el-form-item>
              
                <el-form-item>
                    <el-button @click="updateTodoType" type="primary">确认</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import {getTodoTypes,getUser,updateTodoType} from '../../api/assistant'
import {getUserId} from '../../utils/store'
export default {
    data(){
        return{
            types:[],
            form:{},
            loading:false,
            showEdit:false,
            type_id:'',
            formats:['有用','内容','活动','讲座','投放','研发','装饰','流程','课程'],
            
            
        }
    },
    mounted(){
        this.fetchData()
    },
    methods:{
        fetchData(){
            this.loading=true
            getTodoTypes().then(res=>{
                this.types = res.data
                this.loading=false
            })
        },
        addType(){
            this.form={
                
            }
            this.type_id = ''
            this.showEdit = true
        },
        updateTodoType(){
            updateTodoType(this.type_id,this.form.format,this.form.type,this.form.note).then(res=>{
                this.fetchData()
                this.showEdit = false
            })
        },
        view(data){
            this.form = JSON.parse(JSON.stringify(data))
            this.type_id = data._id.$id
            
            this.showEdit = true
        },

        
    }
}
</script>

<style>

</style>