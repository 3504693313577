<template>
    <div class="homeContainer">
        <div>
            <el-button type="primary" @click="add">创建新任务</el-button>
        </div>
        <el-table :data="tableData">
            <el-table-column prop="text" label="文字"></el-table-column>
            <el-table-column prop="result" label="音频地址" width="300px"></el-table-column>
        </el-table>
        <el-dialog :visible.sync="showAdd">
            <el-form>
                <el-form-item label="需要转化为音频的文字">
                    <el-input type="textarea" :rows="6" v-model="form.text"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button style="margin-top:20px" @click="submit" type="primary">确认</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>

    </div>
</template>

<script>
import { getUserId } from '../../utils/store.js'
import { getUserTextToAudioTaskList, initTextToAudioTask } from '../../api/assistant'
export default {
  data () {
    return {
      tableData: [],
      form: {},
      showAdd: false,
      id: ''
    }
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      getUserTextToAudioTaskList({
        user_id: getUserId()
      }).then(res => {
        if (res.data.code === 0) {
          this.tableData = res.data.data
        }
      })
    },
    add () {
      this.id = ''
      this.form = {
        text: ''
      }
      this.showAdd = true
    },
    submit () {
      const loading = this.$loading({
        lock: true,
        text: '音频生成中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      initTextToAudioTask({
        text: this.form.text,
        user_id: getUserId()
      }).then(res => {
        if (res.data.code === 0) {
          this.$message({
            message: '创建成功',
            type: 'success'
          })
          this.fetchData()
          this.showAdd = false
        }
      }).finally(() => {
        loading.close()
      })
    }
  }
}
</script>

<style scoped>
.el-header{
    background-color: #409EFF;
    color: #333;
    line-height: 60px;
    text-align: right;
}
.cardItem{
    width: 1000px;
    border-radius: 10px;
    border: 1px solid #DCDFE6;
    height: 400px;
    margin: 20px auto;
    text-align: left;
}
.title{
    margin-top:20px;
}
.content{
    margin-top:20px;
    height: 50%;
}
.label{
    margin-top:20px;
    margin-left:30px
}

.title .el-input__inner{
    padding-left:30px;
    border: none;
    font-size: 24px;
    font-weight: 600;
}

.content .el-textarea__inner{
    padding-left:30px;
    border: none;
    height: 200px;
    font-size: 16px;
    width: 970px;
    resize: none;
}

</style>
