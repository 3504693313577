import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'normalize.css'
import App from './App.vue'
import router from './router'
import $ from 'jquery'
import { socketClient } from './utils/socket'
import { getUserId } from './utils/store'
import dayjs from 'dayjs'

import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(VueQuillEditor)

Vue.config.productionTip = false

Vue.use(ElementUI)

const ws_wyf_url = 'wss://team-chat.learningfirst.cn:9001'
const wsAuto = `userLogin&${getUserId()}`
const wsocket = new socketClient(ws_wyf_url, wsAuto)
wsocket.initWebSocket()
Vue.prototype.$ws = wsocket
Vue.prototype.$ = $
Vue.prototype.$dayjs = dayjs

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
