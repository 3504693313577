<template>
    <div>
        <div class="add-subject">
            <el-button type="primary" @click="handleEdit()">新增课程</el-button>
        </div>
        <el-table :data="classlist">
            <el-table-column label="编号" width="100">
                <template slot-scope="scope"> 
                    {{scope.$index+1}}
                </template>
            </el-table-column>
            <el-table-column label="所属BU" prop="class_info.class_BU"></el-table-column>
            <el-table-column label="课程名称" prop="class_info.class_name"></el-table-column>
            <el-table-column label="编辑者" prop="class_info.class_editor_names"></el-table-column>
            <el-table-column label="编辑" width="400">
                <template slot-scope="scope">
                    <el-button @click="viewClass(scope.row)" type="primary" size="small">查看</el-button>
                    <el-button @click="toggleUserShow(scope.$index, scope.row)" type="primary" size="small">分配员工</el-button>
                    <el-button @click="toggleProgressShow(scope.$index, scope.row)" type="primary" size="small">完课情况</el-button>
                    <el-button @click="toggleQuizStatShow(scope.$index, scope.row)" type="primary" size="small">题目统计</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- 新增弹框 -->
        <el-dialog :title="title" :visible.sync="editFormVisible" width="50%" @click="closeDialog">
            <el-form :model="editForm"  ref="editForm">
                <el-form-item label="课程名称" prop="class_name">
                <el-input size="small" v-model="editForm.class_name" auto-complete="off" placeholder="请输入部门名称"></el-input>
                </el-form-item>
                <el-form-item label="课程所属BU" prop="class_BU">
                <el-input size="small" v-model="editForm.class_BU" auto-complete="off" placeholder="请输入部门代码"></el-input>
                </el-form-item>
                <el-form-item label="课程介绍" prop="class_description">
                <el-input size="small" v-model="editForm.class_description" auto-complete="off" placeholder="请输入部门代码"></el-input>
                </el-form-item>
                <el-form-item label="课程头像链接" prop="class_avatar_url">
                <el-input size="small" v-model="editForm.class_avatar_url" auto-complete="off" placeholder="请输入部门代码"></el-input>
                </el-form-item>
                <el-form-item label="是否要求完成前一节才能进入下一节？" prop="session_completion_required">
                    <el-radio-group v-model="editForm.session_completion_required">
                    <el-radio label="是"></el-radio>
                    <el-radio label="否"></el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="small" @click="closeDialog">取消</el-button>
                <el-button size="small" type="primary"  class="title" @click="submitForm('editForm')">保存</el-button>
            </div>
        </el-dialog>
        <!-- 课程分配弹框 -->
        <el-dialog title="课程分配" :visible.sync="toggleFormVisible" width="50%" @click="closeDialogto">
            <span>已分配：</span>
            <el-tag v-for="(item,index) in assignedUsers" :key="index" closable style="margin-right:6px" @close="cancelAssigned(item._id.$id)">
            {{item.user_lastName+item.user_givenName}}
            </el-tag>
            <el-form :model="toggleForm"  ref="toggleForm" style="margin-top:20px">
                <el-form-item label="员工列表" prop="toggleForm">
                    <el-select v-model="toggleForm.class_name" placeholder="请选择员工" @change="styleChange">
                        <el-option :label="item.user_lastName+item.user_givenName" :value="item._id.$id" v-for="(item,index) in userList" :key=index></el-option>
                        <!-- <el-option label="区域二" value="beijing"></el-option> -->
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="small" @click="closeDialogto">取消</el-button>
                <el-button size="small" type="primary"  class="title" @click="toggleUser('toggleForm')">保存</el-button>
            </div>
        </el-dialog>
         <el-dialog title="完课进度" :visible.sync="toggleProgressVisible" width="50%" @click="closeProgressDialog()">
            <el-table :data="progress_info">
            
            <el-table-column label="姓名" prop="user_name"></el-table-column>
            <el-table-column label="答题进度" prop="progress_string"></el-table-column>
            <el-table-column label="正确率" prop="correct_rate"></el-table-column>
        </el-table>
        </el-dialog>
        <el-dialog title="题目统计" :visible.sync="toggleQuizStatVisible" width="50%" @click="closeQuizStatDialog()">
            <el-table :data="quiz_stats_info">
            <el-table-column label="编号"  width="50px;">
                <template slot-scope="scope"> 
                    {{scope.$index+1}}
                </template>
            </el-table-column>
            <el-table-column label="题目" prop="quiz_question"></el-table-column>
            
            <el-table-column label="正确率" prop="correct_rate" width="80px;" ></el-table-column>
        </el-table>
        </el-dialog>
    </div>
</template>

<script>
import {getClassList, updateClass, getEmployees, assignClassToEmployee,getClassAssignedUsers,cancelAssigned,getClassUserProgress,getClassQuizStats} from '../api/assistant'
export default {
    data(){
        return{
            classlist:[],
            editFormVisible: false,
            title: '新增课程',
            editForm: {
                class_id: '',
                class_name: '',
                class_BU: '',
                class_description: '',
                class_avatar_url: '',
                session_completion_required: '否'
            },
            toggleFormVisible: false,
            toggleProgressVisible:false,
            toggleQuizStatVisible:false,
            userList: [],
            toggleForm: {
                class_name: '',
                class_id: ''
            },
            assignedUsers:[],
            userid: '',
            progress_info:[],
            quiz_stats_info:[]

        }
    },
    mounted(){
        this.fetchData()
        this.getUserList()
    },
    methods:{
        fetchData(){
            getClassList().then(res=>{
                console.log(res)
                this.classlist = res.data
            })
        },
        getUserList(){
            getEmployees().then(res=>{
                this.userList = res.data.data
            })
        },
        viewClass(row){
            this.$router.push({
                path:'classinfo',
                query:{
                    id:row._id.$id
                }
            })
        },
        //显示新增界面
        handleEdit: function() {
            this.editFormVisible = true
            this.title = '新增课程'
        },
        cancelAssigned(user_id){
            cancelAssigned(user_id,this.toggleForm.class_id).then(res=>{
                if(res.data.code===0){
                    this.$message('取消成功')
                    this.getClassAssignedUsers(this.toggleForm.class_id)
                }
            })
        },

        // 编辑、增加页面保存方法
        submitForm(editData) {
            this.$refs[editData].validate(valid => {
                console.log('valid-----'+valid)
                if (valid) {
                    if(!this.editForm.class_name){
                        this.$message({
                            message:'请填写课程名称',
                            type:'error'
                        })
                        return false
                    }
                    updateClass(this.editForm.class_id,
                    this.editForm.class_name,
                    this.editForm.class_BU,
                    this.editForm.class_description,
                    this.editForm.class_avatar_url,
                    this.editForm.session_completion_required)
                .then(res=>{
                        console.log('res-------')
                        console.log(res)
                        this.editFormVisible = false    
                        this.$message({
                            message:'新增成功',
                            type:'success'
                        })
                        this.fetchData()
                    })
                } else {
                return false
                }
            })
        },
        toggleUserShow(index, row){
            this.toggleFormVisible = true
            this.toggleForm.class_id = row._id.$id
            this.getClassAssignedUsers(row._id.$id)
        },
        toggleProgressShow(index, row){
            this.toggleProgressVisible = true
            // this.toggleForm.class_id = row._id.$id
            this.getClassUserProgress(row._id.$id)
        },
        toggleQuizStatShow(index, row){
            this.toggleQuizStatVisible = true
            // 
            this.getClassQuizStats(row._id.$id)
        },
        getClassAssignedUsers(id){
            getClassAssignedUsers(id).then(res=>{
                console.log(res)
                if(res.data.code==0){
                    this.assignedUsers = res.data.data
                }
            })
        },
        getClassUserProgress(id){
            getClassUserProgress(id).then(res=>{
                
                if(res.data.code==0){
                    this.progress_info = res.data.data
                }
            })
        },
        getClassQuizStats(id){
            getClassQuizStats(id).then(res=>{
                
                if(res.data.code==0){
                    this.quiz_stats_info = res.data.data
                }
            })
        },
        styleChange(val) {
            console.log('val=====')
            console.log(val)
            this.userid = val
        },
        // 分配员工
        toggleUser() {
            if(!this.userid){
                this.$message({
                    type: 'error',
                    message: '请选择分配员工'
                })
                return false
            }
            assignClassToEmployee(
                this.toggleForm.class_id,
                this.userid)
            .then(res=>{
                this.$message({
                    message:'新增成功',
                    type:'success'
                })
                this.getClassAssignedUsers(this.toggleForm.class_id)
            })
        },
        // 关闭编辑、增加弹出框
        closeDialog() {
        this.editFormVisible = false
        },
        // 关闭编辑、增加弹出框
        closeDialogto() {
        this.toggleFormVisible = false
        },
        closeProgressDialog(){
            this.toggleProgressVisible = false
        },
        closeQuizStatDialog(){
            this.toggleQuizStatVisible = false
        }
    }
}
</script>

<style scoped>
.add-subject{
    margin: 20px 0;
}
</style>
