<template>
    <div
        style=" height: calc(100vh - 60px); display: flex;flex-direction: column;overflow-y: auto;padding: 0px 20px;box-sizing: border-box;">
        <h4 style="margin-bottom: 0px;">Client Datas</h4>
        <div style="margin-top: 0px;">
            <h4>Contact Info</h4>
            <div v-if="contacts.length > 0" class="table-container">
                <div v-for="(contact,index) in contacts" :key="index" class="table-item">
                    <p> Name: {{ contact.contact_lastName + contact.contact_givenName }}</p>
                    <p> Email: {{ contact.contact_email }}</p>
                    <p> Account: {{ contact.account_name }}</p>
                    <p> Country: {{ contact.account_country }}</p>
                </div>
            </div>
            <p v-else>No Data</p>
        </div>
        <div>
            <h4>Student Info</h4>
            <div v-if="students.length > 0" class="table-container">
                <div v-for="(student,index) in students" :key="index" class="table-item">
                    <p> Name: {{ student.student_lastName_pinyin + student.student_givenName_pinyin }}</p>
                    <p> Email: {{ student.student_email }}</p>
                    <p> Account: {{ student.account_name }}</p>
                    <p> AccountCountry: {{ student.account_country }}</p>
                </div>
            </div>
            <p v-else>No Data</p>
        </div>
        <div>
            <h4>Applications</h4>
            <div v-if="applications.length > 0" class="table-container">
                <div v-for="(application,index) in applications" :key="index" class="table-item">
                    <p> Project: {{ application.application_project}}</p>
                    <p> Status: {{ application.application_status }}</p>
                </div>
            </div>
            <p v-else>No Data</p>
        </div>
    </div>
</template>
<script>

export default ({
    name: 'index',
    props: {
        clientData: {
            type: Object,
            default: {}
        }
    },
    watch: {
        clientData: {
            handler(newVal, oldVal) {
                this.handleClientData()
            },
            immediate: true
        }
    },
    data() {
        return {
            contacts: [],
            students: [],
            applications: []
        }
    },
    methods: {
        handleClientData() {
            this.contacts = []
            this.students = []
            this.applications = []

            if (this.clientData.contacts && this.clientData.contacts.length > 0) {
                this.contacts = this.clientData.contacts
            }

            if (this.clientData.students && this.clientData.students.length > 0) {
                this.students = this.clientData.students
            }

            if (this.clientData.students && this.clientData.students.length > 0) {
                let firstItem = this.clientData.students[0]
                if (firstItem.applications && firstItem.applications.length > 0) {
                    this.applications = firstItem.applications
                }
            }
        }
    }
})
</script>
<style lang="scss" scoped>
.table-container {
    width: 100%;

    .table-item {
        width: 100%;
        padding: 6px 12px;
        box-sizing: border-box;
        margin-bottom: 10px;
        color: #333;
        font-size: 14px;
        background-color: #FFD9D4;
        border-radius: 5px;
    }
}

</style>