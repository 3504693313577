<template>
    <div>
        <el-button @click="add" type="primary">添加</el-button>
        <el-table :data="tableData">
            <el-table-column label="内容" prop="content"></el-table-column>
            <el-table-column label="操作" width="200px">
                <template slot-scope="scope">
                    <el-button type="primary" @click="edit(scope.row)">修改</el-button>
                    <el-button type="danger" @click="remove(scope.row._id.$id)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-dialog :visible.sync="show">
            <el-input type="textarea" v-model="content"></el-input>
            <rtasr style="margin-top: 10px;margin-bottom: 10px;" @changeValue="changeText"></rtasr>
            <el-button @click="confirm" type="primary">确定</el-button>
        </el-dialog>
    </div>
</template>

<script>
import {removeText,getTexts,updateText} from '../api/assistant'
import rtasr from "../components/RealtimeTransfer.vue";
export default {
    components: {
        rtasr,
    },
    data(){
        return{
            tableData:[],
            content:'',
            show:false,
            id:''
        }
    },
    mounted(){
        this.fetch()
    },
    methods:{
        fetch(){
            getTexts().then(res=>{
                this.tableData = res.data.data
            })
        },
        changeText(e){
            console.log(111111,e)
            this.content = e
        },
        add(){
            this.content = ''
            this.id = ''
            this.show = true
        },
        edit(row){
            this.content = row.content
            this.id = row._id.$id
            this.show = true
        },
        confirm(){
            updateText({
                id:this.id,
                content:this.content
            }).then(res=>{
                    this.fetch()
                    this.show = false
                })
        },
        remove(id){
            this.$confirm('是否删除该条数据','提示',{
                confirmButtonText:'确定',
                cancelButtonText:'取消',
                type:'warning'
            }).then(()=>{
                removeText({
                    id:id
                }).then(res=>{
                    this.fetch()
                })
            }).catch(()=>{})
        }
    }
}
</script>

<style>

</style>