<template>
  <div class="container" v-loading="loading">
    <div id="filter_bar">
      <!-- <span style="margin-left:26px">类型：</span>
            <el-select size="mini" v-model="type" @change="fetchData">
                <el-option value="all" label="全部"></el-option>
                <el-option v-for="item in type_arr" :label="item" :value="item" :key="item"></el-option>
            </el-select> -->
      <!--  <span style="margin-left:26px">状态：</span> -->
      <!--    <el-select size="mini" v-model="status" @change="fetchData">
                <el-option value="all" label="全部"></el-option>
                <el-option label="待处理" value="待处理"></el-option>
                <el-option label="已完成" value="已完成"></el-option>
            </el-select> -->

      <el-button
        @click="fetchAllData"
        type="primary"
        style="margin-left: 20px; background-color: lightBlue; border: none"
        v-if="data_type == 'filter'"
        >全部</el-button
      >
      <el-button
        @click="fetchAllData"
        type="primary"
        style="
          margin-left: 20px;
          background-color: white;
          border: 1px solid lightBlue;
          color: lightBlue;
        "
        v-if="data_type != 'filter'"
        plain
        >全部</el-button
      >
      <el-button
        @click="getMyAssignedTodos"
        type="primary"
        style="margin-left: 20px; background-color: orange; border: none"
        v-if="data_type == 'assign'"
        >我负责的</el-button
      >
      <el-button
        @click="getMyAssignedTodos"
        type="primary"
        style="
          margin-left: 20px;
          background-color: white;
          border: 1px solid orange;
          color: orange;
        "
        v-if="data_type != 'assign'"
        plain
        >我负责的</el-button
      >
      <el-button
        @click="getMySubs"
        type="primary"
        style="margin-left: 20px; background-color: red; border: none"
        v-if="data_type == 'sub'"
        >我要做的({{ myTodoNum }})</el-button
      >
      <el-button
        @click="getMySubs"
        type="primary"
        style="
          margin-left: 20px;
          background-color: white;
          border: 1px solid red;
          color: red;
        "
        v-if="data_type != 'sub'"
        plain
        >我要做的({{ myTodoNum }})</el-button
      >
      <el-button
        @click="getTeamRecords"
        type="primary"
        style="margin-left: 20px; background-color: blue; border: none"
        v-if="data_type == 'team'"
        >团队动向</el-button
      >
      <el-button
        @click="getTeamRecords"
        type="primary"
        style="
          margin-left: 20px;
          background-color: white;
          border: 1px solid blue;
          color: blue;
        "
        v-if="data_type != 'team'"
        plain
        >团队动向</el-button
      >
      <el-button
        @click="getCompleteTodos"
        type="primary"
        style="margin-left: 20px; background-color: green; border: none"
        v-if="data_type == 'complete'"
        >已完成的</el-button
      >

      <el-button
        @click="getCompleteTodos"
        type="primary"
        style="
          margin-left: 20px;
          background-color: white;
          border: 1px solid green;
          color: green;
        "
        v-if="data_type != 'complete'"
        plain
        >已完成的</el-button
      >
      <el-button
        @click="getPublishTodos"
        type="primary"
        style="margin-left: 20px; background-color: lightGreen; border: none"
        v-if="data_type == 'publish'"
        >待发布的({{ PublishNum }})</el-button
      >
      <el-button
        @click="getPublishTodos"
        type="primary"
        style="
          margin-left: 20px;
          background-color: white;
          border: 1px solid lightGreen;
          color: lightGreen;
        "
        v-if="data_type != 'publish'"
        plain
        >待发布的({{ PublishNum }})</el-button
      >
      <el-button
        @click="getDesignTodos"
        type="primary"
        style="margin-left: 20px; background-color: orange; border: none"
        v-if="data_type == 'design'"
        >外包设计中的({{ DesignTodosNum }})</el-button
      >
      <el-button
        @click="getDesignTodos"
        type="primary"
        style="
          margin-left: 20px;
          background-color: white;
          border: 1px solid orange;
          color: orange;
        "
        v-if="data_type != 'design'"
        plain
        >外包设计中的({{ DesignTodosNum }})</el-button
      >
      <el-button
        @click="getPriorityTodos"
        type="primary"
        style="margin-left: 20px; background-color: red; border: none"
        v-if="data_type == 'priority'"
        >重要紧急</el-button
      >
      <el-button
        @click="getPriorityTodos"
        type="primary"
        style="
          margin-left: 20px;
          background-color: white;
          border: 1px solid red;
          color: red;
        "
        v-if="data_type != 'priority'"
        plain
        >重要紧急</el-button
      >
      <el-button @click="addTodo" type="primary" style="margin-left: 50px"
        >添加Todo</el-button
      >

      <div
        style="flex-wrap;height:26px;justify-content:flex-start;margin: 20px"
      >
        <span>业务：</span>
        <el-select size="mini" v-model="project" @change="fetchData">
          <el-option value="all" label="全部"></el-option>
          <el-option
            v-for="item in brands"
            :label="item.brand"
            :value="item.brand"
            :key="item.brand"
          ></el-option>
        </el-select>
        <span style="margin-left: 26px">形式：</span>
        <el-select size="mini" v-model="format" @change="fetchData">
          <el-option value="all" label="全部"></el-option>
          <el-option
            v-for="item in format_arr"
            :label="item"
            :value="item"
            :key="item"
          ></el-option>
        </el-select>

        <el-input
          style="width: 200px; margin-left: 50px"
          v-model="keywords"
          placeholder="输入搜索关键字"
          @keyup.enter.native="searchTODOList"
        >
        </el-input>
      </div>
    </div>
    <div id="view_bar" style="margin: 10px 0px">
      <span style="margin-left: 0px">视图选择：</span>
      <el-tag
        v-if="view_type == 'list'"
        size="small"
        style="margin-left: 10px"
        effect="dark"
        @click="changeView('list')"
        >列表视图</el-tag
      >
      <el-tag
        v-if="view_type != 'list'"
        size="small"
        style="margin-left: 10px"
        effect="light"
        @click="changeView('list')"
        >列表视图</el-tag
      >
      <el-tag
        v-if="view_type == 'project'"
        size="small"
        style="margin-left: 10px"
        effect="dark"
        @click="changeView('project')"
        >分业务视图</el-tag
      >
      <el-tag
        v-if="view_type != 'project'"
        size="small"
        style="margin-left: 10px"
        effect="light"
        @click="changeView('project')"
        >分业务视图</el-tag
      >
      <el-tag
        v-if="view_type == 'type'"
        size="small"
        style="margin-left: 10px"
        effect="dark"
        @click="changeView('type')"
        >分类型视图</el-tag
      >
      <el-tag
        v-if="view_type != 'type'"
        size="small"
        style="margin-left: 10px"
        effect="light"
        @click="changeView('type')"
        >分类型视图</el-tag
      >
      <el-tag
        v-if="view_type == 'taskforce'"
        size="small"
        style="margin-left: 10px"
        effect="dark"
        @click="changeView('taskforce')"
        >分人员视图（仅待办）</el-tag
      >
      <el-tag
        v-if="view_type != 'taskforce'"
        size="small"
        style="margin-left: 10px"
        effect="light"
        @click="changeView('taskforce')"
        >分人员视图（仅待办）</el-tag
      >
      <el-tag
        v-if="view_type == 'sub'"
        size="small"
        style="margin-left: 10px"
        effect="dark"
        @click="changeView('sub')"
        >分子任务视图（仅待办）</el-tag
      >
      <el-tag
        v-if="view_type != 'sub'"
        size="small"
        style="margin-left: 10px"
        effect="light"
        @click="changeView('sub')"
        >分子任务视图（仅待办）</el-tag
      >
      <el-tag
        v-if="view_type == 'sourcer'"
        size="small"
        style="margin-left: 10px"
        effect="dark"
        @click="changeView('sourcer')"
        >分供应商视图</el-tag
      >
      <el-tag
        v-if="view_type != 'sourcer'"
        size="small"
        style="margin-left: 10px"
        effect="light"
        @click="changeView('sourcer')"
        >分供应商视图</el-tag
      >
      <el-tag
        v-if="view_type == 'date'"
        size="small"
        style="margin-left: 10px"
        effect="dark"
        @click="changeView('date')"
        >日历视图</el-tag
      >
      <el-tag
        v-if="view_type != 'date'"
        size="small"
        style="margin-left: 10px"
        effect="light"
        @click="changeView('date')"
        >日历视图</el-tag
      >
    </div>

    <div v-for="item in todos" :key="item._id.$id" @click="initEdit(item)">
      <div v-if="view_type == 'list'" class="todo">
        <div
          style="
            font-size: 13px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: space-between;
          "
        >
          <div>
            <span>所属业务：{{ item.project }}</span>
            <span style="margin-left: 30px">形式：{{ item.format }}</span>
            <span style="margin-left: 30px">类型：{{ item.type }}</span>
            <!-- <span style="margin-left:30px">内容发布pi平台：{{item.wechat}}</span> -->
            <!-- <span style="margin-left:30px">内容名称：{{item.article_name}}</span> -->

            <!-- //：{{item.article_address}} -->
            <span style="margin-left: 30px"
              >主负责人：<span
                v-for="x in item.userNames"
                :key="x"
                style="margin-left: 10px; font-weight: bold"
                >{{ x }}</span
              ></span
            >
            <span style="margin-left: 30px">整体状态：</span>
            <el-tag
              size="small"
              effect="dark"
              :type="
                item.status === '待处理' || item.status === '设计中'
                  ? 'warning'
                  : 'success'
              "
              >{{ item.status }}</el-tag
            >

            <span style="margin-left: 20px" v-if="item.date"
              >计划发布日期：{{ formateDate(item.date) }}</span
            >
          </div>
          <div style="display: flex; align-items: center">
            <a
              v-if="item.article_address && item.article_address != ''"
              :href="item.article_address"
              target="_blank"
              ><img src="../../assets/linked.png" class="link_img"
            /></a>
            <img
              v-if="!item.article_address || item.article_address == ''"
              src="../../assets/link.png"
              class="link_img"
            />
            <i
              class="el-icon-star-off todo-icon-star"
              v-show="!item.star_status"
              @click.stop="changeProjectTodoStarStatus(item, true)"
            ></i>
            <i
              class="el-icon-star-on todo-icon-star"
              v-show="item.star_status"
              @click.stop="changeProjectTodoStarStatus(item, false)"
            ></i>
            <img
              src="../../assets/jinji.png"
              class="todo-icon-fast"
              v-show="item.fast_status"
              @click.stop="changeProjectTodoFastStatus(item, false)"
            />
            <img
              src="../../assets/jinji1.png"
              class="todo-icon-fast"
              v-show="!item.fast_status"
              @click.stop="changeProjectTodoFastStatus(item, true)"
            />
          </div>
        </div>
        <!-- <div style="margin-top:8px;font-size:18px;margin-bottom:4px">{{item.content}}</div> -->
        <div style="margin-top: 8px; font-size: 18px; margin-bottom: 4px">
          {{ item.article_name }}
        </div>
        <div style="font-size: 8px; margin-top: 10px">
          <div style="display: flex; flex-wrap: wrap">
            <span style="font-size: 8px">我的任务：</span>
            <div v-for="(sub, index) in item.all_subs" :key="index">
              <el-checkbox
                style="margin-left: 10px"
                v-if="
                  sub.user_ids &&
                  sub.user_ids[0] === user_id &&
                  (data_type === 'publish' || !sub.finish_status)
                "
                @change="
                  updateTodoSubFinishStatusHome(
                    sub._id.$id,
                    sub.type,
                    item._id.$id
                  )
                "
                @click.native="stopDefault($event)"
                :disabled="!sub.require_status"
                size="small"
                v-model="sub.finish_status"
              >
                <div :class="sub.finish_status ? 'deleteline' : ''">
                  <div>
                    {{ sub.content }}
                    <span v-if="sub.plan_date" style="font-size: 12px"
                      >计划发布日期：{{ formateDate(sub.plan_date) }}</span
                    >
                  </div>
                </div>
              </el-checkbox>
            </div>
          </div>
          <div style="display: flex; flex-wrap: wrap">
            <span style="font-size: 8px">同事任务：</span>
            <div v-for="(sub, index) in item.all_subs" :key="index">
              <el-checkbox
                style="margin-left: 10px"
                v-if="
                  sub.user_ids &&
                  sub.user_ids.length > 0 &&
                  sub.user_ids[0] !== user_id &&
                  (data_type === 'publish' || !sub.finish_status)
                "
                @change="
                  updateTodoSubFinishStatusHome(
                    sub._id.$id,
                    sub.type,
                    item._id.$id
                  )
                "
                @click.native="stopDefault($event)"
                disabled
                size="small"
                v-model="sub.finish_status"
              >
                <div :class="sub.finish_status ? 'deleteline' : ''">
                  <div>
                    {{ sub.content }}
                    <span v-if="sub.plan_date" style="font-size: 12px"
                      >计划发布日期：{{ formateDate(sub.plan_date) }}</span
                    >
                  </div>
                </div>
              </el-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="view_type == 'project'"
      class="outContainer"
      :style="{ height: height + 'px' }"
    >
      <div
        v-for="(project_todos, project_name, index) in todos_by_project"
        :key="index"
        class="project_view"
      >
        <div
          class="column_header"
          style="display: flex; flex-wrap: wrap; justify-content: space-between"
        >
          <div>{{ project_name }} ({{ project_todos.length }})</div>
          <div style="display: flex; flex-wrap: wrap">
            <img
              v-if="showIm[index]"
              src="../../assets/importance.png"
              style="
                display: flex;
                width: 35px;
                height: 26px;
                margin-left: 12px;
              "
              @click.stop="
                filterByImportanceFromProject(
                  project_todos,
                  project_name,
                  'project',
                  index
                )
              "
            />
            <img
              v-if="!showIm[index]"
              src="../../assets/unImportance.png"
              style="
                display: flex;
                width: 35px;
                height: 26px;
                margin-left: 12px;
              "
              @click.stop="
                filterByImportanceFromProject(
                  project_todos,
                  project_name,
                  'project',
                  index
                )
              "
            />

            <img
              v-if="showIm[index]"
              src="../../assets/unFilterTime.png"
              style="
                display: flex;
                width: 35px;
                height: 26px;
                margin-left: 12px;
                margin-right: 20px;
              "
              @click.stop="
                filterByImportanceFromTime(
                  project_todos,
                  project_name,
                  'project',
                  index
                )
              "
            />
            <img
              v-if="!showIm[index]"
              src="../../assets/filterTime.png"
              style="
                display: flex;
                width: 35px;
                height: 26px;
                margin-left: 12px;
                margin-right: 20px;
              "
              @click.stop="
                filterByImportanceFromTime(
                  project_todos,
                  project_name,
                  'project',
                  index
                )
              "
            />
          </div>
        </div>

        <!-- <el-button @click="filterByImportanceFromProject(project_todos,project_name,'project')"  plain icon="../../assets/importance.png"></el-button> -->

        <!-- <el-button @click="filterByImportanceFromTime(project_todos,project_name,'project')" type="primary" plain>按照发布时间排序</el-button> -->
        <div :style="{ height: scrollHeight + 'px' }" class="inContainer">
          <div
            v-for="item in project_todos"
            :key="item._id.$id"
            class="todo_box"
            @click="initEdit(item)"
            :class="format_css_arr[item.format]"
          >
            <div>
              <div
                style="
                  margin-bottom: 8px;
                  display: flex;
                  align-items: center;
                  flex-wrap: wrap;
                  justify-content: space-between;
                "
              >
                <div>
                  <span class="info_small_header"
                    >{{ item.type }}{{ item.format }}</span
                  >

                  <!-- <span style="margin-left:30px">内容发布pi平台：{{item.wechat}}</span> -->
                  <!-- <span style="margin-left:30px">内容名称：{{item.article_name}}</span> -->

                  <!-- //：{{item.article_address}} -->
                  <span class="info_small_header"
                    ><span
                      v-for="x in item.userNames"
                      :key="x"
                      style="margin-left: 10px; font-weight: bold"
                      >{{ x }}</span
                    ></span
                  >
                  <span class="info_small_header"></span>
                  <el-tag
                    class="info_small_header"
                    size="small"
                    effect="dark"
                    :type="
                      item.status === '待处理' || item.status === '设计中'
                        ? 'warning'
                        : 'success'
                    "
                    >{{ item.status }}</el-tag
                  >
                </div>

                <div style="display: flex; align-items: center">
                  <a
                    v-if="item.article_address && item.article_address != ''"
                    :href="item.article_address"
                    target="_blank"
                    ><img src="../../assets/linked.png" class="link_img"
                  /></a>
                  <img
                    v-if="!item.article_address || item.article_address == ''"
                    src="../../assets/link.png"
                    class="link_img"
                  />
                  <i
                    class="el-icon-star-off todo-icon-star"
                    v-show="!item.star_status"
                    @click.stop="changeProjectTodoStarStatus(item, true)"
                  ></i>
                  <i
                    class="el-icon-star-on todo-icon-star"
                    v-show="item.star_status"
                    @click.stop="changeProjectTodoStarStatus(item, false)"
                  ></i>
                  <img
                    src="../../assets/jinji.png"
                    class="todo-icon-fast"
                    v-show="item.fast_status"
                    @click.stop="changeProjectTodoFastStatus(item, false)"
                  />
                  <img
                    src="../../assets/jinji1.png"
                    class="todo-icon-fast"
                    v-show="!item.fast_status"
                    @click.stop="changeProjectTodoFastStatus(item, true)"
                  />
                </div>
              </div>
              <div>{{ item.article_name }}</div>
              <div style="font-size: 8px; margin-top: 10px">
                <div style="display: flex; flex-wrap: wrap">
                  <span style="font-size: 8px">我的任务：</span>
                  <div v-for="(sub, index) in item.all_subs" :key="index">
                    <el-checkbox
                      style="margin-left: 10px"
                      v-if="
                        sub.user_ids &&
                        sub.user_ids[0] === user_id &&
                        ((data_type === 'publish' && sub.type === 'publish') ||
                          !sub.finish_status)
                      "
                      @change="
                        updateTodoSubFinishStatusHome(
                          sub._id.$id,
                          sub.type,
                          item._id.$id
                        )
                      "
                      @click.native="stopDefault($event)"
                      :disabled="!sub.require_status"
                      size="small"
                      v-model="sub.finish_status"
                    >
                      <div :class="sub.finish_status ? 'deleteline' : ''">
                        <div>
                          {{ sub.content }}
                          <span v-if="sub.plan_date" style="font-size: 12px"
                            >计划发布日期：{{
                              formateDate(sub.plan_date)
                            }}</span
                          >
                        </div>
                      </div>
                    </el-checkbox>
                  </div>
                </div>
                <div style="display: flex; flex-wrap: wrap">
                  <span style="font-size: 8px">同事任务：</span>
                  <div v-for="(sub, index) in item.all_subs" :key="index">
                    <el-checkbox
                      style="margin-left: 10px"
                      v-if="
                        sub.user_ids &&
                        sub.user_ids.length > 0 &&
                        sub.user_ids[0] !== user_id &&
                        ((data_type === 'publish' && sub.type === 'publish') ||
                          !sub.finish_status)
                      "
                      @change="
                        updateTodoSubFinishStatusHome(
                          sub._id.$id,
                          sub.type,
                          item._id.$id
                        )
                      "
                      @click.native="stopDefault($event)"
                      disabled
                      size="small"
                      v-model="sub.finish_status"
                    >
                      <div :class="sub.finish_status ? 'deleteline' : ''">
                        <div>
                          {{ sub.content }}
                          <span v-if="sub.plan_date" style="font-size: 12px"
                            >计划发布日期：{{
                              formateDate(sub.plan_date)
                            }}</span
                          >
                        </div>
                      </div>
                    </el-checkbox>
                  </div>
                </div>
              </div>
              <div style="text-align: right; color: rgb(250, 84, 120)">
                <span class="info_small_header" v-if="item.date"
                  >计划发布：{{ formateDate(item.date) }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="view_type == 'type'"
      class="outContainer"
      :style="{ height: height + 'px' }"
    >
      <div
        v-for="(type_todos, type_name, index) in todos_by_type"
        :key="index"
        class="project_view"
      >
        <div
          class="column_header"
          style="display: flex; flex-wrap: wrap; justify-content: space-between"
        >
          <div>{{ type_name }} ({{ type_todos.length }})</div>
          <div style="display: flex; flex-wrap: wrap">
            <img
              v-if="showImType[index]"
              src="../../assets/importance.png"
              style="width: 35px; height: 26px; margin-left: 12px"
              @click.stop="
                filterByImportanceFromProject(
                  type_todos,
                  type_name,
                  'type',
                  index
                )
              "
            />
            <img
              v-if="!showImType[index]"
              src="../../assets/unImportance.png"
              style="width: 35px; height: 26px; margin-left: 12px"
              @click.stop="
                filterByImportanceFromProject(
                  type_todos,
                  type_name,
                  'type',
                  index
                )
              "
            />

            <img
              v-if="showImType[index]"
              src="../../assets/unFilterTime.png"
              style="
                width: 35px;
                height: 26px;
                margin-left: 12px;
                margin-right: 20px;
              "
              @click.stop="
                filterByImportanceFromTime(type_todos, type_name, 'type', index)
              "
            />
            <img
              v-if="!showImType[index]"
              src="../../assets/filterTime.png"
              style="
                width: 35px;
                height: 26px;
                margin-left: 12px;
                margin-right: 20px;
              "
              @click.stop="
                filterByImportanceFromTime(type_todos, type_name, 'type', index)
              "
            />
          </div>
        </div>
        <!-- <el-button @click="filterByImportanceFromProject(type_todos,type_name,'type')" type="danger" plain>按照重要性排序</el-button>
                <el-button @click="filterByImportanceFromTime(type_todos,type_name,'type')" type="primary" plain>按照发布时间排序</el-button> -->

        <div :style="{ height: scrollHeight + 'px' }" class="inContainer">
          <div
            v-for="item in type_todos"
            :key="item._id.$id"
            class="todo_box"
            @click="initEdit(item)"
            :class="format_css_arr[item.format]"
          >
            <div>
              <div
                style="
                  margin-bottom: 8px;
                  display: flex;
                  align-items: center;
                  flex-wrap: wrap;
                  justify-content: space-between;
                "
              >
                <div>
                  <span class="info_small_header"
                    >{{ item.type }}{{ item.format }}</span
                  >

                  <!-- <span style="margin-left:30px">内容发布pi平台：{{item.wechat}}</span> -->
                  <!-- <span style="margin-left:30px">内容名称：{{item.article_name}}</span> -->

                  <!-- //：{{item.article_address}} -->
                  <span class="info_small_header"
                    ><span
                      v-for="x in item.userNames"
                      :key="x"
                      style="margin-left: 10px; font-weight: bold"
                      >{{ x }}</span
                    ></span
                  >
                  <span class="info_small_header"></span>
                  <el-tag
                    class="info_small_header"
                    size="small"
                    effect="dark"
                    :type="
                      item.status === '待处理' || item.status === '设计中'
                        ? 'warning'
                        : 'success'
                    "
                    >{{ item.status }}</el-tag
                  >
                </div>
                <div style="display: flex; align-items: center">
                  <a
                    v-if="item.article_address && item.article_address != ''"
                    :href="item.article_address"
                    target="_blank"
                    ><img src="../../assets/linked.png" class="link_img"
                  /></a>
                  <img
                    v-if="!item.article_address || item.article_address == ''"
                    src="../../assets/link.png"
                    class="link_img"
                  />
                  <i
                    class="el-icon-star-off todo-icon-star"
                    v-show="!item.star_status"
                    @click.stop="changeProjectTodoStarStatus(item, true)"
                  ></i>
                  <i
                    class="el-icon-star-on todo-icon-star"
                    v-show="item.star_status"
                    @click.stop="changeProjectTodoStarStatus(item, false)"
                  ></i>
                  <img
                    src="../../assets/jinji.png"
                    class="todo-icon-fast"
                    v-show="item.fast_status"
                    @click.stop="changeProjectTodoFastStatus(item, false)"
                  />
                  <img
                    src="../../assets/jinji1.png"
                    class="todo-icon-fast"
                    v-show="!item.fast_status"
                    @click.stop="changeProjectTodoFastStatus(item, true)"
                  />
                </div>
              </div>
              <div>{{ item.article_name }}</div>
              <div style="font-size: 8px; margin-top: 10px">
                <div style="display: flex; flex-wrap: wrap">
                  <span style="font-size: 8px">我的任务：</span>
                  <div v-for="(sub, index) in item.all_subs" :key="index">
                    <el-checkbox
                      style="margin-left: 10px"
                      v-if="
                        sub.user_ids &&
                        sub.user_ids[0] === user_id &&
                        (data_type === 'publish' || !sub.finish_status)
                      "
                      @change="
                        updateTodoSubFinishStatusHome(
                          sub._id.$id,
                          sub.type,
                          item._id.$id
                        )
                      "
                      @click.native="stopDefault($event)"
                      :disabled="!sub.require_status"
                      size="small"
                      v-model="sub.finish_status"
                    >
                      <div :class="sub.finish_status ? 'deleteline' : ''">
                        <div>
                          {{ sub.content }}
                          <span v-if="sub.plan_date" style="font-size: 12px"
                            >计划发布日期：{{
                              formateDate(sub.plan_date)
                            }}</span
                          >
                        </div>
                      </div>
                    </el-checkbox>
                  </div>
                </div>
                <div style="display: flex; flex-wrap: wrap">
                  <span style="font-size: 8px">同事任务：</span>
                  <div v-for="(sub, index) in item.all_subs" :key="index">
                    <el-checkbox
                      style="margin-left: 10px"
                      v-if="
                        sub.user_ids &&
                        sub.user_ids.length > 0 &&
                        sub.user_ids[0] !== user_id &&
                        (data_type === 'publish' || !sub.finish_status)
                      "
                      @change="
                        updateTodoSubFinishStatusHome(
                          sub._id.$id,
                          sub.type,
                          item._id.$id
                        )
                      "
                      @click.native="stopDefault($event)"
                      disabled
                      size="small"
                      v-model="sub.finish_status"
                    >
                      <div :class="sub.finish_status ? 'deleteline' : ''">
                        <div>
                          {{ sub.content }}
                          <span v-if="sub.plan_date" style="font-size: 12px"
                            >计划发布日期：{{
                              formateDate(sub.plan_date)
                            }}</span
                          >
                        </div>
                      </div>
                    </el-checkbox>
                  </div>
                </div>

                <!-- <span v-for="sub in item.my_subs" style="margin-left:30px">{{sub.content}}</span> -->
              </div>
              <div style="text-align: right; color: rgb(250, 84, 120)">
                <span class="info_small_header" v-if="item.date"
                  >计划发布：{{ formateDate(item.date) }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="view_type == 'date'"
      class="outContainer"
      :style="{ height: height + 'px' }"
    >
      <div
        v-for="(project_todos, date, index) in todos_by_date"
        :key="index"
        class="project_view"
      >
        <div
          class="column_header"
          style="display: flex; flex-wrap: wrap; justify-content: space-between"
        >
          <div>{{ formateDate(date) }} ({{ project_todos.length }})</div>

          <div>
            <img
              v-if="showImDate[index]"
              src="../../assets/importance.png"
              style="width: 35px; height: 26px; margin-left: 12px"
              @click.stop="
                filterByImportanceFromProject(
                  project_todos,
                  date,
                  'date',
                  index
                )
              "
            />
            <img
              v-if="!showImDate[index]"
              src="../../assets/unImportance.png"
              style="width: 35px; height: 26px; margin-left: 12px"
              @click.stop="
                filterByImportanceFromProject(
                  project_todos,
                  date,
                  'date',
                  index
                )
              "
            />

            <img
              v-if="showImDate[index]"
              src="../../assets/unFilterTime.png"
              style="
                width: 35px;
                height: 26px;
                margin-left: 12px;
                margin-right: 20px;
              "
              @click.stop="
                filterByImportanceFromTime(project_todos, date, 'date', index)
              "
            />
            <img
              v-if="!showImDate[index]"
              src="../../assets/filterTime.png"
              style="
                width: 35px;
                height: 26px;
                margin-left: 12px;
                margin-right: 20px;
              "
              @click.stop="
                filterByImportanceFromTime(project_todos, date, 'date', index)
              "
            />
          </div>
        </div>
        <div
          style="flex-wrap;height:26px;justify-content:flex-start;margin-left: 12px"
        >
          {{ formateDay(date) }}
        </div>
        <!-- <el-button @click="filterByImportanceFromProject(project_todos,date,'date')" type="danger" plain>按照重要性排序</el-button> -->

        <!-- <el-button @click="filterByImportanceFromTime(project_todos,date,'date')" type="primary" plain>按照发布时间排序</el-button> -->
        <div :style="{ height: scrollHeight + 'px' }" class="inContainer">
          <div
            v-for="item in project_todos"
            :key="item._id.$id"
            class="todo_box"
            @click="initEdit(item)"
            :class="format_css_arr[item.format]"
          >
            <div>
              <div
                style="
                  margin-bottom: 8px;
                  display: flex;
                  align-items: center;
                  flex-wrap: wrap;
                  justify-content: space-between;
                "
              >
                <div>
                  <span class="info_small_header"
                    >{{ item.type }}{{ item.format }}</span
                  >

                  <!-- <span style="margin-left:30px">内容发布pi平台：{{item.wechat}}</span> -->
                  <!-- <span style="margin-left:30px">内容名称：{{item.article_name}}</span> -->

                  <!-- //：{{item.article_address}} -->
                  <span class="info_small_header"
                    ><span
                      v-for="x in item.userNames"
                      :key="x"
                      style="margin-left: 10px; font-weight: bold"
                      >{{ x }}</span
                    ></span
                  >
                  <span class="info_small_header"></span>
                  <el-tag
                    class="info_small_header"
                    size="small"
                    effect="dark"
                    :type="
                      item.status === '待处理' || item.status === '设计中'
                        ? 'warning'
                        : 'success'
                    "
                    >{{ item.status }}</el-tag
                  >
                </div>

                <div style="display: flex; align-items: center">
                  <a
                    v-if="item.article_address && item.article_address != ''"
                    :href="item.article_address"
                    target="_blank"
                    ><img src="../../assets/linked.png" class="link_img"
                  /></a>
                  <img
                    v-if="!item.article_address || item.article_address == ''"
                    src="../../assets/link.png"
                    class="link_img"
                  />
                  <i
                    class="el-icon-star-off todo-icon-star"
                    v-show="!item.star_status"
                    @click.stop="changeProjectTodoStarStatus(item, true)"
                  ></i>
                  <i
                    class="el-icon-star-on todo-icon-star"
                    v-show="item.star_status"
                    @click.stop="changeProjectTodoStarStatus(item, false)"
                  ></i>
                  <img
                    src="../../assets/jinji.png"
                    class="todo-icon-fast"
                    v-show="item.fast_status"
                    @click.stop="changeProjectTodoFastStatus(item, false)"
                  />
                  <img
                    src="../../assets/jinji1.png"
                    class="todo-icon-fast"
                    v-show="!item.fast_status"
                    @click.stop="changeProjectTodoFastStatus(item, true)"
                  />
                </div>
              </div>
              <div>{{ item.article_name }}</div>
              <div style="font-size: 8px; margin-top: 10px">
                <div style="display: flex; flex-wrap: wrap">
                  <span style="font-size: 8px">我的任务：</span>
                  <div v-for="(sub, index) in item.all_subs" :key="index">
                    <el-checkbox
                      style="margin-left: 10px"
                      v-if="
                        sub.user_ids &&
                        sub.user_ids[0] === user_id &&
                        (data_type === 'publish' || !sub.finish_status)
                      "
                      @change="
                        updateTodoSubFinishStatusHome(
                          sub._id.$id,
                          sub.type,
                          item._id.$id
                        )
                      "
                      @click.native="stopDefault($event)"
                      :disabled="!sub.require_status"
                      size="small"
                      v-model="sub.finish_status"
                    >
                      <div :class="sub.finish_status ? 'deleteline' : ''">
                        <div>
                          {{ sub.content }}
                          <span v-if="sub.plan_date" style="font-size: 12px"
                            >计划发布日期：{{
                              formateDate(sub.plan_date)
                            }}</span
                          >
                        </div>
                      </div>
                    </el-checkbox>
                  </div>
                </div>
                <div style="display: flex; flex-wrap: wrap">
                  <span style="font-size: 8px">同事任务：</span>
                  <div v-for="(sub, index) in item.all_subs" :key="index">
                    <el-checkbox
                      style="margin-left: 10px"
                      v-if="
                        sub.user_ids &&
                        sub.user_ids.length > 0 &&
                        sub.user_ids[0] !== user_id &&
                        (data_type === 'publish' || !sub.finish_status)
                      "
                      @change="
                        updateTodoSubFinishStatusHome(
                          sub._id.$id,
                          sub.type,
                          item._id.$id
                        )
                      "
                      @click.native="stopDefault($event)"
                      disabled
                      size="small"
                      v-model="sub.finish_status"
                    >
                      <div :class="sub.finish_status ? 'deleteline' : ''">
                        <div>
                          {{ sub.content }}
                          <span v-if="sub.plan_date" style="font-size: 12px"
                            >计划发布日期：{{
                              formateDate(sub.plan_date)
                            }}</span
                          >
                        </div>
                      </div>
                    </el-checkbox>
                  </div>
                </div>

                <!-- <span v-for="sub in item.my_subs" style="margin-left:30px">{{sub.content}}</span> -->
              </div>
              <div style="text-align: right; color: rgb(250, 84, 120)">
                <span class="info_small_header" v-if="item.date"
                  >计划发布：{{ formateDate(item.date) }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="view_type == 'taskforce'"
      class="outContainer"
      :style="{ height: height + 'px' }"
    >
      <div
        v-for="(type_todos, type_name, index) in todos_by_taskforce"
        :key="index"
        class="project_view"
      >
        <div
          class="column_header"
          style="display: flex; flex-wrap: wrap; justify-content: space-between"
        >
          <div>{{ type_name }} ({{ type_todos.length }})</div>
          <div>
            <img
              v-if="showImTaskforce[index]"
              src="../../assets/importance.png"
              style="width: 35px; height: 26px; margin-left: 12px"
              @click.stop="
                filterByImportanceFromProject(
                  type_todos,
                  type_name,
                  'taskforce',
                  index
                )
              "
            />
            <img
              v-if="!showImTaskforce[index]"
              src="../../assets/unImportance.png"
              style="width: 35px; height: 26px; margin-left: 12px"
              @click.stop="
                filterByImportanceFromProject(
                  type_todos,
                  type_name,
                  'taskforce',
                  index
                )
              "
            />

            <img
              v-if="showImTaskforce[index]"
              src="../../assets/unFilterTime.png"
              style="
                width: 35px;
                height: 26px;
                margin-left: 12px;
                margin-right: 20px;
              "
              @click.stop="
                filterByImportanceFromTime(
                  type_todos,
                  type_name,
                  'taskforce',
                  index
                )
              "
            />
            <img
              v-if="!showImTaskforce[index]"
              src="../../assets/filterTime.png"
              style="
                width: 35px;
                height: 26px;
                margin-left: 12px;
                margin-right: 20px;
              "
              @click.stop="
                filterByImportanceFromTime(
                  type_todos,
                  type_name,
                  'taskforce',
                  index
                )
              "
            />
          </div>
        </div>
        <!-- <el-button @click="filterByImportanceFromProject(type_todos,type_name,'taskforce')" type="danger" plain>按照重要性排序</el-button>
                <el-button @click="filterByImportanceFromTime(type_todos,type_name,'taskforce')" type="primary" plain>按照发布时间排序</el-button> -->

        <div :style="{ height: scrollHeight + 'px' }" class="inContainer">
          <div
            v-for="item in type_todos"
            :key="item._id.$id"
            class="todo_box"
            @click="initEdit(item)"
            :class="format_css_arr[item.format]"
          >
            <div>
              <div
                style="
                  margin-bottom: 8px;
                  display: flex;
                  align-items: center;
                  flex-wrap: wrap;
                  justify-content: space-between;
                "
              >
                <div>
                  <span class="info_small_header">{{ item.project }}</span>
                  <span class="info_small_header"
                    >{{ item.type }}{{ item.format }}</span
                  >

                  <!-- <span style="margin-left:30px">内容发布pi平台：{{item.wechat}}</span> -->
                  <!-- <span style="margin-left:30px">内容名称：{{item.article_name}}</span> -->

                  <!-- //：{{item.article_address}} -->
                  <span class="info_small_header"
                    ><span
                      v-for="x in item.userNames"
                      :key="x"
                      style="margin-left: 10px; font-weight: bold"
                      >{{ x }}</span
                    ></span
                  >
                  <span class="info_small_header"></span>
                  <el-tag
                    class="info_small_header"
                    size="small"
                    effect="dark"
                    :type="
                      item.status === '待处理' || item.status === '设计中'
                        ? 'warning'
                        : 'success'
                    "
                    >{{ item.status }}</el-tag
                  >
                </div>

                <div style="display: flex; align-items: center">
                  <a
                    v-if="item.article_address && item.article_address != ''"
                    :href="item.article_address"
                    target="_blank"
                    ><img src="../../assets/linked.png" class="link_img"
                  /></a>
                  <img
                    v-if="!item.article_address || item.article_address == ''"
                    src="../../assets/link.png"
                    class="link_img"
                  />
                  <i
                    class="el-icon-star-off todo-icon-star"
                    v-show="!item.star_status"
                    @click.stop="changeProjectTodoStarStatus(item, true)"
                  ></i>
                  <i
                    class="el-icon-star-on todo-icon-star"
                    v-show="item.star_status"
                    @click.stop="changeProjectTodoStarStatus(item, false)"
                  ></i>
                  <img
                    src="../../assets/jinji.png"
                    class="todo-icon-fast"
                    v-show="item.fast_status"
                    @click.stop="changeProjectTodoFastStatus(item, false)"
                  />
                  <img
                    src="../../assets/jinji1.png"
                    class="todo-icon-fast"
                    v-show="!item.fast_status"
                    @click.stop="changeProjectTodoFastStatus(item, true)"
                  />
                </div>
              </div>
              <div>{{ item.article_name }}</div>
              <div
                style="
                  font-size: 8px;
                  margin-top: 10px;
                  display: flex;
                  flex-wrap: wrap;
                "
              >
                <span style="font-size: 8px">TA的分工：</span>

                <el-checkbox
                  v-for="(sub, index) in item.taskforce_subs"
                  :key="index"
                  @change="
                    updateTodoSubFinishStatusHome(
                      sub._id.$id,
                      sub.type,
                      item._id.$id
                    )
                  "
                  @click.native="stopDefault($event)"
                  :disabled="1 == 1"
                  size="small"
                  v-model="sub.finish_status"
                >
                  <div :class="sub.finish_status ? 'deleteline' : ''">
                    <div>
                      {{ sub.content }}
                      <span v-if="sub.plan_date" style="font-size: 12px"
                        >计划发布日期：{{ formateDate(sub.plan_date) }}</span
                      >
                    </div>
                  </div>
                </el-checkbox>
                <!-- <span v-for="sub in item.my_subs" style="margin-left:30px">{{sub.content}}</span> -->
              </div>
              <div style="text-align: right; color: rgb(250, 84, 120)">
                <span class="info_small_header" v-if="item.date"
                  >计划发布：{{ formateDate(item.date) }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="view_type == 'sub'"
      class="outContainer"
      :style="{ height: height + 'px' }"
    >
      <div
        v-for="(type_todos, type_name, index) in todos_by_sub"
        :key="index"
        class="project_view"
      >
        <div
          class="column_header"
          style="display: flex; flex-wrap: wrap; justify-content: space-between"
        >
          <div>{{ type_name }} ({{ type_todos.length }})</div>
          <div>
            <img
              v-if="showImSub[index]"
              src="../../assets/importance.png"
              style="width: 35px; height: 26px; margin-left: 12px"
              @click.stop="
                filterByImportanceFromProject(
                  type_todos,
                  type_name,
                  'sub',
                  index
                )
              "
            />
            <img
              v-if="!showImSub[index]"
              src="../../assets/unImportance.png"
              style="width: 35px; height: 26px; margin-left: 12px"
              @click.stop="
                filterByImportanceFromProject(
                  type_todos,
                  type_name,
                  'sub',
                  index
                )
              "
            />

            <img
              v-if="showImSub[index]"
              src="../../assets/unFilterTime.png"
              style="
                width: 35px;
                height: 26px;
                margin-left: 12px;
                margin-right: 20px;
              "
              @click.stop="
                filterByImportanceFromTime(type_todos, type_name, 'sub', index)
              "
            />
            <img
              v-if="!showImSub[index]"
              src="../../assets/filterTime.png"
              style="
                width: 35px;
                height: 26px;
                margin-left: 12px;
                margin-right: 20px;
              "
              @click.stop="
                filterByImportanceFromTime(type_todos, type_name, 'sub', index)
              "
            />
          </div>
        </div>
        <!-- <el-button @click="filterByImportanceFromProject(type_todos,type_name,'sub')" type="danger" plain>按照重要性排序</el-button>
                <el-button @click="filterByImportanceFromTime(type_todos,type_name,'sub')" type="primary" plain>按照发布时间排序</el-button> -->

        <div :style="{ height: scrollHeight + 'px' }" class="inContainer">
          <div
            v-for="item in type_todos"
            :key="item._id.$id"
            @click="initEdit(item)"
            :class="format_css_arr[item.format]"
          >
            <div>
              <div
                style="
                  margin-bottom: 8px;
                  display: flex;
                  align-items: center;
                  flex-wrap: wrap;
                  justify-content: space-between;
                "
              >
                <div>
                  <span class="info_small_header">{{ item.project }}</span>
                  <span class="info_small_header"
                    >{{ item.type }}{{ item.format }}</span
                  >

                  <!-- <span style="margin-left:30px">内容发布pi平台：{{item.wechat}}</span> -->
                  <!-- <span style="margin-left:30px">内容名称：{{item.article_name}}</span> -->

                  <!-- //：{{item.article_address}} -->
                  <span class="info_small_header"
                    ><span
                      v-for="x in item.userNames"
                      :key="x"
                      style="margin-left: 10px; font-weight: bold"
                      >{{ x }}</span
                    ></span
                  >
                  <span class="info_small_header"></span>
                  <el-tag
                    class="info_small_header"
                    size="small"
                    effect="dark"
                    :type="
                      item.status === '待处理' || item.status === '设计中'
                        ? 'warning'
                        : 'success'
                    "
                    >{{ item.status }}</el-tag
                  >
                </div>

                <div
                  style="
                    float: right;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                  "
                >
                  <a
                    v-if="item.article_address && item.article_address != ''"
                    :href="item.article_address"
                    target="_blank"
                    ><img src="../../assets/linked.png" class="link_img"
                  /></a>
                  <img
                    v-if="!item.article_address || item.article_address == ''"
                    src="../../assets/link.png"
                    class="link_img"
                  />
                  <i
                    class="el-icon-star-off todo-icon-star"
                    v-show="!item.star_status"
                    @click.stop="changeProjectTodoStarStatus(item, true)"
                  ></i>
                  <i
                    class="el-icon-star-on todo-icon-star"
                    v-show="item.star_status"
                    @click.stop="changeProjectTodoStarStatus(item, false)"
                  ></i>
                  <img
                    src="../../assets/jinji.png"
                    class="todo-icon-fast"
                    v-show="item.fast_status"
                    @click.stop="changeProjectTodoFastStatus(item, false)"
                  />
                  <img
                    src="../../assets/jinji1.png"
                    class="todo-icon-fast"
                    v-show="!item.fast_status"
                    @click.stop="changeProjectTodoFastStatus(item, true)"
                  />
                </div>
              </div>
              <div>{{ item.article_name }}</div>
              <div style="font-size: 8px; margin-top: 10px">
                <div style="display: flex; flex-wrap: wrap">
                  <span style="font-size: 8px">我的任务：</span>
                  <div v-for="(sub, index) in item.all_subs" :key="index">
                    <el-checkbox
                      style="margin-left: 10px"
                      v-if="
                        sub.user_ids &&
                        sub.user_ids[0] === user_id &&
                        (data_type === 'publish' || !sub.finish_status)
                      "
                      @change="
                        updateTodoSubFinishStatusHome(
                          sub._id.$id,
                          sub.type,
                          item._id.$id
                        )
                      "
                      @click.native="stopDefault($event)"
                      :disabled="!sub.require_status"
                      size="small"
                      v-model="sub.finish_status"
                    >
                      <div :class="sub.finish_status ? 'deleteline' : ''">
                        <div>
                          {{ sub.content }}
                          <span v-if="sub.plan_date" style="font-size: 12px"
                            >计划发布日期：{{
                              formateDate(sub.plan_date)
                            }}</span
                          >
                        </div>
                      </div>
                    </el-checkbox>
                  </div>
                </div>
                <div style="display: flex; flex-wrap: wrap">
                  <span style="font-size: 8px">同事任务：</span>
                  <div v-for="(sub, index) in item.all_subs" :key="index">
                    <el-checkbox
                      style="margin-left: 10px"
                      v-if="
                        sub.user_ids &&
                        sub.user_ids.length > 0 &&
                        sub.user_ids[0] !== user_id &&
                        (data_type === 'publish' || !sub.finish_status)
                      "
                      @change="
                        updateTodoSubFinishStatusHome(
                          sub._id.$id,
                          sub.type,
                          item._id.$id
                        )
                      "
                      @click.native="stopDefault($event)"
                      disabled
                      size="small"
                      v-model="sub.finish_status"
                    >
                      <div :class="sub.finish_status ? 'deleteline' : ''">
                        <div>
                          {{ sub.content }}
                          <span v-if="sub.plan_date" style="font-size: 12px"
                            >计划发布日期：{{
                              formateDate(sub.plan_date)
                            }}</span
                          >
                        </div>
                      </div>
                    </el-checkbox>
                  </div>
                </div>

                <!-- <span v-for="sub in item.my_subs" style="margin-left:30px">{{sub.content}}</span> -->
              </div>
              <div style="text-align: right; color: rgb(250, 84, 120)">
                <span class="info_small_header" v-if="item.date"
                  >计划发布：{{ formateDate(item.date) }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="view_type == 'team_records'"
      class="outContainer"
      :style="{ height: height + 'px' }"
    >
      <div
        v-for="(type_todos, type_name, index) in team_records"
        :key="index"
        class="project_view"
      >
        <div class="column_header">{{ type_name }}</div>
        <!-- <el-button @click="filterByImportanceFromProject(type_todos,type_name,'team_records')" type="danger" plain>按照重要性排序</el-button>
                <el-button @click="filterByImportanceFromTime(type_todos,type_name,'team_records')" type="primary" plain>按照发布时间排序</el-button> -->

        <!-- <img v-if="showImTeam_records[index]"  src="../../assets/importance.png" style="width:35px;height:26px;margin-left: 12px;" @click.stop="filterByImportanceFromProject(type_todos,type_name,'team_records',index)">
                <img v-if="!showImTeam_records[index]" src="../../assets/unImportance.png" style="width:35px;height:26px;margin-left: 12px;" @click.stop="filterByImportanceFromProject(type_todos,type_name,'team_records',index)">


                <img v-if="showImTeam_records[index]"  src="../../assets/unFilterTime.png" style="width:35px;height:26px;margin-left: 12px;" @click.stop="filterByImportanceFromTime(type_todos,type_name,'team_records',index)">
                <img v-if="!showImTeam_records[index]" src="../../assets/filterTime.png" style="width:35px;height:26px;margin-left: 12px;" @click.stop="filterByImportanceFromTime(type_todos,type_name,'team_records',index)"> -->

        <div :style="{ height: scrollHeight + 'px' }" class="inContainer">
          <div
            v-for="item in type_todos"
            :key="item._id.$id"
            class="todo_box"
            :class="format_css_arr[item.format]"
          >
            <div>
              <div
                style="
                  margin-bottom: 8px;
                  display: flex;
                  align-items: center;
                  flex-wrap: wrap;
                  justify-content: space-between;
                "
              >
                <div>
                  <span class="info_small_header">{{ item.project }}</span>
                  <span class="info_small_header"
                    >{{ item.todo_type }}{{ item.format }} 《{{
                      item.article_name
                    }}》</span
                  >

                  <!-- <span style="margin-left:30px">内容发布pi平台：{{item.wechat}}</span> -->
                  <!-- <span style="margin-left:30px">内容名称：{{item.article_name}}</span> -->

                  <!-- //：{{item.article_address}} -->
                </div>
              </div>
              <div>
                完成 {{ phases[item.type] }} {{ item.content }} [{{
                  formateTime(item.finish_time)
                }}]
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="view_type == 'sourcer'"
      class="outContainer"
      :style="{ height: height + 'px' }"
    >
      <div
        v-for="(type_todos, type_name, index) in todos_by_sourcer"
        :key="index"
        class="project_view"
      >
        <div
          class="column_header"
          style="display: flex; flex-wrap: wrap; justify-content: space-between"
        >
          <div>{{ type_name }} ({{ type_todos.length }})</div>
          <div>
            <img
              v-if="showImSourcer[index]"
              src="../../assets/importance.png"
              style="width: 35px; height: 26px; margin-left: 12px"
              @click.stop="
                filterByImportanceFromProject(
                  type_todos,
                  type_name,
                  'sourcer',
                  index
                )
              "
            />
            <img
              v-if="!showImSourcer[index]"
              src="../../assets/unImportance.png"
              style="width: 35px; height: 26px; margin-left: 12px"
              @click.stop="
                filterByImportanceFromProject(
                  type_todos,
                  type_name,
                  'sourcer',
                  index
                )
              "
            />

            <img
              v-if="showImSourcer[index]"
              src="../../assets/unFilterTime.png"
              style="
                width: 35px;
                height: 26px;
                margin-left: 12px;
                margin-right: 20px;
              "
              @click.stop="
                filterByImportanceFromTime(
                  type_todos,
                  type_name,
                  'sourcer',
                  index
                )
              "
            />
            <img
              v-if="!showImSourcer[index]"
              src="../../assets/filterTime.png"
              style="
                width: 35px;
                height: 26px;
                margin-left: 12px;
                margin-right: 20px;
              "
              @click.stop="
                filterByImportanceFromTime(
                  type_todos,
                  type_name,
                  'sourcer',
                  index
                )
              "
            />
          </div>
        </div>
        <!-- <el-button @click="filterByImportanceFromProject(type_todos,type_name,'sourcer')" type="danger" plain>按照重要性排序</el-button>
                <el-button @click="filterByImportanceFromTime(type_todos,type_name,'sourcer')" type="primary" plain>按照发布时间排序</el-button> -->

        <div :style="{ height: scrollHeight + 'px' }" class="inContainer">
          <div
            v-for="item in type_todos"
            :key="item._id.$id"
            @click="initEdit(item)"
            :class="format_css_arr[item.format]"
          >
            <div>
              <div
                style="
                  margin-bottom: 8px;
                  display: flex;
                  align-items: center;
                  flex-wrap: wrap;
                  justify-content: space-between;
                "
              >
                <div>
                  <span class="info_small_header">{{ item.project }}</span>
                  <span class="info_small_header"
                    >{{ item.type }}{{ item.format }}</span
                  >

                  <!-- <span style="margin-left:30px">内容发布pi平台：{{item.wechat}}</span> -->
                  <!-- <span style="margin-left:30px">内容名称：{{item.article_name}}</span> -->

                  <!-- //：{{item.article_address}} -->
                  <span class="info_small_header"
                    ><span
                      v-for="x in item.userNames"
                      :key="x"
                      style="margin-left: 10px; font-weight: bold"
                      >{{ x }}</span
                    ></span
                  >
                  <span class="info_small_header"></span>
                  <el-tag
                    class="info_small_header"
                    size="small"
                    effect="dark"
                    :type="
                      item.status === '待处理' || item.status === '设计中'
                        ? 'warning'
                        : 'success'
                    "
                    >{{ item.status }}</el-tag
                  >
                </div>

                <div
                  style="
                    float: right;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                  "
                >
                  <a
                    v-if="item.article_address && item.article_address != ''"
                    :href="item.article_address"
                    target="_blank"
                    ><img src="../../assets/linked.png" class="link_img"
                  /></a>
                  <img
                    v-if="!item.article_address || item.article_address == ''"
                    src="../../assets/link.png"
                    class="link_img"
                  />
                  <i
                    class="el-icon-star-off todo-icon-star"
                    v-show="!item.star_status"
                    @click.stop="changeProjectTodoStarStatus(item, true)"
                  ></i>
                  <i
                    class="el-icon-star-on todo-icon-star"
                    v-show="item.star_status"
                    @click.stop="changeProjectTodoStarStatus(item, false)"
                  ></i>
                  <img
                    src="../../assets/jinji.png"
                    class="todo-icon-fast"
                    v-show="item.fast_status"
                    @click.stop="changeProjectTodoFastStatus(item, false)"
                  />
                  <img
                    src="../../assets/jinji1.png"
                    class="todo-icon-fast"
                    v-show="!item.fast_status"
                    @click.stop="changeProjectTodoFastStatus(item, true)"
                  />
                </div>
              </div>
              <div>{{ item.article_name }}</div>
              <div
                style="
                  font-size: 8px;
                  margin-top: 10px;
                  display: flex;
                  flex-wrap: wrap;
                "
              >
                <span style="font-size: 8px">外包设计状态：</span>
                <el-tag>{{
                  item.design_sourcer_status ? item.design_sourcer_status : "无"
                }}</el-tag>
                <span style="font-size: 8px">外包付款状态：</span>
                <el-tag>{{
                  item.design_sourcer_payment_status
                    ? item.design_sourcer_payment_status
                    : "无"
                }}</el-tag>

                <!-- <span v-for="sub in item.my_subs" style="margin-left:30px">{{sub.content}}</span> -->
              </div>
              <div style="text-align: right; color: rgb(250, 84, 120)">
                <span
                  class="info_small_header"
                  v-if="item.design_sourcer_target_finish_date"
                  >外包计划交稿：{{
                    formateDate(item.design_sourcer_target_finish_date)
                  }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog :visible.sync="showEdit" title="添加TODO" width="60%">
      <el-form label-width="200px">
        <el-form-item label="所属业务">
          <el-select v-model="form.project">
            <el-option
              v-for="item in brands"
              :label="item.brand"
              :value="item.brand"
              :key="item.brand"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="形式">
          <el-select v-model="form.format">
            <el-option
              v-for="item in format_arr"
              :label="item"
              :value="item"
              :key="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="类型">
          <div @click="enterTypeEdit">
            <span
              style="
                background-color: lightGray;
                border-radius: 5px;
                padding: 2px 5px;
              "
              >{{ form.type ? form.type : "请选择" }}</span
            >
          </div>
          <!-- <el-select v-model="form.type">
                        <el-option v-for="item in type_arr[form.format]" :label="item" :value="item" :key="item"></el-option>
                    </el-select> -->
        </el-form-item>
        <!-- <el-form-item label="内容发布平台">
                    <el-select v-model="form.wechat">
                        <el-option label="微信公众号" value="微信公众号"></el-option>
                    </el-select>
                </el-form-item> -->

        <el-form-item label="内容名称（文章名）">
          <el-input v-model="form.article_name" style="width: 80%"></el-input>
        </el-form-item>
        <el-form-item label="文章链接">
          <el-input
            v-model="form.article_address"
            style="width: 80%"
          ></el-input>
        </el-form-item>
        <el-form-item label="背景信息与参考资料">
          <el-input
            type="textarea"
            :rows="5"
            v-model="form.refer"
            style="width: 80%"
          ></el-input>
        </el-form-item>
        <el-form-item label="对产品研发的启示（研发需求）">
          <el-input
            type="textarea"
            :rows="5"
            v-model="form.content"
            style="width: 80%"
          ></el-input>
        </el-form-item>
        <el-form-item label="主负责人">
          <el-select
            multiple
            v-model="form.assigned_user_ids"
            style="width: 80%"
            :clearable="true"
          >
            <el-option
              v-for="item in users"
              :key="item._id.$id"
              :label="item.user_lastName + item.user_givenName"
              :value="item._id.$id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="日期">
          <el-date-picker
            v-model="form.date"
            type="date"
            placeholder="选择日期"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button @click="updateETATodo" type="primary">确认</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog :visible.sync="showTypeEdit" title="选择类型" width="80%">
      <div
        v-for="(type, index) in select_types"
        :key="index"
        style="padding-left: 30px"
      >
        <div
          class="type_block"
          @click="chooseEditType(type.type)"
          style="
            padding: 10px;
            margin: 10px;
            border-bottom: 1px solid #eee;
            display: flex;
          "
        >
          <div style="width: 150px; font-size: 20px; flex-shrink: 0">
            {{ type.type }}
          </div>
          <div>{{ type.note }}</div>
        </div>
      </div>
    </el-dialog>

    <el-drawer :visible.sync="drawer" direction="rtl" size="1200px">
      <div style="overflow-y: scroll">
        <div
          style="
            margin: 0px 10px;
            display: flex;
            justify-content: space-between;
          "
        >
          <div>
            <div style="display: flex">
              <el-button
                @click="syncStandards()"
                type="primary"
                style="margin-left: 20px"
                >同步最新标准</el-button
              >
              <div style="margin: 5px 0 0 20px">
                <a
                  v-if="form.article_address && form.article_address != ''"
                  :href="form.article_address"
                  target="_blank"
                  ><img src="../../assets/linked.png" class="link_img"
                /></a>
                <img
                  v-if="!form.article_address || form.article_address == ''"
                  src="../../assets/link.png"
                  class="link_img"
                />
              </div>
              <div style="margin: 5px 0 0 50px">
                <img
                  @click="deleteProjectTODO(todo_id)"
                  src="../../assets/delete_2.png"
                  class="link_img"
                />
              </div>
              <div style="text-align: right; margin: 10px 50px">
                创建于{{ formateDate(form.create_time) }}
              </div>
            </div>
          </div>
          <div style="display: flex; align-items: center">
            <i
              class="el-icon-star-on todo-icon-star"
              v-if="form.star_status"
              style="font-size: 30px"
            ></i>
            <img
              src="../../assets/jinji.png"
              class="todo-icon-fast"
              v-if="form.fast_status"
              style="width: 17px"
            />
          </div>
        </div>
        <div style="font-size: 18px; margin: 15px">{{ form.article_name }}</div>
        <el-tabs v-model="activeName" type="card">
          <el-tab-pane label="基本信息" name="first">
            <el-form
              style="padding: 20px; height: 66vh; overflow-y: scroll"
              label-width="200px"
            >
              <el-form-item label="所属业务">
                <el-select v-model="form.project" @change="updateETATodo">
                  <el-option
                    v-for="item in brands"
                    :label="item.brand"
                    :value="item.brand"
                    :key="item"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="形式">
                <el-select v-model="form.format" @change="updateETATodo">
                  <el-option
                    v-for="item in format_arr"
                    :label="item"
                    :value="item"
                    :key="item"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="类型">
                <div @click="enterTypeEdit">
                  <span
                    style="
                      background-color: lightGray;
                      border-radius: 5px;
                      padding: 2px 5px;
                    "
                    >{{ form.type ? form.type : "请选择" }}</span
                  >
                </div>
                <!-- <el-select v-model="form.type" @change="updateETATodo">
                                    <el-option v-for="item in type_arr[form.format]" :label="item" :value="item" :key="item"></el-option>
                                </el-select> -->
              </el-form-item>
              <!-- <el-form-item label="内容发布平台">
                                <el-select v-model="form.wechat" @change="updateETATodo" :disabled="form.creater_id!==user_id">
                                    <el-option label="微信公众号" value="微信公众号"></el-option>
                                </el-select>
                            </el-form-item> -->
              <el-form-item label="内容名称（文章名）">
                <el-input
                  v-model="form.article_name"
                  style="width: 80%"
                  @change="updateETATodo"
                ></el-input>
              </el-form-item>
              <el-form-item label="文章链接">
                <el-input
                  v-model="form.article_address"
                  style="width: 80%"
                  @change="updateETATodo"
                ></el-input>
              </el-form-item>
              <el-form-item label="背景信息与参考资料">
                <el-input
                  type="textarea"
                  :rows="5"
                  v-model="form.refer"
                  style="width: 80%"
                  @change="updateETATodo"
                ></el-input>
              </el-form-item>
              <el-form-item label="对产品研发的启示">
                <el-input
                  type="textarea"
                  :rows="5"
                  v-model="form.content"
                  style="width: 80%"
                  @change="updateETATodo"
                ></el-input>
              </el-form-item>
              <el-form-item label="主负责人">
                <el-select
                  multiple
                  v-model="form.assigned_user_ids"
                  style="width: 80%"
                  :clearable="true"
                  @change="updateETATodo"
                >
                  <el-option
                    v-for="item in users"
                    :key="item._id.$id"
                    :label="item.user_lastName + item.user_givenName"
                    :value="item._id.$id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="日期">
                <el-date-picker
                  @change="updateETATodo"
                  v-model="form.date"
                  type="date"
                  placeholder="选择日期"
                  :picker-options="pickerOptions"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="TODO状态">
                <el-select v-model="form.status" @change="changeTodoStatus">
                  <el-option value="待处理" label="待处理"></el-option>
                  <el-option value="设计中" label="设计中"></el-option>
                  <el-option value="待发布" label="待发布"></el-option>
                  <el-option value="已完成" label="已完成"></el-option>
                  <el-option value="已搁置" label="已搁置"></el-option>
                  <el-option value="以后发" label="以后发"></el-option>
                </el-select>
                <!-- <span style="font-size:14px;margin-left:10px">仅分配给外包设计的才需要更改为“设计中”，内部设计师负责的依旧为“待处理”</span> -->
              </el-form-item>
              <el-form-item>
                <div style="font-size: 14px">
                  -
                  仅分配给外包设计的才需要更改为“设计中”，内部设计师负责的依旧为“待处理”
                </div>
                <div style="font-size: 14px">
                  -
                  请谨慎更改为“已完成”，“已完成”任务将不会出现在待办列表中，只有发布环节全部完成后才能改为“已完成”
                </div>
              </el-form-item>
            </el-form>
          </el-tab-pane>

          <el-tab-pane
            v-for="(tab, index) in tabs"
            :label="tab.label"
            :name="tab.name"
            :key="index"
          >
            <div v-if="tab.name == 'design'" style="margin: 20px">
              <div v-if="design_guides[form.project]">
                <a :href="design_guides[form.project]" target="_blank"
                  >品牌设计指南</a
                >
              </div>
              <div v-if="!design_guides[form.project]">暂无品牌设计指南</div>
            </div>
            <div v-if="tab.name === 'custom'">
              <el-button
                @click="initAddNewSub"
                type="primary"
                style="margin-left: 20px"
                >添加</el-button
              >
            </div>
            <div style="margin: 30px; height: 66vh; overflow-y: scroll">
              <div
                v-for="(item, index1) in todoSubs[tab.name]"
                :key="index1"
                class="checkDiv2"
              >
                <div
                  style="
                    display: flex;
                    height: 60px;
                    border-bottom: 1px solid #ddd;
                    padding-top: 10px;
                  "
                >
                  <!-- <el-checkbox
                    style="width: 200px; flex-shrink: 0"
                    @change="updateTodoSubFinishStatus(item._id.$id, item.type)"
                    :disabled="!item.require_status"
                    size="small"
                    v-model="item.finish_status"
                  >
                    <div :class="item.finish_status ? 'deleteline' : ''">
                      <div>{{ item.content }}</div>
                    </div>
                  </el-checkbox> -->
                  <div
                    style="
                      display: flex;
                      font-size: 14px;
                      width: 200px;
                      cursor: pointer;
                    "
                    @click="updateTodoSubFinishType(item)"
                  >
                    <div
                      :class="`myCheckCircle ${item.finish_type} ${
                        !item.require_status ? 'info' : ''
                      }`"
                    >
                      <img
                        src="../../assets/gou.png"
                        v-if="
                          item.finish_type === 'success' && item.require_status
                        "
                        style="width: 12px"
                      />
                      <img
                        src="../../assets/wenhao.png"
                        v-if="
                          item.finish_type === 'warning' && item.require_status
                        "
                        style="width: 14px"
                      />
                      <img
                        src="../../assets/cha.png"
                        v-if="
                          item.finish_type === 'error' && item.require_status
                        "
                        style="width: 12px"
                      />
                    </div>
                    <div>{{ item.content }}</div>
                  </div>
                  <div style="width: 350px; font-size: 10px; flex-shrink: 0">
                    <i>{{ item.note }}</i>
                  </div>

                  <el-button
                    v-if="tab.name == 'custom'"
                    @click="changSubName(item)"
                    style="
                      margin-left: 10px;
                      margin-right: 10px;
                      flex-shrink: 0;
                    "
                    type="info"
                    icon="el-icon-edit"
                    circle
                  ></el-button>

                  <div style="width: 80px; flex-shrink: 0">
                    <el-tag
                      size="small"
                      effect="dark"
                      :type="item.require_status ? 'warning' : 'success'"
                      @click="updateRequireStatus(item._id.$id, item.type)"
                      >{{ item.require_status ? "不用" : "启用" }}</el-tag
                    >
                  </div>

                  <div style="width: 100px; flex-shrink: 0">
                    <el-select
                      size="mini"
                      style="font-size: 8px"
                      v-model="item.user_ids[0]"
                      @change="
                        updateUserIDs(item._id.$id, item.user_ids[0], item.type)
                      "
                    >
                      <el-option
                        style="font-size: 8px"
                        label="待选择"
                        value=""
                      ></el-option>
                      <div
                        v-if="tab.name == 'process' || tab.name == 'quality'"
                        style="font-size: 8px"
                      >
                        <el-option
                          style="font-size: 8px"
                          v-for="force in taskforces"
                          :label="force.user_name"
                          :value="force.user_id"
                          :key="force.user_id"
                        ></el-option>
                      </div>
                      <!-- <el-option v-if="tab.name == 'process' || tab.name == 'quality'" style="font-size:8px" v-for="force in taskforces" :label="force.user_name" :value="force.user_id"></el-option> -->
                      <div v-if="tab.name == 'publish'">
                        <el-option
                          style="font-size: 8px"
                          v-for="force in publish_taskforces"
                          :label="force.user_name"
                          :value="force.user_id"
                          :key="force.user_id"
                        ></el-option>
                      </div>
                      <div v-if="tab.name == 'design'">
                        <el-option
                          style="font-size: 8px"
                          v-for="force in design_taskforces"
                          :label="force.user_name"
                          :value="force.user_id"
                          :key="force.user_id"
                        ></el-option>
                      </div>
                      <div v-if="tab.name == 'custom'">
                        <el-option
                          style="font-size: 8px"
                          v-for="force in taskforces"
                          :label="force.user_name"
                          :value="force.user_id"
                          :key="force.user_id"
                        ></el-option>
                      </div>
                    </el-select>
                  </div>

                  <el-button
                    @click="sendMessageToUser(item)"
                    style="margin-left: 10px; flex-shrink: 0"
                    type="info"
                    icon="el-icon-message-solid"
                    circle
                  ></el-button>

                  <el-input
                    placeholder="输入备注按回车保存"
                    v-model="item.remark"
                    @blur="updateSubRemark(item)"
                    @keyup.enter.native="enterBlur($event)"
                    style="width: 200px; margin-left: 10px; flex-shrink: 0"
                    size="mini"
                  ></el-input>
                  <div
                    style="flex-shrink: 0; width: 130px; margin-left: 10px"
                    v-if="tab.name === 'publish'"
                  >
                    <el-date-picker
                      style="width: 130px"
                      @change="(e) => updateSubDate(item._id.$id, e)"
                      v-model="item.plan_date"
                      type="date"
                      placeholder="选择计划日期"
                      size="mini"
                    ></el-date-picker>
                  </div>
                  <star
                    v-if="item.finish_status"
                    :score.sync="item.point"
                    @mark="(e) => markGuide(item, e)"
                    style="
                      margin-left: 10px;
                      flex-shrink: 0;
                      margin-right: 10px;
                    "
                  ></star>
                  <!-- <p class="reviewer_name" v-if="ele.reviewer_name">
                                      {{ ele.reviewer_name }}
                                    </p> -->
                </div>
              </div>
            </div>
          </el-tab-pane>

          <el-tab-pane label="历史同类任务" name="history">
            <div style="margin: 30px; height: 66vh; overflow-y: scroll">
              <div v-if="history_todos.length == 0">暂无</div>
              <div
                v-for="(item, index) in history_todos"
                :key="index"
                class="history_todo"
              >
                <el-tag
                  size="small"
                  effect="dark"
                  :type="item.status === '待处理' ? 'warning' : 'success'"
                  >{{ item.status }}</el-tag
                >{{ item.article_name }}
                <a
                  v-if="item.article_address && item.article_address != ''"
                  :href="item.article_address"
                  target="_blank"
                  ><img src="../../assets/linked.png" class="link_img"
                /></a>
                <img
                  v-if="!item.article_address || item.article_address == ''"
                  src="../../assets/link.png"
                  class="link_img"
                />
              </div>
            </div>
          </el-tab-pane>

          <el-tab-pane label="附件列表" name="attach">
            <div style="margin: 30px; height: 66vh; overflow-y: scroll">
              <el-button
                type="primary"
                @click="initUpload"
                style="margin-bottom: 10px"
                >上传</el-button
              >
              <div v-if="attaches.length == 0">暂无</div>
              <ul>
                <li
                  v-for="(item, index) in attaches"
                  :key="index"
                  class="attach"
                  style="margin-top: 10px"
                >
                  <el-link
                    :href="item.attach_url"
                    type="primary"
                    target="_blank"
                    >{{ item.name ? item.name : item.attach_key }}</el-link
                  >
                  <!-- <a download :href="item.attach_url" style="margin-left:10px">下载</a> -->
                  <el-button
                    type="danger"
                    size="mini"
                    @click="remove(item)"
                    style="margin-left: 20px"
                    >删除</el-button
                  >
                </li>
              </ul>
            </div>
          </el-tab-pane>

          <el-tab-pane label="设计外包信息" name="outsource">
            <el-form style="padding: 20px" label-width="200px">
              <el-form-item label="分配外包商">
                <el-select
                  v-model="form.design_sourcer_name"
                  @change="updateTodoDesignSourcer"
                >
                  <el-option
                    v-for="item in design_sourcers"
                    :label="item"
                    :value="item"
                    :key="item"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="外包价格">
                <el-input
                  type="number"
                  v-model="form.design_sourcer_price"
                  style="width: 80%"
                  @change="updateTodoDesignSourcer"
                ></el-input>
              </el-form-item>
              <el-form-item label="日期">
                <el-date-picker
                  @change="updateTodoDesignSourcer"
                  v-model="form.design_sourcer_target_finish_date"
                  type="date"
                  placeholder="选择目标完成日期"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="外包工作状态">
                <el-select
                  v-model="form.design_sourcer_status"
                  @change="updateTodoDesignSourcer"
                >
                  <el-option
                    v-for="item in design_sourcer_statuses"
                    :label="item"
                    :value="item"
                    :key="item"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="外包付款状态">
                <el-select
                  v-model="form.design_sourcer_payment_status"
                  @change="updateTodoDesignSourcer"
                >
                  <el-option
                    v-for="item in sourcer_payment_statuses"
                    :label="item"
                    :value="item"
                    :key="item"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="淘宝订单号">
                <el-input
                  v-model="form.design_sourcer_transaction_id"
                  style="width: 80%"
                  @change="updateTodoDesignSourcer"
                ></el-input>
              </el-form-item>
            </el-form>
          </el-tab-pane>

          <!-- 自定义tab -->
          <!-- <el-tab-pane label="自定义子任务" name="custom">
                        <div>
                            <el-button type="primary" @click="initAddNewSub">添加子任务</el-button>
                        </div>
                    </el-tab-pane> -->
        </el-tabs>
      </div>
    </el-drawer>

    <el-dialog :visible.sync="showAttach">
      <h3>文件上传</h3>
      <uploadItem
        v-if="showAttach"
        @confirmUpload="onConfirmUpload"
        @removeFile="confirmRemoveFile"
        style="margin-top: 20px"
      />
      <hr style="margin-top: 30px" />
      <h3 style="margin-top: 30px">基本信息</h3>
      <el-form>
        <el-form-item label="文件名称">
          <el-input v-model="name"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="addProjectTodoAttach"
            >提交</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog :visible.sync="isShowNewSub">
      <el-form>
        <el-form-item label="名称">
          <el-input v-model="newSubForm.content"></el-input>
        </el-form-item>
        <el-form-item label="注释">
          <el-input
            v-model="newSubForm.note"
            type="textarea"
            :rows="5"
          ></el-input>
        </el-form-item>
        <el-form-item label="优先级">
          <el-input v-model.number="newSubForm.priority"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="addNewSub">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog :visible.sync="isShowEditSubName">
      <el-form>
        <el-form-item label="名称">
          <el-input v-model="editSubForm.content"></el-input>
        </el-form-item>
        <!-- <el-form-item label="注释">
                    <el-input v-model="newSubForm.note" type="textarea" :rows="5"></el-input>
                </el-form-item>
                <el-form-item label="优先级">
                    <el-input v-model.number="newSubForm.priority"></el-input>
                </el-form-item> -->
        <el-form-item>
          <el-button type="primary" @click="editSubName">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  updateETATodo,
  getAllTodos,
  changeTodoStatus,
  getUserList,
  getAllTODOProjects,
  getTodoSubs,
  updateTodoSub,
  updateTodoSubFinishStatus,
  updateTodoSubRequireStatus,
  updateTodoSubUserIDs,
  refreshStandardSubList,
  getMySubs,
  getMyAssignedTodos,
  changeProjectTodoFastStatus,
  changeProjectTodoStarStatus,
  getTodoTypesByFormat,
  getTeamRecords,
  getCompleteTodos,
  getPublishTodos,
  searchProjectTODO,
  getPriorityTodos,
  deleteProjectTODO,
  getHistoryTODOs,
  getDesignTodos,
  getProjectTodoAttaches,
  addProjectTodoAttach,
  removeProjectTodoAttach,
  getQiniuToken,
  getBrands,
  updateTodoDesignSourcer,
  removeRemoteFile,
  updateSubRemark,
  updateSubPoints,
  sendRemindMessageToSubTodoUsers,
  updateSubDate,
  updateTodoSubName,
  getUserWorkStartTimeList,
  updateTodoSubFinishStatusNew,
} from "../../api/assistant";
import star from "../../components/Star";
import { getUserId } from "../../utils/store";
import uploadItem from "../../components/UploadItem";
export default {
  components: {
    uploadItem,
    star,
  },
  data() {
    return {
      attaches: [],
      keywords: "",
      data_type: "filter",
      view_type: "list",
      todos: [],
      history_todos: [],
      todos_by_project: {},
      todos_by_type: {},
      todos_by_taskforce: {},
      todos_by_sub: {},
      todos_by_date: {},
      team_records: {},
      todos_by_sourcer: {},
      showEdit: false,
      form: {
        assigned_user_ids: [],
      },
      users: [],
      todo_id: "",
      drawer: false,
      user_id: "",
      project: "all",
      projects: [],
      format: "all",
      formats: [],
      type: "all",
      types: [],
      loading: false,
      status: "待处理",
      businesses: [
        "AM",
        "Berkeley-FBL",
        "CEC",
        "CEO",
        "CHS",
        "DSA",
        "Ecoplore",
        "ELA",
        "Envirothon",
        "ETA",
        "FBLA",
        "FBLC",
        "FEL",
        "Gocademy",
        "GreenMate",
        "HTS",
        "IBO",
        "IPsyO",
        "JEA",
        "LF社区",
        "MR",
        "PAC",
        "PG Open",
        "SI",
        "SI2",
        "UDC",
        "WEC",
        "WitMaker",
        "YIC",
        "YOC",
        "创能普研",
        "可能学院",
        "明日挑战",
        "小参",
      ],
      brands: [],
      design_guides: {},
      format_arr: [
        "有用",
        "内容",
        "研发",
        "装饰",
        "流程",
        "讲座",
        "活动",
        "投放",
        "课程",
      ],
      type_arr: {
        内容: [
          "学术类",
          "指南类",
          "百科类",
          "宣讲类",
          "实体类",
          "时事类",
          "数据类",
          "包装类",
          "流程类",
          "福利类",
          "采访类",
          "案例类",
          "资源类",
          "理念类",
          "报道类",
          "周边类",
          "荣誉类",
          "转化类",
          "定位类",
          "问答类",
          "测试类",
          "功能类",
          "肌肉类",
          "碰瓷类",
        ],
        讲座: ["指南类", "培训类"],
        研发: ["学术类"],
        投放: ["包装类"],
        活动: ["策划类"],
      },
      activeName: "first",
      todoSubs: {
        process: [],
        publish: [],
        design: [],
        quality: [],
        custom: [],
      },
      taskforces: [
        { user_id: "5e991290023091024e6a614d", user_name: "朱伦" },
        { user_id: "60a1caaea4640b683a8b456f", user_name: "谢维维" },
        { user_id: "5fcdd059a4640b55298b4569", user_name: "郑婉怡" },
        { user_id: "5f6ae2cbf19b934bb3800b1d", user_name: "赵伊然" },
        { user_id: "5eb8fd68023091024e6a615b", user_name: "杨莺瑛" },
        { user_id: "60934b05a4640b3e108b4582", user_name: "景晨" },
        { user_id: "5ec12629023091024e6a6163", user_name: "张玉娇" },
        { user_id: "5f20e2c5023091024e6a618d", user_name: "钱利圣" },
        { user_id: "60b04b10a4640bb3428b4582", user_name: "黄恺琛" },
        { user_id: "5ec12661023091024e6a6165", user_name: "陈薇伊" },
        { user_id: "603c4c75a4640ba62b8b457a", user_name: "曹馨懿" },
        { user_id: "612edfd7a4640b66648b4567", user_name: "胡文洁" },
        { user_id: "60b6eedca4640b0d0a8b4567", user_name: "符蓉" },
        { user_id: "5fb49686a4640b5a458b4567", user_name: "白梦佳" },
        { user_id: "5ea642ca023091024e6a6151", user_name: "程艳" },
        { user_id: "5ea646d1023091024e6a6153", user_name: "虞启承" },
        { user_id: "6080d5c2a4640b9d1d8b4569", user_name: "刘梦君" },
        { user_id: "6083fad0a4640b9d6a8b4591", user_name: "宋剑文" },
        { user_id: "5fc61739a4640b13418b457c", user_name: "再努然" },
        { user_id: "61767414a4640ba3168b4567", user_name: "王连剑" },
        { user_id: "617f4a15a4640bb8498b4567", user_name: "曾婉儿" },
      ],
      publish_taskforces: [
        { user_id: "5ea642ca023091024e6a6151", user_name: "程艳" },
        { user_id: "5ec12661023091024e6a6165", user_name: "陈薇伊" },
        { user_id: "603c4c75a4640ba62b8b457a", user_name: "曹馨懿" },
        { user_id: "60a1ca53a4640bd83c8b4571", user_name: "阮菲彦" },
        { user_id: "5fe95084a4640bbf238b4568", user_name: "茹意" },
        { user_id: "612edfd7a4640b66648b4567", user_name: "胡文洁" },
        { user_id: "60934b4aa4640b7b068b45c2", user_name: "孙灏杰" },
        { user_id: "5ec12629023091024e6a6163", user_name: "张玉娇" },
        { user_id: "5ea646d1023091024e6a6153", user_name: "虞启承" },
        { user_id: "5eb8fd68023091024e6a615b", user_name: "杨莺瑛" },
        { user_id: "5f6ae2cbf19b934bb3800b1d", user_name: "赵伊然" },
        { user_id: "5ec126ea023091024e6a616b", user_name: "顾美婷" },
        { user_id: "6083fad0a4640b9d6a8b4591", user_name: "宋剑文" },
        { user_id: "5fc61739a4640b13418b457c", user_name: "再努然" },
        { user_id: "617f4a15a4640bb8498b4567", user_name: "曾婉儿" },
      ],

      tabs: [
        { name: "process", label: "流程控制" },
        { name: "quality", label: "文案质量控制" },
        { name: "design", label: "设计质量控制" },
        { name: "publish", label: "发布控制" },
        { name: "custom", label: "自定义" },
      ],
      phases: {
        process: "流程",
        quality: "质量",
        design: "设计",
        publish: "发布",
        custom: "自定义",
      },
      height: 0,
      scrollHeight: 0,
      select_types: [],
      showTypeEdit: false,
      format_css_arr: {
        内容: "todo_box neirong",
        活动: "todo_box huodong",
        讲座: "todo_box jiangzuo",
        投放: "todo_box toufang",
        研发: "todo_box yanfa",
        装饰: "todo_box zhuangshi",
      },
      showAttach: false,
      attach_url: "",
      name: "",
      design_sourcers: [
        "品朝设计",
        "小勇设计",
        "暔尚设计",
        "一希设计",
        "魔灯设计",
        "王连剑视频",
      ],
      design_sourcer_statuses: [
        "未动工",
        "进行中",
        "待修改",
        "待传文件",
        "已完成",
        "已搁置",
      ],
      sourcer_payment_statuses: ["未付款", "已付款", "已收货", "已开发票"],
      isShowNewSub: false,
      isShowEditSubName: false,
      editSubForm: { sub_id: "", content: "" },
      newSubForm: {},
      showIm: [],
      showImSourcer: [],
      showImType: [],
      showImDate: [],
      showImTaskforce: [],
      showImSub: [],
      showImTeam_records: [],
      myTodoNum: 0,
      DesignTodosNum: 0,
      PublishNum: 0,
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date().setHours(0, 0, 0, 0));
            },
          },
        ],
      },
    };
  },
  mounted() {
    this.getScreenHeight();
    this.user_id = getUserId();
    // this.fetchData()
    this.getMySubs();
    this.getUserList();
    this.getBrands();
    this.getDesignTodosNum();
    this.getPublishTodosNum();
  },
  methods: {
    getScreenHeight() {
      this.height = document.documentElement.clientHeight - 190;
      this.scrollHeight = this.height - 80;
    },
    getBrands() {
      getBrands().then((res) => {
        this.brands = res.data;
        this.design_guides = {};
        for (let key in this.brands) {
          if (this.brands[key]["attach_url"]) {
            this.design_guides[this.brands[key]["brand"]] =
              this.brands[key]["attach_url"];
          }
        }
      });
    },
    fetchAllData() {
      this.data_type = "all";
      this.fetchData();
    },
    fetchData() {
      if (this.data_type == "publish") {
        this.loading = true;
        getPublishTodos(this.user_id, this.project, this.format).then((res) => {
          let todos = res.data;
          for (let item of todos) {
            item.userNames = item.users.map(
              (x) => x.user_lastName + x.user_givenName
            );
          }
          this.todos = todos;
          this.loading = false;

          this.changeData("publish");
          this.changeView("project");
          this.refreshTodosByProject(todos);
          this.refreshTodosByType(todos);
          this.refreshTodosByTaskforce(todos);
          this.refreshTodosBySub(todos);
          this.refreshTodosByOutsourcer(todos);
          this.refreshTodosByDate(todos);
        });
      } else {
        this.loading = true;
        getAllTodos(
          this.project,
          this.status,
          this.format,
          this.type,
          this.user_id,
          status
        ).then((res) => {
          let todos = res.data;
          for (let item of todos) {
            item.userNames = item.users.map(
              (x) => x.user_lastName + x.user_givenName
            );
          }
          this.todos = todos;
          this.loading = false;

          this.refreshTodosByProject(todos);
          this.refreshTodosByType(todos);
          this.refreshTodosByTaskforce(todos);
          this.refreshTodosBySub(todos);
          this.refreshTodosByOutsourcer(todos);
          this.refreshTodosByDate(todos);
          this.changeData("filter");

          if (this.project == "all") {
            this.changeView("project");
          } else {
            this.changeView("type");
          }
        });
      }
    },
    deleteProjectTODO(todo_id) {
      this.$confirm("是否确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then((res) => {
        deleteProjectTODO(todo_id).then((res) => {
          this.drawer = false;
          this.fetchData();
        });
      });
    },
    getCompleteTodos() {
      this.loading = true;
      getCompleteTodos().then((res) => {
        let todos = res.data;
        for (let item of todos) {
          item.userNames = item.users.map(
            (x) => x.user_lastName + x.user_givenName
          );
        }
        this.todos = todos;
        this.loading = false;

        this.changeData("complete");
        this.changeView("project");
        this.refreshTodosByProject(todos);
        this.refreshTodosByType(todos);
        this.refreshTodosByTaskforce(todos);
        this.refreshTodosBySub(todos);
        this.refreshTodosByOutsourcer(todos);
        this.refreshTodosByDate(todos);
      });
    },
    getPublishTodos() {
      this.loading = true;
      console.log(222, this.type);
      getPublishTodos(this.user_id, this.project, this.format).then((res) => {
        let todos = res.data;
        for (let item of todos) {
          item.userNames = item.users.map(
            (x) => x.user_lastName + x.user_givenName
          );
        }
        this.todos = todos;
        this.loading = false;
        // this.PublishNum = this.todos.length

        this.changeData("publish");
        this.changeView("project");
        this.refreshTodosByProject(todos);
        this.refreshTodosByType(todos);
        this.refreshTodosByTaskforce(todos);
        this.refreshTodosBySub(todos);
        this.refreshTodosByOutsourcer(todos);
        this.refreshTodosByDate(todos);
      });
    },
    getPublishTodosNum() {
      getPublishTodos(this.user_id, "all", "all").then((res) => {
        this.PublishNum = res.data.length;
      });
    },
    getDesignTodos() {
      this.loading = true;
      getDesignTodos(this.user_id).then((res) => {
        let todos = res.data;
        for (let item of todos) {
          item.userNames = item.users.map(
            (x) => x.user_lastName + x.user_givenName
          );
        }
        this.todos = todos;
        this.loading = false;

        this.DesignTodosNum = this.todos.length;

        this.changeData("design");
        this.changeView("sourcer");
        this.refreshTodosByProject(todos);
        this.refreshTodosByType(todos);
        this.refreshTodosByTaskforce(todos);
        this.refreshTodosBySub(todos);
        this.refreshTodosByOutsourcer(todos);
        this.refreshTodosByDate(todos);
      });
    },
    getDesignTodosNum() {
      getDesignTodos(this.user_id).then((res) => {
        this.DesignTodosNum = res.data.length;
      });
    },
    getPriorityTodos() {
      this.loading = true;
      getPriorityTodos(this.user_id).then((res) => {
        let todos = res.data;
        for (let item of todos) {
          item.userNames = item.users.map(
            (x) => x.user_lastName + x.user_givenName
          );
        }
        this.todos = todos;
        this.loading = false;

        this.changeData("priority");
        this.changeView("project");
        this.refreshTodosByProject(todos);
        this.refreshTodosByType(todos);
        this.refreshTodosByTaskforce(todos);
        this.refreshTodosBySub(todos);
        this.refreshTodosByOutsourcer(todos);
        this.refreshTodosByDate(todos);
      });
    },

    getMyAssignedTodos() {
      this.loading = true;
      getMyAssignedTodos(
        this.project,
        this.status,
        this.format,
        this.type,
        this.user_id
      ).then((res) => {
        let todos = res.data;
        for (let item of todos) {
          item.userNames = item.users.map(
            (x) => x.user_lastName + x.user_givenName
          );
        }
        this.todos = todos;
        this.loading = false;

        this.changeView("project");
        this.changeData("assign");
        this.refreshTodosByProject(todos);
        this.refreshTodosByType(todos);
        this.refreshTodosByTaskforce(todos);
        this.refreshTodosBySub(todos);
        this.refreshTodosByOutsourcer(todos);
        this.refreshTodosByDate(todos);
      });
    },
    changeView(type) {
      this.view_type = type;
    },
    changeData(type) {
      this.data_type = type;
    },
    getTaskforceNameByID(id) {
      for (let index in this.taskforces) {
        if (this.taskforces[index].user_id == id) {
          return this.taskforces[index].user_name;
        }
      }

      for (let index in this.publish_taskforces) {
        if (this.publish_taskforces[index].user_id == id) {
          return this.publish_taskforces[index].user_name;
        }
      }
    },
    refreshTodosByProject(todos) {
      this.todos_by_project = {};
      this.showIm = [];
      for (let item of todos) {
        item.priority_count = 0;
        if (item.star_status) {
          item.priority_count++;
        }
        if (item.fast_status) {
          item.priority_count++;
        }

        if (!this.todos_by_project[item.project]) {
          this.todos_by_project[item.project] = [];
        }

        this.todos_by_project[item.project].push(item);
        this.showIm.push(true);
      }

      // for(let key in this.todos_by_project){

      //     this.todos_by_project[key].sort(function(x,y){
      //         return y.priority_count-x.priority_count;
      //     });
      // }

      this.filterByImportance1(this.todos_by_project);
    },
    refreshPageData() {
      if (this.data_type == "publish") {
        this.getPublishTodos();
      } else if (this.data_type == "design") {
        this.getDesignTodos();
      } else if (this.data_type == "filter") {
        this.fetchData();
      } else if (this.data_type == "sub") {
        this.getMySubs();
      } else if (this.data_type == "assign") {
        this.getMyAssignedTodos();
      } else if (this.data_type == "priority") {
        this.getPriorityTodos();
      } else if (this.data_type == "complete") {
        this.getCompleteTodos();
      } else if (this.data_type == "team") {
        this.getTeamRecords();
      }
    },
    refreshTodosByType(todos) {
      this.todos_by_type = {};

      for (let item of todos) {
        item.priority_count = 0;
        if (item.star_status) {
          item.priority_count++;
        }
        if (item.fast_status) {
          item.priority_count++;
        }

        if (!item.type) {
          item.type = "无类型";
        }
        if (!this.todos_by_type[item.type]) {
          this.todos_by_type[item.type] = [];
        }

        this.todos_by_type[item.type].push(item);
        this.showImType.push(true);
      }

      // for(let key in this.todos_by_type){

      //     this.todos_by_type[key].sort(function(x,y){
      //         return y.priority_count-x.priority_count;
      //     });
      // }
      // console.log(11111,this.todos_by_type)
      this.filterByImportance1(this.todos_by_type);
    },

    refreshTodosByDate(todos) {
      this.todos_by_date = {};

      this.todos_by_date["nodate"] = [];

      for (let item of todos) {
        let flag = 0; //设置一个标志位 如果todo没有时间或者一个sub没有时间就+1
        item.priority_count = 0;
        if (item.star_status) {
          item.priority_count++;
        }
        if (item.fast_status) {
          item.priority_count++;
        }

        if (item.date) {
          if (!this.todos_by_date[item.date]) {
            this.todos_by_date[item.date] = [];
          }
          this.todos_by_date[item.date].push(item);
          this.showImDate.push(true);
        } else {
          flag++;
          this.todos_by_date["nodate"].push(item);
          this.showImDate.push(true);
        }
        // let subs = item.all_subs

        // for(let item1 of subs){
        //     if(item1.plan_date){
        //         if(!this.todos_by_date[item1.plan_date]){
        //             this.todos_by_date[item1.plan_date] = []
        //         }
        //         // let exits = this.todos_by_date[item1.plan_date].filter(x=>x._id.$id==item._id.$id);
        //         if(!this.todos_by_date[item1.plan_date].includes(x=>x._id.$id===item._id.$id)){
        //             this.todos_by_date[item1.plan_date].push(item);
        //             this.showImDate.push(true)
        //         }else{
        //             flag ++;
        //         }
        //     }else{
        //         flag ++;
        //     }
        // }
        // if(flag == subs.length+1){
        //     this.todos_by_date['nodate'].push(item)
        //     this.showImDate.push(true)
        // }
      }

      console.log(1234, this.todos_by_date["nodate"]);

      // for(let key in this.todos_by_date){

      //     this.todos_by_date[key].sort(function(x,y){
      //         return y.priority_count-x.priority_count;
      //     });
      // }
      this.filterByImportance1(this.todos_by_date);
    },
    refreshTodosByOutsourcer(todos) {
      this.todos_by_sourcer = {};
      for (let item of todos) {
        item.priority_count = 0;
        if (item.star_status) {
          item.priority_count++;
        }
        if (item.fast_status) {
          item.priority_count++;
        }

        if (!item.design_sourcer_name) {
          item.design_sourcer_name = "无设计外包供应商";
        }
        if (!this.todos_by_sourcer[item.design_sourcer_name]) {
          this.todos_by_sourcer[item.design_sourcer_name] = [];
        }

        this.todos_by_sourcer[item.design_sourcer_name].push(item);
        this.showImSourcer.push(true);
      }

      // for(let key in this.todos_by_sourcer){

      //     this.todos_by_sourcer[key].sort(function(x,y){
      //         return y.priority_count-x.priority_count;
      //     });
      // }

      for (let sourcer of this.design_sourcers) {
        if (!this.todos_by_sourcer[sourcer]) {
          this.todos_by_sourcer[sourcer] = [];
        }
      }
      this.filterByImportance1(this.todos_by_sourcer);
    },
    refreshTodosByTaskforce(todos) {
      this.todos_by_taskforce = {};

      for (let item of todos) {
        item.priority_count = 0;
        if (item.star_status) {
          item.priority_count++;
        }
        if (item.fast_status) {
          item.priority_count++;
        }
        //todo的sub有多少人参与
        var people = {};
        for (let sub of item.all_subs) {
          if (sub.user_ids[0]) {
            if (!people[sub.user_ids[0]]) {
              people[sub.user_ids[0]] = sub.user_ids[0];
            }
          }
        }

        //遍历参加的每个人
        for (let taskforce in people) {
          var name = this.getTaskforceNameByID(taskforce);

          if (!this.todos_by_taskforce[name]) {
            this.todos_by_taskforce[name] = [];
          }
          item.taskforce_subs = [];

          for (let sub of item.all_subs) {
            if (
              sub.user_ids &&
              sub.user_ids[0] &&
              sub.user_ids[0] == taskforce &&
              !sub.finish_status &&
              sub.require_status
            ) {
              item.taskforce_subs.push(sub);
            }
          }

          if (item.taskforce_subs.length > 0) {
            this.todos_by_taskforce[name].push(item);
            this.showImTaskforce.push(true);
          }
        }
      }

      // for(let key in this.todos_by_taskforce){

      //     this.todos_by_taskforce[key].sort(function(x,y){
      //         return y.priority_count-x.priority_count;
      //     });
      // }
      this.filterByImportance1(this.todos_by_taskforce);
    },

    refreshTodosBySub(todos) {
      this.todos_by_sub = {};
      var no_arr = [];

      for (let item of todos) {
        var contents = {};
        item.priority_count = 0;
        if (item.star_status) {
          item.priority_count++;
        }
        if (item.fast_status) {
          item.priority_count++;
        }
        for (let sub of item.my_subs) {
          if (sub.require_status && !sub.finish_status) {
            if (!contents[sub.content]) {
              contents[sub.content] = sub.content;
            }
          }
        }

        for (let content in contents) {
          if (!this.todos_by_sub[content]) {
            this.todos_by_sub[content] = [];
          }

          if (item.my_subs.length > 0) {
            this.todos_by_sub[content].push(item);
            this.showImSub.push(true);
          } else {
            no_arr.push(item);
          }
        }

        // for(let key in this.todos_by_sub){

        //     this.todos_by_sub[key].sort(function(x,y){
        //         return y.priority_count-x.priority_count;
        //     });
        // }
        this.filterByImportance1(this.todos_by_sub);
      }

      this.todos_by_sub["没有任务"] = no_arr;
    },
    addTodo() {
      this.form = {
        assigned_user_ids: [],
      };
      this.todo_id = "";
      this.showEdit = true;
    },
    getUserList() {
      getUserList().then((res) => {
        this.users = res.data;

        var newUserList = [];

        for (var index = 0; index < this.users.length; index++) {
          var userDetail = this.users[index];
          var detail = [];
          detail["user_id"] = userDetail._id.$id;
          detail["user_name"] =
            userDetail.user_lastName + userDetail.user_givenName;

          newUserList.push(detail);
        }
        this.taskforces = newUserList;
        this.publish_taskforces = newUserList;
      });
    },
    updateETATodo() {
      console.log(1111, this.form.date);
      console.log(2222, new Date(this.form.date).getTime() / 1000);
      if (!this.form.project) {
        this.$message("请选择项目");
        return;
      }
      if (!this.form.format) {
        this.$message("请选择形式");
        return;
      }
      if (!this.form.type) {
        this.$message("请选择类型");
        return;
      }

      updateETATodo(
        this.todo_id,
        getUserId(),
        null,
        this.form.article_name,
        this.form.content,
        this.form.assigned_user_ids,
        this.form.project,
        this.form.article_address,
        this.form.refer,
        this.form.format,
        this.form.type,
        new Date(this.form.date).getTime() / 1000
      ).then((res) => {
        this.refreshPageData();
        this.showEdit = false;
      });
    },
    updateTodoDesignSourcer() {
      updateTodoDesignSourcer(
        this.todo_id,
        getUserId(),
        this.form.design_sourcer_name,
        this.form.design_sourcer_price,
        new Date(this.form.design_sourcer_target_finish_date).getTime() / 1000,
        this.form.design_sourcer_status,
        this.form.design_sourcer_payment_status,
        this.form.design_sourcer_transaction_id
      ).then((res) => {
        this.refreshPageData();
      });
    },
    initEdit(data) {
      this.form = JSON.parse(JSON.stringify(data));
      this.form.date = this.form.date * 1000;
      this.form.design_sourcer_target_finish_date =
        this.form.design_sourcer_target_finish_date * 1000;
      this.todo_id = data._id.$id;
      this.getProjectTodoAttaches();
      this.refreshAllTodoSubs();
      this.getHistoryTODOs(data._id.$id);
      this.drawer = true;
    },
    getHistoryTODOs(todo_id) {
      this.history_todos = [];
      getHistoryTODOs(todo_id).then((res) => {
        this.history_todos = res.data;
      });
    },
    changeTodoStatus() {
      changeTodoStatus(getUserId(), this.todo_id, this.form.status).then(
        (res) => {
          this.refreshPageData();
        }
      );
    },
    refreshAllTodoSubs() {
      this.refreshTodoSubs("process");
      this.refreshTodoSubs("publish");
      this.refreshTodoSubs("quality");
      this.refreshTodoSubs("design");
      this.refreshTodoSubs("custom");
    },
    refreshTodoSubs(type) {
      getTodoSubs(this.todo_id, type).then((res) => {
        this.todoSubs[type] = res.data.map((item) => {
          if (item.plan_date) {
            item.plan_date = item.plan_date * 1000;
          }
          return item;
        });
      });
    },
    updateTodoSubFinishStatus(id, type) {
      console.log(this.todo_id);

      updateTodoSubFinishStatus(id, this.user_id).then((res) => {
        this.todoSubs[type] = this.todoSubs[type].map((m) => {
          if (m._id.$id == id) {
            m.check_status = !m.check_status;
          }
          return m;
        });

        this.todos.map((mm) => {
          if (mm._id.$id == this.todo_id) {
            mm.my_subs.map((n) => {
              if (n._id.$id == id) {
                n.finish_status = !n.finish_status;
              }
            });
          }
        });
      });
    },
    updateTodoSubFinishStatusHome(id, type, todo_id) {
      updateTodoSubFinishStatus(id).then((res) => {
        this.todos = this.todos.map((m, index) => {
          if (m._id.$id == todo_id) {
            m.my_subs = m.my_subs.map((n, index_n) => {
              if (n._id.$id == id) {
                n.check_status = !n.check_status;
              }

              return n;
            });
          }
          return m;
        });
      });
    },
    updateRequireStatus(id, type, todo_id) {
      //TODO
      updateTodoSubRequireStatus(id).then((res) => {
        let todoCursor = "";
        this.todoSubs[type] = this.todoSubs[type].map((m, index) => {
          if (m._id.$id == id) {
            todoCursor = m;
            m.require_status = !m.require_status;
          }
          return m;
        });

        this.todos.map((mm) => {
          if (mm._id.$id == this.todo_id) {
            // mm.my_subs.map((n) => {
            //     if (n._id.$id == id) {
            //         n.require_status = !n.require_status;
            //     }

            // });
            let index = mm.my_subs.findIndex((item) => item._id.$id === id);
            // let cursor = mm.my_subs.find(item=>item._id.$id===id)
            if (!todoCursor.require_status) {
              mm.my_subs.splice(index, 1);
            }
            if (todoCursor.require_status) {
              mm.my_subs.push(JSON.parse(JSON.stringify(todoCursor)));
            }
          }
        });
      });
    },
    formateDate(date) {
      if (date === "nodate") {
        return "无确定时间";
      }
      let time = new Date(date * 1000);
      return `${time.getFullYear()}年${
        time.getMonth() + 1
      }月${time.getDate()}日`;
    },
    formateTime(date) {
      let time = new Date(date * 1000);
      return `${
        time.getMonth() + 1
      }-${time.getDate()} ${time.getHours()}:${time.getMinutes()}`;
    },
    formateDay(date) {
      if (date === "nodate") {
        return "无确定时间";
      }
      let time = new Date(date * 1000);
      var days = time.getDay();

      // console.log(111,days)
      switch (days) {
        case 1:
          days = "周一";

          break;

        case 2:
          days = "周二";

          break;

        case 3:
          days = "周三";

          break;

        case 4:
          days = "周四";

          break;

        case 5:
          days = "周五";

          break;

        case 6:
          days = "周六";

          break;

        case 0:
          days = "周日";

          break;
      }
      return days;
    },
    sendMessageToUser(data) {
      if (data.user_ids.length > 0) {
        var name = "";
        for (let sub_user_id of data.user_ids) {
          name = name + this.getTaskforceNameByID(sub_user_id);
        }

        this.$message.success(
          "你已成功提醒" + name + "注意" + data.content + "子任务"
        );

        sendRemindMessageToSubTodoUsers(
          this.user_id,
          this.form.article_name,
          data.content,
          data.user_ids
        ).then((res) => {});
      } else {
        this.$message.warning("未设置人员");
      }
    },
    updateUserIDs(id, user_id, type) {
      var user_ids = [];
      if (user_id != "") {
        user_ids.push(user_id);
      }
      updateTodoSubUserIDs(id, user_ids).then((res) => {
        this.todoSubs[type] = this.todoSubs[type].map((m, index) => {
          if (m._id.$id == id) {
            m.user_ids = [user_id];
          }
          return m;
        });
      });
    },
    syncStandards() {
      refreshStandardSubList(this.todo_id).then((res) => {
        this.refreshAllTodoSubs();
      });
    },
    getMySubs() {
      getMySubs(this.user_id).then((res) => {
        let todos = res.data;
        for (let item of todos) {
          item.userNames = item.users.map(
            (x) => x.user_lastName + x.user_givenName
          );
        }
        this.todos = todos;
        this.loading = false;
        this.myTodoNum = this.todos.length;

        this.refreshTodosByProject(todos);
        this.refreshTodosByType(todos);
        this.refreshTodosByTaskforce(todos);
        this.refreshTodosBySub(todos);
        this.refreshTodosByDate(todos);

        // this.changeView("sub");
        this.changeData("sub");
      });
    },
    stopDefault(e) {
      e.stopPropagation();
    },
    changeProjectTodoFastStatus(item, status) {
      changeProjectTodoFastStatus(status, item._id.$id, getUserId()).then(
        (res) => {
          this.$set(item, "fast_status", status);
        }
      );
    },
    changeProjectTodoStarStatus(item, status) {
      changeProjectTodoStarStatus(status, item._id.$id, getUserId()).then(
        (res) => {
          this.$set(item, "star_status", status);
        }
      );
    },
    enterTypeEdit() {
      getTodoTypesByFormat(this.form.format).then((res) => {
        this.select_types = res.data;
        this.showTypeEdit = true;
      });
    },
    chooseEditType(type) {
      this.form.type = type;
      this.showTypeEdit = false;

      if (this.todo_id != "") {
        this.updateETATodo();
      }
    },
    getTeamRecords() {
      getTeamRecords().then((res) => {
        this.team_records = res.data;
        this.changeView("team_records");
      });
      this.changeData("team");
    },
    searchTODOList() {
      var t = [];
      var f = [];
      var all = [];
      if (!this.keywords) {
        this.$message("请输入关键词");
      } else {
        searchProjectTODO(this.user_id, this.keywords, false).then((res) => {
          let todos = res.data;
          for (let item of todos) {
            item.userNames = item.users.map(
              (x) => x.user_lastName + x.user_givenName
            );
          }
          this.todos = todos;
          this.loading = false;

          this.refreshTodosByProject(todos);
          this.refreshTodosByType(todos);
          this.refreshTodosByTaskforce(todos);
          this.refreshTodosBySub(todos);
          this.refreshTodosByDate(todos);

          this.changeView("project");
          this.changeData("search");
        });
      }
    },
    initUpload() {
      this.name = "";
      this.attach_url = "";
      this.showAttach = true;
    },
    getProjectTodoAttaches() {
      getProjectTodoAttaches(this.todo_id).then((res) => {
        this.attaches = res.data;
      });
    },
    addProjectTodoAttach() {
      addProjectTodoAttach(
        this.todo_id,
        this.name,
        this.attach_url,
        this.user_id
      ).then((res) => {
        console.log(res);
        this.showAttach = false;
        this.getProjectTodoAttaches();
      });
    },
    remove(data) {
      this.$confirm("是否确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        removeProjectTodoAttach(data._id.$id).then((res) => {
          console.log(res);
          removeRemoteFile(data.attach_key);
          this.getProjectTodoAttaches();
        });
      });
    },
    onConfirmUpload(e) {
      this.attach_url = e.key;
      this.name = e.key;
    },
    confirmRemoveFile() {
      this.attach_url = "";
    },
    updateSubRemark(item) {
      updateSubRemark(item._id.$id, item.remark, getUserId());
    },
    markGuide(item, point) {
      this.$set(item, "point", point);
      console.log(item);
      updateSubPoints(item._id.$id, point, getUserId());
    },
    enterBlur(e) {
      e.target.blur();
    },
    initAddNewSub() {
      this.newSubForm = {
        content: "",
        note: "",
        format: "",
        priority: 0,
      };
      this.isShowNewSub = true;
    },
    addNewSub() {
      updateTodoSub(
        this.form._id.$id,
        "custom",
        this.newSubForm.content,
        this.newSubForm.note,
        this.form.format,
        this.newSubForm.priority
      ).then((res) => {
        this.isShowNewSub = false;
        this.refreshAllTodoSubs();
      });
    },
    changSubName(item) {
      this.editSubForm.sub_id = item._id.$id;
      this.editSubForm.content = item.content;
      this.isShowEditSubName = true;
    },
    editSubName() {
      console.log(11111, this.editSubForm);

      updateTodoSubName(this.editSubForm.sub_id, this.editSubForm.content).then(
        (res) => {
          this.isShowEditSubName = false;
          this.refreshAllTodoSubs();
        }
      );
    },
    updateSubDate(id, e) {
      let time = new Date(e);
      updateSubDate(id, time.getTime() / 1000);
    },
    filterByImportanceFromProject(
      project_todos,
      project_name,
      selectType,
      index
    ) {
      if (selectType === "project") {
        let newDatas = JSON.parse(JSON.stringify(project_todos));
        this.filterByImportance(newDatas);
        this.$set(this.todos_by_project, project_name, newDatas);
        this.$set(this.todos_by_project, "default", []);
        delete this.todos_by_project["default"];
        this.showIm[index] = true;
      }

      if (selectType === "type") {
        let newDatas = JSON.parse(JSON.stringify(project_todos));
        this.filterByImportance(newDatas);
        this.$set(this.todos_by_type, project_name, newDatas);
        this.$set(this.todos_by_type, "default", []);
        delete this.todos_by_type["default"];
        this.showImType[index] = true;
      }

      if (selectType === "date") {
        let newDatas = JSON.parse(JSON.stringify(project_todos));
        this.filterByImportance(newDatas);
        this.$set(this.todos_by_date, project_name, newDatas);
        this.$set(this.todos_by_date, "default", []);
        delete this.todos_by_date["default"];
        this.showImDate[index] = true;
      }

      if (selectType === "taskforce") {
        let newDatas = JSON.parse(JSON.stringify(project_todos));
        this.filterByImportance(newDatas);
        this.$set(this.todos_by_taskforce, project_name, newDatas);
        this.$set(this.todos_by_taskforce, "default", []);
        delete this.todos_by_taskforce["default"];
        this.showImTaskforce[index] = true;
      }

      if (selectType === "sub") {
        let newDatas = JSON.parse(JSON.stringify(project_todos));
        this.filterByImportance(newDatas);
        this.$set(this.todos_by_sub, project_name, newDatas);
        this.$set(this.todos_by_sub, "default", []);
        delete this.todos_by_sub["default"];
        this.showImSub[index] = true;
      }

      if (selectType === "team_records") {
        // let newDatas = JSON.parse(JSON.stringify(project_todos))
        // this.filterByImportance(newDatas)
        // this.$set(this.team_records,project_name,newDatas)
        // this.$set(this.team_records,'default',[])
        // delete this.team_records['default']
      }
      if (selectType === "sourcer") {
        let newDatas = JSON.parse(JSON.stringify(project_todos));
        this.filterByImportance(newDatas);
        this.$set(this.todos_by_sourcer, project_name, newDatas);
        this.$set(this.todos_by_sourcer, "default", []);
        delete this.todos_by_sourcer["default"];
        this.showImSourcer[index] = true;
      }
    },
    filterByImportanceFromTime(project_todos, project_name, selectType, index) {
      if (selectType === "project") {
        let newDatas = JSON.parse(JSON.stringify(project_todos));
        this.filterByTime(newDatas);
        this.$set(this.todos_by_project, project_name, newDatas);
        this.$set(this.todos_by_project, "default", []);
        delete this.todos_by_project["default"];
        this.showIm[index] = false;
      }
      if (selectType === "type") {
        let newDatas = JSON.parse(JSON.stringify(project_todos));
        this.filterByTime(newDatas);
        this.$set(this.todos_by_type, project_name, newDatas);
        this.$set(this.todos_by_type, "default", []);
        delete this.todos_by_type["default"];
        this.showImType[index] = false;
      }

      if (selectType === "date") {
        let newDatas = JSON.parse(JSON.stringify(project_todos));
        this.filterByTime(newDatas);
        this.$set(this.todos_by_date, project_name, newDatas);
        this.$set(this.todos_by_date, "default", []);
        delete this.todos_by_date["default"];
        this.showImDate[index] = false;
      }

      if (selectType === "taskforce") {
        let newDatas = JSON.parse(JSON.stringify(project_todos));
        this.filterByTime(newDatas);
        this.$set(this.todos_by_taskforce, project_name, newDatas);
        this.$set(this.todos_by_taskforce, "default", []);
        delete this.todos_by_taskforce["default"];
        this.showImTaskforce[index] = false;
      }
      if (selectType === "sub") {
        let newDatas = JSON.parse(JSON.stringify(project_todos));
        this.filterByTime(newDatas);
        this.$set(this.todos_by_sub, project_name, newDatas);
        this.$set(this.todos_by_sub, "default", []);
        delete this.todos_by_sub["default"];
        this.showImSub[index] = false;
      }

      if (selectType === "team_records") {
        // let newDatas = JSON.parse(JSON.stringify(project_todos))
        // this.filterByTime(newDatas)
        // this.$set(this.team_records,project_name,newDatas)
        // this.$set(this.team_records,'default',[])
        // delete this.team_records['default']
      }

      if (selectType === "sourcer") {
        let newDatas = JSON.parse(JSON.stringify(project_todos));
        this.filterByTime(newDatas);
        this.$set(this.todos_by_sourcer, project_name, newDatas);
        this.$set(this.todos_by_sourcer, "default", []);
        delete this.todos_by_sourcer["default"];
        this.showImSourcer[index] = false;
      }
    },
    filterByImportance(datas) {
      datas.sort(function (x, y) {
        return y.priority_count - x.priority_count;
      });
    },
    filterByTime(datas) {
      datas.sort(function (x, y) {
        if (!x.date) {
          return 1;
        }
        if (!y.date) {
          return -1;
        }
        return x.date - y.date;
      });
    },
    filterByImportance1(datas) {
      for (let key in datas) {
        datas[key].sort(function (x, y) {
          return y.priority_count - x.priority_count;
        });
      }
    },
    updateTodoSubFinishType(item) {
      if (!item.require_status) {
        return;
      }
      let finish_type;
      let finish_status;

      if (!item.finish_type) {
        this.$set(item, "finish_type", "success");
        finish_type = "success";
        finish_status = true;
      } else if (item.finish_type === "success") {
        this.$set(item, "finish_type", "warning");
        finish_type = "warning";
        finish_status = true;
      } else if (item.finish_type === "warning") {
        this.$set(item, "finish_type", "error");
        finish_type = "error";
        finish_status = true;
      } else if (item.finish_type === "error") {
        this.$set(item, "finish_type", "");
        finish_type = "";
        finish_status = false;
      }
      updateTodoSubFinishStatusNew(
        item._id.$id,
        this.user_id,
        finish_type,
        finish_status
      ).then((res) => {
        this.todos.map((mm) => {
          if (mm._id.$id == this.todo_id) {
            mm.my_subs.map((n) => {
              if (n._id.$id == id) {
                n.finish_status = finish_status;
                n.finish_type = finish_type;
              }
            });
          }
        });
      });
    },
  },
};
</script>
<style>
.el-checkbox__input.is-disabled + span.el-checkbox__label {
  color: #606266;
}
</style>
<style scoped>
.container {
  width: 100%;
}
.todo {
  width: 95%;
  padding: 20px;
  border: 16px #d9d9d9;
  border-radius: 10px;
  background: #eeeeee;
  margin-top: 8px;
  margin-bottom: 3px;
}
.deleteline {
  text-decoration: line-through;
}
.project_view {
  width: 500px;
  margin-right: 10px;
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 6px;
  flex-shrink: 0;
  height: 100%;
  overflow-y: hidden;
}

.todo_box {
  width: 90%;
  background-color: #e9e9e9;
  margin: 10px 6px;
  padding: 10px;
  border-radius: 5px;
  /*height: 80px;*/
  padding-bottom: 20px;
  line-height: 150%;
}

.column_header {
  margin: 10px;
  font-size: 30px;
}

.info_small_header {
  font-size: 8px;
  margin-right: 4px;
}
.todo-icon-fast {
  width: 12px;
  margin-left: 20px;
}
.todo-icon-star {
  color: black;
  font-size: 22px;
  margin-left: 20px;
}

.neirong {
  background-color: rgba(167, 241, 250, 0.4);
}

.huodong {
  background-color: rgba(250, 167, 185, 0.4);
}
.toufang {
  background-color: rgba(232, 167, 250, 0.4);
}
.jiangzuo {
  background-color: rgba(250, 248, 167, 0.4);
}
.yanfa {
  background-color: rgba(167, 250, 206, 0.4);
}

.zhuangshi {
  background-color: rgba(131, 84, 250, 0.4);
}

.link_img {
  width: 25px;
}

.tag_unselected {
  background-color: white;
  color: blue;
}
.outContainer {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
}
.inContainer {
  overflow-y: scroll;
}

.history_todo {
  margin: 10px;
  display: flex;
}
.attach {
}
.myCheckCircle {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 1px solid #dcdfe6;
  margin-right: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.myCheckCircle.success {
  background: #67c23a;
  border-color: #67c23a;
}
.myCheckCircle.warning {
  background: #e6a23c;
  border-color: #e6a23c;
}
.myCheckCircle.error {
  background: #f56c6c;
  border-color: #f56c6c;
}
.myCheckCircle.info {
  background: #909399;
  border-color: #909399;
}
</style>