<template>
  <div style="height: 100vh;padding: 10px;box-sizing: border-box;">
    <div style="display: flex; margin-top: 10px;">
      <el-button @click="add" type="primary" style="margin-left: 10px"
        >新增</el-button
      >
    </div>
    <div style="height: calc(100% - 100px);overflow-y: scroll;margin-top: 10px;">
      <el-table :data="tableData">

        <el-table-column label="类型" prop="type"></el-table-column>
        <el-table-column label="品牌" prop="brand"></el-table-column>
        <el-table-column label="人工审核状态" prop="status"></el-table-column>
        <el-table-column label="标题" prop="title"></el-table-column>
        <el-table-column label="Aiffect状态" prop="aiffect_status"></el-table-column>
        
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="primary" @click="edit(scope.row)"
              >编辑</el-button
            >
            <el-button v-if="scope.row.aiffect_status === 'COMPLETE'" type="primary" @click="viewContent(scope.row)"
              >查看</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog :visible.sync="show" title="内容信息" :close-on-click-modal="false">
      <el-form :model="form" label-width="80px">
        <el-form-item label="标题">
          <el-input v-model="form.title"></el-input>
        </el-form-item>
        <el-form-item label="类型">
          <el-select v-model="form.type" placeholder="请选择类型">
            <el-option v-for="item in ['Marketing','Tutorial']" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="品牌">
          <el-select v-model="form.brand" placeholder="请选择品牌">
            <el-option v-for="item in ['WAO','NIM','茶吉茶利','WEC','GYP']" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="人工审核状态">
          <el-select v-model="form.status" placeholder="请选择状态">
            <el-option v-for="item in ['PLAN TO REVIEW','FOR PRODUCTION','TO PUBLISH','PUBLISHED','REJECTED']" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="提示">
          <el-input type="textarea" v-model="form.hint"></el-input>
        </el-form-item>
        <el-form-item label="形式">
          <el-select v-model="form.form" placeholder="请选择形式">
            <el-option v-for="item in ['passage']" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Aiffect状态">
          <el-select v-model="form.aiffect_status" placeholder="请选择Aiffect状态">
            <el-option v-for="item in ['COMPLETE', 'REDO']" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="show = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
    
    <el-dialog :visible.sync="showContent" title="Aiffect内容" :close-on-click-modal="true" @close="closeContent">
      <!--write a el-tabs分别显示contentData的blog_content,email_content,sns_content,poster_content。-->
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="Blog Content" name="first">
          <!--show HTML format-->
          <div v-html="contentData.blog_content"></div>
        </el-tab-pane>
        <el-tab-pane label="Email Content" name="second">
          <div v-if="contentData.email_content_array">
            <div v-for="(key,item) in contentData.email_content_array" :key="item">
              <h3>{{key}}</h3>
              <div v-html="item"></div>
            </div>
          </div>
          <div v-else>
            <div v-html="contentData.email_content"></div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Web Content" name="third">
          <div v-html="contentData.web_content"></div>
        </el-tab-pane>
        <el-tab-pane label="SNS Content" name="fourth">  
          <div v-html="contentData.sns_content"></div>
        </el-tab-pane>
        <el-tab-pane label="Poster Content" name="fifth">  
          <div v-html="contentData.poster_content"></div>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
  </div>
</template>

<script>
import {
  postRequest
} from "../api/assistant";
import VueTagsInput from '@johmun/vue-tags-input'
export default {
  components:{
    VueTagsInput
  },
  data() {
    return {
      tableData: [],
      form: {},
      show: false,
      contentData:{},
     
    };
  },
  mounted() {
    this.fetchData();

  },
  methods: {

    add(){
      this.form = {
        id:'',
        title:'',
        hint:'',
        type:'Marketing',
        brand:'WAO',
        status:'IN PLANNING',
        form:'passage',
      }
      this.show = true
    },

    fetchData() {
      
      postRequest({'function':'getAIContents'}).then((res) => {
        if (res.data.code === 0) {
          this.tableData = res.data.data;
        }
      });
    },
    
    edit(row){
      this.form = row;
      this.form.id = row._id.$id;
      this.show = true
    },
    
    submit(){
      this.form.function = 'updateAIContent';
      postRequest(this.form).then(res=>{
        if(res.data.code===0){
          this.$message({
            type: "success",
            message: "修改成功!",
          });
          this.show = false;
          this.fetchData();
        }
      })
    },
    viewContent(row){
      this.contentData = row;
      this.showContent = true;
    },
    closeContent(){
      this.contentData = {};
      this.showContent = false;
    } 
    
  },
};
</script>

<style lang="scss">
.vue-tags-input{
  flex: 1!important;
  margin-left: 10px;
  max-width: none!important;
  .ti-input{
    width: 100%!important;
  }
}
</style>