<template>
  <div>
    <el-button @click="addCheckTask" size="small" type="primary">添加审核任务</el-button>
    <div style="margin-top: 20px">
      <el-tag
        style="margin-left: 10px; cursor: pointer"
        :effect="tagType == 'review' ? 'dark' : 'light'"
        @click="changeStatus('review')"
        >待我审核的任务</el-tag
      >
      <el-tag
        style="margin-left: 10px; cursor: pointer"
        :effect="tagType == 'my' ? 'dark' : 'light'"
        @click="changeStatus('my')"
        >我创建的任务</el-tag
      >
      <el-tag
        style="margin-left: 10px; cursor: pointer"
        :effect="tagType == 'finish' ? 'dark' : 'light'"
        @click="changeStatus('finish')"
        >我审核完的任务</el-tag>
    </div>

    <div
      style="
        margin-top: 20px;
        margin-bottom: 20px;
        font-family: 'Microsoft YaHei';
      "
    >
      审核任务清单
    </div>
    <el-table v-loading="taskLoading" :data="checkTaskList" size="small">
      <el-table-column
        fixed
        prop="name"
        label="标题"
        width="400"
      ></el-table-column>
      <el-table-column prop="type" label="类型" width="150"></el-table-column>
      <el-table-column
        prop="creater"
        label="创建人"
        width="100"
      ></el-table-column>

      <el-table-column label="审核人" width="180">
        <template slot-scope="scope">
          <p v-for="(item, index) in scope.row.reviewer_ids" :key="index">
            {{
              "- " +
              scope.row.reviewer_names[index] +
              ": " +
              status_arr[scope.row.reviewer_status[index]]
            }}
          </p>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="400">
        <template slot-scope="scope">
          <el-button
            v-if="user_id == scope.row.creater_id"
            @click="editCheckTask(scope.row)"
            size="mini"
            >修改</el-button>
          <el-button
            v-if="user_id == scope.row.creater_id"
            @click="refreshReviewerStatus(scope.row._id.$id)"
            size="mini">重新提交</el-button>
          <el-button @click="assignTask(scope.row)" size="mini" type="warning">分配</el-button>
          <el-button @click="toDetail(scope.row)" size="mini" type="primary">查看</el-button>
          <!-- <el-button type="success" @click="isChooseList(scope.row)" size="mini">检查</el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :visible.sync="showEdit" title="修改任务信息">
      <p>标题</p>
      <el-input v-model="name"></el-input>
      <p>笔记</p>
      <el-input type="textarea" v-model="note" :rows="6"></el-input>
      <p>类型</p>
      <el-select v-model="currentCheckType" @change="selectType">
        <el-option
          v-for="(item, index) in typeList"
          :key="index"
          :value="item._id.$id"
          :label="item.name"
        ></el-option>
      </el-select>
      <!-- <p>检查清单</p>
            <el-select v-model="checklist_id">
                <el-option v-for="(item,index) in checkLists" :key="index" :value="item._id.$id" :label="item.name"></el-option>
            </el-select> -->
      <div style="margin-top: 20px">
        <el-button size="medium" type="primary" @click="updateNewCheckTask"
          >确定</el-button>
      </div>
    </el-dialog>
    <el-dialog  :close-on-click-modal="false" :visible.sync="showAssign"   title="分配详情">
      <el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane label="已分配对象" name="first"> <el-table
        :data="assignList"
        v-loading="assignLoading"
        style="margin-top: 10px">
        <el-table-column label="姓名">
          <template slot-scope="scope">
            <span>{{
              scope.row.user_lastName + scope.row.user_givenName
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="user_email" label="邮箱"></el-table-column>
        <el-table-column prop="assignStatus" label="状态">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.assignStatus == 1">已分配</el-tag>
            <el-tag type="info" v-if="scope.row.assignStatus == 2">未分配</el-tag>
          </template>
        </el-table-column>

        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.assignStatus == 1"
              @click="reassignConfirm(scope.row)"
              type="warning"
              size="small">取消分配</el-button>
          </template>
        </el-table-column>
      </el-table></el-tab-pane>
    <el-tab-pane label="选择分配对象" name="second">
       <p>请选择分配对象</p>
      <el-input
        v-model="searchEmail"
        style="width: 60%"
        placeholder="请输入要搜索的邮箱" clearable
      ></el-input>
      <el-button
        @click="searchEmployee"
        style="margin-left: 10px"
        type="primary"
        size="medium"
        >搜索</el-button
      >
      <el-table
        :data="searchResults"
        v-loading="assignLoading"
        style="margin-top: 10px"
      >
        <el-table-column label="姓名">
          <template slot-scope="scope">
            <span>{{
              scope.row.user_lastName + scope.row.user_givenName
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="user_email" label="邮箱"></el-table-column>
        <el-table-column prop="assignStatus" label="状态">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.assignStatus == 1">已分配</el-tag>
            <el-tag type="info" v-if="scope.row.assignStatus == 2">未分配</el-tag>
          </template>
        </el-table-column>

        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.assignStatus == 2"
              @click="assignConfirm(scope.row)"
              type="primary"
              size="small"
              >分配</el-button
            >
            <el-button
              v-if="scope.row.assignStatus == 1"
              @click="reassignConfirm(scope.row)"
              type="warning"
              size="small"
              >取消分配</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-tab-pane>

  </el-tabs>

    </el-dialog>
    <el-dialog
      @close="closeCheckList"
      :visible.sync="showListChoosed"
      title="检查清单"
    >
      <div
        v-loading="typeLoading"
        class="checkDiv"
        v-for="(item, index) in check_items"
        :key="index"
      >
        <el-checkbox
          @change="changeCheckList(item)"
          :label="index"
          :key="index"
          v-model="item.check_status"
        >
          <div
            :class="
              tagType === 'creater' && item.check_status == false ? 'red' : ''
            "
          >
            {{ item.name }}
          </div>
        </el-checkbox>
        <p class="reviewer_name" v-if="item.reviewer_name">
          {{ item.reviewer_name }}
        </p>
      </div>
      <p>{{ itemTxt }}</p>
    </el-dialog>
  </div>
</template>

<script>
import {
  checkTaskItem,
  getItemsByList,
  updateNewCheckTask,
  getCheckTypes,
  getAllCheckTasks,
  getMyCheckTasksToReview,
  findEmployeeByEmail,
  assignReviewerToCheckTask,
  getListsByType,
  getTypeAllInfo,
  getMyCreatedCheckTasks,
  refreshReviewerStatus,
  resignReviewer,
  getCheckTask
} from '../api/assistant.js'
import { getUserId } from '../utils/store'
export default {
  data () {
    return {
      itemTxt: '',
      activeName: 'first',
      taskData: '',
      showListChoosed: false,
      checkTaskList: [],
      check_items: [],
      showEdit: false,
      typeList: [],
      checklist_id: '',
      currentCheckType: '',
      name: '',
      note: '',
      id: '',
      task_id: '',
      showAssign: false,
      searchEmail: '',
      searchResults: [],
      checkLists: [],
      chooseList: '',
      checkNewList: '',
      tagType: 'review',
      reviewer_id: '',
      assignreviewer_ids: [],
      type_id: '',
      typeLoading: false,
      user_id: '',
      assignLoading: false,
      taskLoading: false,
      assignList: [],
      status_arr: {
        pending: '待审核',
        approved: '通过',
        reject: '打回'
      }
    }
  },
  created () {},
  mounted () {
    this.user_id = getUserId()
    this.getCheckTypes()

    this.getMyCheckTasksToReview('false')
  },
  methods: {
    handleClick (tab, event) {
      console.log(tab, event)
    },
    closeCheckList () {
      this.showListChoosed = false
      this.check_item = []
    },
    getTypeAllInfo () {
      this.check_items = []
      this.typeLoading = true
      getTypeAllInfo({ type_id: this.type_id }).then((res) => {
        this.typeLoading = false
        if (res.data.code === 0) {
          const list_info = res.data.data.list_info
          if (list_info && list_info.data && list_info.data.length > 0) {
            const listData = list_info.data
            if (listData.length > 0) {
              this.itemTxt = ''
              listData.forEach((ele) => {
                ele.checkName = res.data.data.name
                if (ele.item_list && ele.item_list.length > 0) {
                  ele.item_list.forEach((f) => {
                    f.typeTitleName = ele.checkName
                    f.typeTitleId = res.data.data._id.$id
                    f.detailTitleName = ele.name
                    f.detailTitleId = ele._id.$id
                    f.type = ele.type
                    console.log(
                      'f._id.$id:' + f._id.$id,
                      'f.typeTitleId:' + f.typeTitleId,
                      'f.detailTitleId:' + ele._id.$id
                    )

                    if (
                      this.checkNewList &&
                      this.checkNewList.check_results &&
                      f._id.$id in this.checkNewList.check_results
                    ) {
                      f.review_time = this.formatTime(
                        this.checkNewList.check_results[f._id.$id].review_time
                      )
                      if (this.checkNewList.check_results[f._id.$id].status) {
                        f.check_status = true
                        f.reviewer_name =
                          this.checkNewList.check_results[f._id.$id]
                            .reviewer_name +
                          '于' +
                          f.review_time +
                          '已打勾'
                      } else {
                        f.reviewer_name =
                          this.checkNewList.check_results[f._id.$id]
                            .reviewer_name +
                          '于' +
                          f.review_time +
                          '已取消'
                      }
                    } else {
                      f.check_status = false
                    }
                    this.check_items.push(f)
                  })
                  console.log(this.check_items)
                }
              })
            } else {
              this.itemTxt = '暂无审核清单'
            }
          }
        }
      })
    },
    changeCheckList (item) {
      // 反复调用--表示取消或者选中

      // if (item.check_status) {
      // 选中
      checkTaskItem(this.reviewer_id, item._id.$id, this.taks_id).then(
        (eleData) => {
          if (eleData.data.code === 0) {
            const check_results = eleData.data.data.check_results
            this.check_items = this.check_items.map((m, index) => {
              if (m._id.$id == item._id.$id) {
                m.check_status = item.check_status
                if (item._id.$id in check_results) {
                  if (check_results[item._id.$id].status) {
                    m.review_time = this.formatTime(
                      check_results[item._id.$id].review_time
                    )
                    m.reviewer_name =
                      check_results[item._id.$id].reviewer_name +
                      '于' +
                      m.review_time +
                      '打勾'
                  } else {
                    m.reviewer_name =
                      check_results[item._id.$id].reviewer_name +
                      '于' +
                      m.review_time +
                      '取消打勾'
                  }
                }
              }
              return m
            })
            console.log(this.check_items)
          }
        }
      )
      // }
    },
    formatTime (timestamp) {
      // 将时间戳转换为时间
      timestamp = timestamp * 1000
      const time = new Date(timestamp)
      const y = time.getFullYear()
      const m = time.getMonth() + 1
      const d = time.getDate()
      const h = time.getHours()
      const mm = time.getMinutes()
      const s = time.getSeconds()
      return (
        y +
        '-' +
        this.add0(m) +
        '-' +
        this.add0(d) +
        ' ' +
        this.add0(h) +
        ':' +
        this.add0(mm) +
        ':' +
        this.add0(s)
      )
    },
    add0 (m) {
      return m < 10 ? '0' + m : m
    },
    isChooseList (row) {
      this.taks_id = row._id.$id
      this.reviewer_id = getUserId()
      this.showListChoosed = true

      getCheckTask(row._id.$id).then((res) => {
        if (res.data.code === 0) {
          this.checkNewList = res.data.data
          console.log(this.checkNewList.check_results)
          this.type_id = this.checkNewList.type_id
          this.getTypeAllInfo()
          // getItemsByList(this.checkNewList.checklist_id).then((eleData) => {
          //   if (eleData.data.code === 0) {
          //     this.check_items = eleData.data.data;
          //     for (const item of this.check_items) {
          //       if (item._id.$id in this.checkNewList.check_results) {
          //         item.check_status = true;
          //       } else {
          //         item.check_status = false;
          //       }
          //     }
          //   }
          // });
        }
      })
    },
    selectType (val) {
      this.getListsByType(val)
    },
    getListsByType (type_id) {
      getListsByType(type_id).then((res) => {
        console.log(res)
        if (res.data.code === 0) {
          this.checkLists = res.data.data
        }
      })
    },
    getMyCreatedCheckTasks () {
      this.taskLoading = true
      const my_id = getUserId()
      getMyCreatedCheckTasks(my_id).then((res) => {
        this.taskLoading = false
        console.log(res)
        if (res.data.code === 0) {
          this.checkTaskList = res.data.data
        }
      })
    },

    getCheckTypes () {
      getCheckTypes().then((res) => {
        if (res.data.code === 0) {
          this.typeList = res.data.data
        }
      })
    },
    addCheckTask () {
      this.name = ''
      this.note = ''
      this.currentCheckType = ''
      this.showEdit = true
      this.id = ''
    },
    getCheckTask () {
      const that = this

      this.checkTaskLoading = true
      getCheckTask(this.task_id).then((res) => {
        this.checkTaskLoading = false
        if (res.data.code === 0) {
          that.taskData = res.data.data
          this.assignreviewer_ids = Object.keys(that.taskData.reviewer_ids)
          this.findEmployee()
        }
      })
    },
    editCheckTask (row) {
      this.name = row.name
      this.note = row.note
      this.currentCheckType = row.type_id
      this.checklist_id = row.checklist_id
      this.$nextTick(() => {
        this.getListsByType(row.type_id)
      })

      this.showEdit = true
      this.id = row._id.$id
    },
    getAllCheckTasks () {
      getAllCheckTasks().then((res) => {
        console.log(res)
        this.checkTaskList = res.data.data
      })
    },

    getMyCheckTasksToReview (status) {
      const my_id = getUserId()
      getMyCheckTasksToReview(my_id, status).then((res) => {
        this.checkTaskList = res.data.data
      })
    },

    updateNewCheckTask () {
      const creater_id = getUserId()
      updateNewCheckTask(
        this.id,
        this.currentCheckType,
        this.name,
        this.note,
        creater_id,
        this.checklist_id
      ).then((res) => {
        if (res.data.code === 0) {
          this.$message({
            message: '更新成功！',
            type: 'success'
          })
          this.showEdit = false

          this.getMyCreatedCheckTasks()
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    refreshReviewerStatus (id) {
      refreshReviewerStatus({ task_id: id }).then((res) => {
        console.log(res)
        if (res.data.code === 0) {
          this.$message({
            message: '更新成功！',
            type: 'success'
          })
          // this.getReviewsByTask();
        }
      })
    },
    searchEmployee () {
      if (!this.searchEmail) {
        this.$message.warning('请输入要搜索的邮箱')
      } else {
        this.assignLoading = true
        this.searchResults = []
        findEmployeeByEmail(this.searchEmail).then((res) => {
          console.log(res)
          this.assignLoading = false
          if (res.data && res.data.length > 0) {
            res.data.forEach((f) => {
              const isContainArr = this.assignreviewer_ids.filter(
                (a) => a == f._id.$id
              )
              if (isContainArr.length > 0) {
                f.assignStatus = '1' // 已分配
              } else {
                f.assignStatus = '2'
              }
            })
            this.searchResults = res.data
            console.log(this.searchResults)
          }
        })
      }
    },
    assignTask (row) {
      this.id = row._id.$id

      this.chooseList = ''

      this.showAssign = true
      this.searchEmail = ''
      this.searchResults = []
      // 获取已经分配的人员名单 reviewer_ids
      this.assignreviewer_ids = Object.keys(row.reviewer_ids)
      this.findEmployee()
    },
    findEmployee () {
      findEmployeeByEmail(this.searchEmail).then((res) => {
        console.log(res)
        this.assignList = []
        this.assignLoading = false
        if (res.data && res.data.length > 0) {
          res.data.forEach((f) => {
            const isContainArr = this.assignreviewer_ids.filter(
              (a) => a == f._id.$id
            )
            if (isContainArr.length > 0) {
              f.assignStatus = '1' // 已分配
              this.assignList.push(f)
            } else {
              f.assignStatus = '2'
            }
          })
        }
      })
    },
    reassignConfirm (row) {
      // 取消分配
      const param = {
        task_id: this.id,
        reviewer_id: row._id.$id
      }
      resignReviewer(param).then((res) => {
        if (res.data.code === 0) {
          this.$message({
            message: '取消分配成功',
            type: 'success'
          })
          this.task_id = this.id
          this.getCheckTask()
        }
      })
    },

    assignConfirm (row) {
      const reviewer_id = row._id.$id
      const task_id = this.id

      const creater_id = getUserId()
      assignReviewerToCheckTask(task_id, reviewer_id, creater_id).then(
        (res) => {
          if (res.data.code === 0) {
            this.$message({
              message: '分配成功',
              type: 'success'
            })
            this.task_id = this.id
            this.getCheckTask()
          }
        }
      )
    },
    toDetail (row) {
      this.$router.push({
        path: '/adminmanageuser/reviewdetail',
        query: {
          task_id: row._id.$id,
          type_id: row.type_id
        }
      })
    },
    changeStatus (data) {
      this.tagType = data
      if (data === 'my') {
        this.getMyCreatedCheckTasks()
      } else if (data === 'all') {
        this.getAllCheckTasks()
      } else if (data == 'review') {
        this.getMyCheckTasksToReview('false')
      } else if (data == 'finish') {
        this.getMyCheckTasksToReview('true')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.checkDiv {
  display: flex;
  align-items: center;
}
.reviewer_name {
  margin: 0;
  padding: 0;
  margin-left: 80px;
  font-size: 12px;
  color: #909399;
}
</style>
