<template>
    <div class="container">
        <el-button type="primary" size="mini" @click="lastWeek">上一周</el-button>
        <el-button type="primary" size="mini" @click="nextWeek">下一周</el-button>
        <div class="table">
            <div class="tableHead">
                <div class="headItem">姓名</div>
                <div class="headItem" v-for="(item,index) in dates" :key="item">{{mapWeek[index]}} {{item}}</div>
                <div class="headItem border">每周总结</div>
            </div>
            <div class="tableHead">
                <div class="headItem">Total Comp</div>
                <div class="headItem" v-for="(item,index) in comps" :key="index">{{item}}</div>
                <div class="headItem border"></div>
            </div>
            <div class="tableContent" v-for="(item,index) in employees" :key="item._id.$id">
                <div :class="{'headItem':true,'border-bottom':index===(employees.length-1)}">{{item.user_lastName+item.user_givenName}}</div>
                <div v-for="(x,i) in item.sheets" :key="i" :class="{'content':x.sheet,'headItem':true,'border-bottom':index===(employees.length-1)}" @click="toForm(x)">
                    {{x.sheet?`${x.sheet.sheet_schedule} (${Number(x.sheet.sheet_work_timelen)+(x.sheet.sheet_parttime_work_timelen?x.sheet.sheet_parttime_work_timelen:0)}小时)`:''}}
                </div>
                <div :class="{'headItem':true,'border':true,'border-bottom':index===(employees.length-1)}">
                    {{`工作${item.work_time.length}天${sum(item.work_time)}小时`}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {getWorkingLogTable} from '../../api/assistant'
import {getUserId} from '../../utils/store'
export default {
    data(){
        return{
            number:0,
            employees:[],
            comps:[],
            mapWeek:['Mon','Tue','Wed','Thu','Fri','Sat','Sun'],
            dates:[],
            time_len:{},
            loading:false
        }
    },
    created(){
        this.fetchData()
    },
    methods:{
        fetchData(){
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            getWorkingLogTable(this.number).then(res=>{
                // for(let i = 0 ; i < 7 ; i++){
                //     res.data[i].weekName = this.mapWeek[i]
                // }
                // this.schedule = res.data
                let employees = res.data.employees
                employees.map(item=>{
                    item.work_time = item.sheet_work_timelen.filter(x=>x!==0)
                })
                this.employees = employees
                this.comps = res.data.comps
                this.dates = res.data.employees[0].sheets.map(item=>item.name)
                loading.close()
            })
        },
        lastWeek(){
            this.number -= 1
            this.fetchData()
        },
        nextWeek(){
            this.number += 1
            this.fetchData()
        },
        toForm(data){
            if(data.sheet){
                this.$router.push({
                    path:'/adminmanage/dailyLogForm',
                    query:{
                        date:data.name,
                        start_day:data.start_day,
                        end_day:data.end_day,
                        sheet_id:data.sheet ? data.sheet._id.$id : '',
                        user_id:data.sheet.employee_id,
                        type:'admin'
                    }
                })
            }
        },
        sum(arr) {
            var len = arr.length;
            if(len == 0){
                return 0;
            } else if (len == 1){
                return arr[0];
            } else {
                return arr[0] + this.sum(arr.slice(1));
            }
        }
    }
}
</script>

<style scoped>
.container{
    width: 100%;
    color: #909399;
}
.table{
    width:100%;
    margin-top:20px
}

.tableHead{
    text-align: center;
    display: flex;
    height: 46px;
    line-height: 46px;
    font-weight: 600;
    font-size: 96%
}
.headItem{
    border-top: 0.5px solid #E4E7ED;
    border-left: 0.5px solid #E4E7ED;
    width: 11%;
    font-size: 15px;
}
.tableContent{
    height: 50px;
    display: flex;
    line-height: 50px;
    text-align: center;
}
.border{
    border-right: 0.5px solid #E4E7ED;
}
.border-bottom{
    border-bottom: 0.5px solid #E4E7ED;
}
.content{
    cursor: pointer;
    background: #DCDFE6;
}
.content:hover{
    background: #67C23A;
}
</style>