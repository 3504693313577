<template>
    <div>
        <el-button @click="$router.back(-1)" size="medium">返回</el-button>
        <el-form>
            <el-form-item label="内容名称">
                <el-input v-model="content.content_info.content_name"></el-input>
            </el-form-item>
            <el-form-item label="内容级别">
                <!-- <el-input v-model="content.content_info.content_level"></el-input> -->
                <el-select v-model="content.content_info.content_level">
                    <el-option value="1" label="1"></el-option>
                    <el-option value="2" label="2"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="内容类型">
                <!-- <el-input v-model="content.content_info.content_type"></el-input> -->
                <el-select v-model="content.content_info.content_type">
                    <el-option value="HTML" label="HTML"></el-option>
                    <el-option value="VIDEO" label="VIDEO"></el-option>
                    <el-option value="AUDIO" label="AUDIO"></el-option>
                    <el-option value="SLIDES" label="SLIDES"></el-option>
                    <el-option value="PDF" label="PDF"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="内容提示">
                <el-input v-model="content.content_info.content_instruction" type="textarea" :rows="6"></el-input>
            </el-form-item>
            <el-form-item label="内容长度">
                <el-input v-model="content.content_info.content_length"></el-input>
            </el-form-item>
            <el-form-item label="音频地址">
                <el-input v-model="content.content_info.audio_url"></el-input>
            </el-form-item>
            <el-form-item label="视频地址">
                <el-input v-model="content.content_info.video_url"></el-input>
            </el-form-item>
            <el-form-item label="PDF地址">
                <el-input v-model="content.content_info.pdf_url"></el-input>
            </el-form-item>
            <el-form-item label="Workshop">
                <el-select v-model="content.content_info.workshop_ids" multiple>
                    <el-option v-for="item in workshops" :key="item._id.$id" :label="item.name" :value="item._id.$id"></el-option>
                </el-select>
            </el-form-item>


            <tinymce :height="200" v-model="content.content_info.html_text"/>

        </el-form>
        <el-button @click="submit" type="primary" style="margin-top:30px;margin-bottom:30px">提交</el-button>

        <p>随堂练习</p>
        <el-button type="primary" @click="add">添加</el-button>
        <el-table :data="quiz_list">
            <el-table-column label="题干" prop="question_title"></el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button type="primary" @click="view(scope.row._id.$id)" size="small">查看</el-button>
                    <el-button @click="removeContentQuiz(scope.row._id.$id)" type="warning" size="small">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import Tinymce from '../components/Tinymce' 
import { getContent,updateContent,updateContentQuiz,getContentAdmin ,removeContentQuiz,getWorkshops} from '../api/assistant'
export default {
    components: {
        tinymce: Tinymce
    },
    data(){
        return{
            id:'',
            content:{
                content_info:{
                    workshop_ids:[]
                },
            },
            class_id:this.$route.query.class_id,
            session_id:this.$route.query.session_id,
            quiz_list:[],
            workshops:[]
        }
    },
    mounted(){
        this.id = this.$route.query.id
        this.fetchData()
        this.getWorkshops()
    },
    methods:{
        fetchData(){
            getContentAdmin(this.id).then(res=>{
                if(res.data){
                    console.log(res)
                    this.content = res.data
                    this.quiz_list = res.data.quiz
                }
            })
        },
        submit(){
            updateContent(this.id,this.content.content_info.content_name,this.content.content_info.content_level,this.content.content_info.content_type,this.content.content_info.content_instruction,this.content.content_info.content_length,this.content.content_info.audio_url,this.content.content_info.video_url,this.content.content_info.pdf_url,this.content.content_info.html_text,this.class_id,this.session_id,this.content.content_info.workshop_ids).then(res=>{
                this.$message({
                    message:'更新成功',
                    type:'success'
                })
            })
        },
        add(){
            updateContentQuiz('',this.content._id.$id,'','','','','','').then(res=>{
                this.$message('添加成功');
                console.log(res)
                this.fetchData()
            })
        },
        view(id){
            this.$router.push({
                path:'quizInfo',
                query:{
                    id:id
                }
            })
        },
        removeContentQuiz(id){

            // console.log('1111',id,this.id)
            removeContentQuiz(this.id,id).then(res=>{
                this.$message('删除成功')
            })
            this.fetchData()
        },
        getWorkshops(){
            getWorkshops().then(res=>{
                if(res.data.code===0){
                    this.workshops = res.data.data
                }
            })
        }
    }
}
</script>

<style>

</style>