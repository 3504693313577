<template>
    <div>
        <el-button v-if="!$route.path.includes('adminmanageuser')" @click="toUpdate" type="primary">新增</el-button>
        <el-table :data="standards" v-loading="loading">
            <el-table-column prop="Standard_business" label="所属业务" width="200"></el-table-column>
            <el-table-column prop="Standard_department" label="所属部门" width="180"></el-table-column>
            <el-table-column prop="Standard_name" label="标准名称" width="300"></el-table-column>
            <el-table-column prop="version" label="版本" width="180"></el-table-column>
            <el-table-column label="是否知晓" v-if="$route.path.includes('adminmanageuser')">
                <template slot-scope="scope">
                    <span v-if="scope.row.check_status">已知晓并同意</span>
                    <span v-else style="color:#F56C6C;font-weight:bold">尚未知晓</span>
                </template>
            </el-table-column>
            <el-table-column label="详细" width="150">
                <template slot-scope="scope">
                    <el-button type="primary" size="small" @click="view(scope.row._id.$id,scope.row.Standard_department)">查看</el-button>
                    <el-button type="danger" size="small" v-if="!$route.path.includes('adminmanageuser')" @click="remove(scope.row._id.$id)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import {getStandards,getUser,removeStandard,getUserStandards} from '../../api/assistant'
import {getUserId} from '../../utils/store'
export default {
    data(){
        return{
            standards:[],
            loading:false
        }
    },
    mounted(){
        this.fetchData()
    },
    methods:{
        fetchData(){
            this.loading=true
            console.log(11111,getUserId())
            if(this.$route.path.includes('adminmanageuser')){
                getUserStandards(getUserId()).then(res=>{
                    this.standards = res.data
                    this.loading=false
                })
            }else{
                getStandards().then(res=>{
                    this.standards = res.data
                    this.loading=false
                })
            }
            
        },
        view(id,department){
            console.log(111,department)
            if(this.$route.path.includes('adminmanageuser')){
                getUser(getUserId()).then(res=>{
                    
                    if(department.includes(res.data.employee_department) || department.includes('其它') || department.includes('全员') || res.data.employee_department==='总办'){
                        this.$router.push({
                            path:'/adminmanageuser/standard',
                            query:{
                                id:id
                            }
                        })
                    }else{
                        this.$message.error('您无权查看此文档')
                    }
                })
                
            }else{
                this.$router.push({
                    path:'/adminmanage/standard',
                    query:{
                        id:id
                    }
                })
            }
        },
        toUpdate(){
            this.$router.push({
                path:'/adminmanage/standard',
                query:{
                    id:''
                }
            })
        },
        remove(id){
            this.$confirm('是否确认删除?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(()=>{
                    removeStandard(id).then(res=>{
                        this.fetchData()
                    })
                })
        }
    }
}
</script>

<style>

</style>