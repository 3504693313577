<template>
  <article>
    <h4 class="commonP">{{checkName}}</h4>
    <p class="commonP">创建人: {{taskData.creater}}</p>
    <section class="partsec part1">
      <span class="titleName">任务标题</span>
      <el-input
        class="widthRight"
        v-model="taskData.name"
        disabled
      ></el-input>
    </section>
    <section class="partsec part2">
      <span class="titleName">笔记</span>
      <el-input
        class="widthRight"
        v-model="taskData.note"
        type="textarea"
        :rows="5"
        disabled
      ></el-input>
    </section>
    <!-- <section class="partsec part3">
      <span  class="titleName">检查清单</span>
      <div class="widthRight checkitems">
  <section
        class="checkDiv"
        v-for="(item, index) in check_items"
        :key="index"
      >
        <el-checkbox
          
          @change="changeCheckList(item)"
          :label="index"
          :key="index"
          v-model="item.check_status"
        >
          <div>
            {{ item.name }}
          </div>
        </el-checkbox>
        <p class="reviewer_name" v-if="item.reviewer_name">
          {{ item.reviewer_name }}
        </p>
      </section>
      </div>
    </section> -->

    <section class="partsec part4">
      <span  class="titleName">审核清单</span>
     <div class="widthRight checkitems">
        
       <section  v-for="(item, index) in checkAllList" :key="index">
         <p class="commonP nameQingdan">{{item.name}}</p>
      <section
        class="checkDiv2"
        v-for="(ele, index) in item.item_list"
        :key="index">
        <el-checkbox
          @change="changeCheckList(item,ele)"
          :label="index"
          :key="index" size="small"
          v-model="ele.check_status">
          <div>
            {{ ele.name }}
          </div>
        </el-checkbox>
        <p class="reviewer_name" v-if="ele.reviewer_name">
          {{ ele.reviewer_name }}
        </p>
      </section>
       </section>
    
      </div>
    </section>
    <section class="partsec part4">
      <span class="titleName">审核状态</span>
      <div class="noteSession widthRight">
        <el-table :data="reviewersList" v-loading=checkTaskLoading>
          <el-table-column  prop="reviewer_name" label="审核人"></el-table-column>
          <el-table-column prop="reviewer_status" label="审核状态">
             <template slot-scope="scope">
               <el-tag type="info" v-if="scope.row.reviewer_status == 'pending'">暂未审核</el-tag>
               <el-tag type="success" v-if="scope.row.reviewer_status=='approved'">同意</el-tag>
               <el-tag type="danger" v-if="scope.row.reviewer_status=='reject'">打回</el-tag>
             </template>
          </el-table-column>
          <el-table-column label="操作">
                <template slot-scope="scope">
                  <div v-if="scope.row.reviewer_id==reviewer_id">
                     <el-button @click="changeReviewerStatus(scope.row,'approved')" type="text" size="mini">通过</el-button>
                    <el-button @click="changeReviewerStatus(scope.row,'reject')" type="text" size="mini">打回</el-button>
                  </div>
                    
                </template>
            </el-table-column>
        </el-table>
      </div>
    </section>
   
    <section class="partsec part4">
      <span  class="titleName">批注</span>
      <div class="noteSession1 widthRight">
        <div>
          <el-button class="noteNew" @click="addNote" type="primary" size="mini">新增批注</el-button>
        </div>
        <div class="noteBox">
       <div v-for="(review,index) in reviewsList" :key="index">
          <div v-if="review.note" class="reviewnote">
            <span>{{review.note}}</span>  
            <div>
              <span class="graySpan">{{review.reviewer + ', ' + formatTime(review.create_time)}}</span>   
              <el-button v-if="review.reviewer_id == reviewer_id" style="margin-left:20px" @click="updateNote(review)" type="text" size="mini">编辑</el-button>
            </div>
          </div>
         
        </div>
        </div>
      
      </div>
    </section>
    <el-dialog
      @close="closeNote"
      :visible.sync="showNoteDiag"
      :title="noteTile"
    >
      <section class="flexNote">
        <span class="noteTxt">批注</span>
        <el-input
          type="textarea"
          :rows="6"
          v-model="note"
          :disabled="type === 'creater'"
        ></el-input>
      </section>
      <div class="btnBote">
        <el-button @click="updateReviewNote()" type="primary" size="medium"
          >确定</el-button
        >
      </div>
    </el-dialog>
    <el-dialog :visible.sync="showEdit" :title="diaTitle" width="600px">
      <p>清单标题</p>
      <el-input v-model="currentRow.detailTitleName"></el-input>
      <p>副标题</p>
            <el-input v-model="currentRow.name"></el-input>
            <p>是否生效</p>
            <el-select v-model="currentRow.valid_status">
                <el-option value="YES" label="是"></el-option>
                <el-option value="NO" label="否"></el-option>
            </el-select>
            <div>
 <el-button
        size="medium"
        type="primary"
        style="margin-top: 20px"
        @click="updateCheckList"
        >确定</el-button>
            </div>
     
    </el-dialog>
    <el-button
      @click="$router.back(-1)"
      style="margin-left: 100px; margin-top: 20px"
      size="medium"
      >返回</el-button
    >
  </article>
</template>

<script>
import {
  getCheckTask,
  getReviewsByTask,
  getTypeAllInfo,
  checkItem,
  getItemsByList,
  updateReviewNote,
  findEmployeeByEmail,
  checkTaskItem,
  changeReviewerStatus,
  updateCheckList,
  updateCheckListItem
} from "../api/assistant";
import { getUserId } from "../utils/store";
export default {
  data() {
    return {
      checkItemLoading:false,
      showNoteDiag: false,
      id: "",
      noteTile: "新增批注",
      taskData: {},
      check_items: [],
      reviewsList: [],
      type: "",
      note: "",
      review_id: "",
      reviewer_id:"",
      task_id: "",
      type_id: "",
      checkLists: [],
      checkAllList:[],
      check_results: [],
      reviewersList: [],
      assignResults: [],
      checkTaskLoading:false,
      currentRow:"",
      isNew:false,
      diaTitle:"",
      showEdit:false,
      checkName:"",
      
    };
  },
  created() {
    this.type = this.$route.query.type;
 this.reviewer_id = getUserId();
    this.type_id = this.$route.query.type_id;
    this.task_id = this.$route.query.task_id;
    this.searchEmployee();
    this.getCheckTask();
  },
  mounted() {
    this.getReviewsByTask();
  },
  methods: {
        addList() {//添加审核清单
      this.name = "";
      this.id = "";
      this.showEdit = true;
      this.isNew=true;
      this.currentRow={};
      this.diaTitle="新增审核清单"
    },
        updateList(row) {
      this.currentRow = row;
    this.isNew=false;
this.diaTitle="修改审核清单"
      this.showEdit = true;
    },
        updateCheckList() {
        this.name=this.currentRow.detailTitleName;
        this.id=this.currentRow.list_id?this.currentRow.list_id:"";
      updateCheckList(this.id, this.type_id, this.name).then((res) => {
        console.log(res);
        if (res.data.code === 0) {
          let resData= res.data.data;
         this.type_id = resData.type_id;
           this.currentRow.list_id= resData._id.$id;
         this.updateCheckListItem();
         
        }
      });
    },
        updateCheckListItem () {
      this.content = this.currentRow.name;
      this.valid_status = this.currentRow.valid_status;
      this.itemId=this.currentRow._id?this.currentRow._id.$id:""
      updateCheckListItem(this.itemId, this.currentRow.list_id, this.content, this.valid_status).then(res => {
        console.log(res)
        if (res.data.code === 0) {
          this.$message({
            message: '更新成功',
            type: 'success'
          })
          this.showEdit = false;
          this.checkLists=[];
         
          this.getTypeAllInfo()
        }
      })
    },
        changeReviewerStatus (row, data) {
      debugger
      let param={
        reviewer_id:getUserId(),
        task_id:this.task_id,
        status:data
      }
      changeReviewerStatus(param).then(res => {
        console.log(res)
        if (res.data.code === 0) {
          this.$message({
            message: '更新成功！',
            type: 'success'
          })
         this.getCheckTask();
        }
      })
    },
    changeCheckList(itemList,item) {//反复调用--表示取消或者选中
      checkTaskItem(this.reviewer_id, item._id.$id, this.task_id).then((eleData) => {
        if (eleData.data.code === 0) {
          let check_results = eleData.data.data.check_results;
         itemList.item_list = itemList.item_list.map((m, index) => {
            if (m._id.$id == item._id.$id) {
              m.check_status = item.check_status;
              if(item._id.$id in check_results){
                if(check_results[item._id.$id].status){
                  m.review_time = this.formatTime(check_results[item._id.$id].review_time)
                  m.reviewer_name = check_results[item._id.$id].reviewer_name+"于"+ m.review_time+"打勾"
                }else{
                  m.reviewer_name=check_results[item._id.$id].reviewer_name+"于"+ m.review_time+"取消打勾"
                }
                
              }
            }
            return m;
          });
        this.checkAllList=this.checkAllList.map(m=>{
          if(m._id.$id==itemList._id.$id){
              m.item_list = itemList.item_list
          }
          return m;
        })
        }
      });
      // }
    },
    closeNote() {
      this.showNoteDiag = false;
    },
    addNote() {
      //新增批注
      this.note = "";
      this.review_id = "";
      this.isNew = true;
      this.showNoteDiag = true;
    },
    updateNote(row) {
      //编辑
      this.note = row.note;
      this.review_id = row._id.$id;
      this.noteTile = "编辑批注";
      this.showNoteDiag = true;
    },
    getTypeAllInfo() {
      let that =this;
      getTypeAllInfo({ type_id: this.type_id }).then((res) => {
        if (res.data.code === 0) {
          let list_info = res.data.data.list_info;
        
        this.checkName = res.data.data.name;
        // this.creater_name = res.data.data.creater;
          if (list_info && list_info.data && list_info.data.length > 0) {
              this.checkAllList= res.data.data.list_info.data;
            list_info.data.forEach((ele) => {
              ele.checkName = res.data.data.name;
              if (ele.item_list && ele.item_list.length > 0) {
                ele.item_list.forEach((f) => {
                  f.typeTitleName = ele.checkName;
                  f.typeTitleId = res.data.data._id.$id;
                  f.detailTitleName = ele.name;
                  f.detailTitleId = ele._id.$id;
                  f.type = ele.type;
                  if (that.taskData && that.taskData.check_results && f._id.$id in that.taskData.check_results) {
                      f.review_time = that.formatTime(
                        that.taskData.check_results[f._id.$id].review_time
                      );
                      if (that.taskData.check_results[f._id.$id].status) {
                        f.check_status = true;
                        f.reviewer_name =
                          that.taskData.check_results[f._id.$id].reviewer_name +
                          "于" +
                          f.review_time +
                          "已打勾";
                      } else {
                        f.reviewer_name =
                          this.taskData.check_results[f._id.$id].reviewer_name +
                          "于" +
                          f.review_time +
                          "已取消";
                      }
                      console.log(that.taskData.check_results)
                      }
                     else {
                      f.check_status = false;
                    }
                  

                  this.check_items.push(f);
                });
                console.log(this.check_items);
              }
            });
          }
        }
      });
    },
    formatTime(timestamp) {
      // 将时间戳转换为时间
      timestamp = timestamp * 1000;
      let time = new Date(timestamp);
      let y = time.getFullYear();
      let m = time.getMonth() + 1;
      let d = time.getDate();
      let h = time.getHours();
      let mm = time.getMinutes();
      let s = time.getSeconds();
      return (
        y +
        "-" +
        this.add0(m) +
        "-" +
        this.add0(d) +
        " " +
        this.add0(h) +
        ":" +
        this.add0(mm)
      );
    },
    add0(m) {
      return m < 10 ? "0" + m : m;
    },
    searchEmployee() {
      findEmployeeByEmail("").then((res) => {
        this.assignResults = res.data;
      });
    },
    // async fetchData () {
    //   // let task_id = await this.getReviewById()
    //   let list_id = await this.getCheckTask()
    //   this.getItemsByList(list_id)
    // },
    // getReviewById(){
    //   return new Promise((resolve,reject)=>{
    //     getReviewById(this.review_id).then(res=>{
    //       if(res.data.code===0){
    //         this.reviewData=res.data.data
    //         this.note = res.data.data.task_note
    //         resolve(res.data.data.task_id)
    //       }
    //     })
    //   })
    // },
    getCheckTask() {
      let that =this;
    
      this.checkTaskLoading=true;
      getCheckTask(this.task_id).then((res) => {
        this.checkTaskLoading=false;
        if (res.data.code === 0) {
          that.taskData = res.data.data;
          that.getTypeAllInfo();
          that.review_id = "";
          that.reviewersList=[];
          for (let reviewer_id_r in that.taskData.reviewer_status) {

            var review_data = []
            review_data['reviewer_id'] = reviewer_id_r
            review_data['reviewer_name'] = that.taskData['reviewer_names'][reviewer_id_r]
            review_data['reviewer_status'] = that.taskData['reviewer_status'][reviewer_id_r]



            that.reviewersList.push(review_data);
          
          
           
          }
          

          console.log(that.reviewersList);

          that.check_results = res.data.data.check_results;
        } else {
          that.getTypeAllInfo();
        }
      });
    },
    getItemsByList(list_id) {
      getItemsByList(list_id).then((res) => {
        console.log(res);
        if (res.data.code === 0) {
          this.check_items = res.data.data;
          for (let item of this.check_items) {
            if (item._id.$id in this.check_results) {
              item.check_status = true;
            } else {
              item.check_status = false;
            }
          }
        }
      });
    },
    handleCheckedCitiesChange(item) {
      const reviewer_id = getUserId();
      checkItem(reviewer_id, item._id.$id, this.review_id).then((res) => {
        this.getReviewById();
      });
    },
    updateReviewNote() {
      if(!this.note){
 this.$message({
            message: "请填写批注内容",
            type: "warning",
          });
      }else{
      let param = {
        review_id: this.review_id ? this.review_id : "",
        task_id: this.task_id,
        reviewer_id: getUserId(),
        note: this.note,
      };
      updateReviewNote(param).then((res) => {
        console.log(res);
        if (res.data.code == 0) {
          this.$message({
            message: "批注更新成功！",
            type: "success",
          });
          this.showNoteDiag = false;
          this.getReviewsByTask();
        } else {
          this.$message({
            message: "批注更新失败！",
            type: "error",
          });
        }
      });
      }

    },
    getReviewsByTask() {
      getReviewsByTask(this.task_id).then((res) => {
        console.log(res);
        if (res.data.code === 0) {
          this.reviewsList = res.data.data;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.widthRight {
  width: 50%;
}
.partsec {
  display: flex;
  margin-top: 30px;
  .titleName {
    font-size: 14px;
    width: 100px;
    text-align: right;
    margin-right: 10px;
  }
}
.checkDiv {
  display: flex;
  align-items: center;
      justify-content: space-between;
}
.checkDiv2 {
  display: flex;
  align-items: center;
      justify-content: space-between;
      padding-left: 25px;
      margin-top: 10px;
}
.reviewer_name {
  margin: 0;
  padding: 0;
  margin-left: 80px;
  font-size: 12px;
  color: #909399;
}
.red {
  color: #ee0000;
}
.graySpan{
  color:#333;
  font-size: 13px;
}
.noteSession {

    padding-bottom: 30px;
  border:1px solid #dedede;
  padding: 0 20px;
  padding-bottom: 30px;
}
.noteSession1{
 
      padding-bottom: 30px;
  padding: 0 20px;
  padding-bottom: 30px;
}
.noteBox{
    padding: 0 20px;
    margin-top: 20px;
//  box-shadow: 4px 5px 5px #dedede;
}
.noteNew {
  margin: 10px;
}
.flexNote {
  display: flex;
}
.commonP{
  margin: 0;
  padding:0;
}
.noteTxt {
  width: 50px;
}
.btnBote {
  display: flex;
  margin-top: 30px;
  text-align: right;
  justify-content: center;
}
.checkitems{

}
.reviewnote{
  /*display: flex;*/
  justify-content: space-between;
  /*height: 40px;*/
  line-height: 40px;
  border-bottom: 1px solid #dedede;
}
.nameQingdan{
font-size: 13px;
margin-top: 20px;
padding-left: 18px;
position: relative;
&:before{
     position: absolute;
    content: '';
    display: block;
    width: 8px;
    height:8px;
    background: #409eff;
    left: 0;
    border-radius: 50%;
    top: 50%;
    margin-top: -4px;
}
}
.contentQingdan{
font-size: 12px;
color: rgb(87, 79, 79);
margin:5px 0;
padding-left: 18px;
}
</style>
