<template>
    <div class="container">
        <el-button @click="add" type="primary">添加</el-button>
        <el-table :data="templates" v-loading="loading">
            <el-table-column label="编号" width="100px" align="center">
                <template slot-scope="scope">
                    {{scope.$index+1}}
                </template>
            </el-table-column>
            <el-table-column prop="template_name" label="模板名" align="center"></el-table-column>
            <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                    <el-button @click="edit(scope.row._id.$id)" type="primary" size="small">编辑</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import { getWorkLogTemplates } from '../../api/assistant'
export default {
  data () {
    return {
      templates: [],
      loading: false
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      this.loading = true
      getWorkLogTemplates().then(res => {
        this.templates = res.data
        this.loading = false
      })
    },
    edit (id) {
      this.$router.push({
        path: '/adminmanage/workTemplateForm',
        query: {
          id: id
        }
      })
    },
    add () {
      this.$router.push({
        path: '/adminmanage/workTemplateForm',
        query: {
          id: ''
        }
      })
    }
  }
}
</script>

<style>

</style>
