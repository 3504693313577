<template>
    <div class="container">
        <div style="padding-left:10px">
            <p>Work Shop列表</p>
            <el-button type="primary" @click="add" size="small">添加</el-button>
            <el-table :data="workShopList" v-loading="loading">
                <el-table-column label="名字" prop="name" width="200"></el-table-column>
                <el-table-column label="简介" prop="intro"></el-table-column>
                <el-table-column label="模板名称" prop="template.name" width="200"></el-table-column>
                <el-table-column label="操作" width="400px">
                    <template slot-scope="scope">
                        <el-button type="primary" size="small" @click="setEvaluationForm(scope.row)">设置评价表</el-button>
                        <!-- <el-button type="primary" size="small" @click="addInit(scope.row._id.$id)">设置Session</el-button> -->
                        <el-button type="warning" size="small" @click="view(scope.row._id.$id)">编辑信息</el-button>
                        <el-button type="danger" size="small" @click="removeWorkshop(scope.row._id.$id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-dialog title="Session列表" :visible.sync="showSession">
                <el-table :data="sessions" height="60vh" size="medium">
                    <el-table-column label="name" prop="session_info.session_name"></el-table-column>
                    <el-table-column label="work_shop" prop="workshopNames"></el-table-column>
                    <el-table-column label="button" width="240px">
                        <template slot-scope="scope">
                            <el-button type="primary" @click="addSessionWorkshop(scope.row._id.$id)" size="mini">添加到该课程</el-button>
                            <el-button type="primary" @click="deleteSessionWorkshop(scope.row._id.$id)" size="mini">从该课程删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-dialog>
        </div>
    </div>
</template>


<script>
import {getWorkshops,getSessions,removeWorkshop} from '../../api/assistant'
export default {
    data(){
        return{
            workShopList:[],
            loading:false,
            sessions:[],
            workshop_id:'',
            showSession:false
        }
    },
    mounted(){
        this.fetchData()
    },
    methods:{
        fetchData(){
            this.loading = true
            getWorkshops().then(res=>{
                console.log(res)
                if(res.data.code===0){
                    this.workShopList=res.data.data
                    this.loading=false
                }
            })
        },
        add(){
            this.$router.push({
                path:'workShopInfo',
                query:{
                    id:''
                }
            })
        },
        view(id){
            this.$router.push({
                path:'workShopInfo',
                query:{
                    id:id
                }
            })
        },
        setEvaluationForm(row){
            if(row.evaluationForm){
                this.$router.push({
                    path:'workShopEvaluation',
                    query:{
                        id:row.evaluationForm._id.$id,
                        workshop_id:row._id.$id
                    }
                })
            }else{
                this.$router.push({
                    path:'workShopEvaluation',
                    query:{
                        id:'',
                        workshop_id:row._id.$id
                    }
                })
            }
        },
        addInit(id){
            this.workshop_id = id
            this.getSessions()
            this.showSession=true
        },
        getSessions(){
            getSessions().then(res=>{
                console.log(111,res)
                if(res.data.code===0){
                    let sessions = res.data.data
                    sessions.map(item=>{
                        let workshopNames = item.workshops.map(x=>x.name)
                        item.workshopNames = workshopNames.join(',')
                    })
                    this.sessions = sessions
                }
            })
        },
        removeWorkshop(id){
            this.$confirm('是否确认删除?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(res=>{
                removeWorkshop(id).then(res=>{
                    this.fetchData()
                })
            })
        }
    }
}
</script>

<style>
.container{
    width: 100%;
}
</style>