<template>
    <div>
        <div style="margin:40px 0">
            <el-table :data="userList">
                <el-table-column label="姓名">
                    <template slot-scope="scope"> 
                        {{scope.row.user_lastName + scope.row.user_givenName}}
                    </template>
                </el-table-column>
                <el-table-column label="职位" prop="employee_territory_str"></el-table-column>
                <el-table-column label="工作职责" prop="responsibility"></el-table-column>

                <el-table-column label="OKR" prop="okr"></el-table-column>

                <!-- <el-table-column label="查看" width="180">
                    <template slot-scope="scope">
                        <el-button @click="viewClass(scope.row)" type="primary">查看</el-button>
                    </template>
                </el-table-column> -->
            </el-table>
        </div>
    </div>
</template>

<script>
import {
  getEmail,
  getUserId,
  removeUserId,
  removeUserName,
  getUserName
} from '../utils/store'
import {getFulltimeUserList} from '../api/assistant'
export default {
    data(){
        return{
            userList:[]
        }
    },
    mounted(){
        this.getUserList()
    },
    methods:{
        getUserList(){
            this.loading = true
            getFulltimeUserList().then(res => {
                if (res.data) {
                    this.userList = res.data
                    
                    this.loading = false
                }
            })
        },
        viewClass(row){
            this.$router.push({
                path:'classinfouser',
                query:{
                    id:row._id.$id
                }
            })
        }
    }
}
</script>

<style scoped>
.add-subject{
    margin: 20px 0;
}
</style>
