<template>
    <div>

      <div>
        <h3>采样位数</h3>
        <el-select v-model="cyw" placeholder="请选择">
          <el-option
            v-for="item in arr"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>

        </el-select>


        <h3>采样率</h3>
        <el-select v-model="cyl" placeholder="请选择">
          <el-option
            v-for="item in arr1"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
          
        </el-select>

        <h3>声道</h3>
        <el-select v-model="sd" placeholder="请选择">
          <el-option
            v-for="item in arr2"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
          
        </el-select>

      </div>
      <br>
      <el-button type="button" @click="startRecordAudio">开始录音</el-button>
      <br>

      <h3>录音时长：{{ recorder.duration.toFixed(4) }}</h3>
      <br>
      <el-button type="button" @click="stopRecordAudio">停止录音</el-button>
      <el-button type="button" @click="playRecordAudio">播放录音</el-button>
      <el-button type="button" @click="getPCBRecordAudioData"
        >获取PCB录音数据</el-button
      >
      <el-button type="button" @click="downloadPCBRecordAudioData"
        >下载PCB录音文件</el-button
      >
      <el-button type="button" @click="getWAVRecordAudioData"
        >获取WAV录音数据</el-button
      >
      <el-button type="button" @click="downloadWAVRecordAudioData"
        >下载WAV录音文件</el-button
      >
      <!-- <el-button type="button" @click="downloadMP3"
        >下载MP3录音文件</el-button
      > -->
      <!-- <el-button type="button" @click="uploadWAVData">上传WAV录音数据</el-button> -->
      <br />
    </div>
  </template>

<script>
import Recorder from "js-audio-recorder";
import lamejs from "lamejs"
export default {
  name: "audioRecorder",
  data() {
    return {


      cyw:16,
      cyl:16000,
      sd:1,

      recorder: new Recorder({
        sampleBits: 16, // 采样位数，支持 8 或 16，默认是16
        sampleRate: 16000, // 采样率，支持 11025、16000、22050、24000、44100、48000，根据浏览器默认值，我的chrome是48000
        numChannels: 1, // 声道，支持 1 或 2， 默认是1
        // compiling: false,(0.x版本中生效,1.x增加中)  // 是否边录边转换，默认是false
      }),

      arr:[{'value':8,'label':8},{'value':16,'label':16}],
      arr1:[{'value':11025,'label':11025},{'value':16000,'label':16000},{'value':22050,'label':22050},{'value':24000,'label':24000},{'value':44100,'label':44100},{'value':48000,'label':48000}],
      arr2:[{'value':1,'label':1},{'value':2,'label':2}],


    };

  },
  mounted() {
    
  },
  methods: {
    //开始录音
    startRecordAudio() {

      this.recorder.sampleBits = this.cyw
      this.recorder.sampleRate = this.cyl
      this.recorder.numChannels = this.sd



      Recorder.getPermission().then(
        () => {
          console.log("开始录音");
          console.log(this.recorder)

          this.recorder.start(); // 开始录音
        },
        (error) => {
          this.$message({
            message: "请先允许该网页使用麦克风",
            type: "info",
          });
          console.log(`${error.name} : ${error.message}`);
        }
      );
    },
    //停止录音
    stopRecordAudio() {
      console.log("停止录音");
      this.recorder.stop();
    },
    //播放录音
    playRecordAudio() {
      console.log("播放录音");
      this.recorder.play();
    },
    //获取PCB录音数据
    getPCBRecordAudioData() {
      var pcmBlob = this.recorder.getPCMBlob();
      console.log(pcmBlob);
    },
    //获取WAV录音数据
    getWAVRecordAudioData() {
      var wavBlob = this.recorder.getWAVBlob();
      console.log(wavBlob);
    },
    //下载PCB录音文件
    downloadPCBRecordAudioData() {
      this.recorder.downloadPCM("badao");
    },
    //下载WAV录音文件
    downloadWAVRecordAudioData() {
      this.recorder.downloadWAV("badao");
    },
    //上传wav录音数据
    uploadWAVData() {
      var wavBlob = this.recorder.getWAVBlob();
      // 创建一个formData对象
      var formData = new FormData()
        // 此处获取到blob对象后需要设置fileName满足当前项目上传需求，其它项目可直接传把blob作为file塞入formData
        const newbolb = new Blob([wavBlob], { type: 'audio/wav' })
        //获取当时时间戳作为文件名
        const fileOfBlob = new File([newbolb], new Date().getTime() + '.wav')
        formData.append('file', fileOfBlob)
        uploadWavData(formData).then((response) => {
          console.log(response);
        });
      },

      downloadMP3(){

        const mp3blob = this.convertToMp3(this.recorder.getWAV())
        this.recorder.download(mp3blob,'recorder','mp3')

        
      },

    convertToMp3(wavDataView) {
        // 获取wav头信息
        const wav = lamejs.WavHeader.readHeader(wavDataView); // 此处其实可以不用去读wav头信息，毕竟有对应的config配置
        const { channels, sampleRate } = wav;
        const mp3enc = new lamejs.Mp3Encoder(channels, sampleRate, 128);
        // 获取左右通道数据
        const result = this.recorder.getChannelData()
        const buffer = [];

        const leftData = result.left && new Int16Array(result.left.buffer, 0, result.left.byteLength / 2);
        const rightData = result.right && new Int16Array(result.right.buffer, 0, result.right.byteLength / 2);
        const remaining = leftData.length + (rightData ? rightData.length : 0);

        const maxSamples = 1152;
        for (let i = 0; i < remaining; i += maxSamples) {
            const left = leftData.subarray(i, i + maxSamples);
            let right = null;
            let mp3buf = null;

            if (channels === 2) {
                right = rightData.subarray(i, i + maxSamples);
                mp3buf = mp3enc.encodeBuffer(left, right);
            } else {
                mp3buf = mp3enc.encodeBuffer(left);
            }

            if (mp3buf.length > 0) {
                buffer.push(mp3buf);
            }
        }

        const enc = mp3enc.flush();

        if (enc.length > 0) {
            buffer.push(enc);
        }

        return new Blob(buffer, { type: 'audio/mp3' });
    }


  },
  watch: {},
};
</script>

<style scoped lang="scss">
</style>