<template>
  <div style="width: 100%">
    <div style="padding: 20px">
      <el-form :inline="true" :model="searchForm" size="medium">
        <el-form-item>
          <el-input
            class="w180"
            clearable
            v-model="searchForm.question_question_string"
            placeholder="题目"
          >
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            size="small"
            @click="searchQuestion"
            icon="el-icon-search"
            >搜索
          </el-button>
        </el-form-item>
        <el-form-item class="pull-right">
          <el-button type="primary"  icon="el-icon-plus" size="small" @click="addQuestion"
            >添加问题</el-button
          >
          <!-- <el-button type="primary" icon="el-icon-upload" size="small" @click="importQuestion">导入问题</el-button> -->
          <div class="btnInpput">
 <input
            class="file"
            type="file"
            id="files"
            
            ref="refFile"
            accept="*"
            @change="getFile"
          />
          <p class="el-icon-upload uploadBtn"  v-loading="excelLoading">上传题目</p>
          </div>
         
        </el-form-item>
      </el-form>

      <el-table class="sortDiv" :data="questions" v-loading="excelLoading">
        <el-table-column
          label="题目"
          prop="question_question_string"
          width="500"
        ></el-table-column>
        <el-table-column label="主题" prop="question_subject"></el-table-column>
        <el-table-column label="年份" prop="question_year"></el-table-column>
        <el-table-column label="等级" prop="question_level"></el-table-column>
        <el-table-column
          label="考点"
          prop="question_competency"
        ></el-table-column>
        <el-table-column label="操作" width="180">
          <template slot-scope="scope">
            <el-button
              type="primary"
              @click="edit(scope.row._id.$id)"
              size="mini"
              >编辑</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import Papa from "papaparse";
import {
  getExerciseQuestionsByName,
  getExerciseQuestions,
  importCsv
} from "../../api/assistant";
export default {
  data() {
    return {
      paginationLoading: false,
      searchForm: {},
      questions: [],
      excelLoading:false,
      csvData:[],
    };
  },
  created() {
    this.getExerciseQuestions();
  },
  mounted() {
  },
  methods: {
    getFile: function (e) {
        this.excelLoading=true;
      let selectedFile = null;
      selectedFile = this.$refs.refFile.files[0];
      if (selectedFile === undefined) {
        return;
      }
      var reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onload = (evt) => {
        // 检查编码
        // let encoding = this.checkEncoding(evt.target.result);
        // 将csv转换成二维数组
        Papa.parse(selectedFile, {
          encoding: "ANSI",
          complete: (res) => {
            // UTF8 \r\n与\n混用时有可能会出问题
            let data = res.data;
            if (data[data.length - 1] == "") {
              //去除最后的空行
              data.pop();
            }
            data.splice(0, 1);
            if (data.length > 0) {
                
              data.forEach((item, index) => {
                let excelItem = {
                  question_question: '<p>'+item[2]+'</p>',
                  question_question_string: item[2],
                  question_optionA_string: item[3],
                  question_optionA: '<p>'+item[3]+'</p>',
                  question_optionB_string: item[4],
                  question_optionB: '<p>'+item[4]+'</p>',
                  question_optionC_string: item[5],
                  question_optionC: '<p>'+item[5]+'</p>',
                  question_optionD_string: item[6],
                  question_optionD: '<p>'+item[6]+'</p>',
                  question_answer: item[7],
                };
                this.csvData.push(excelItem)
                
              });
             //开始上传
          console.log({'questions':this.csvData})   
          //解析数据收开始上传至数据库
            importCsv( {'questions':this.csvData}).then((resdata) => {
                
                        if(resdata.data.code==0){//
                         this.excelLoading=false;
                        this.getExerciseQuestions();
                        }
                });
         }
           
          },
        });
      };
    },
    getExerciseQuestions() {
        this.excelLoading=true;
      getExerciseQuestions().then((res) => {
          this.excelLoading=false;
        this.questions = res.data.data;
      });
    },
    searchQuestion() {
      if (!this.searchForm.question_question_string) {
        this.$message.warning("请先输入题目");
      } else {
        getExerciseQuestionsByName(
          this.searchForm.question_question_string
        ).then((res) => {
          console.log(res);
          if (res.data.code === 0) {
            this.questions = res.data.data;
          }
        });
      }
    },
    addQuestion() {
      this.$router.push({
        path: "/questionInfo",
        query: {
          question_id: "",
        },
      });
    },
    edit(id) {
      this.$router.push({
        path: "/questionInfo",
        query: {
          question_id: id,
        },
      });
    },
  },
};
</script>

<style scoped>
.uploadBtn{
    color: #FFF;
    background-color: #409EFF;
    border-color: #409EFF;
    padding:9px 10px;
    border-radius: 2px;
    cursor: pointer;
    margin:0;
    margin-left: 10px;
    font-size: 12px;
}
.btnInpput{
position: relative;
    display: inline-block;
    margin-left: 10px;
}
.file{
        position: absolute;
    width: 89px;
    right: 0;
    height: 33px;
    background: red;
    opacity: 0;
    z-index: 999;
    cursor: pointer;
}
</style>