<template>
    <div
        style="padding: 20px;box-sizing: border-box;width: 75%;margin: 0 auto;background-color: #f5f5f5;height: 100vh;overflow-y: auto;">
        <div style="display: flex;justify-content: space-between;">
            <p style="font-weight: bold;font-size: 22px;color: #4a8af4;margin: 0px;">Email Sign Information</p>
            <el-button type="success" size="middle" @click="saveSign">Save</el-button>
        </div>
        <el-form style="margin-top: 15px;">
            <el-form-item label="Email">
                <el-input v-model="signInfo.email" style="width: 75%;"></el-input>
            </el-form-item>
            <el-button type="primary" size="small" @click="addNewTemplate" style="margin: 5px 0px;">Add
                Template</el-button>
            <el-form-item label="Templates">
                <div v-for="(content, index) in signInfo.templates" :key="index" class="template-item">
                    <p v-html="content" style="flex: 1;"></p>
                    <el-button type="primary" size="small" @click="editContent(index)">Edit</el-button>
                    <el-button type="danger" size="small" @click="doRemoveTemplate(index)">Delete</el-button>
                </div>
            </el-form-item>
        </el-form>
        <el-dialog :visible.sync="showTemplateInfoDialog" title="Template Info" width="55%">
            <div>
                <div class="editContainer">
                    <vue-ueditor-wrap v-model="content" :config="myConfig" @beforeInit="addCustomButtom"
                        class="ueditor-wrap"></vue-ueditor-wrap>
                </div>
                <div slot="footer" class="dialog-footer" style="margin-top: 20px;">
                    <el-button @click="showTemplateInfoDialog = false">Cancel</el-button>
                    <el-button type="primary" @click="saveTemplate()">Save</el-button>
                </div>
            </div>
        </el-dialog>

        <el-dialog :visible.sync="showAddPicDialog" title="Insert Picture" width="45%">
            <div>
                <el-form>
                    <el-form-item label="Select picture">
                        <el-upload :before-upload="beforeAddPicUpoad" class="avatar-uploader" action="#">
                            <i class="el-icon-plus avatar-uploader-icon" />
                        </el-upload>
                    </el-form-item>
                </el-form>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import {
    updateEmailSign,
    getEmailSign,
    getSingaporeQiniuToken
} from '../../api/assistant'
import VueUeditorWrap from 'vue-ueditor-wrap'
import * as qiniu from "qiniu-js"
export default ({
    name: 'index',
    components: {
        VueUeditorWrap
    },
    data() {
        return {
            id: this.$route.query.id,
            content: '',
            signInfo: {
                id: '',
                email: '',
                templates: []
            },
            myConfig: {
                UEDITOR_HOME_URL: '/UEditor/',
                // 编辑器不自动被内容撑高
                autoHeightEnabled: false,
                // 初始容器高度
                initialFrameHeight: 300,
                pasteImage: true, // 是否允许粘贴图片
                autoFloatEnabled: false,
                elementPathEnabled: false,
                initialFrameWith: "100%"
            },
            showTemplateInfoDialog: false,
            editorHandler: undefined,
            showAddPicDialog: false,
            showTemplateInfoType: 'new',
            editIndex: -1
        }
    },
    mounted() {
        if (this.id != '') {
            this.fetchData()
        }
    },
    methods: {

        saveSign() {
            if (this.signInfo.email == '') {
                this.$message.warning('Please input email')
                return
            }
            if (this.signInfo.templates.length <= 0) {
                this.$message.warning('Please input template info ')
                return
            }
            updateEmailSign(this.signInfo).then((res) => {
                this.$message.success('Save success~')
                if (res.data != -1) {
                    this.$emit('handleEmailSignChanged')
                    this.$router.go(-1)
                } else {
                    this.$message.warning('The Email already exist~')
                }
            })
        },

        fetchData() {
            getEmailSign(this.id).then((res) => {
                let result = res.data
                this.signInfo = {
                    id: result._id.$id,
                    email: result.email
                }
                if (result.templates) {
                    this.signInfo.templates = result.templates
                }
            })
        },

        doRemoveTemplate(index) {
            this.$alert('Are you sure remove this sign template', 'Tips', {
                showCancelButton: false,
                callback: action => {
                    if (action == 'confirm') {
                        console.log('delete -----')
                        this.signInfo.templates.splice(index, 1)
                        this.$forceUpdate()
                    }
                }
            })
        },

        addNewTemplate() {
            this.editIndex = -1
            this.content = ''
            this.showTemplateInfoType = 'new'
            this.showTemplateInfoDialog = true
        },
        editContent(index) {
            this.editIndex = index
            this.showTemplateInfoType = 'edit'
            this.content = this.signInfo.templates[index]
            this.showTemplateInfoDialog = true
        },
        saveTemplate() {
            if (this.showTemplateInfoType == 'edit') {
                this.signInfo.templates[this.editIndex] = this.content
            } else {
                this.signInfo.templates.push(this.content)
            }
            this.showTemplateInfoDialog = false
        },

        addCustomButtom(editorId) {
            let that = this;
            window.UE.registerUI("autoimg", (editor, uname) => {
                const btnImg = new window.UE.ui.Button({
                    name: 'my-img',
                    title: '上传图片',
                    cssRules: "background-image: url('https://mainbucket.learningfirst.cn/icon/icon_image.png') !important;background-size: cover",
                    onclick: function () {
                        that.editorHandler = editor;
                        that.showAddPicDialog = true;
                    }
                });
                return btnImg
            }, 0, editorId);
        },

        async beforeAddPicUpoad(file) {
            const result = await getSingaporeQiniuToken()
            if (result.data.code === 0) {
                const token = result.data.data
                this.observable = qiniu.upload(
                    file,
                    new Date().getTime() + file.name,
                    token
                )
                const observer = {
                    complete: (res) => {
                        let url = 'https://ap.learningfirst.tech/' + res.key
                        let imageHtml = "<p><img style='max-height: 200px;width: auto;object-fit: cover' src=\"" + url + "\"/></p>";
                        this.showAddPicDialog = false
                        this.editorHandler.execCommand('inserthtml', imageHtml)
                    }
                }
                this.observable.subscribe(observer)
            }
        }
    }
})
</script>

<style scoped lang="scss">
.template-item {
    align-items: flex-start;
    display: flex;
    width: 100%;
    padding: 6px 12px;
    box-sizing: border-box;
    margin-bottom: 10px;
    color: #333;
    font-size: 14px;
    background-color: #FFD9D4;
    border-radius: 5px;
}

::-webkit-scrollbar {
    display: none;
}

::v-deep .ueditor-wrap {
    white-space: pre-wrap !important;
    word-wrap: break-word !important;
    word-break: break-all !important;
}

.editContainer {
    width: 90%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 480px;

    .editor {
        height: 100%;
    }

    .btnGroup {
        width: 100px;
        flex-shrink: 0;
        margin-left: 10px;
        display: block;
    }

}

.avatar-uploader {
    width: 178px;
    height: 178px;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    box-sizing: border-box;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

.avatar {
    object-fit: cover;
    width: 178px;
    height: 178px;
    display: block;
}
</style>