<template>
  <div>
    <div class="titleP">
      审核清单
      <el-button
        class="el-icon-circle-plus-outline addTypeBtn"
        @click="addList"
        type="primary"
        size="mini"
        >添加清单</el-button
      >
    </div>
    <section v-for="(item, index) in checkAllList" :key="index">
      <div class="commonP nameQingdan">
        {{ item.name }}
        <span
          class="addTypeContent el-icon-circle-plus-outline"
          @click="addContent(item, '')"
          >增加内容</span
        >
        <span @click="editList(item, '')" class="editspan el-icon-edit">编辑</span>
        <span @click="deleteTypeList(item)" class="delespan el-icon-delete">删除</span>
      </div>
      <section
        class="checkDiv2"
        v-for="(ele, index) in item.item_list"
        :key="index"
      >
        <div class="elename">
          {{ ele.name }}
          <span class="editspan el-icon-edit" @click="updateList(item, ele)"></span>
          <span @click="deleteList(item,ele)" class="delespan el-icon-delete"></span>
        </div>
      </section>
    </section>
    <el-button @click="$router.back(-1)" style="margin-top: 20px" size="medium">返回</el-button>
    <el-dialog :visible.sync="showEdit" :title="diaTitle" width="600px">
      <p>清单标题</p>
      <el-input
        placeholder="请输入清单标题"
        clearable
        :disabled="currentRow.typeCode!=1"
        v-model="currentRow.name"
      ></el-input>
      <div v-if="currentRow.typeCode!=1">
        <p>内容</p>
        <el-input
          placeholder="请输入清单审核内容"
          clearable
          v-model="currentRow.itemname"
          @input="$forceUpdate()"
        ></el-input>
        <p>是否生效</p>
        <el-select v-model="currentRow.valid_status" @change="$forceUpdate()">
          <el-option value="YES" label="是"></el-option>
          <el-option value="NO" label="否"></el-option>
        </el-select>
      </div>

      <div>
        <el-button  size="medium"  type="primary"  style="margin-top: 20px"  @click="confirmTypeList">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import VueTagsInput from '@johmun/vue-tags-input'
import {
  updateCheckList,
  getListsByType,
  checkTaskItem,
  getTypeAllInfo,
  deleteCheckListItem,
  deleteCheckList,
  updateCheckListItem
} from '../api/assistant.js'
export default {
  components: {
    VueTagsInput
  },
  data () {
    return {
      typeList: [],
      isNew: true,
      typeLoading: false,
      checkLists: [],
      diaTitle: '新增审核清单',
      currentRow: {
        itemname: ''
      },
      showEdit: false,
      content: '',
      name: '',
      id: '',
      currentType: '',
      valid_status: '',
      type_id: '',
      itemId: '',
      checkAllList: []
    }
  },
  mounted () {
    this.type_id = this.$route.query.type_id

    this.getTypeAllInfo()
    // this.getListsByType();
  },
  methods: {
    deleteTypeList (row) { // 删除审核清单
      this.$confirm('确定要删除该条审核记录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteCheckList({ list_id: row._id.$id }).then((res) => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.checkLists = []
            this.getTypeAllInfo()
          }
        })
      })
    },
    getListsByType () {
      getListsByType(this.type_id).then((res) => {
        if (res.data.code === 0) {
          this.checkLists = res.data.data
        }
      })
    },
    getTypeAllInfo () {
      const that = this
      this.typeLoading = true
      getTypeAllInfo({ type_id: this.type_id }).then((res) => {
        if (res.data.code === 0) {
          this.typeLoading = false
          const list_info = res.data.data.list_info

          this.checkName = res.data.data.name
          // this.creater_name = res.data.data.creater;
          if (list_info && list_info.data && list_info.data.length > 0) {
            this.checkAllList = res.data.data.list_info.data
            list_info.data.forEach((ele) => {
              ele.checkName = res.data.data.name
              if (ele.item_list && ele.item_list.length > 0) {
                ele.item_list.forEach((f) => {
                  f.typeTitleName = ele.checkName
                  f.typeTitleId = res.data.data._id.$id
                  f.detailTitleName = ele.name
                  f.detailTitleId = ele._id.$id
                  f.type = ele.type
                  if (
                    that.taskData &&
                    that.taskData.check_results &&
                    f._id.$id in that.taskData.check_results
                  ) {
                    f.review_time = that.formatTime(
                      that.taskData.check_results[f._id.$id].review_time
                    )
                    if (that.taskData.check_results[f._id.$id].status) {
                      f.check_status = true
                      f.reviewer_name =
                        that.taskData.check_results[f._id.$id].reviewer_name +
                        '于' +
                        f.review_time +
                        '已打勾'
                    } else {
                      f.reviewer_name =
                        this.taskData.check_results[f._id.$id].reviewer_name +
                        '于' +
                        f.review_time +
                        '已取消'
                    }
                    console.log(that.taskData.check_results)
                  } else {
                    f.check_status = false
                  }
                })
              }
            })
          }
        }
      })
    },
    updateTypeList () {
      if (!this.currentRow.name) {
        this.$message.warning('请输入清单标题!')
      } else {
        this.name = this.currentRow.name
        this.id = this.currentRow.list_id ? this.currentRow.list_id : ''
        updateCheckList(this.id, this.type_id, this.name).then((res) => {
          console.log(res)
          if (res.data.code === 0) {
            const resData = res.data.data
            this.type_id = resData.type_id
            this.currentRow.list_id = resData._id.$id
            this.$message({
              message: '更新成功',
              type: 'success'
            })
            this.showEdit = false
            this.checkLists = []

            this.getTypeAllInfo()
          }
        })
      }
    },
    confirmTypeList () {
      switch (this.currentRow.typeCode) {
        case 1: // 修改清单标题
          this.updateTypeList()
          break
        case 2: // 新增清单内容
          if (!this.currentRow.itemname) {
            this.$message.warning('请输入清单内容！')
          } else if (!this.currentRow.valid_status) {
            this.$message.warning('请选择状态！')
          } else {
            this.updateCheckListItem()
          }
          break
        case 3:
          break
        case 4:
          break
      }
    },
    updateCheckListItem () {
      this.content = this.currentRow.itemname
      this.valid_status = this.currentRow.valid_status
      this.itemId =
        this.currentRow.item && this.currentRow.item._id
          ? this.currentRow.item._id.$id
          : ''
      updateCheckListItem(
        this.itemId,
        this.currentRow.list_id,
        this.content,
        this.valid_status
      ).then((res) => {
        console.log(res)
        if (res.data.code === 0) {
          this.$message({
            message: '更新成功',
            type: 'success'
          })
          this.showEdit = false
          this.checkLists = []

          this.getTypeAllInfo()
        }
      })
    },

    addList () {
      this.name = ''
      this.id = ''

      this.showEdit = true
      this.isNew = true
      this.currentRow = {}
      this.currentRow.typeCode = 1
    },
    editList (row) {
      // 修改审核清单
      this.currentRow = row
      this.currentRow.typeCode = 1
      this.currentRow.list_id = row._id.$id
      this.isNew = false
      this.diaTitle = '修改审核清单'
      this.showEdit = true
    },
    updateList (row, ele) {
      this.currentRow = row
      this.currentRow.typeCode = 2
      if (ele) {
        this.currentRow.item = ele
        this.currentRow.list_id = ele.list_id
        this.currentRow.itemname = ele.name
        this.currentRow.valid_status = ele.valid_status
      } else {
        this.currentRow.item = {}
      }
      this.isNew = false
      this.diaTitle = '修改审核清单'
      this.showEdit = true
    },
    deleteList (item, ele) {
      this.$confirm('确定要删除该条审核内容?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteCheckListItem({ item_id: ele._id.$id }).then((res) => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.checkLists = []
            this.getTypeAllInfo()
          }
        })
      })
    },
    view (row) {
      this.$router.push({
        path: '/adminmanageuser/checkitem',
        query: {
          list_id: row._id.$id
        }
      })
    },
    addContent (row, ele) {
      // 新增清单内容
      this.currentRow.typeCode = 2
      this.currentRow = row
      this.currentRow.list_id = row._id.$id
      if (ele) {
        this.currentRow.item = ele
        this.currentRow.itemname = ele.name
        this.currentRow.valid_status = ele.valid_status
      } else {
        this.currentRow.item = {}
      }

      this.isNew = false
      this.diaTitle = '新增审核清单内容'
      this.showEdit = true
    }
  }
}
</script>

<style scoped lang="scss">
.titleP {
  margin-bottom: 20px;
  font-family: "Microsoft YaHei";
  border-bottom: 1px solid #dedede;
  height: 40px;
  line-height: 40px;
  .addTypeBtn {
    margin-left: 30px;
    position: relative;
  }
  .el-icon-circle-plus-outline:before {
    padding-right: 5px;
  }
}
.addTypeContent {
  color: #409eff;
  padding-left: 20px;
  cursor: pointer;
}
.delespan {
  color: #f56c6c;
  cursor: pointer;
}
.checkDiv {
  display: flex;
  align-items: center;
}
.reviewer_name {
  margin: 0;
  padding: 0;
  margin-left: 80px;
  font-size: 12px;
  color: #909399;
}
.nameQingdan {
  font-size: 13px;
  margin-top: 20px;
  padding-left: 18px;
  position: relative;
  &:before {
    position: absolute;
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    background: #409eff;
    left: 0;
    border-radius: 50%;
    top: 50%;
    margin-top: -4px;
  }
}
.contentQingdan {
  font-size: 12px;
  color: rgb(87, 79, 79);
  margin: 5px 0;
  padding-left: 18px;
}
.elename {
  font-size: 13px;
  padding-left: 20px;
  margin-top: 10px;
}
.editspan {
  color: #409eff;
  padding: 0 20px;
  cursor: pointer;
}
</style>
