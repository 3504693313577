<template>
    <div class="container">
        <h4>问题描述：{{form.instruction}}</h4>
        <span style="margin-right:20px;line-height:30px;vertical-align:top;font-size:90%">{{form.label}}</span>
        <el-input :disabled="disabled" type="text" v-model="data" v-if="form.form_type==='input'" @input="dataChange" style="width:80%"></el-input>
        <el-select v-model="data" v-if="form.form_type==='select'" @change="dataChange" :disabled="disabled">
            <el-option v-for="item in form.form_value" :key="item" :label="item" :value="item"></el-option>
        </el-select>
        <el-input type="textarea" v-model="data" :rows="6" v-if="form.form_type==='textarea'" @input="dataChange" style="width:80%" :disabled="disabled"></el-input>
    </div>
</template>

<script>
export default {
    name:'CustomInput',
    props:['formItem','value','disabled'],
    data(){
        return{
            form:this.formItem,
            data:this.value
        }
    },
    watch:{
        value(){
            this.data = this.value
        },
        formItem(){
            this.form = this.formItem
        }
    },
    mounted(){
        console.log(typeof this.value)
    },
    methods:{
        dataChange(){
            this.$emit('dataChange', {
                key:this.form.key,
                data:this.data
            });
        }
    }
}
</script>

<style scoped>
.container{
    color:#606266;
}
</style>