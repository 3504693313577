<template>
    <div>
        <el-button @click="addItem" type="primary" size="medium">添加审核元素</el-button>
        <div style="margin-top:20px;margin-bottom:20px;font-family:'Microsoft YaHei'">审核元素列表</div>
        <el-table :data="checkItemsList">
            <el-table-column prop="name" label="内容"></el-table-column>
            <el-table-column prop="list" label="所属清单"></el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button @click="updateItem(scope.row)" type="primary" size="mini">修改</el-button>
                    <!-- <el-button @click="view(scope.row)" type="warning" size="mini">查看</el-button> -->
                </template>
            </el-table-column>
        </el-table>
        <el-button @click="$router.back(-1)" style="margin-top:20px" size="medium">返回</el-button>
        <el-dialog :visible.sync="showEdit" title="修改审核元素信息" width="600px">
            <p>内容</p>
            <el-input v-model="content"></el-input>
            <p>是否生效</p>
            <el-select v-model="valid_status">
                <el-option value="YES" label="是"></el-option>
                <el-option value="NO" label="否"></el-option>
            </el-select>
            <div>
            <el-button size="medium" type="primary" style="margin-top:20px" @click="updateCheckListItem">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import VueTagsInput from '@johmun/vue-tags-input'
import { updateCheckListItem, getItemsByList } from '../api/assistant.js'
export default {
  components: {
    VueTagsInput
  },
  data () {
    return {
      typeList: [],
      checkItemsList: [],
      currentRow: '',
      showEdit: false,
      content: '',
      id: '',
      currentType: '',
      list_id: '',
      valid_status: ''
    }
  },
  mounted () {
    this.list_id = this.$route.query.list_id
    this.getItemsByList()
  },
  methods: {
    getCheckTypes () {
      getCheckTypes().then(res => {
        if (res.data.code === 0) {
          this.typeList = res.data.data
        }
      })
    },
    getItemsByList () {
      getItemsByList(this.list_id).then(res => {
        console.log(res)
        if (res.data.code === 0) {
          this.checkItemsList = res.data.data
        }
      })
    },
    updateCheckListItem () {
      updateCheckListItem(this.id, this.list_id, this.content, this.valid_status).then(res => {
        console.log(res)
        if (res.data.code === 0) {
          this.$message({
            message: '更新成功',
            type: 'success'
          })
          this.showEdit = false
          this.getItemsByList()
        }
      })
    },
    addItem () {
      this.content = ''
      this.id = ''
      this.valid_status = ''
      this.showEdit = true
    },
    updateItem (row) {
      this.currentRow = row
      this.content = row.name
      this.id = row._id.$id
      this.valid_status = row.valid_status

      this.showEdit = true
    }
  }
}
</script>

<style>

</style>
