<template>
    <div class="homeContainer">
        <!-- <el-container> -->

            <div style="width:1000px;margin:30px auto">
                <el-input v-model="keyword" placeholder="请输入你要搜索的内容" @keydown.enter="search"></el-input>
                <div style="margin-top:20px">
                    <el-button type="primary" @click="add">添加</el-button>
                </div>
                <el-table :data="notes">
                    <el-table-column prop="game_name" label="游戏名"></el-table-column>
                    <el-table-column prop="producer_name" label="游戏工作室"></el-table-column>
                    <el-table-column prop="type" label="类型"></el-table-column>
                    <el-table-column prop="note" label="笔记" width="300"></el-table-column>
                    <el-table-column label="编辑">
                        <template #default="scope">
                            <el-button @click="edit(scope.row)" type="primary" size="small">编辑</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <el-dialog :visible.sync="showAdd">
                <el-form>
                    <el-form-item label="游戏名">
                        <el-input v-model="form.game_name"></el-input>
                    </el-form-item>

                    <el-form-item label="游戏工作室">
                        <el-input v-model="form.poroducer_name"></el-input>
                    </el-form-item>
                    <el-form-item label="角度">
                        <el-select v-model="form.aspect">
                            <el-option v-for="item in aspects" :key="item" :value="item"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="类型">
                        <el-select v-model="form.type">
                            <el-option v-for="item in types" :key="item" :value="item"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="平台">
                        <el-select v-model="form.platform">
                            <el-option v-for="item in platforms" :key="item" :value="item"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="笔记">
                        <el-input type="textarea" :rows="6" v-model="form.note"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button style="margin-top:20px" @click="submit" type="primary">提交</el-button>
                    </el-form-item>
                </el-form>
            </el-dialog>
<!--         </el-container> -->

    </div>
</template>

<script>
import { removeUserId, getUserId } from '../../utils/store.js'
import { updateGameNote, getMyGameNotes, searchGameNote } from '../../api/assistant'
export default {
  data () {
    return {
      notes: [],
      keyword: '',
      form: {
        game_name: '',

        producer_name: '',
        type: '',
        platform: '',
        note: '',
        aspect: ''
      },
      showAdd: false,
      id: '',
      types: ['RPG', 'MOBA', '解谜'],
      platforms: [],
      aspects: []
    }
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      getMyGameNotes().then(res => {
        if (res.data.code === 0) {
          this.notes = res.data.data
        }
      })
    },
    edit (row) {
      this.id = row._id.$id
      this.form = JSON.parse(JSON.stringify(row))
      this.showAdd = true
    },
    add () {
      this.id = ''
      this.form = {
        game_name: '',
        producer_name: '',
        type: '',
        note: '',
        platform: '',
        aspect: ''
      }
      this.showAdd = true
    },
    submit () {
      updateGameNote(this.id, this.form.game_name, this.form.producer_name, this.form.type, this.form.note, this.form.aspect, this.form.platform).then(res => {
        if (res.data.code === 0) {
          this.$message({
            message: '更新成功',
            type: 'success'
          })
          this.fetchData()
          this.showAdd = false
        }
      })
    },
    formateDate (date) {
      const time = new Date(date * 1000)
      return `${time.getFullYear()}/${time.getMonth() + 1}/${time.getDate()}`
    },
    searchGameNote () {
      searchGameNote(this.keyword).then(res => {
        if (res.data.code === 0) {
          this.notes = res.data.data
        }
      })
    },
    logout () {
      removeUserId()
      location.reload()
    }
  }
}
</script>

<style scoped>
.el-header{
    background-color: #409EFF;
    color: #333;
    line-height: 60px;
    text-align: right;
}
.cardItem{
    width: 1000px;
    border-radius: 10px;
    border: 1px solid #DCDFE6;
    height: 400px;
    margin: 20px auto;
    text-align: left;
}
.title{
    margin-top:20px;
}
.content{
    margin-top:20px;
    height: 50%;
}
.label{
    margin-top:20px;
    margin-left:30px
}

.title .el-input__inner{
    padding-left:30px;
    border: none;
    font-size: 24px;
    font-weight: 600;
}

.content .el-textarea__inner{
    padding-left:30px;
    border: none;
    height: 200px;
    font-size: 16px;
    width: 970px;
    resize: none;
}

</style>
