<template>
  <div class="Home">
    <el-container>
      <el-header class="head">
        <!-- <el-button @click="$router.push('/nav')" style="float:left;margin-top:10px" size="medium">Home</el-button> -->
        <div class="back" @click="$router.push('/nav')" style="float: left">
          <i class="el-icon-arrow-left" style="font-size: 120%"></i
          ><span style="margin-left: 4px">返回</span>
        </div>
        <el-input
          style="width: 200px"
          v-model="keywords"
          placeholder="输入搜索关键字"
          @keyup.enter.native="searchTODOList"
        >
        </el-input>
        <el-popover width="400" placement="bottom" trigger="click">
          <div v-for="(message, index) in messages" :key="index">
            <div style="padding: 5px">
              <span
                >{{
                  message.user_info.user_lastName +
                  message.user_info.user_givenName
                }}
                完成了任务
                {{ message.todo_info ? message.todo_info.todo_content : "" }} (
                {{ formatPhpTime(message.create_time) }} )</span
              >
              <div
                style="height: 1px; background: #b3b3b3; margin-top: 10px"
              ></div>
            </div>
          </div>
          <el-button
            slot="reference"
            class="el-icon-message"
            style="border: none; background: #ff000000; margin-right: 10px"
            @click="fetchMessages"
          />
        </el-popover>
        <el-dropdown>
          <i class="el-icon-setting" style="margin-right: 15px"></i>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>
              <div @click="logout()">退出</div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <span>{{ userName }}</span>
      </el-header>
      <el-container>
        <el-aside width="250px" class="aside" :style="height">
          <el-row class="tac" :style="row">
            <el-col>
              <el-menu default-active="1">
                <el-menu-item
                  v-for="(item, index) in lists"
                  :key="index"
                  @click="handleMenuItem(item, index, 'custom')"
                  v-if="index < 4"
                >
                  <div>
                    <img
                      v-if="item.list_name === '稍后处理'"
                      src="../assets/tentative_blue.png"
                      style="width: 15px; height: 15px; margin-right: 6px"
                    />
                    <img
                      v-if="item.list_name === '阶段重点'"
                      src="../assets/qizhi_blue.png"
                      style="width: 15px; height: 15px; margin-right: 6px"
                    />
                    <i
                      v-if="item.list_name === '重要紧急'"
                      class="el-icon-star-on"
                      style="margin-right: 6px"
                    />
                    <img
                      v-if="item.list_name === '分配给我'"
                      src="../assets/fenpei.png"
                      style="width: 15px; height: 15px; margin-right: 6px"
                    />
                    <!-- <img
                      v-if="item.list_name === '紧急任务'"
                      src="../assets/jinji_blue.png"
                      style="width:12px;height:18px;margin-right: 6px;"
                    /> -->
                    <span>{{ item.list_name }}</span>
                    <span v-if="item.list_name === '稍后处理'"
                      >({{ listsCount.tentative_count }})</span
                    >
                    <span v-if="item.list_name === '阶段重点'"
                      >({{ listsCount.flag_count }})</span
                    >
                    <span v-if="item.list_name === '重要紧急'"
                      >({{ listsCount.star_count }})</span
                    >
                    <span v-if="item.list_name === '分配给我'"
                      >({{ listsCount.assign_count }})</span
                    >
                    <!-- <span v-if="item.list_name === '紧急任务'">({{ listsCount.fast_count }})</span> -->
                    <span
                      v-if="
                        index != 0 && index != 1 && index != 2 && index != 3
                      "
                      >({{ getLeftListCount(item) }})</span
                    >
                    <i
                      class="el-icon-edit"
                      style="margin-left: 15px"
                      @click.stop="showDialogForEditList(item)"
                      v-if="
                        index != 0 &&
                        index != 1 &&
                        index != 2 &&
                        index != 3 &&
                        item.visible &&
                        !item.type
                      "
                    ></i>
                    <span
                      v-if="
                        index != 0 &&
                        index != 1 &&
                        index != 2 &&
                        index != 3 &&
                        item.type
                      "
                      class="icon"
                      >协作</span
                    >
                  </div>
                </el-menu-item>

                <el-submenu index="2">
                  <template slot="title">
                    <!-- <i class="el-icon-location"></i> -->
                    <span slot="title" style="color: #409eff">自定义列表</span>
                  </template>

                  <el-menu-item
                    v-for="(item, index) in lists"
                    :key="index"
                    @click="handleMenuItem(item, index, 'custom')"
                    v-if="index >= 4"
                  >
                    <div v-if="index >= 4">
                      <span v-if="index >= 4">{{ item.list_name }}</span>

                      <span v-if="index >= 4"
                        >({{ getLeftListCount(item) }})</span
                      >
                      <i
                        class="el-icon-edit"
                        style="margin-left: 15px"
                        @click.stop="showDialogForEditList(item)"
                        v-if="index >= 4 && item.visible && !item.type"
                      ></i>
                      <span v-if="index >= 4 && item.type" class="icon"
                        >协作</span
                      >
                    </div>
                  </el-menu-item>
                </el-submenu>

                <el-submenu index="3">
                  <template slot="title">
                    <!-- <i class="el-icon-location"></i> -->
                    <span slot="title" style="color: #409eff"
                      >项目清单列表</span
                    >
                  </template>

                  <el-menu-item
                    v-for="(item, index) in projectlists"
                    :key="index"
                    @click="handleMenuItem(item, index, 'project')"
                  >
                    <div>
                      <span>{{ item.list_name }}</span>

                      <span>({{ getLeftListCount(item) }})</span>
                      <i
                        class="el-icon-edit"
                        style="margin-left: 15px"
                        @click.stop="showDialogForEditList(item)"
                        v-if="item.visible && !item.type"
                      ></i>
                      <span v-if="item.type" class="icon">协作</span>
                    </div>
                  </el-menu-item>
                </el-submenu>
              </el-menu>
            </el-col>
          </el-row>
          <el-button
            type="primary"
            class="btn"
            size="middle"
            @click="showDialogForEditList(null)"
            >添加List</el-button
          >
        </el-aside>
        <el-main :style="height">
          <div v-if="currentListName != ''" class="list_tip">
            {{ listTips[currentListName] }}
          </div>

          <el-button
            v-if="currentListName == ''"
            type="primary"
            class=""
            size="middle"
            @click="deleteList()"
            style="background: red"
            >删除此list</el-button
          >

          <div
            v-if="currentListName == ''"
            style="margin-bottom: 10px; margin-top: 10px"
          >
            标签筛选：<span
              style="color: rgb(68, 187, 187); margin: 0.2em"
              @click="selectTag(item, false)"
              v-for="item in list_labels"
              :key="item"
              >#{{ item }}</span
            >
          </div>

          <el-input
            v-if="showInputTODO"
            v-model="content"
            @keyup.enter.native="addTODO"
            placeholder="在此新建TODO: 请输入TODO的内容；开头输入#号+标签词（如“#工作”）并敲回车可为TODO添加标签，点击上方标签可查看该标签下所有TODO"
            style="margin-bottom: 10px"
          ></el-input>

          <div
            v-if="todo_labels && todo_labels.length > 0"
            style="display: flex; flex-direction: row; margin-bottom: 10px"
          >
            <span>标签</span>
            <div v-for="(label, index) in todo_labels" :key="index">
              <span style="color: #44bbbb; margin-left: 10px">{{
                "#" + label
              }}</span>
              <img
                src="../assets/delete.png"
                style="
                  width: 15px;
                  height: 15px;
                  margin-left: 5px;
                  margin-right: 5px;
                  transform: translate(0, 10%);
                "
                @click="deleteLabel(label)"
                @click.stop
              />
            </div>
          </div>

          <el-container class="home">
            <div v-if="currentListName == ''">
              <div
                style="
                  font-size: 14px;
                  margin-bottom: 10px;
                  margin-top: 10px;
                  color: #888888;
                "
              >
                分配给别人的：
              </div>

              <Todo
                v-for="item in otherTodos"
                :key="item._id.$id"
                :todoData="item"
                @refresh="confirmRefresh"
                @callback="refreshTODOList"
                @selectTag="selectTag"
                @click.native="showTODODetail(item)"
              />
            </div>
            <div v-if="currentListName == ''">
              <div
                style="
                  font-size: 14px;
                  margin-bottom: 10px;
                  margin-top: 10px;
                  color: #888888;
                "
              >
                分配给我的：
              </div>

              <Todo
                v-for="item in assignMyList"
                :key="item._id.$id"
                :todoData="item"
                @refresh="confirmRefresh"
                @callback="refreshTODOList"
                @selectTag="selectTag"
                @click.native="showTODODetail(item)"
              />
            </div>
            <div
              v-if="currentListName == ''"
              style="
                font-size: 14px;
                margin-top: 10px;
                margin-bottom: 10px;
                color: #888888;
              "
            >
              整体任务：
            </div>
            <div v-if="currentListName == '重要紧急'">
              <div
                style="
                  font-size: 14px;
                  margin-top: 10px;
                  margin-bottom: 10px;
                  color: #888888;
                "
              >
                重要紧急：
              </div>
              <Todo
                v-for="item in starFast"
                :key="item._id.$id"
                :todoData="item"
                :selectedList="selectedList"
                @refresh="confirmRefresh"
                @callback="refreshTODOList"
                @selectTag="selectTag"
                @click.native="showTODODetail(item)"
              />
            </div>
            <div v-if="currentListName == '重要紧急'">
              <div
                style="
                  font-size: 14px;
                  margin-top: 10px;
                  margin-bottom: 10px;
                  color: #888888;
                "
              >
                重要不紧急：
              </div>
              <Todo
                v-for="item in starUnFast"
                :key="item._id.$id"
                :todoData="item"
                :selectedList="selectedList"
                @refresh="confirmRefresh"
                @callback="refreshTODOList"
                @selectTag="selectTag"
                @click.native="showTODODetail(item)"
              />
            </div>
            <div v-if="currentListName == '重要紧急'">
              <div
                style="
                  font-size: 14px;
                  margin-top: 10px;
                  margin-bottom: 10px;
                  color: #888888;
                "
              >
                紧急不重要：
              </div>
              <Todo
                v-for="item in unStarFast"
                :key="item._id.$id"
                :todoData="item"
                :selectedList="selectedList"
                @refresh="confirmRefresh"
                @callback="refreshTODOList"
                @selectTag="selectTag"
                @click.native="showTODODetail(item)"
              />
            </div>
            <div v-if="currentListName !== '重要紧急'">
              <Todo
                v-for="item in todolist"
                :key="item._id.$id"
                :todoData="item"
                :selectedList="selectedList"
                @refresh="confirmRefresh"
                @callback="refreshTODOList"
                @selectTag="selectTag"
                @click.native="showTODODetail(item)"
              />
            </div>
          </el-container>

          <div
            v-if="finishedCount > 0"
            style="display: flex; flex-direction: column"
          >
            <div
              style="
                margin-top: 15px;
                display: flex;
                flex-direction: row;
                align-items: center;
              "
            >
              <span
                style="
                  margin-right: 10px;
                  background: #eeeeee;
                  padding: 10px;
                  width: 120px;
                "
                >{{ finishedCount + "个已完成任务" }}</span
              >
              <el-switch
                v-model="switchFinishedTODOS"
                active-color="#13ce66"
                inactive-color="#ff4949"
              />
            </div>
            <div v-if="switchFinishedTODOS" style="margin-top: 15px">
              <Todo
                v-for="item in finishedTODOS"
                :key="item._id.$id"
                :todoData="item"
                @refresh="confirmRefresh"
                @callback="refreshTODOList"
                @click.native="showTODODetail(item)"
              />
            </div>
          </div>
        </el-main>
      </el-container>
    </el-container>
    <el-dialog :visible.sync="dialogForEditList">
      <div style="padding: 5px; color: red">
        请注意：你正在添加新的TODO List，而不是TODO；TODO
        List是TODO的清单，你需要先创建一个TODO
        List，再在List中添加具体的任务；不要把具体的TODO任务创建在这里。TODO
        List方便你将不同的TODO分门别类，你可以创建多个不同的TODO
        List来管理不同项目、类型或者其他方面的TODO。所有的TODO都必须归属于一个TODO
        List下，你无法在不创建TODO List的情况下直接添加TODO。TODO
        List创建好后，点击这个List，你可以在右侧主界面看到这个List下的具体TODO，并且可以在主界面创建新的TODO。<br />

        你创建的TODO List只有你自己看得到，如果你要和你的同事进行这个TODO
        List的协作，让TA也可以看到和访问你的TODO
        List，请在下方协作人中通过邮箱确定具体协作的同事。
      </div>
      <el-form>
        <el-form-item label="请输入list名字">
          <el-input
            v-model="singleList.list_name"
            style="margin-left: 5px; width: 80%"
          />
        </el-form-item>
        <el-form-item label="选择协作人">
          <el-select
            v-model="singleList.cooperator_ids"
            multiple
            style="width: 80%"
            filterable
          >
            <el-option
              v-for="item in users"
              :key="item._id.$id"
              :label="
                item.user_lastName + item.user_givenName + ' ' + item.user_email
              "
              :value="item._id.$id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <div class="flex-right" style="margin-top: 40px">
        <el-button type="primary" @click="editList">确认</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="showSwitchList">
      <div style="display: flex; flex-direction: column">
        <span style="margin-bottom: 10px">请选择切换的List</span>
        <el-select
          v-model="currentTODOList"
          value-key="_id.$id"
          placeholder="请选择"
        >
          <el-option
            v-for="item in originalLists"
            :key="item._id.$id"
            :label="item.list_name"
            :value="item"
          >
          </el-option>
        </el-select>
      </div>
      <div class="flex-right" style="margin-top: 40px">
        <el-button type="primary" @click="confirmMoveTODOToList"
          >确认</el-button
        >
      </div>
    </el-dialog>
    <el-dialog :visible.sync="dialogForLabel">
      <el-input
        v-model="LabelContent"
        @keyup.enter.native="addLabel"
        placeholder="请输入#加标签的内容 敲回车可添加标签"
        style="margin-bottom: 10px"
      ></el-input>
      <div
        v-if="todoInfo.todo_labels && todoInfo.todo_labels.length > 0"
        style="display: flex; flex-direction: row; margin-bottom: 10px"
      >
        <span>标签</span>
        <div v-for="(label, index) in todoInfo.todo_labels" :key="index">
          <span style="color: #44bbbb; margin-left: 10px">{{
            "#" + label
          }}</span>
          <img
            src="../assets/delete.png"
            style="
              width: 15px;
              height: 15px;
              margin-left: 5px;
              margin-right: 5px;
              transform: translate(0, 10%);
            "
            @click="deleteTODOInfoLabel(label)"
            @click.stop
          />
        </div>
      </div>
      <div class="flex-right" style="margin-top: 40px">
        <el-button type="primary" @click="editTODO">确认</el-button>
      </div>
    </el-dialog>
    <el-drawer
      title="todo标题"
      :with-header="false"
      size="600px"
      :visible.sync="enableShowDrawer"
      direction="rtl"
      :before-close="handleClose"
    >
      <div style="padding: 20px; height: 100vh; overflow-y: scroll">
        <div style="display: flex; flex-direction: column">
          <div>
            <span @click="doShowSwitchListDialog">{{
              getListName(todoInfo.list_id)
            }}</span>
            <span
              v-if="todoInfo.todo_labels && todoInfo.todo_labels.length === 0"
              style="color: #44bbbb; margin-left: 10px; font-size: 80%"
              @click="showDialogForLabel"
              >+</span
            >
            <span
              v-for="(item, index) in todoInfo.todo_labels"
              :key="index"
              style="color: #44bbbb; margin-left: 10px; font-size: 80%"
              @click="showDialogForLabel"
              >{{ "#" + item }}</span
            >
            <div
              style="
                position: absolute;
                top: 10px;
                right: 30px;
                display: flex;
                flex-direction: row;
                justify-items: center;
                align-items: center;
              "
            >
              <img
                src="../assets/icon1/02.png"
                style="width: 30px; height: 30px; margin-right: 15px"
                @click="changeList"
                title="修改所属list"
              />
              <img
                v-if="todoInfo.tentative_status"
                src="../assets/icon1/03.png"
                style="width: 30px; height: 30px; margin-right: 15px"
                @click="tentativeChanged"
              />
              <img
                v-if="!todoInfo.tentative_status"
                src="../assets/icon1/04.png"
                style="width: 30px; height: 30px; margin-right: 15px"
                @click="tentativeChanged"
              />
              <img
                v-if="todoInfo.flag_status"
                src="../assets/icon1/05.png"
                style="width: 30px; height: 30px; margin-right: 15px"
                @click="flagChanged"
              />
              <img
                v-if="!todoInfo.flag_status"
                src="../assets/icon1/06.png"
                style="width: 30px; height: 30px; margin-right: 15px"
                @click="flagChanged"
              />
              <div @click="starChanged" @click.stop>
                <img
                  v-if="todoInfo.star_status"
                  src="../assets/icon1/07.png"
                  style="width: 30px; height: 30px; margin-right: 15px"
                />
                <img
                  v-if="!todoInfo.star_status"
                  src="../assets/icon1/08.png"
                  style="width: 30px; height: 30px; margin-right: 15px"
                />
              </div>
              <img
                v-if="todoInfo.fast_status"
                src="../assets/icon1/09.png"
                style="width: 30px; height: 30px"
                @click="fastChanged"
              />
              <img
                v-if="!todoInfo.fast_status"
                src="../assets//icon1/10.png"
                style="width: 30px; height: 30px"
                @click="fastChanged"
              />
            </div>
          </div>
          <div
            style="
              margin-top: 20px;
              display: flex;
              flex-direction: row;
              word-wrap: break-word;
            "
          >
            <div @click="finishTask" @click.stop style="display: inline-block">
              <el-checkbox
                v-model="todoInfo.finish_status"
                size="medium"
              ></el-checkbox>
            </div>
            <div
              v-if="!canEdit"
              :class="todoInfo.finish_status ? 'deleteline' : ''"
              style="margin-left: 10px"
              @click="doEditTODO"
            >
              {{ todoInfo.todo_content }}
            </div>
            <el-input
              style="margin-left: 10px"
              v-if="canEdit"
              v-model="todoInfo.todo_content"
              @keyup.enter.native="editTODO"
            ></el-input>
          </div>
        </div>
        <div style="margin-top: 20px; display: flex">
          <span style="color: #606266; font-size: 14px">任务等级</span>
          <!-- <el-select style="margin-left:10px" v-model="todoInfo.level" size="small" @change="(e)=>updateTodoLevel(todoInfo,e)">
                <el-option value="1" label="级别1">
                    <i class="el-icon-medal" style="font-size:20px"></i>
                    <span style="float:right">级别1</span>
                </el-option>
                <el-option value="2" label="级别2">
                    <i class="el-icon-medal" style="font-size:20px"></i>
                    <i class="el-icon-medal" style="font-size:20px"></i>
                    <span style="float:right">级别2</span>
                </el-option>
                <el-option value="3" label="级别3">
                    <i class="el-icon-medal" style="font-size:20px"></i>
                    <i class="el-icon-medal" style="font-size:20px"></i>
                    <i class="el-icon-medal" style="font-size:20px"></i>
                    <span style="float:right">级别3</span>
                </el-option>
            </el-select> -->
          <star
            :score.sync="todoInfo.level"
            @mark="markGuide"
            style="margin-left: 10px"
          ></star>
        </div>
        <div
          v-if="todoInfo.user_info && todoInfo.user_info._id.$id !== userId"
          style="margin-top: 10px; margin-bottom: 10px"
        >
          <span style="font-size: 10px; color: #b3b3b3">{{
            "创建人：" +
            todoInfo.user_info.user_lastName +
            todoInfo.user_info.user_givenName +
            " ( " +
            todoInfo.user_info.user_email +
            " )"
          }}</span>
          <br />
        </div>
        <div style="margin-top: 14px; display: flex; align-items: center">
          <img src="../assets/time.png" style="width: 20px" />
          <el-radio-group
            style="margin-left: 16px"
            v-model="time_type"
            @change="changeTimeType"
          >
            <el-radio label="时间段"></el-radio>
            <el-radio label="截止时间"></el-radio>
          </el-radio-group>
          <el-date-picker
            v-if="time_type === '时间段'"
            style="margin-left: 16px; width: 140px"
            v-model="start_time"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
            @change="ontimechange1"
          >
          </el-date-picker>
          <span v-if="time_type === '时间段'" style="margin-left: 10px"
            >至</span
          >
          <el-date-picker
            v-if="time_type"
            :style="{
              marginLeft: '10px',
              width: time_type === '时间段' ? '140px' : '300px',
            }"
            v-model="end_time"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
            @change="ontimechange1"
          >
          </el-date-picker>
        </div>
        <div>
          <img src="../assets/time.png" style="width: 20px" />
          <el-input
            style="padding-top: 20px; width: 90%; margin-left: 10px"
            v-model="todo_time"
            placeholder="输入任务的时间标记"
            @change="ontimechange"
          >
          </el-input>
        </div>
        <div>
          <img src="../assets/fenpei1.png" style="width: 20px" />
          <el-input
            style="padding-top: 20px; width: 90%; margin-left: 10px"
            v-model="assign_label"
            placeholder="输入分配人的标记（限外部人员，仅自我提示）"
            @change="onsignchange"
          >
          </el-input>
        </div>
        <div>
          <img src="../assets/e-mail.png" style="width: 20px" />
          <el-autocomplete
            style="
              padding-top: 20px;
              margin-bottom: 15px;
              width: 90%;
              margin-left: 10px;
            "
            type="text"
            placeholder="输入邮箱来搜索待分配人"
            :autofocus="false"
            :fetch-suggestions="querySearch"
            :trigger-on-focus="false"
            @select="handleSelect"
            v-model="email"
          ></el-autocomplete>
        </div>
        <div v-if="todoInfo.assign_users && todoInfo.assign_users.length > 0">
          <span style="font-size: 10px; color: #b3b3b3">{{
            "已分配给："
          }}</span>
          <div
            v-for="(item, index) in todoInfo.assign_users"
            :key="index"
            style="margin-top: 10px; margin-bottom: 20px"
          >
            <span>{{ item.user_lastName + item.user_givenName }}</span>
            <span style="margin-left: 10px">{{ item.user_email }}</span>

            <span
              style="margin-left: 10px; cursor: pointer"
              @click="delassign(item)"
              >x</span
            >
          </div>
        </div>
        <!-- 编辑协作人 -->
        <img src="../assets/e-mail.png" style="width: 20px" />
        <el-autocomplete
          style="margin-bottom: 15px; width: 90%; margin-left: 10px"
          type="text"
          placeholder="输入邮箱来搜索TODO协作人"
          :autofocus="false"
          :fetch-suggestions="querySearch"
          :trigger-on-focus="false"
          @select="handleCooperation"
          v-model="cooperationEmail"
        ></el-autocomplete>
        <div
          v-if="
            todoInfo.cooperation_users && todoInfo.cooperation_users.length > 0
          "
        >
          <span style="font-size: 10px; color: #b3b3b3">{{ "协作人：" }}</span>
          <div
            v-for="(item, index) in todoInfo.cooperation_users"
            :key="index"
            style="margin-top: 10px; margin-bottom: 20px"
          >
            <span>{{ item.user_lastName + item.user_givenName }}</span>
            <span style="margin-left: 10px">{{ item.user_email }}</span>

            <span
              style="margin-left: 10px; cursor: pointer"
              @click="delCooperation(item)"
              >x</span
            >
          </div>
        </div>
        <p>子任务</p>
        <el-table :data="subTODOS" :show-header="null" maxHeight="350px">
          <el-table-column prop label align="left">
            <template slot-scope="scope">
              <div
                style="display: flex; flex-direction: row; align-items: center"
              >
                <div v-if="scope.row.id != -1">
                  <el-checkbox
                    v-model="scope.row.flag"
                    size="medium"
                    @change="exchangeSubTODO(scope.row.flag, scope.row.index)"
                  />
                </div>
                <div
                  @dblclick="editSubTODO(scope.row)"
                  v-if="scope.row.id !== -1"
                  :class="scope.row.flag ? 'deleteline' : ''"
                  style="
                    margin-left: 10px;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                  "
                >
                  <div style="width: 306px">
                    <el-input
                      @blur="confirmEditSubTodo(scope.row)"
                      v-model="scope.row.content"
                      v-if="scope.row.showInput"
                    ></el-input>
                    <span v-else>{{ scope.row.content }}</span>
                  </div>
                  <el-select
                    style="width: 130px"
                    clearable
                    size="small"
                    v-model="scope.row.user_id"
                    placeholder="子任务负责人"
                    @change="(e) => changeChildAssign(e, scope.$index)"
                  >
                    <el-option
                      v-for="item in todoInfo.assign_users"
                      :key="item._id.$id"
                      :value="item._id.$id"
                      :label="item.user_lastName + item.user_givenName"
                    ></el-option>
                  </el-select>
                  <div style="flex-shrink: 0">
                    <i
                      v-if="
                        (todoInfo.assign_users &&
                          todoInfo.assign_users.length > 0) ||
                        (todoInfo.cooperation_users &&
                          todoInfo.cooperation_users.length > 0)
                      "
                      class="el-icon-message-solid"
                      style="
                        cursor: pointer;
                        margin-right: 10px;
                        font-size: 16px;
                      "
                      @click.stop="toRemind(scope.$index, scope.row.user_id)"
                    ></i>
                    <i
                      style="cursor: pointer"
                      class="el-icon-delete"
                      @click="remoteSub(scope.$index)"
                    ></i>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div style="display: flex; align-items: center; margin-top: 10px">
          <img
            src="../assets/add.png"
            style="width: 15px; height: 15px; margin: 2px"
            @click="addSubTODO(subContent)"
          />
          <el-input
            v-model="subContent"
            placeholder="请输入子任务内容"
            style="margin-left: 10px"
            @keyup.enter.native="addSubTODO(subContent)"
          ></el-input>
        </div>

        <p>时间估计（总时长：{{ computeTotalTimeEs }}小时）</p>
        <el-table :data="timeEstimateList" :show-header="null" maxHeight="350px">
          <el-table-column prop label align="left">
            <template slot-scope="scope">
              <div
                style="display: flex; flex-direction: row; align-items: center"
              >
                <div
                  @dblclick="editTimeEstimate(scope.row)"
                  v-if="scope.row.id !== -1"
                  :class="scope.row.flag ? 'deleteline' : ''"
                  style="
                    margin-left: 10px;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                  "
                >
                  <div style="width: 306px">
                    <el-input
                      @blur="confirmEditTimeEstimate(scope.row,scope.$index)"
                      v-model="scope.row.content"
                      v-if="scope.row.showInput"
                    ></el-input>
                    <span v-else>{{ scope.row.content }}</span>
                  </div>
                  <el-input @change="confirmEditTimeEstimate(scope.row,scope.$index)" size="small" style="width: 160px;margin-right: 10px;" v-model="scope.row.time">
                    <template slot="append">小时</template>
                  </el-input>
                  <div style="flex-shrink: 0">
                    <i
                      style="cursor: pointer"
                      class="el-icon-delete"
                      @click="remoteTimeEs(scope.$index)"
                    ></i>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div style="display: flex; align-items: center; margin-top: 10px">
          <img
            src="../assets/add.png"
            style="width: 15px; height: 15px; margin: 2px"
            @click="addTimeEs(timeEsContent)"
          />
          <el-input
            v-model="timeEsContent"
            placeholder="请输入时间估计内容"
            style="margin-left: 10px"
            @keyup.enter.native="addTimeEs(timeEsContent)"
          ></el-input>
        </div>

        <el-input
          style="padding-top: 20px"
          type="textarea"
          :rows="5"
          ref="input"
          placeholder="添加备注"
          :autofocus="false"
          @focus="onfocus"
          v-model="note"
        ></el-input>
        <div>
          <p>批注</p>
          <div v-if="todoInfo.comment_list">
            <el-table
              :data="todoInfo.comment_list"
              :show-header="null"
              maxHeight="350px"
            >
              <el-table-column prop label align="left" show-overflow-tooltip>
                <template slot-scope="scope">
                  <div>{{ scope.row.content }}</div>
                </template>
              </el-table-column>
              <el-table-column
                prop
                label
                align="left"
                show-overflow-tooltip
                width="60px"
              >
                <template slot-scope="scope">
                  <div>{{ scope.row.user_name }}</div>
                </template>
              </el-table-column>
              <el-table-column
                prop
                label
                align="left"
                show-overflow-tooltip
                width="100px"
              >
                <template slot-scope="scope">
                  <div>{{ formatPhpAllTime(scope.row.create_time) }}</div>
                </template>
              </el-table-column>
              <el-table-column
                prop
                label
                align="left"
                show-overflow-tooltip
                width="60px"
              >
                <template slot-scope="scope">
                  <i
                    class="el-icon-message-solid"
                    style="cursor: pointer; margin-right: 10px; font-size: 16px"
                    @click.stop="toRemindComment(scope.$index)"
                  ></i>
                  <i
                    style="cursor: pointer"
                    class="el-icon-delete"
                    @click="remoteComment(scope.$index)"
                  ></i>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div style="display: flex; align-items: center; margin-top: 30px">
            <img
              src="../assets/add.png"
              style="width: 15px; height: 15px; margin: 2px"
              @click="addComment()"
            />
            <el-input
              v-model="commentContent"
              placeholder="请输入添加的批注内容"
              style="margin-left: 10px"
              @keyup.enter.native="addComment()"
            ></el-input>
          </div>

          <div style="font-size: 10px; color: #b3b3b3; margin-top: 20px">
            {{
              "创建于:" +
              (todoInfo.create_time != 0
                ? formatPhpAllTime(todoInfo.create_time)
                : "")
            }}
          </div>
        </div>

        <el-button style="margin-top: 20px" @click="deleteTodo()"
          >删除</el-button
        >
      </div>
    </el-drawer>
    <el-dialog :visible.sync="showChangeList" title="修改所属list">
      <el-select
        filterable
        placeholder="请选择要修改的list"
        v-model="change_list_id"
      >
        <el-option
          v-for="item in originalLists"
          :key="item._id.$id"
          :value="item._id.$id"
          :label="item.list_name"
        ></el-option>
      </el-select>
      <template slot="footer">
        <el-button @click="confirmChangeList" type="primary" size="medium"
          >确认</el-button
        >
      </template>
    </el-dialog>
  </div>
</template>
<script>
import Todo from "@/components/Todo.vue";
import star from "../components/Award";
import {
  getUserId,
  removeUserId,
  removeUserName,
  getUserName,
} from "../utils/store";
import {
  netFetch,
  assignChildTodo,
  moveTODOtoList,
  getLabelsByList,
  getListsCount,
  tentativeTODO,
  untentativeTODO,
  getMyTentativeTODOs,
  getMyFlagTODOs,
  unflagTODO,
  flagTODO,
  getMyMessages,
  getFinishedTODOsByList,
  findMyGivenTODOS,
  getMyStarTODOS,
  unfinishSubTODO,
  finishSubTODO,
  updateSubTODO,
  getLists,
  updateList,
  getTODOsByList,
  updateTODO,
  getList,
  getTODO,
  updateTODONote,
  netService,
  starTODO,
  unstarTODO,
  finishTODO,
  unfinishTODO,
  getMyTODOsByLabel,
  searchTODO,
  getTODOsAssigned,
  getMyFastTODOs,
  fastTODO,
  unfastTODO,
  getUserList,
  updateTodoLevel,
  deleteTODO,
  hideList,
  getProjectLists,
  removeSubTODO,
  changeTodoList,
  sorSubTodo,
  updateSubTODO1,
  sendSubTodoRemind,
  addComment,
  removeComment,
  sendCommentRemind,
  getMyOtherTODOsByLabel,
  updateTimeTypeForTODO,
  getTODOsAssignedMeByList,
} from "../api/assistant";
import Sortable from "sortablejs";
import { formatDate } from "../utils/date";
export default {
  name: "Home",
  components: {
    Todo,
    star,
  },
  data() {
    return {
      list_type: "custom",
      height: {
        height: "",
      },
      row: {
        height: "",
      },
      todo_time: null,
      start_time: null,
      end_time: null,
      time_type: "",
      list_labels: [],
      listsCount: { list_counts: [] },
      menuItemIndex: 0,
      menuItem: {},
      showInputTODO: false,
      messages: [],
      switchFinishedTODOS: false,
      finishedTODOS: [],
      finishedCount: -1,
      userId: "",
      subTODOS: [],
      enableShowDrawer: false,
      dialogForEditList: false,
      dialogForEditTODO: false,
      dialogForLabel: false,
      userName: "",
      content: "",
      todolist: [],
      todo_labels: [],
      drawer: false,
      direction: "ltr",
      singleList: { id: "", list_name: "", cooperator_ids: [] },
      lists: [],
      customlists: [],
      projectlists: [],
      originalLists: [],
      selectedList: {},
      todoInfo: { todo_content: {}, create_time: 0 },
      lastLabelPosition: -1,
      note: "",
      email: "",
      nowItem: {},
      canEdit: false,
      currentListName: "",
      listTips: {
        稍后处理:
          "TIP: 并不是所有遇到的事项都需要第一时间进入你的工作计划；有些事项如果你还是很不确定是否要做、什么时候做、如何去做等等，就可以先把它标记为稍后处理，待晚些时候再进行研究；一些不紧急的事项也可以标记到此清单；有了这个清单，你就可以放心将所有的事项都先进行记录，并先放到这里来；及时地记录事项是优化任务和时间管理的前提",
        阶段重点:
          "TIP: 总有一些事项不属于单个的任务，而是阶段性的工作重点；这些事项的记录目标并不是为了急于在某个时间点之前完成，而是希望在一段时间内能够持续提醒自己的工作重点和努力方向",
        分配给我: "TIP: 别的用户可以将建的任务分配给你，并在这里统一罗列",
        重要紧急:
          "TIP: 需要重点关注或者优先级非常高的事项，可以通过添加星标或闪电来加以区别，星标表示重要，闪电表示紧急",
        // 紧急任务:
        // 'TIP: 紧急事件'
      },
      showSwitchList: false,
      currentTODOList: { _id: { $id: "" }, list_name: "" },
      keywords: "",
      LabelContent: "",
      otherTodos: [],
      assign_label: "",
      cooperationEmail: "",
      starFast: [],
      starUnFast: [],
      unStarFast: [],
      users: [],
      change_list_id: "",
      showChangeList: false,
      subContent: "",
      commentContent: "",
      assignMyList: [],
      timeEstimateList:[],
      timeEsContent: "",
    };
  },
  mounted() {
    // 默认获取第一个list数据
    this.height.height = window.innerHeight - 60 + "px";
    this.row.height = window.innerHeight - 126 + "px";
    this.userId = getUserId();
    this.userName = getUserName();
    this.fetchData();
    this.fetchMessages();
    this._getMyTentativeTODOs();
    this._getListsCount();
    this.getUserList();
    console.log(1111);
  },
  computed: {
    computeTotalTimeEs() {
      let total = 0;
      this.timeEstimateList.forEach((item) => {
        total += parseFloat(item.time);
      });
      return total;
    },
  },
  watch: {
    // content(value) {
    //   var lastValue = value.charAt(value.length - 1);
    //   console.log(11111,lastValue)
    //   if (lastValue === "#") {
    //     this.lastLabelPosition = value.lastIndexOf("#");
    //     console.log(222,this.lastLabelPosition)
    //   }
    // },
  },
  methods: {
    getUserList() {
      getUserList().then((res) => {
        this.users = res.data;
      });
    },
    ontimechange(e) {
      // const timestap = this.todo_time.getTime()
      // console.log(this.todo_time, timestap, this.todo_time.getTime())
      console.log(this.todo_time);
      console.log(this.nowItem);
      netService("updateTimeLabelForTODO", {
        todo_id: this.nowItem._id.$id,
        time_label: this.todo_time,
      }).then((data) => {
        let item;
        item =
          this.todolist.find((x) => x._id.$id === this.nowItem._id.$id) ||
          this.otherTodos.find((x) => x._id.$id === this.nowItem._id.$id) ||
          this.starFast.find((x) => x._id.$id === this.nowItem._id.$id) ||
          this.starUnFast.find((x) => x._id.$id === this.nowItem._id.$id) ||
          this.unStarFast.find((x) => x._id.$id === this.nowItem._id.$id) ||
          this.finishedTODOS.find((x) => x._id.$id === this.nowItem._id.$id);
        this.$set(item, "time_label", this.todo_time);
      });
    },
    ontimechange1(e) {
      netService("updateStartTimeForTODO", {
        todo_id: this.nowItem._id.$id,
        start_time: this.start_time,
        end_time: this.end_time,
      }).then((data) => {
        let item;
        item =
          this.todolist.find((x) => x._id.$id === this.nowItem._id.$id) ||
          this.otherTodos.find((x) => x._id.$id === this.nowItem._id.$id) ||
          this.starFast.find((x) => x._id.$id === this.nowItem._id.$id) ||
          this.starUnFast.find((x) => x._id.$id === this.nowItem._id.$id) ||
          this.unStarFast.find((x) => x._id.$id === this.nowItem._id.$id) ||
          this.finishedTODOS.find((x) => x._id.$id === this.nowItem._id.$id);
        this.$set(item, "start_time", this.start_time);
        this.$set(item, "end_time", this.end_time);
      });
    },
    changeTimeType(e) {
      console.log(e);
      updateTimeTypeForTODO({
        todo_id: this.nowItem._id.$id,
        time_type: e,
      }).then((res) => {
        let item;
        item =
          this.todolist.find((x) => x._id.$id === this.nowItem._id.$id) ||
          this.otherTodos.find((x) => x._id.$id === this.nowItem._id.$id) ||
          this.starFast.find((x) => x._id.$id === this.nowItem._id.$id) ||
          this.starUnFast.find((x) => x._id.$id === this.nowItem._id.$id) ||
          this.unStarFast.find((x) => x._id.$id === this.nowItem._id.$id) ||
          this.finishedTODOS.find((x) => x._id.$id === this.nowItem._id.$id);
        this.$set(item, "time_type", e);
      });
    },
    onsignchange(e) {
      console.log(this.assign_label);
      console.log(this.nowItem);
      netService("updateAssignLabelForTODO", {
        todo_id: this.nowItem._id.$id,
        assign_label: this.assign_label,
      }).then((data) => {
        let item;
        item =
          this.todolist.find((x) => x._id.$id === this.nowItem._id.$id) ||
          this.otherTodos.find((x) => x._id.$id === this.nowItem._id.$id) ||
          this.starFast.find((x) => x._id.$id === this.nowItem._id.$id) ||
          this.starUnFast.find((x) => x._id.$id === this.nowItem._id.$id) ||
          this.unStarFast.find((x) => x._id.$id === this.nowItem._id.$id) ||
          this.finishedTODOS.find((x) => x._id.$id === this.nowItem._id.$id);
        this.$set(item, "assign_label", this.assign_label);
        console.log("标记变更");
      });
    },
    doShowSwitchListDialog() {
      this.currentTODOList = { _id: { $id: "" }, list_name: "" };
      this.currentTODOList._id.$id = this.todoInfo.list_id;
      this.currentTODOList.list_name = this.getListName(this.todoInfo.list_id);
      this.showSwitchList = true;
    },
    confirmMoveTODOToList() {
      this.showSwitchList = false;
      moveTODOtoList(this.todoInfo._id.$id, this.currentTODOList._id.$id).then(
        (res) => {
          this.enableShowDrawer = false;
          this.fetchData();
          this._getListsCount();
          this.handleMenuItem(
            this.menuItem,
            this.menuItemIndex,
            this.list_type
          );
        }
      );
    },
    getLeftListCount(item) {
      if (this.listsCount && this.listsCount.list_counts) {
        var count = this.listsCount.list_counts[item._id.$id];
        if (count) {
          return count;
        }
      }
      return 0;
    },
    _getListsCount() {
      this.listsCount = [];
      getListsCount(getUserId()).then((res) => {
        this.listsCount = res.data;
      });
    },
    tentativeChanged() {
      var id = this.todoInfo._id.$id;
      const flag_status = this.todoInfo.tentative_status;
      if (flag_status) {
        untentativeTODO(id).then((res) => {});
      } else {
        tentativeTODO(id).then((res) => {});
      }
      this.todoInfo.tentative_status = !this.todoInfo.tentative_status;
    },
    flagChanged() {
      var id = this.todoInfo._id.$id;
      const flag_status = this.todoInfo.flag_status;
      if (flag_status) {
        unflagTODO(id).then((res) => {});
      } else {
        flagTODO(id).then((res) => {});
      }
      this.todoInfo.flag_status = !this.todoInfo.flag_status;
    },
    deleteTodo() {
      this.$confirm("此操作将永久删除该TODO, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          var id = this.todoInfo._id.$id;
          deleteTODO(id).then((res) => {
            this.fetchData();
            this._getListsCount();
            this.handleMenuItem(
              this.menuItem,
              this.menuItemIndex,
              this.list_type
            );
            this.enableShowDrawer = false;
          });
        })
        .catch(() => {
          this.enableShowDrawer = false;
        });
    },
    fastChanged() {
      var id = this.todoInfo._id.$id;
      const fast_status = this.todoInfo.fast_status;
      if (fast_status) {
        unfastTODO(id, this.userId).then((res) => {
          this.fetchData();
          this._getListsCount();
          this.handleMenuItem(
            this.menuItem,
            this.menuItemIndex,
            this.list_type
          );
        });
      } else {
        fastTODO(id, this.userId).then((res) => {
          this.fetchData();
          this._getListsCount();
          this.handleMenuItem(
            this.menuItem,
            this.menuItemIndex,
            this.list_type
          );
        });
      }
      this.todoInfo.fast_status = !this.todoInfo.fast_status;
    },
    fetchMessages() {
      this.messages = [];
      getMyMessages(this.userId).then((res) => {
        this.messages = res.data;
      });
    },
    doEditTODO() {
      if (this.selectedList._id) {
        this.canEdit = true;
      }
    },
    editTODO() {
      this.dialogForLabel = false;
      updateTODO(
        this.todoInfo._id.$id,
        getUserId(),
        "user",
        this.todoInfo.todo_content,
        this.selectedList._id.$id,
        this.todoInfo.todo_labels
      ).then((res) => {
        this.canEdit = false;
        this.nowItem.todo_content = this.todoInfo.todo_content;
      });

      for (var i = 0; i < this.todolist.length; i++) {
        if (this.todolist[i]._id.$id === this.todoInfo._id.$id) {
          this.todolist[i].todo_content = this.todoInfo.todo_content;
          this.todolist[i].todo_labels = this.todoInfo.todo_labels;
        }
      }
      console.log(this.todolist);
    },
    refreshTODOList(type) {
      this._getListsCount();
      if (type === "qizhiChanged") {
        if (this.menuItemIndex === 0 && this.list_type === "custom") {
          this._getMyTentativeTODOs();
        }
        if (this.menuItemIndex === 1 && this.list_type === "custom") {
          this._getMyFlagTODOs();
        }
      } else if (type === "finishTaskChanged") {
        if (this.menuItemIndex === 0 && this.list_type === "custom") {
          this._getMyTentativeTODOs();
          return;
        }
        if (this.menuItemIndex === 1 && this.list_type === "custom") {
          this._getMyFlagTODOs();
          return;
        }
        if (this.menuItemIndex === 2 && this.list_type === "custom") {
          this._getMyStarTODOS();
          return;
        }
        if (this.menuItemIndex === 3 && this.list_type === "custom") {
          this._findMyGivenTODOS();
          return;
        }
        // if(this.menuItemIndex === 4){
        //     this.getMyFastTODOs()
        // }
        if (this.selectedList._id) {
          this.finishedCount = -1;
          this.finishedTODOS = [];
          this.todolist = [];
          this.getTODOs(this.selectedList._id.$id);
          this.getOtherTODOs();
          this.getTODOsAssignedMeByList();
          this._getFinishedTODOsByList(this.selectedList._id.$id);
        }
      }
    },
    confirmRefresh() {
      this._getListsCount();
      this.handleMenuItem(this.menuItem, this.menuItemIndex, this.list_type);
    },
    _getMyTentativeTODOs() {
      this.todolist = [];
      this.finishedCount = -1;
      this.finishedTODOS = [];
      getMyTentativeTODOs(getUserId(), false).then((res) => {
        this.todolist = res.data;
      });
      getMyTentativeTODOs(getUserId(), true).then((res) => {
        this.finishedTODOS = res.data;
        this.finishedCount = this.finishedTODOS.length;
      });
      this.currentListName = "稍后处理";
    },
    _getMyFlagTODOs() {
      this.todolist = [];
      this.finishedCount = -1;
      this.finishedTODOS = [];
      getMyFlagTODOs(getUserId(), false).then((res) => {
        this.todolist = res.data;
      });
      getMyFlagTODOs(getUserId(), true).then((res) => {
        this.finishedTODOS = res.data;
        this.finishedCount = this.finishedTODOS.length;
      });
      this.currentListName = "阶段重点";
    },
    _getMyStarTODOS() {
      this.todolist = [];
      this.finishedCount = -1;
      this.finishedTODOS = [];
      getMyStarTODOS(getUserId(), false).then((res) => {
        this.starFast = res.data.starFast;
        this.starUnFast = res.data.starUnFast;
        this.unStarFast = res.data.unStarFast;
      });
      getMyStarTODOS(getUserId(), true).then((res) => {
        this.finishedTODOS = res.data.finished;
        this.finishedCount = this.finishedTODOS.length;
      });
      this.currentListName = "重要紧急";
    },
    getMyFastTODOs() {
      this.todolist = [];
      this.finishedCount = -1;
      this.finishedTODOS = [];
      getMyFastTODOs(getUserId(), false).then((res) => {
        this.todolist = res.data;
      });
      getMyFastTODOs(getUserId(), true).then((res) => {
        this.finishedTODOS = res.data;
        this.finishedCount = this.finishedTODOS.length;
      });
      this.currentListName = "紧急任务";
    },
    _findMyGivenTODOS() {
      this.todolist = [];
      this.finishedCount = -1;
      this.finishedTODOS = [];
      findMyGivenTODOS(getUserId(), false).then((res) => {
        this.todolist = res.data;
      });
      findMyGivenTODOS(getUserId(), true).then((res) => {
        this.finishedTODOS = res.data;
        this.finishedCount = this.finishedTODOS.length;
      });
      this.currentListName = "分配给我";
    },
    exchangeSubTODO(flag, sub_index) {
      if (flag) {
        finishSubTODO(this.todoInfo._id.$id, sub_index - 1, this.userId).then(
          (res) => {
            this.showTODODetail(this.nowItem);
            this.refreshTODOList("finishTaskChanged");
          }
        );
      } else {
        unfinishSubTODO(this.todoInfo._id.$id, sub_index - 1).then((res) => {
          this.showTODODetail(this.nowItem);
          this.refreshTODOList("finishTaskChanged");
        });
      }
    },
    addSubTODO(content) {
      updateSubTODO(this.todoInfo._id.$id, content).then((res) => {
        this.fetchData();
        this.showTODODetail(this.nowItem);
        this.subContent = "";
        this.refreshTODOList("finishTaskChanged");
      });
    },
    addTimeEs(content) {
      netFetch({
        function:'addTODOTimeEstimate',
        content: content,
        todo_id: this.nowItem._id.$id,
        time:''
      }).then((res) => {
        this.fetchData();
        this.showTODODetail(this.nowItem);
        this.timeEsContent = "";
        this.refreshTODOList("finishTaskChanged");
      });
    },
    addComment() {
      addComment({
        todo_id: this.todoInfo._id.$id,
        content: this.commentContent,
        user_id: getUserId(),
      }).then((res) => {
        this.fetchData();
        this.showTODODetail(this.nowItem);
        this.commentContent = "";
      });
    },
    remoteComment(index) {
      removeComment({
        todo_id: this.todoInfo._id.$id,
        index: index,
      }).then((res) => {
        this.fetchData();
        this.showTODODetail(this.nowItem);
      });
    },
    deleteLabel(label) {
      var index = this.todo_labels.indexOf(label);
      if (index > -1) {
        this.todo_labels.splice(index, 1);
      }
    },
    starChanged() {
      var id = this.todoInfo._id.$id;
      const star_status = this.todoInfo.star_status;
      if (star_status) {
        unstarTODO(id, this.userId).then((res) => {});
      } else {
        starTODO(id, this.userId).then((res) => {});
      }
      this.todoInfo.star_status = !this.todoInfo.star_status;
    },
    handleSelect(item) {
      netService("giveTODOtoUser", {
        todo_id: this.nowItem._id.$id,
        user_id: item._id.$id,
      }).then((data) => {
        this.showTODODetail(this.nowItem);
        this.refreshTODOList("finishTaskChanged");
      });
    },
    handleCooperation(item) {
      netService("addCooperationToTODO", {
        todo_id: this.nowItem._id.$id,
        user_id: item._id.$id,
      }).then((data) => {
        this.showTODODetail(this.nowItem);
        this.refreshTODOList("finishTaskChanged");
      });
    },
    delassign(item) {
      netService("unassignUserFromTODO", {
        todo_id: this.nowItem._id.$id,
        user_id: item._id.$id,
      }).then((data) => {
        this.showTODODetail(this.nowItem);
        this.refreshTODOList("finishTaskChanged");
      });
    },
    delCooperation(item) {
      netService("unassignCooperationUserFromTODO", {
        todo_id: this.nowItem._id.$id,
        user_id: item._id.$id,
      }).then((data) => {
        this.showTODODetail(this.nowItem);
        this.refreshTODOList("finishTaskChanged");
      });
    },
    querySearch(queryString, cb) {
      netService("searchUsersByEmail", {
        email: queryString,
      }).then((data) => {
        let emails = data;
        emails = emails.map((item) => ({
          ...item,
          value: `${item.user_lastName}${item.user_givenName}:${item.user_email}`,
        }));
        cb(emails);
      });
    },
    onfocus(e) {
      if (!this.firstactivate) {
        e.target.blur();
        this.firstactivate = true;
      }
    },
    addTODO() {
      // if (this.lastLabelPosition >= 0) {
      //   var label = this.content.substring(
      //     this.lastLabelPosition + 1,
      //     this.content.length
      //   );
      //   if (label) {
      //     this.lastLabelPosition = -1;
      //     this.todo_labels.push(label);
      //     this.content = this.content.replace("#" + label, "");
      //     return;
      //   }
      // }
      if(!this.content){
        return
      }
      let index = this.content.indexOf("#");
      if(this.content[0]==='#'){
        let label = this.content.substring(
          1,
          this.content.length
        );
        this.todo_labels.push(label);
        this.content = ''
        return
      }
      var tempContent = this.content;
      for (var i in this.todo_labels) {
        var todo_label = this.todo_labels[i];
        tempContent = tempContent.replace("#" + todo_label, "");
      }
      updateTODO(
        "new",
        getUserId(),
        "user",
        tempContent,
        this.selectedList._id.$id,
        this.todo_labels
      ).then((res) => {
        this.todo_labels = [];
        this.lastLabelPosition = -1;
        this.content = "";
        this.getTODOs(this.selectedList._id.$id);
        this._getListsCount();
      });
    },
    showTODODetail(item) {
      if (!this.selectedList._id) {
        this.selectedList = { _id: { $id: item.list_id } };
      }
      this.canEdit = false;
      this.nowItem = item;
      console.log(item.time_label, item);
      if (item.time_label) {
        this.todo_time = item.time_label;
      } else {
        this.todo_time = null;
      }
      this.start_time = item.start_time ? item.start_time : null;
      this.end_time = item.end_time ? item.end_time : null;
      this.time_type = item.time_type ? item.time_type : "";
      if (item.assign_label) {
        this.assign_label = item.assign_label;
      } else {
        this.assign_label = null;
      }
      this.subTODOS = [];
      this.timeEstimateList = [];
      this.email = "";
      this.cooperationEmail = "";
      getTODO(item._id.$id).then((res) => {
        this.todoInfo = res.data;
        console.log(1111, this.todoInfo.create_time);
        if (this.todoInfo) {
          var todos = this.todoInfo.sub_todos;
        }
        if (todos) {
          var index = 1;
          for (var i in todos) {
            var content = {};
            var subTodo = todos[i];
            content.index = index;
            content.content = subTodo.content;
            content.flag = subTodo.status;
            content.user_id = subTodo.user_id;
            this.subTODOS.push(content);
            index++;
          }
        }
        if (this.todoInfo.time_estimates) {
          this.timeEstimateList = this.todoInfo.time_estimates;
        }
        this.note = res.data.todo_note;
        this.enableShowDrawer = true;
        this.$nextTick(() => {
          this.initSort();
        });
      });
    },
    initSort() {
      const el = document.getElementsByTagName("tbody")[0];
      console.log("el", el);
      const sort = new Sortable(el, {
        onEnd: (e) => {
          console.log(e);
          sorSubTodo(this.todoInfo._id.$id, e.oldIndex, e.newIndex);
        },
      });
    },
    showDialogForEditList(list) {
      this.dialogForEditList = true;
      if (!list) {
        this.singleList.id = "";
        this.singleList.list_name = "";
        this.singleList.cooperator_ids = [];
      } else {
        getList(list._id.$id).then((res) => {
          this.singleList.id = res.data._id.$id;
          this.singleList.list_name = res.data.list_name;
          this.singleList.cooperator_ids = res.data.cooperator_ids;
          // TODO: 可以进行添加处理
        });
      }
    },
    fetchData() {
      this.lists = [];
      this.customlists = [];
      this.projectlists = [];
      this.originalLists = [];
      getLists(getUserId(), "user").then((res) => {
        var result = res.data;
        this.originalLists = result;
        this.lists.push({ list_name: "稍后处理" });
        this.lists.push({ list_name: "阶段重点" });
        this.lists.push({ list_name: "重要紧急" });
        this.lists.push({ list_name: "分配给我" });
        // this.lists.push({ list_name: '紧急任务' })
        for (var i in result) {
          this.lists.push(result[i]);

          this.customlists.push(result[i]);
        }
        this.lists = this.lists.map((item) => ({
          ...item,
          visible: false,
        }));
        this.customlists = this.customlists.map((item) => ({
          ...item,
          visible: false,
        }));
        //        if (this.lists) {
        //          this.handleMenuItem(this.lists[0])
        //        }
      }),
        getProjectLists(getUserId(), "user").then((res) => {
          var result = res.data;
          // this.originalLists = result

          // this.lists.push({ list_name: '紧急任务' })
          for (var i in result) {
            this.projectlists.push(result[i]);
          }

          this.projectlists = this.projectlists.map((item) => ({
            ...item,
            visible: false,
          }));
          //        if (this.lists) {
          //          this.handleMenuItem(this.lists[0])
          //        }
        });
    },
    editList() {
      this.dialogForEditList = false;
      if (!this.singleList.list_name) {
        return;
      }
      var id = "new";
      if (this.singleList.id) {
        id = this.singleList.id;
      }
      updateList(
        id,
        getUserId(),
        "user",
        this.singleList.list_name,
        this.singleList.cooperator_ids
      ).then((res) => {
        this.fetchData();
      });
    },
    handleMenuItem(item, index, type) {
      this.list_type = type;
      this.menuItem = item;
      this.menuItemIndex = index;
      this.menuItemIndex = index;
      this.restoreListEditIcon(index);
      this.selectedList = {};
      this.finishedCount = -1;
      this.finishedTODOS = [];
      this.switchFinishedTODOS = false;
      this.showInputTODO = false;
      if (index === 0 && type == "custom") {
        this._getMyTentativeTODOs();
        return;
      }
      if (index === 1 && type == "custom") {
        this._getMyFlagTODOs();
        return;
      }
      if (index === 2 && type == "custom") {
        this._getMyStarTODOS();
        return;
      }
      if (index === 3 && type == "custom") {
        this._findMyGivenTODOS();
        return;
      }
      //   if(index === 4){
      //       this.getMyFastTODOs()
      //       return
      //   }
      this.showInputTODO = true;
      this.selectedList = item;
      this.todolist = [];
      this.getTODOs(item._id.$id);
      this.getOtherTODOs();
      this.getTODOsAssignedMeByList();
      this._getFinishedTODOsByList(item._id.$id);
    },
    _getFinishedTODOsByList(list_id) {
      getFinishedTODOsByList(this.userId, "user", list_id).then((res) => {
        this.finishedTODOS = res.data;
        this.finishedCount = this.finishedTODOS.length;
      });
    },
    restoreListEditIcon(index) {
      for (var i = 0; i < this.lists.length; i++) {
        if (i === index) {
          this.lists[i].visible = true;
        } else {
          this.lists[i].visible = false;
        }
      }
    },
    getTODOs(listId) {
      getTODOsByList(getUserId(), "user", listId).then((res) => {
        this.todolist = res.data;
        getLabelsByList(listId, true).then((result) => {
          this.list_labels = result.data;
        });
      });
      this.currentListName = "";
    },
    logout() {
      removeUserId();
      removeUserName();
      location.reload();
    },
    handleClose() {
      this.firstactivate = false;
      const id = this.todoInfo._id.$id;
      console.log(id);
      updateTODONote(id, this.note).then((res) => {
        this.enableShowDrawer = false;
        this.fetchData();
      });
    },
    finishTask() {
      const id = this.todoInfo._id.$id;
      const finish_status = this.todoInfo.finish_status;
      if (finish_status) {
        unfinishTODO(id, getUserId()).then((res) => {
          this.refreshTODOList("finishTaskChanged");
        });
      } else {
        finishTODO(id, getUserId()).then((res) => {
          this.refreshTODOList("finishTaskChanged");
        });
      }
    },
    enter(index) {
      if (index === 0 || index === 1) {
        return;
      }
      this.$set(this.lists, index, { ...this.lists[index], visible: true });
    },
    leave(index) {
      if (index === 0 || index === 1) {
        return;
      }
      this.$set(this.lists, index, { ...this.lists[index], visible: false });
    },
    formatPhpTime(php_time) {
      var val = JSON.parse(php_time);
      var time = val * 1000;
      var date = new Date(time);
      return formatDate(date, "yyyy-MM-dd");
    },
    formatPhpAllTime(php_time) {
      var val = JSON.parse(php_time);
      var time = val * 1000;
      var date = new Date(time);
      return formatDate(date, "yyyy-MM-dd");
    },
    formatPhpAllTime1(php_time) {
      var val = JSON.parse(php_time);
      var time = val * 1000;
      var date = new Date(time);
      return formatDate(date, "MM-dd hh:mm");
    },
    getListName(list_id) {
      for (var i = 0; i < this.lists.length; i++) {
        if (this.lists[i]._id) {
          if (list_id === this.lists[i]._id.$id) {
            return this.lists[i].list_name;
          }
        }
      }
    },
    selectTag(tag, status) {
      getMyTODOsByLabel(
        this.userId,
        tag,
        status,
        this.selectedList._id.$id
      ).then((res) => {
        console.log(res.data);
        this.todolist = res.data;
      });

      getMyOtherTODOsByLabel({
        user_id: this.userId,
        label: tag,
        list_id: this.selectedList._id.$id,
      }).then((res) => {
        this.otherTodos = res.data;
      });
    },
    searchTODOList() {
      var t = [];
      var f = [];
      var all = [];
      if (!this.keywords) {
        this.$message("请输入关键词");
      } else {
        searchTODO(this.userId, this.keywords, false).then((res) => {
          t = res.data;
          searchTODO(this.userId, this.keywords, true).then((result) => {
            f = result.data;
            all = t.concat(f);
            console.log(all);
            if (all.length !== 0) {
              this.todolist = t;
              this.finishedTODOS = f;
              this.finishedCount = this.finishedTODOS.length;
            } else {
              this.$message("没有结果");
            }
          });
        });
      }
    },
    showDialogForLabel() {
      this.dialogForLabel = true;
    },
    addLabel() {
      var labelList = this.todoInfo.todo_labels;
      labelList.push(this.LabelContent.replace("#", ""));
    },

    deleteList() {
      this.$confirm("此操作将删除该List, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          hideList(this.menuItem._id.$id).then((res) => {
            // this.fetchData()
            // this._getListsCount()
            // this.handleMenuItem(this.menuItem, this.menuItemIndex)
            location.reload();
          });
        })
        .catch(() => {});
      // this.menuItem = item
      // this.menuItemIndex = index
      // this.menuItemIndex = index
    },

    deleteTODOInfoLabel(label) {
      var index = this.todoInfo.todo_labels.indexOf(label);
      if (index > -1) {
        this.todoInfo.todo_labels.splice(index, 1);
      }
    },
    getOtherTODOs() {
      console.log("用户id是" + this.userId);
      console.log("列表id是" + this.selectedList._id.$id);
      getTODOsAssigned(this.userId, "user", this.selectedList._id.$id).then(
        (res) => {
          this.otherTodos = res.data;

          console.log(res.data);
          console.log("其他更新成功");
        }
      );
    },
    getTODOsAssignedMeByList() {
      getTODOsAssignedMeByList({
        user_id: this.userId,
        list_id: this.selectedList._id.$id,
      }).then((res) => {
        this.assignMyList = res.data;
      });
    },
    markGuide(e) {
      console.log(e);
      this.todoInfo.level = e;
      updateTodoLevel(this.todoInfo._id.$id, e).then((res) => {
        this.refreshTODOList("finishTaskChanged");
      });
    },
    remoteSub(index) {
      removeSubTODO(this.todoInfo._id.$id, index).then((res) => {
        this.showTODODetail(this.nowItem);
      });
    },
    remoteTimeEs(index) {
      netFetch({
        function:'removeTODOTimeEstimate',
        todo_id: this.todoInfo._id.$id,
        index: index
      }).then((res) => {
        this.showTODODetail(this.nowItem);
      });
    },
    changeList() {
      this.change_list_id = "";
      this.showChangeList = true;
    },
    confirmChangeList() {
      if (!this.change_list_id) {
        return;
      }
      changeTodoList(this.todoInfo._id.$id, this.change_list_id).then((res) => {
        this.showChangeList = false;
        this.fetchData();
        this._getListsCount();
        this.refreshTODOList("finishTaskChanged");
        this.enableShowDrawer = false;
      });
    },
    editSubTODO(item) {
      this.$set(item, "showInput", true);
    },
    editTimeEstimate(item) {
      this.$set(item, "showInput", true);
    },
    confirmEditSubTodo(item) {
      updateSubTODO1({
        todo_id: this.todoInfo._id.$id,
        content: item.content,
        index: item.index - 1,
      });
      this.$set(item, "showInput", false);
    },
    confirmEditTimeEstimate(item,index) {
      netFetch({
        function:'updateTODOTimeEstimate',
        todo_id: this.todoInfo._id.$id,
        content: item.content,
        time: item.time,
        index: index
      });
      this.$set(item, "showInput", false);
    },
    toRemind(index, user_id) {
      let user = this.todoInfo.assign_users.find(
        (item) => item._id.$id === user_id
      );
      sendSubTodoRemind({
        wechat_ids: user_id
          ? [user.wechat_work_id]
          : [
              ...this.todoInfo.assign_users.map((item) => item.wechat_work_id),
              ...this.todoInfo.cooperation_users.map(
                (item) => item.wechat_work_id
              ),
            ],
        todo_id: this.todoInfo._id.$id,
        index,
      }).then((res) => {
        if (res.data.code === 0) {
          this.$message.success("提醒已通过企业微信发送");
        }
      });
    },
    toRemindComment(index) {
      sendCommentRemind({
        wechat_ids: [
          ...this.todoInfo.assign_users.map((item) => item.wechat_work_id),
          ...this.todoInfo.cooperation_users.map((item) => item.wechat_work_id),
        ],
        todo_id: this.todoInfo._id.$id,
        index,
        user_id: getUserId(),
      }).then((res) => {
        if (res.data.code === 0) {
          this.$message.success("提醒已通过企业微信发送");
        }
      });
    },
    changeChildAssign(e, index) {
      assignChildTodo({
        todo_id: this.todoInfo._id.$id,
        index,
        user_id: e,
      });
    },
  },
};
</script>

<style scoped>
.tac {
  overflow-y: scroll;
}
.home {
  display: flex;
  flex-direction: column;
}
.head {
  position: relative;
  text-align: right;
  font-size: 12px;
}
.head-menu {
  position: absolute;
  left: 20px;
}
/deep/ .el-checkbox__inner {
  width: 20px;
  height: 20px;
}
/deep/ .el-checkbox__input .el-checkbox__inner::after {
  box-sizing: content-box;
  content: "";
  border-left: 0;
  border-top: 0;
  height: 10px;
  left: 6px;
  position: absolute;
  top: 1px;
  width: 5px;
  transform: rotate(45deg) scaleY(1);
}
</style>
<style>
.el-header {
  background-color: #b3c0d1;
  color: #333;
  line-height: 60px;
}
.deleteline {
  text-decoration: line-through;
}
.el-aside {
  background-color: white;
  color: #333;
  text-align: center;
  /* line-height: 500px; */
}
.aside {
  position: relative;
  /* display: flex;
  justify-content: space-between;
  flex-direction: column; */
}
.favorite {
  position: absolute;
  top: 15px;
  right: 20px;
}
.btn {
  position: fixed;
  bottom: 10px;
  /* bottom: 10021px; */
  left: 40px;
  height: 38px;
}
.box {
  position: absolute;
  top: 20px;
  left: 20px;
}
.el-icon-star-on {
  font-size: 32px;
}
.el-icon-star-off {
  font-size: 24px;
}

.list_tip {
  padding: 15px;
  background-color: rgba(255, 0, 0, 0.15);
  border-radius: 6px;
  margin-bottom: 20px;
  width: 95%;
  color: gray;
  line-height: 150%;
}
.icon {
  font-size: 12px;
  background: #409eff;
  color: white;
  border-radius: 4px;
  padding: 2px 6px;
  margin-left: 6px;
}
</style>
