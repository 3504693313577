<template>
    <div class="exam-list">
        <div>
            <el-table :data="examList">
                <el-table-column label="试卷名" prop="template_name" ></el-table-column>
                <el-table-column label="主题" prop="test_subject" width="120"></el-table-column>
                <el-table-column label="考试时长" width="120">
                <template slot-scope="scope">
                    <p>{{scope.row.test_duration?scope.row.test_duration+'分钟':''}}</p>
                </template>
                </el-table-column>
                <el-table-column label="考试时间" prop="test_time" width="120"></el-table-column>
                <!-- <el-table-column label="考试题数" prop="question_count" width="120"></el-table-column> -->
                <el-table-column label="参考资料" prop="test_refer"></el-table-column>
                <el-table-column label="操作" width="120">
                <template slot-scope="scope">
                    <el-button
                        size="small"
                        type="primary"
                        @click="toExam(scope.row._id.$id,scope.row.finish_status)"
                    >
                    {{scope.row.finish_status==='YES'?'查看试卷':'开始答题'}}
                    </el-button>
                </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
import { getExerciseTemplatesByStudent } from "../../api/assistant";
import { getUserId } from "../../utils/store";
export default {
  data() {
    return {
      examList: []
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      let userId = getUserId();
      getExerciseTemplatesByStudent(userId).then(res => {
        if (res.data.code === 0) {
          this.examList = res.data.data;
        }
      });
    },
    toExam(id,status) {
      this.$router.push({
        path:'/examview',
        query: {
          id: id,
          type:status==='YES'?'review':'exam'
        }
      })
    }
  }
};
</script>

<style>
.exam-list {
  width: 100%;
}
</style>
