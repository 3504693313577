<template>
  <div class="content">
    <div class="content-header">
      <div>
        <div>{{ info.project_code || info.project_product }}</div>
        <div class="school-name">{{ info.account_name }}</div>
      </div>
      <img class="group-icon" src="../../assets/group.png" @click="showGroupInfo" />
    </div>
    <div class="content-main" ref="chatRef" @scroll="onScroll">
      <div class="main">
        <div v-for="(item, index) in messageList" class="message" :key="index">
          <div class="sender-item" v-if="item.message_type === 'web'">
            <el-avatar shape="square" :size="40" :src="item.sender_info.headPicUrl || squareUrl"></el-avatar>
            <div style="flex: 1;">
              <div class="info">
                <div class="name">{{ item.sender_info.user_enName }}</div>
                <div class="name">
                  {{ $dayjs(item.create_time * 1000).format("MM-DD HH:mm") }}
                </div>
              </div>
              <div class="sender-content">{{ item.message_content }}</div>
            </div>
          </div>
          <div class="receiver-item" v-else>
            <el-avatar shape="square" :size="40" :src="item.sender_info.headPicUrl || squareUrl"></el-avatar>
            <div style="flex: 1;">
              <div class="info">
                <div class="name">{{ item.sender_info.contact_enName }}</div>
                <div class="name">
                  {{ $dayjs(item.create_time * 1000).format("MM-DD HH:mm") }}
                </div>
              </div>
              <div class="receiver-content">{{ item.message_content }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getMessageDetail } from '../../api/assistant'
import { getUserId } from '../../utils/store'

export default {
  name: 'MessageContent',
  props: {
    info: {
      type: Object
    }
  },
  data () {
    return {
      squareUrl:
        'http://mainbucket.reachable-edu.com/hEaIyvBOHDTV0cd6468c61f7992cd9ef0aa688424c55.jpeg',
      messageList: [],
      userId: getUserId()
    }
  },
  mounted () {
    this.getChatDetail()
  },
  watch: {
    info () {
      this.getChatDetail()
    }
  },
  methods: {
    async getChatDetail () {
      const res = await getMessageDetail({
        account_id: this.info.account_id,
        group_id: this.info._id.$id,
        get_type: 'web',
        user_id: getUserId(),
        create_time: Date.now()
      })
      if (res.data.code === 0) {
        this.messageList = res.data.data.sort(
          (a, b) => a.create_time - b.create_time
        )
        this.$nextTick(() => this.scrollToBottom())
      }
    },
    scrollToBottom () {
      const el = document.getElementsByClassName('message')
      console.log(el)
      if (el) {
        el[el.length - 1].scrollIntoView({ behavior: 'smooth' })
      }
    },
    showGroupInfo () {
      this.$emit('show')
    },
    async onScroll (e) {
      if (e.target.scrollTop === 0) {
        const res = await getMessageDetail({
          account_id: this.info.account_id,
          group_id: this.info._id.$id,
          get_type: 'web',
          user_id: getUserId(),
          create_time: this.messageList[0].create_time
        })
        if (res.data.data.length > 0) {
          this.messageList = [...res.data.data.sort(
            (a, b) => a.create_time - b.create_time
          ), ...this.messageList]
          this.$refs.chatRef.scrollTop = 60
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  height: 100%;
  .content-header {
    padding: 18px;
    border-bottom: 2px solid #f2f2f2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .school-name {
      font-size: 12px;
      color: #999;
      margin-top: 8px;
    }
    .group-icon {
      width: 28px;
      height: 22px;
      cursor: pointer;
    }
  }
  .main {
    padding: 18px;
  }

  .content-main {
    height: calc(100% - 60px);
    overflow-y: scroll;
  }

  .receiver-item {
    display: flex;
    margin-bottom: 18px;
    .info {
      display: flex;
      margin-bottom: 2px;
    }
    .name {
      color: #999;
      font-size: 12px;
      margin-left: 16px;
    }
    .receiver-content {
      background: #f5f5f5;
      height: fit-content;
      border-radius: 10px;
      padding: 16px;
      word-break: break-word;
      max-width: 600px;
      margin-left: 16px;
      width: fit-content;
    }
  }
  .sender-item {
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 18px;
    .info {
      display: flex;
      flex-direction: row-reverse;
      margin-bottom: 2px;
    }
    .name {
      color: #999;
      font-size: 12px;
      margin-right: 16px;
    }
    .sender-content {
      background: #508cff;
      color: white;
      height: fit-content;
      border-radius: 10px;
      padding: 16px;
      word-break: break-word;
      max-width: 600px;
      width: fit-content;
      margin-right: 16px;
      float: right;
    }
  }
}
</style>
