var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"adminContainer"},[_c('div',{staticClass:"header"},[_c('img',{staticStyle:{"width":"90px","margin-left":"50px"},attrs:{"src":require("../assets/loginTitle.png")}}),_c('div',{staticClass:"rightPart"},[_c('span',[_vm._v(_vm._s(_vm.userName))]),_c('img',{staticStyle:{"margin-left":"20px","cursor":"pointer"},attrs:{"src":require("../assets/exit.svg")},on:{"click":_vm.logout}})])]),_c('div',{staticClass:"nav"},[_c('div',{staticClass:"contentContainer"},[_c('div',{staticClass:"item",on:{"click":function($event){return _vm.toManage('userAdmin')}}},[_c('img',{attrs:{"src":require("../assets/icon/员工账号管理.svg")}}),_vm._m(0)]),_c('div',{staticClass:"item",on:{"click":function($event){return _vm.toManage('smartAdmin')}}},[_c('img',{attrs:{"src":require("../assets/icon/smart.svg")}}),_vm._m(1)]),_c('div',{staticClass:"item",on:{"click":function($event){return _vm.toManage('trackfeedbacknumAdmin')}}},[_c('img',{attrs:{"src":require("../assets/icon/追踪反馈数量.svg")}}),_vm._m(2)]),_c('div',{staticClass:"item",on:{"click":function($event){return _vm.toManage('examAdmin')}}},[_c('img',{attrs:{"src":require("../assets/icon/考试管理.svg")}}),_vm._m(3)]),_c('div',{staticClass:"item",on:{"click":function($event){return _vm.toManage('holiday')}}},[_c('img',{attrs:{"src":require("../assets/icon/假期管理1.svg")}}),_vm._m(4)]),_c('div',{staticClass:"item",on:{"click":function($event){return _vm.toManage('recorder')}}},[_c('img',{attrs:{"src":require("../assets/icon/假期管理1.svg")}}),_vm._m(5)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-content"},[_c('div',{staticClass:"item-title"},[_vm._v("员工账号管理")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-content"},[_c('div',{staticClass:"item-title"},[_vm._v("SmartWork培训管理")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-content"},[_c('div',{staticClass:"item-title"},[_vm._v("追踪反馈数量")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-content"},[_c('div',{staticClass:"item-title"},[_vm._v("考试管理")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-content"},[_c('div',{staticClass:"item-title"},[_vm._v("假期管理")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-content"},[_c('div',{staticClass:"item-title"},[_vm._v("录音测试")])])
}]

export { render, staticRenderFns }