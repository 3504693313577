<template>
  <div>


    <el-button @click="initAdd" type="primary" style="margin-top:10px"
      >新增员工BU</el-button>

    <div style="margin-top:30px" >员工所属BU列表</div>

    <el-table :data="userList" style="margin-top:30px" v-loading="loading">
      
      <el-table-column prop="name" label="bu_name" width="120px"></el-table-column>
      
     
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            @click="view(scope.row)"
            type="primary"
            size="small"
            >修改</el-button
          >
          <el-button
            @click="deleteBu(scope.row._id.$id)"
            type="primary"
            size="small"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-dialog title="新增/更新BU" :visible.sync="showAdd">
      <el-form>
        
        <el-form-item label="BUname">
          <el-input  type="text"  placeholder="请输入name" v-model="buName"></el-input>
        </el-form-item>
      </el-form>
      <el-button @click="updateBU" type="primary" style="margin-top:20px"
        >更新</el-button
      >
    </el-dialog>

  </div>
</template>

<script>
import { getUserBUList ,updateUserBU ,deleteUserBU} from "../../api/assistant";
export default {
  data() {
    return {
      userList: [],
      userInfo: {},
      loading:false,
      showAdd:false,
      buID:'',
      buName:''
    };
  },
  mounted() {
      this.fetchData();
  },
  methods: {
    fetchData() {
      this.loading = true
      getUserBUList().then(res => {
        if (res.data) {
          this.userList = res.data;
          this.loading = false
        }
      });
    },
    initAdd(){
      this.buID = ''
      this.buName = ''
      this.showAdd = true

    },
    deleteBu(id){
      deleteUserBU(id).then(res => {
        this.$message({
            message:'删除成功',
            type:'success'
        })
          this.fetchData();
      });
    },
    view(row) {

      this.buID = row._id.$id
      this.buName = row.name
      this.showAdd = true
    },
    updateBU(){

      if (this.buName == ''){

        this.$message({
            message:'name不能为空',
            type:'erroe'
        })
        return
      }

      updateUserBU(this.buID,this.buName).then(res => {
          this.showAdd = false
          this.fetchData();
      });


    },
    formateTime(date){
      let time = new Date(date*1000);
      return `${time.getFullYear()}-${time.getMonth()+1}-${time.getDate()}`;
    },
    formateDay(date){
      let time = new Date(date*1000);
      return `${time.getDate()}`;
    },
    formateMonth(date){
      let time = new Date(date*1000);
      return `${time.getMonth()+1}`;
    },
    formateYear(date){
      let time = new Date(date*1000);
      return `${time.getFullYear()}`;
    },
  }
};
</script>

<style></style>
