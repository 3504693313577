<template>
  <div class="tongxun">
    <div class="left">
      <div
        class="userItem"
        v-for="(item, index) in userList"
        :key="index"
        @click="selectUser(item)"
        :class="{ selected: currentUserId === item._id.$id }"
      >
        {{ item.user_lastName }}{{ item.user_givenName }}
      </div>
    </div>
    <div class="right">
      <div v-if="currentUserId" style="width: 500px">
        <div class="username">
          {{
            currentUser.username ||
            currentUser.user_lastName + currentUser.user_givenName
          }}
        </div>
        <div class="title" v-if="currentUser.employee_territorys">
          {{
            currentUser.employee_territorys
              .map(
                (item) =>
                  item.employee_territory + item.employee_territory_level
              )
              .join("、")
          }}
        </div>
        <div style="margin-top: 30px">
          <div class="info">
            <span class="label">电话：</span>{{ currentUser.user_mobile }}
          </div>
          <div class="info">
            <span class="label">邮箱：</span>{{ currentUser.user_email }}
          </div>
          <div class="info">
            <span class="label">企业微信：</span
            >{{ currentUser.wechat_work_id }}
          </div>
        </div>
        <div style="margin-top: 30px">
          <el-button type="primary" @click="sendMessage">发消息</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getChatUserList } from "../../api/assistant";
import { getUserId } from "../../utils/store";
export default {
  data() {
    return {
      userList: [],
      currentUserId: "",
      currentUser: {},
    };
  },
  mounted() {
    this.getUserList();
  },
  methods: {
    selectUser(item) {
      this.currentUserId = item._id.$id;
      this.currentUser = item;
    },
    async getUserList() {
      const res = await getChatUserList();
      this.userList = res.data;
    },
    sendMessage() {
      this.$emit("message", this.currentUserId);
    },
  },
};
</script>

<style lang="scss" scoped>
.tongxun {
  display: flex;
  height: 100%;
  .left {
    width: 260px;
    height: 100%;
    border-right: 1px solid #ccc;
    position: relative;
    overflow-y: scroll;
    .userItem {
      width: 100%;
      height: 40px;
      padding: 0 20px;
      line-height: 40px;
      box-sizing: border-box;
      cursor: pointer;
      font-size: 16px;
    }
    .userItem:hover {
      background: #f5f5f5;
    }
    .userItem.selected {
      background: rgba(209, 222, 240, 0.4);
    }
  }
  .right {
    flex: 1;
    height: 100%;
    background: #fff;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    .username {
      font-size: 24px;
      color: #333;
    }
    .title {
      margin-top: 10px;
      color: #999;
      font-size: 16px;
    }
    .info {
      margin-top: 12px;
      color: #333;
      .label {
        color: #aaa;
      }
    }
  }
  //美化滚动条
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 5px;
  }
  ::-webkit-scrollbar-track {
    background: #f5f5f5;
  }
}
</style>