<template>
    <div>
        <div class="title" style="font-size:18px;line-height:40px;">我的评语</div>
        <el-table :data="checkTaskList">
            <el-table-column prop="create_time" label="时间" width="200">
                <template slot-scope="scope">
                    {{formate(scope.row.create_time)}}
                </template>
            </el-table-column>
            <el-table-column prop="type" label="类型" width="150"></el-table-column>
            <el-table-column prop="reviewer_name" label="反馈人" width="100"></el-table-column>
            <el-table-column prop="content" label="反馈内容"></el-table-column>
        </el-table>
    </div>
</template>

<script>
import {getFeedbacks } from '../../api/assistant.js'
import { getUserId } from '../../utils/store'
export default {
    data () {
        return {
            checkTaskList: [],
            name: '',
            note: '',
            id: '',
            type: 'my',
        }
    },
    mounted () {
        this.getFeedbacks()
    },
    methods: {
        formate (timestamp) {
            var date = new Date(timestamp * 1000)
            var y = date.getFullYear()
            var m = date.getMonth() + 1
            m = m < 10 ? '0' + m : m
            var d = date.getDate()
            d = d < 10 ? '0' + d : d
            var h = date.getHours()
            h = h < 10 ? '0' + h : h
            var minute = date.getMinutes()
            var second = date.getSeconds()
            minute = minute < 10 ? '0' + minute : minute
            second = second < 10 ? '0' + second : second
            var timeStr =
                        y + '-' + m + '-' + d + '　' + h + ':' + minute + ':' + second
            return timeStr
        },
        getFeedbacks () {
            const my_id = getUserId()
            getFeedbacks(my_id).then(res => {
                console.log(res)
                if (res.data.code === 0) {
                    this.checkTaskList = res.data.data
                }
            })
        }
    }
}
</script>

<style>

</style>
