<template>
  <div>
    <div class="title">{{ regulation.title }}</div>
    <div class="title" v-if="regulation.title_en">
      {{ regulation.title_en }}
    </div>
    <div class="content" style="text-align: center">
      版本号{{ regulation.version }}
    </div>
    <div
      style="
        width: 80%;
        margin: 0 auto;
        line-height: 20px;
        background: #909399;
        color: white;
        padding: 4px 8px;
        border-radius: 6px;
        font-size: 14px;
      "
      v-if="regulation.update_note && regulation.update_note !== '无'"
    >
      版本更新内容：{{ regulation.update_note }}
    </div>
    <div
      style="
        width: 80%;
        margin: 0 auto;
        line-height: 20px;
        background: #909399;
        color: white;
        padding: 4px 8px;
        border-radius: 6px;
        font-size: 14px;
        margin-top: 10px;
      "
      v-if="
        regulation.update_note &&
        regulation.update_note !== '无' &&
        regulation.update_note_en
      "
    >
      {{ regulation.update_note_en }}
    </div>
    <pre class="content">{{ regulation.content }}</pre>
    <pre class="content" v-if="regulation.content_en">{{
      regulation.content_en
    }}</pre>
    <div class="version">
      <el-button
        v-if="!regulation.agree"
        type="primary"
        style="margin-left: 20px"
        @click="checkRegulation"
        >我已知晓并同意</el-button
      >
      <el-button
        v-if="!regulation.agree"
        type="danger"
        style="margin-left: 20px"
        @click="checkRegulationDisagree"
        >我已知晓但不同意</el-button
      >
      <span v-if="regulation.agree" style="margin-left: 20px"
        >已知晓并同意</span
      >
    </div>
    <el-dialog :visible.sync="showInput" title="请输入不同意的理由">
      <el-input
        type="textarea"
        v-model="disagreeStr"
        :rows="6"
        style="width: 100%"
      ></el-input>
      <el-button style="margin-top: 20px" @click="showInput = false"
        >取 消</el-button
      >
      <el-button type="primary" @click="handleSubmit">确 定</el-button>
    </el-dialog>
  </div>
</template>

<script>
import {
  getRegulation,
  checkRegulation,
  checkRegulationDisagree,
} from "../../api/assistant";
import { getUserId } from "../../utils/store";
export default {
  data() {
    return {
      id: this.$route.query.id,
      showInput: false,
      disagreeStr: "",
      check_id: "",
      regulation: {
        title: "",
        content: "",
        version: "",
        check_status: false,
      },
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      getRegulation(this.id, getUserId()).then((res) => {
        if (res.data.code === 0) {
          this.regulation = res.data.data;
          this.check_id = res.data.data.check_id;
        }
      });
    },
    checkRegulation() {
      checkRegulation(
        this.id,
        this.regulation.version,
        getUserId(),
        "",
        this.check_id,
        true
      ).then((res) => {
        if (res.data.code === 0) {
          this.fetchData();
        }
      });
    },
    checkRegulationDisagree() {
      this.showInput = true;
    },
    handleSubmit() {
      console.log(111, this.disagreeStr);
      if (this.disagreeStr.length < 5) {
        this.$message.warning("字数不够");
      } else {
        checkRegulation(
          this.id,
          this.regulation.version,
          getUserId(),
          this.disagreeStr,
          this.check_id,
          false
        ).then((res) => {
          if (res.data.code === 0) {
            this.fetchData();
          }
          this.showInput = false;
          this.$message.success("提交成功");
        });
      }
    },
  },
};
</script>

<style scoped>
.title {
  text-align: center;
  font-size: 28px;
  font-weight: bold;
  color: #777777;
  font-family: Microsoft YaHei;
}
.content {
  width: 80%;
  margin: 20px auto;
  color: #777777;
  font-family: Microsoft YaHei;
  line-height: 22px;
  white-space: pre-wrap; /* css-3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word;
}
.version {
  text-align: center;
  color: #777777;
  margin-top: 40px;
  font-family: Microsoft YaHei;
  line-height: 22px;
}
</style>