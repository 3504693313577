<template>
    <div style="color:#606266">
        <div style="margin-top:20px">
            <span style="margin-left:20px">入职日期：</span>
            <span style="font-size:24px;font-weight:bold">{{user.work_start_time?formateDate(user.work_start_time):'未设置'}}</span>
            <span style="margin-left:50px">入职起累积工龄：</span>
            <span style="font-size:24px;font-weight:bold">{{calcDay(user.work_start_time)}}天</span>
        </div>
        <div style="margin-left:20px;margin-top:20px">
            您本月已申请：
            <span v-for="(item,key) in types" :key="key" style="margin-right:20px;font-size:17px">{{key}}{{Math.floor(item.hour_len/8)}}天<span v-if="item.hour_len%8">{{item.hour_len%8}}小时</span></span>
            <span v-if="types.length===0">暂未申请</span>
        </div>
        <div class="top-holiday">
            <div class="top-holiday-item">
                调休假
                <span v-if="user.lieu_holiday" class="holiday-item-txt">
                    {{types['调休']?user.lieu_holiday-types['调休']['hour_len']/8:user.lieu_holiday}}
                </span>
                <span v-else class="holiday-item-txt">0</span>
                天
            </div>
            <div class="top-holiday-item">
                年假
                <span v-if="user.year_holiday" class="holiday-item-txt">
                    {{types['年假']?user.year_holiday-types['年假']['hour_len']/8:user.year_holiday}}
                </span>
                <span v-else class="holiday-item-txt">0</span>
                天
            </div>
            <div class="top-holiday-item">
                特殊病假
                <span v-if="user.special_holiday" class="holiday-item-txt">
                    {{types['特殊病假']?user.special_holiday-types['特殊病假']['hour_len']/8:user.special_holiday}}
                </span>
                <span v-else class="holiday-item-txt">0</span>
                天
            </div>
        </div>
        <el-select v-model="select_status" @change="getMyHolidayAppliesByStatus" style="margin-top:40px">
            <el-option value="all" label="全部"></el-option>
            <el-option value="pending" label="审核中"></el-option>
            <el-option value="approve" label="通过"></el-option>
            <el-option value="reject" label="不通过"></el-option>
            <el-option value="cancel" label="撤销"></el-option>
        </el-select>
        <el-button @click="add" type="primary" style="margin-left:20px">新的申请</el-button>
        <el-table :data="applies" style="margin-top:40px">
            <el-table-column label="类型" prop="type" width="100"></el-table-column>
            <el-table-column label="请假时间" width="210">
                <template slot-scope="scope">
                    {{`${formateTime(scope.row.time[0])} - ${formateTime(scope.row.time[1])}`}}
                </template>
            </el-table-column>
            <el-table-column label="请假时长（天）" width="140">
                <template slot-scope="scope">
                    {{scope.row.hour_len/8}}
                </template>
            </el-table-column>
            <el-table-column label="请假时长（小时）" width="140" prop="hour_len"></el-table-column>
            <el-table-column label="请假原因" prop="details"></el-table-column>
            <el-table-column label="补充图片" width="400">
                <template slot-scope="scope">
                    <div style="display:flex">
                        <img v-for="(item,index) in scope.row.pic_arr" :key="index" :src="item"  style="width:100px;margin-left:10px"/>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="审批状态" width="200">
                <template slot-scope="scope">
                    <ul style="padding-inline-start:0px;">
                        <li style="margin-top:6px;display:flex;align-items:center" slot="reference" v-for="(item,index) in scope.row.check_status" :key="index">
                            <div>{{item.checker_name}}：</div>
                            <el-popover
                                v-if="item.check_note"
                                placement="top-start"
                                width="200"
                                trigger="hover"
                                :content="item.check_note">
                                <div slot="reference">
                                    <el-tag :type="statusMap[item.check_status].type">{{statusMap[item.check_status].name}}</el-tag>
                                </div>
                            </el-popover>
                            <div v-else>
                                <el-tag :type="statusMap[item.check_status].type">{{statusMap[item.check_status].name}}</el-tag>
                            </div>
                        </li>
                    </ul>
                </template>
            </el-table-column>
            <el-table-column label="审核状态" width="120">
                <template slot-scope="scope">
                    <el-tag :type="statusMap[scope.row.status].type">{{statusMap[scope.row.status].name}}</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="100">
                <template slot-scope="scope">
                    <el-button type="primary" size="small" @click="cancelHolidayApply(scope.row._id.$id)">撤销</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import { getMyHolidayAppliesByStatus,cancelHolidayApply,getUser,checkUserAppliedHolidays } from '../../api/assistant'
import { getUserId } from '../../utils/store'
export default {
    data(){
        return{
            select_status:'all',
            applies:[],
            showApply:false,
            apply_id:'',
            form:{
                type:'',
                details:'',
                time_len:0,
                part_day_status:false,
                part_day_hour:0
            },
            pic_arr:[],
            time:[],
            check_users:[],
            user:{},
            statusMap:{
                pending:{name:'审核中',type:'warning'},
                approve:{name:'通过',type:'success'},
                reject:{name:'不通过',type:'danger'},
                cancel:{name:'已撤销',type:'info'}
            },
            types:[]
        }
    },
    mounted(){
        this.$nextTick(()=>{
            this.getUser()
            this.getMyHolidayAppliesByStatus()
            this.checkUserAppliedHolidays()
        })
    },
    methods:{
        getMyHolidayAppliesByStatus(){
            getMyHolidayAppliesByStatus(getUserId(),this.select_status).then(res=>{
                if(res.data.code===0){
                    this.applies = res.data.data
                }
            })
        },
        checkUserAppliedHolidays(){
            checkUserAppliedHolidays(getUserId()).then(res=>{
                console.log(res)
                this.types = res.data
            })
        },
        getUser(){
            getUser(getUserId()).then(res=>{
                this.user = res.data
                this.check_users = res.data.supervisers
            })
        },
        add(){
            this.$router.push('newapply')
        },
        cancelHolidayApply(id){
            this.$confirm('是否确认撤销？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                cancelHolidayApply(id).then(res=>{
                    if(res.data.code===0){
                        this.$message({
                            message:'撤销成功'
                        })
                        location.reload()
                    }
                })
            })
        },
        formateDate(date){
            let time = new Date(date*1000)
            return `${time.getMonth()+1}月${time.getDate()}日`;
        },
        formateTime(date){
            let time = new Date(date*1000)
            return `${time.getMonth()+1}月${time.getDate()}日 ${time.getHours()}:${time.getMinutes()<10?'0'+time.getMinutes():time.getMinutes()}`;
        },
        calcDay(date){
            let today =  new Date().setHours(0, 0, 0, 0)
            return (today-date*1000)/86400000;
        }
    }
}
</script>

<style>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}
.avatar {
    width: 178px;
    height: 178px;
    display: block;
    cursor: pointer;
}
.top-holiday{
    display: flex;
    justify-content: space-around;
    height: 160px;
    margin-top: 30px;
}
.top-holiday-item{
    width: 31%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #DCDFE6;
    border-radius: 20px;
}
.holiday-item-txt{
    font-size:30px;
    font-weight:bold;
    margin-left:20px;
    margin-right:20px;
}
li {
    /* list-style-type:none; */
}
</style>