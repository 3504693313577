<template>
  <div class="examE">
    <div v-if="questionList">
      <div
        v-for="(item, index) in questionList"
        :key="item._id.$id"
        style="margin-bottom:50px"
      >
        <div class="titleE">
          <div class="questionTitleE">{{ index + 1 }}.</div>
          <div v-html="item.question_question"></div>
        </div>
        <div class="optionE" v-if="item.question_optionA_string">
          <el-radio
            @change="choose('A', item._id.$id)"
            v-model="questionList[index]['select']"
            label="A"
          >
            <span class="optionTitleE">A</span>
            <span class="div_p" v-html="item.question_optionA"></span
          ></el-radio>
        </div>
        <div class="optionE" v-if="item.question_optionB_string">
          <el-radio
            @change="choose('B', item._id.$id)"
            v-model="questionList[index]['select']"
            label="B"
          >
            <span class="optionTitleE">B</span>
            <span class="div_p" v-html="item.question_optionB"></span
          ></el-radio>
        </div>
        <div class="optionE" v-if="item.question_optionC_string">
          <el-radio
            @change="choose('C', item._id.$id)"
            v-model="questionList[index]['select']"
            label="C"
          >
            <span class="optionTitleE">C</span>
            <span class="div_p" v-html="item.question_optionC"></span
          ></el-radio>
        </div>
        <div class="optionE" v-if="item.question_optionD_string">
          <el-radio
            @choose="choose('D', item._id.$id)"
            v-model="questionList[index]['select']"
            label="D"
          >
            <span class="optionTitleE">D</span>
            <span class="div_p" v-html="item.question_optionD"></span
          ></el-radio>
        </div>
        <div class="optionE" v-if="item.question_optionE_string">
          <el-radio
            @change="choose('E', item._id.$id)"
            v-model="questionList[index]['select']"
            label="E"
          >
            <span class="optionTitleE">E</span>
            <span class="div_p" v-html="item.question_optionE"></span>
          </el-radio>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "exam",
  props: ["questions"],
  data() {
    return {
      questionList: this.questions
    };
  },
  methods: {
    choose(choice, id) {
      this.$emit("submitAnswer", { choice: choice, id: id });
    }
  }
};
</script>

<style>
.examE {
  margin: 0 auto;
}
.optionE {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  border-radius: 10px;
  font-weight: bold;
}
.optionTitleE {
  font-size: 18px;
  margin-right: 10px;
}
.questionTitleE{
  margin-top:20px
}
.btn-groupE {
  margin-top: 20px;
}
.borderE {
  color: #0085ff;
  font-weight: bold;
}
.titleE {
  display: flex;
  color: #0085ff;
  font-size: 20px;
  font-weight: bold;
  line-height: 32px;
  margin-top:20px
}

.examE img {
  width: 100px;
}
.el-buttonE {
  /* width: 80px; */
  height: 40px;
}
.el-buttonE > p {
  margin-top: 1px;
}
.el-radio__label {
  /* display: none; */
}
.el-radio {
  color: #333333;
  cursor: pointer;
  margin-right: 10px;
}
.el-radio__inner {
  border: 1px solid #ccc;
}
.div_p {
  font-size: 18px;
  display: inline-block;
}
</style>
