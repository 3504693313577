<template>
  <div>
    <div>员工OKR列表</div>

    <el-table :data="userList" style="margin-top:30px" v-loading="loading">
      <el-table-column label="编号" width="120px">
        <template slot-scope="scope">
          {{scope.$index+1}}
        </template>
      </el-table-column>
      <el-table-column prop="user_lastName" label="姓" width="120px"></el-table-column>
      <el-table-column prop="user_givenName" label="名" width="140px"></el-table-column>
      <el-table-column label="OKR内容">
        <template slot-scope="scope">
          {{scope.row.okr_content}}
        </template>
      </el-table-column>
     
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            @click="view(scope.row._id.$id)"
            type="primary"
            size="small"
            >查看更多</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { getUserOKRList} from "../../api/assistant";
export default {
  data() {
    return {
      userList: [],
      userInfo: {},
      loading:false
    };
  },
  mounted() {
      this.fetchData();
  },
  methods: {
    fetchData() {
      this.loading = true
      getUserOKRList().then(res => {
        if (res.data) {
          this.userList = res.data;
          this.loading = false
        }
      });
    },
    view(id) {
      this.$router.push({
        path: "userokrupdate",
        query: {
          id: id
        }
      });
    },
    formateTime(date){
      let time = new Date(date*1000);
      return `${time.getFullYear()}-${time.getMonth()+1}-${time.getDate()}`;
    },
    formateDay(date){
      let time = new Date(date*1000);
      return `${time.getDate()}`;
    },
    formateMonth(date){
      let time = new Date(date*1000);
      return `${time.getMonth()+1}`;
    },
    formateYear(date){
      let time = new Date(date*1000);
      return `${time.getFullYear()}`;
    },
  }
};
</script>

<style></style>
