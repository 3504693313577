<template>
    <div>
        <el-table :data="regulations">
            <el-table-column prop="title" label="标题"></el-table-column>
            <!-- <el-table-column prop="content" label="内容"></el-table-column> -->
            <el-table-column prop="version" label="版本"></el-table-column>
            <el-table-column label="是否知晓">
                <template slot-scope="scope">
                    <span v-if="scope.row.check_status">已知晓并同意</span>
                    <span v-else style="color:#F56C6C;font-weight:bold">尚未知晓</span>
                </template>
            </el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button @click="view(scope.row._id.$id)" size="medium" type="primary" v-if="scope.row.check_status">查看</el-button>
                    <el-button @click="view(scope.row._id.$id)" size="medium" type="danger" v-else>去确认</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import {getRegulationsUser} from '../../api/assistant'
import {getUserId} from '../../utils/store'
export default {
    data(){
        return{
            regulations:[],
        }
    },
    mounted(){
        this.fetchData()
    },
    methods:{
        fetchData(){
            getRegulationsUser(getUserId()).then(res=>{
                if(res.data.code===0){
                    this.regulations = res.data.data
                }
            })
        },
        view(id){
            this.$router.push({
                path:'regulation',
                query:{
                    id:id
                }
            })
        }
    }
}
</script>

<style>

</style>