<template>
  <div style="height: 100vh;padding: 10px;box-sizing: border-box;">
    <div style="display: flex;width: 100%;">
      <el-select v-model="type" @change="refreshData" clearable placeholder="请选择类型">
        <el-option v-for="item in optionList" :key="item" :value="item"></el-option>
      </el-select>
      <vue-tags-input
              v-model="tag"
              :tags="optionList"
              @tags-changed="changeTag"
      />
    </div>
    <div style="display: flex; margin-top: 10px;">
      <el-input
        v-model="isbn"
        placeholder="请输入isbn书号"
        clearable
        ref="myInput"
        @input="judge"
      ></el-input>
      <el-button @click="search" type="primary" style="margin-left: 10px"
        >搜索</el-button
      >
      <el-button @click="add" type="primary" style="margin-left: 10px"
        >新增</el-button
      >
    </div>
    <div style="height: calc(100% - 100px);overflow-y: scroll;margin-top: 10px;">
      <el-table :data="tableData">
        <el-table-column label="类型">
          <template slot-scope="scope">
            <el-select v-model="scope.row.type" @change="(e)=>changeType(e,scope.row)">
              <el-option v-for="item in optionList" :key="item" :value="item"></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="标题" prop="title"></el-table-column>
        <el-table-column label="作者" prop="author"></el-table-column>
        <el-table-column label="出版社" prop="publisher"></el-table-column>
        <el-table-column label="出版日期" prop="pubDate"></el-table-column>
        <el-table-column label="isbn" prop="isbn"></el-table-column>
        <el-table-column label="价格" prop="price"></el-table-column>
        <el-table-column label="页数" prop="page"></el-table-column>
        <el-table-column label="图片">
          <template slot-scope="scope">
            <img :src="scope.row.img" style="width: 100px" />
          </template>
        </el-table-column>
        <el-table-column label="小图" prop="smallImg">
          <template slot-scope="scope">
            <img :src="scope.row.smallImg" style="width: 100px" />
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="primary" @click="edit(scope.row)"
              >编辑</el-button
            >
            <el-button type="danger" @click="remove(scope.row._id.$id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog :visible.sync="show" title="资料信息" :close-on-click-modal="false">
      <el-form :model="form" label-width="80px">
        <el-form-item label="标题">
          <el-input v-model="form.title"></el-input>
        </el-form-item>
        <el-form-item label="作者">
          <el-input v-model="form.author"></el-input>
        </el-form-item>
        <el-form-item label="出版社">
          <el-input v-model="form.publisher"></el-input>
        </el-form-item>
        <el-form-item label="出版日期">
          <el-input v-model="form.pubDate"></el-input>
        </el-form-item>
        <el-form-item label="isbn">
          <el-input v-model="form.isbn"></el-input>
        </el-form-item>
        <el-form-item label="价格">
          <el-input v-model="form.price"></el-input>
        </el-form-item>
        <el-form-item label="页数">
          <el-input v-model="form.page"></el-input>
        </el-form-item>
        <el-form-item label="图片">
          <el-input v-model="form.img"></el-input>
        </el-form-item>
        <el-form-item label="小图">
          <el-input v-model="form.smallImg"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="show = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  removeZiliao,
  addZiliao,
  getZiliaoList,
  searchISBN,
  updateZiliao
} from "../api/assistant";
import VueTagsInput from '@johmun/vue-tags-input'
export default {
  components:{
    VueTagsInput
  },
  data() {
    return {
      tableData: [],
      tableDataAll: [],
      isbn: "",
      form: {},
      show: false,
      optionList:['未分类','音乐','设计','心理','商业','经济','技术','历史','社会','漫画','建筑','文学','其他'],
      type:'',
      tag:''
    };
  },
  mounted() {
    this.fetchData();
    this.$nextTick(()=>{
      this.$refs.myInput.focus()
    })
  },
  methods: {
    changeTag(e){
      console.log(111111,e)
      this.optionList = e.map(item=>item.text)
    },
    refreshData(){
      if(this.type && this.type!=='未分类'){
        this.tableData = this.tableDataAll.filter(item=>item.type===this.type)
      }else if(this.type==='未分类' ){
        this.tableData = this.tableDataAll.filter(item=>!item.type)
      }else{ 
        this.tableData = this.tableDataAll
      }
    },
    judge(e){
      if(e.length===13){
        this.search()
      }
    },
    add(){
      this.form = {
        id:'',
        title:'',
        author:'',
        publisher:'',
        pubDate:'',
        isbn:'',
        price:'',
        page:'',
        img:'',
        smallImg:'',
      }
      this.show = true
    },
    confirm() {
      addZiliao({
        ...this.form,
      }).then((res) => {
        if (res.data.code === 0) {
          this.$message({
            type: "success",
            message: "添加成功!",
          });
          this.show = false;
          this.fetchData();
        }else{
          this.$message.error(res.data.msg)
        }
      });
    },
    fetchData() {
      getZiliaoList().then((res) => {
        if (res.data.code === 0) {
          this.tableDataAll = res.data.data;
          this.refreshData()
        }
      });
    },
    search() {
      searchISBN({
        isbn: this.isbn,
      }).then((res) => {
        if (res.data.code === 0) {
          let result = res.data.data;
          let index = result.indexOf("{");
          let data = JSON.parse(result.slice(index));
          if (data.code === 200) {
            this.form = {
              title:data.data.details[0].title,
              author:data.data.details[0].author,
              publisher:data.data.details[0].publisher,
              pubDate:data.data.details[0].pubDate,
              isbn:data.data.details[0].isbn,
              price:data.data.details[0].price,
              page:data.data.details[0].page,
              img:data.data.details[0].img,
              smallImg:data.data.details[0].smallImg,
            }
            this.confirm()
          
          } else {
            this.$message.error(data.msg);
          }
        }
      }).finally(()=>{
        this.isbn = ''
      });
    },
    edit(row){
      this.form = {
        id:row._id.$id,
        title:row.title,
        author:row.author,
        publisher:row.publisher,
        pubDate:row.pubDate,
        isbn:row.isbn,
        price:row.price,
        page:row.page,
        img:row.img,
        smallImg:row.smallImg,
      }
      this.show = true
    },
    changeType(e,row){
      let form = {
        id:row._id.$id,
        title:row.title,
        author:row.author,
        publisher:row.publisher,
        pubDate:row.pubDate,
        isbn:row.isbn,
        price:row.price,
        page:row.page,
        img:row.img,
        smallImg:row.smallImg,
        type:e
      }
      updateZiliao(form).then(res=>{
        if(res.data.code===0){
          this.$message({
            type: "success",
            message: "修改成功!",
          });
        }
      })
    },
    submit(){
      updateZiliao(this.form).then(res=>{
        if(res.data.code===0){
          this.$message({
            type: "success",
            message: "修改成功!",
          });
          this.show = false;
          this.fetchData();
        }
      })
    },
    remove(id) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          removeZiliao({
            id,
          }).then((res) => {
            if (res.data.code === 0) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.fetchData();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>

<style lang="scss">
.vue-tags-input{
  flex: 1!important;
  margin-left: 10px;
  max-width: none!important;
  .ti-input{
    width: 100%!important;
  }
}
</style>