<template>
  <div class="container">
    <div style="margin-bottom: 20px">
      今日已有成果
      <span style="font-size: 20px; font-weight: bold">{{ num }}</span
      >元
      <el-button
        @click="viewResult"
        type="primary"
        size="medium"
        style="margin-left: 10px"
        >查看所有成果</el-button
      >
    </div>
    <el-input
      v-model="form.content"
      type="textarea"
      :rows="5"
      placeholder="请输入成果描述"
    ></el-input>
    <div style="margin-top: 20px; margin-bottom: 20px">
      <el-select v-model="form.type" placeholder="请选择类型">
        <el-option
          v-for="item in typeList"
          :value="item"
          :key="item"
        ></el-option>
      </el-select>
      <el-button type="primary" @click="editType" style="margin-left: 10px"
        >编辑类型</el-button
      >
    </div>
    <div style="margin-top: 20px; margin-bottom: 20px">
      <el-select v-model="form.num" placeholder="请选择金额">
        <el-option :value="50" label="50元"></el-option>
        <el-option :value="100" label="100元"></el-option>
        <el-option :value="200" label="200元"></el-option>
        <el-option :value="500" label="500元"></el-option>
        <el-option :value="1000" label="1000元"></el-option>
        <el-option :value="2000" label="2000元"></el-option>
      </el-select>
    </div>

    <el-button @click="confirm" type="primary">提交</el-button>
    <el-dialog :visible.sync="show">
      <el-table :data="list" border height="500px">
        <el-table-column prop="content" label="成果描述"></el-table-column>
        <el-table-column prop="num" label="成果金额"></el-table-column>
        <el-table-column label="提交时间">
          <template slot-scope="scope">
            {{
              $dayjs(scope.row.create_time * 1000).format("YYYY-MM-DD HH:mm")
            }}
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <el-dialog :visible.sync="show1">
      <vue-tags-input
        v-model="tag"
        :tags="optionList"
        @tags-changed="changeTag"
      />
      <div style="margin-top: 20px">
        <el-button @click="submit" type="primary">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import VueTagsInput from "@johmun/vue-tags-input";
import {
  addResult,
  getMyTodayResult,
  getAllMyResult,
  getResultType,
  updateResultType,
} from "../api/assistant";
import { getUserId } from "../utils/store";
export default {
  components: {
    VueTagsInput,
  },
  data() {
    return {
      form: {
        content: "",
        num: null,
        type: "",
      },
      list: [],
      num: 0,
      show: false,
      typeList: [],
      show1: false,
      optionList: [],
      tag: "",
    };
  },
  mounted() {
    this.getMyTodayResult();
    this.getAllMyResult();
    this.getResultType();
  },
  methods: {
    async getResultType() {
      const res = await getResultType();
      if (res.data.code === 0) {
        this.typeList = res.data.data.type;
      }
    },
    editType() {
      this.show1 = true;
      this.optionList = this.typeList.map((item) => {
        return {
          text: item,
        };
      });
    },
    submit() {
      updateResultType({
        type: this.optionList.map((item) => item.text),
      }).then((res) => {
        if (res.data.code === 0) {
          this.show1 = false;
          this.$message.success("编辑成功");
          this.getResultType();
        }
      });
    },
    changeTag(e) {
      this.optionList = e;
    },
    async getMyTodayResult() {
      const res = await getMyTodayResult({
        user_id: getUserId(),
      });
      if (res.data.code === 0) {
        this.num = res.data.data;
      }
    },
    async getAllMyResult() {
      const res = await getAllMyResult({
        user_id: getUserId(),
      });
      if (res.data.code === 0) {
        this.list = res.data.data;
      }
    },
    confirm() {
      if (!this.form.content) {
        this.$message.error("请输入成果描述");
        return;
      }
      if (!this.form.num) {
        this.$message.error("请选择成果金额");
        return;
      }
      if (!this.form.type) {
        this.$message.error("请选择成果类型");
        return;
      }
      addResult({
        user_id: getUserId(),
        content: this.form.content,
        num: this.form.num,
        type: this.form.type,
      }).then((res) => {
        if (res.data.code === 0) {
          this.form.content = "";
          this.form.num = null;
          this.getMyTodayResult();
          this.getAllMyResult();
          this.$message.success("提交成功");
        }
      });
    },
    viewResult() {
      this.show = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
}
</style>