import {getQiniuToken, saveQuestionVideoAddress} from "../api/assistant";
import * as qiniu from 'qiniu-js'

export default class UploadHelper {

    callback = null;

    addCallback(callback) {
        this.callback = callback;
    }

    async startUploadForQuestion(question_id, file) {
        const result = await this.requestQiniuToken()
        if (result.data.code === 0) {
            var name = file.name
            const token = result.data.data
            let observable = qiniu.upload(
                file,
                new Date().getTime() + name,
                token
            )
            var that = this
            const observer = {
                error(err) {
                    console.log('error :' + err)
                },
                complete: (res) => {
                    let address = 'http://mainbucket.reachable-edu.com/' + res.key

                    this.callback(address)

                    // that.updateQuestion(question_id, address, name)
                }
            }
            observable.subscribe(observer)
        }
    }

    updateQuestion(question_id, file_address, file_name) {
        saveQuestionVideoAddress(question_id, file_address, file_name).then((res) => {
            let result = res.data.data
            if (this.callback != null) {
                console.log('call back to view ')
                this.callback(result)
            } else {
                console.log('call back is null')
            }
        })
    }

    async requestQiniuToken() {
        return new Promise(function (resolve, reject) {
            getQiniuToken().then((res) => {
                resolve(res)
            })
        })
    }
}
