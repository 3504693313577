<template>
    <div style="padding: 20px;box-sizing: border-box;width: 100%;">
        <el-button type="primary" size="small" @click="editSing(null)">Add Email Sign</el-button>
        <el-table :data="dataList" style="margin-top: 20px;width: 100%;">
            <el-table-column prop="index" type="index" label="Index" width="100px"></el-table-column>
            <el-table-column prop="email" label="Email"></el-table-column>
            <el-table-column prop="" label="Templates">
                <template slot-scope="scope">
                    <div v-for="(template, index) in scope.row.templates" :key="index" style="display: flex">
                        <p style="margin-right: 10px;">Template {{ (index + 1) }}:</p>
                        <p v-html="template" style="flex: 1;"></p>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="" label="Operation">
                <template slot-scope="scope">
                    <el-button type="success" size="small" @click="editSing(scope.row)">Edit</el-button>
                    <el-button type="danger" size="small" @click="doRemoveSign(scope.row)">Delete</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>
<script>
import {
    getEmailSigns,
    removeEmailSign
} from '../../api/assistant'
export default ({
    name: 'index',
    data() {
        return {
            dataList: [],
        }
    },
    mounted() {
        this.fetchData()
    },
    methods: {
        fetchData() {
            getEmailSigns().then((res) => {
                this.dataList = res.data
            })
        },
        editSing(row) {
            let id = row ? row._id.$id : ''
            this.$router.push({
                path: '/email-sign',
                query: {
                    id: id
                }
            })
        },
        doRemoveSign(id) {
            this.$alert('Are you sure remove this mail sign?', 'Tips', {
                showCancelButton: false,
                callback: action => {
                    if (action == 'confirm') {
                        removeEmailSign(id).then((res) => {
                            this.$messae.success('Remove success~')
                            this.$emit('handleEmailSignChanged')
                            this.fetchData()
                        })
                    }
                }
            })
        }
    }
})
</script>

<style scoped lang="scss">
::-webkit-scrollbar {
    display: none;
}
</style>