<template>
    <div class="adminContainer">
      <div class="leftSide">
        <el-scrollbar style="height:100vh;overflow: hidden;">
          <img class="titleLogo" src="../assets/loginTitle.png">
          <el-aside width="220px">
            <el-menu :default-active="$route.path" :router="true">
              <template v-if="block==='userAdmin'">
                <el-menu-item index="/adminmanage/usermanage">员工信息管理</el-menu-item>
                <el-menu-item index="/adminmanage/workLogTemplate">工作日志模板管理</el-menu-item>
                <el-menu-item index="/adminmanage/workLogTable">排班管理</el-menu-item>
                <el-menu-item index="/adminmanage/performanceStatistic">绩效统计</el-menu-item>
                <el-menu-item index="/adminmanage/monthLogTable">月度日志</el-menu-item>
                <el-menu-item index="/adminmanage/standardList">工作标准</el-menu-item>
                <el-menu-item index="/adminmanage/bank">公司管理</el-menu-item>
                <el-menu-item index="/adminmanage/quarterschedule">季度总结</el-menu-item>
                <el-menu-item index="/adminmanage/regulations">规章制度</el-menu-item>
                <el-menu-item index="/adminmanage/todoStandardList">任务标准</el-menu-item>
                <el-menu-item index="/adminmanage/todoTypeList">任务类型</el-menu-item>
                <el-menu-item index="/adminmanage/correctionSampleList">语病纠正</el-menu-item>
                <el-menu-item index="/adminmanage/scoreCompelete">语病纠正作业评分</el-menu-item>
                <el-menu-item index="/adminmanage/brandList">品牌列表</el-menu-item>
                <el-menu-item index="/adminmanage/specialWorkDayList">特殊工作日</el-menu-item>
                <el-menu-item index="/adminmanage/tipList">工作建议库</el-menu-item>
                <el-menu-item index="/adminmanage/userokr">员工OKR</el-menu-item>
                <el-menu-item index="/adminmanage/setBUs">员工所属BU</el-menu-item>
              </template>
              <template v-if="block==='smartAdmin'">
                <el-menu-item index="/adminmanage/classlist">课程列表</el-menu-item>
                <el-menu-item index="/adminmanage/workShopList">WorkShop列表</el-menu-item>
                <el-menu-item index="/adminmanage/workShopTemplate">WorkShop模板列表</el-menu-item>
              </template>
              <template v-if="block==='trackfeedbacknumAdmin'">
                <el-menu-item index="/adminmanage/trackfeedbacknum">追踪反馈数量</el-menu-item>
              </template>
              <template v-if="block==='examAdmin'">
                <el-menu-item index="/exam">试卷查看</el-menu-item>
                <el-menu-item index="/template">试卷设置</el-menu-item>
                <el-menu-item index="/question">题目库</el-menu-item>
              </template>
              <template v-if="block==='holiday'">
                <!-- <el-menu-item index="1" :route="{path:'/adminmanage/checkholiday'}">假期审批</el-menu-item> -->
                <el-menu-item index="/adminmanage/holidaysetting">余额设置</el-menu-item>
              </template>
              <template v-if="block==='recorder'">
                <!-- <el-menu-item index="1" :route="{path:'/adminmanage/checkholiday'}">假期审批</el-menu-item> -->
                <el-menu-item index="/adminmanage/recorder">录音测试</el-menu-item>
              </template>
            </el-menu>
          </el-aside>
        </el-scrollbar>

      </div>

      <div class="rightSide">
        <div class="header">
          <div class="goBack" @click="$router.push('/admin')">
                  <i class="el-icon-arrow-left" style="font-size:120%;"></i><span style="font-size:100%;margin-left:4px">返回首页</span>
                </div>
          <div class="rightPart">
            <span>{{userName}}</span>
            <img src="../assets/exit.svg" style="margin-left:20px;cursor: pointer;" @click="logout">
          </div>
        </div>
        <div class="tagView">
          {{$route.meta.bread}}
        </div>
        <div class="myMain">
          <router-view></router-view>
        </div>
      </div>
    </div>
</template>

<script>
import {
  getUserId,
  removeUserId,
  removeUserName,
  getUserName,
  getBlock,
  removeBlock
} from '../utils/store'
export default {
  data () {
    return {
      userId: '',
      userName: '',
      block: ''
    }
  },
  mounted () {
    this.block = getBlock()
    this.userId = getUserId()
    this.userName = getUserName()
  },
  methods: {
    logout () {
      removeUserId()
      removeUserName()
      removeBlock()
      location.reload()
    }

  }
}
</script>

<style lang="scss" scoped>
.adminContainer{
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  .leftSide{
    width: 220px;
    flex-shrink: 0;
    height: 100vh;
    background: #fff;
    position: absolute;
    overflow: hidden;
    left: 0;
    z-index: 3;
    box-shadow: 2px 0px 6px 1px rgba(0,21,41,0.1200);
    .titleLogo{
      width: 120px;
      display: block;
      margin: 16px auto;
    }
    .el-menu-item.is-active{
      background: #E5EFFF;
      border-right: 4px solid #4A8AF4;
    }
  }
  .rightSide{
    flex: 1;
    height: 100%;
    overflow: hidden;
    position: relative;
    margin-left: 220px;
    .header{
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
      height: 50px;
      width: 100%;
      background: #fff;
      position: absolute;
      top: 0;
      box-shadow: 0px 3px 6px 1px rgba(0,21,41,0.1200);
      z-index: 2;
      .rightPart{
        display: flex;
        align-items: center;
        margin-right: 30px;
        font-family: Alibaba PuHuiTi 2.0-65 Medium, Alibaba PuHuiTi 20;
        font-weight: normal;
        color: #999999;
        font-size: 16px;
      }
      .goBack{
        font-size: 16px;
        margin-left: 10px;
        cursor: pointer;
      }
    }
    .tagView{
      margin-top: 50px;
      background: #fff;
      height: 50px;
      line-height: 50px;
      padding-left: 30px;
    }
    .myMain{
      height: calc(100vh - 120px);
      padding: 16px;
      overflow-y: scroll;
    }
  }
}

</style>
