<template>
    <div>
        <el-button @click="addBrand" type="primary" style="margin-left:20px">添加品牌</el-button>
        <el-table :data="brands" v-loading="loading">
            <el-table-column prop="group" label="事业部" width="100px"></el-table-column>
            <el-table-column prop="type" label="类型" width="100px"></el-table-column>
            <el-table-column prop="brand" label="品牌" width="100px"></el-table-column>

            <el-table-column prop="full_name" label="完整名字" width="100px"></el-table-column>

            <el-table-column prop="brand" label="品牌" width="200px">

              <template slot-scope="scope">

                <img :src="scope.row.logo " style="width:100px">
              </template>
            </el-table-column>

            <el-table-column label="品牌指南文件">
                <template slot-scope="scope">
                    <el-link type="primary" :href="'http://mainbucket.reachable-edu.com/'+scope.row.attach_key" target="_blank">{{scope.row.attach_name?scope.row.attach_name:scope.row.attach_key}}</el-link>
                    <!-- <a type="primary" v-if="scope.row.attach_key" download :href="'http://mainbucket.reachable-edu.com/'+scope.row.attach_key" style="margin-left:10px">下载</a> -->
                </template>
            </el-table-column>
            <el-table-column label="品牌策略">
              <template slot-scope="scope">
                {{scope.row.policy_list.length?scope.row.policy_list[0].content:''}}
              </template>
            </el-table-column>
            <el-table-column label="详细" width="200">
                <template slot-scope="scope">
                    <el-button type="primary" size="small" @click="view(scope.row)">编辑</el-button>
                    <el-button type="primary" size="small" @click="viewpolicy(scope.row)">品牌策略</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-dialog :visible.sync="showEdit" title="添加品牌" width="80%">
            <el-form label-width="200px">
                <el-form-item label="事业部">
                    <el-select v-model="form.group">
                        <el-option v-for="item in groups" :label="item" :value="item" :key="item"></el-option>

                    </el-select>
                </el-form-item>
                <el-form-item label="品牌">
                    <el-input v-model="form.brand" style="width:80%"></el-input>
                </el-form-item>
                <el-form-item label="完整名字">
                    <el-input v-model="form.full_name" style="width:80%"></el-input>
                </el-form-item>
                <el-form-item label="类型">
                <el-input v-model="form.type" style="width:80%"></el-input>
              </el-form-item>
                <el-form-item label="是否在Reach+显示">
                <el-switch v-model="form.show_in_ReachPlus"></el-switch>
              </el-form-item>
              <el-form-item label="是否运营中">
                <el-switch v-model="form.show_in_Account"></el-switch>
              </el-form-item>
                <el-form-item label="logo">
                    <el-upload
                        class="avatar-uploader"
                        action="#"
                        :show-file-list="false"
                        :before-upload="beforeContentPicUpload">
                        <img v-if="form.logo" :src="form.logo" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item label="官网地址">
                <el-input v-model="form.website" style="width:80%"></el-input>
              </el-form-item>
                <el-form-item label="介绍">
                <el-input v-model="form.intro" type="textarea" :rows="6" style="width:80%"></el-input>
              </el-form-item>
                <el-form-item label="品牌指南文件">
                    <div v-if="attach_key">
                        <el-link type="primary" :href="'http://mainbucket.reachable-edu.com/'+attach_key" target="_blank">{{attach_name?attach_name:attach_key}}</el-link>
                    </div>
                    <el-button type="primary" @click="initUpload" size="small">上传文件</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button @click="updateBrand" type="primary">确认</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog :visible.sync="showAttach">
            <h3>文件上传</h3>
            <uploadItem v-if="showAttach" @confirmUpload="onConfirmUpload" @removeFile="confirmRemoveFile" style="margin-top:20px"/>
            <hr style="margin-top:30px"/>
            <h3 style="margin-top:30px">基本信息</h3>
            <el-form>
                <el-form-item label="文件名称">
                    <el-input v-model="attach_name"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="showAttach=false">确认</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import { getBrands, getUser, updateBrand, removeRemoteFile, getQiniuToken } from '../../api/assistant'
import { getUserId } from '../../utils/store'
import uploadItem from '../../components/UploadItem'
import * as qiniu from 'qiniu-js'
export default {
  components: {
    uploadItem
  },
  data () {
    return {
      brands: [],
      form: {

      },
      loading: false,
      showEdit: false,
      showAttach: false,
      brand_id: '',
      groups: ['商科经济', '项目制', '自然环境', '社区', '学术', '少儿', '科技', '教具', '内容'],
      attach_name: '',
      attach_url: '',
      attach_key: '',
      old_attach_key: ''

    }
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      this.loading = true
      getBrands().then(res => {
        this.brands = res.data
        this.loading = false
      })
    },
    addBrand () {
      this.form = {

      }
      this.attach_name = ''
      this.attach_url = ''
      this.attach_key = ''
      this.old_attach_key = ''
      this.brand_id = ''
      this.showEdit = true
    },
    updateBrand () {
      const params = {
        brand_id: this.brand_id,
        group: this.form.group,
        brand: this.form.brand,
        attach_key: this.attach_key,
        attach_name: this.attach_name,
        logo: this.form.logo,
        show_in_ReachPlus: this.form.show_in_ReachPlus,
        show_in_Account: this.form.show_in_Account,
        website: this.form.website,
        intro: this.form.intro,
        type: this.form.type,
        full_name: this.form.full_name
      }
      updateBrand(params).then(res => {
        this.fetchData()
        this.showEdit = false
      })
    },
    view (data) {
      this.form = JSON.parse(JSON.stringify(data))
      if (data.attach_key) {
        this.old_attach_key = data.attach_key
        this.attach_key = data.attach_key
        this.attach_name = data.attach_name
        this.attach_url = data.attach_url
      } else {
        this.old_attach_key = ''
        this.attach_key = ''
        this.attach_name = ''
        this.attach_url = ''
      }
      this.brand_id = data._id.$id

      this.showEdit = true
    },
    initUpload () {
      this.attach_name = ''
      this.attach_url = ''
      this.attach_key = ''
      this.showAttach = true
    },
    onConfirmUpload (e) {
      if (this.old_attach_key !== '') {
        removeRemoteFile(this.old_attach_key).then(res => {
          this.old_attach_key = e.key
          this.attach_key = e.key
          this.attach_name = e.key
        })
      } else {
        this.old_attach_key = e.key
        this.attach_key = e.key
        this.attach_name = e.key
      }
    },
    confirmRemoveFile () {
      this.attach_key = ''
      this.attach_url = ''
    },
    viewpolicy (data) {
      this.$router.push({
        path: 'brandpolicy',
        query: {
          id: data._id.$id
        }
      })
    },
    async beforeContentPicUpload (file) {
      const result = await getQiniuToken()
      if (result.data.code === 0) {
        const token = result.data.data
        this.observable = qiniu.upload(
          file,
          new Date().getTime() + file.name,
          token
        )
        const observer = {
          complete: (res) => {
            this.$set(this.form, 'logo', 'http://mainbucket.reachable-edu.com/' + res.key)
          }
        }
        this.observable.subscribe(observer)
      }
    }

  }
}
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  object-fit: cover;
  width: 178px;
  height: 178px;
  display: block;
}
</style>
