<template>
    <div>
      <div class="con" >
        <div class="title" style="font-size:18px;line-height:40px;margin-bottom:30px">追踪反馈数量</div>
        <el-table :data="checkTaskList">
          <el-table-column label="时间" width="200">
            <template slot-scope="scope">
              {{formate(scope.row.create_time)}}
            </template>
          </el-table-column>
          <el-table-column prop="reviewer_name" label="评价人" width="120"></el-table-column>
          <el-table-column prop="reviewee_name" label="被评价人" width="120"></el-table-column>
          <el-table-column prop="type" label="类型" width="120"></el-table-column>
          <el-table-column prop="content" label="内容" ></el-table-column>
        </el-table>
      </div>
    </div>
</template>

<script>
import {getFeedbacksAdmin} from '../api/assistant.js'
export default {
  data () {
    return {
      checkTaskList: [],
      block:''
    }
  },
  mounted () {
    this.getFeedbacksAdmin()
  },
  methods: {
    getFeedbacksAdmin () {
      getFeedbacksAdmin().then(res => {
        if (res.data.code === 0) {
          this.checkTaskList = res.data.data
        }
      })
    },
    formate (timestamp) {
      var date = new Date(timestamp * 1000)
      var y = date.getFullYear()
      var m = date.getMonth() + 1
      m = m < 10 ? '0' + m : m
      var d = date.getDate()
      d = d < 10 ? '0' + d : d
      var h = date.getHours()
      h = h < 10 ? '0' + h : h
      var minute = date.getMinutes()
      var second = date.getSeconds()
      minute = minute < 10 ? '0' + minute : minute
      second = second < 10 ? '0' + second : second
      var timeStr =
                y + '-' + m + '-' + d + '　' + h + ':' + minute + ':' + second
      return timeStr
    }
  }
}
</script>

<style>
.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
}
.back{
  display:flex;
  align-items:center;
  cursor:pointer
}

</style>
