<template>
    <div>
        <el-button type="primary" @click="initAdd">添加</el-button>
        <el-table :data="speechs">
            <el-table-column prop="question" label="问题" width="800"></el-table-column>
            <!-- <el-table-column prop="content" label="内容"></el-table-column> -->
            <!-- <el-table-column prop="answer" label="回答"></el-table-column> -->
            <el-table-column label="标签">
                <template slot-scope="scope">
                    <el-tag v-for="item in scope.row.labels" :key="item" style="margin:2px">{{item}}</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="最近修改人" width="200">
                <template slot-scope="scope">
                    {{scope.row.update_records[0].user_name}}
                </template>
            </el-table-column>
            <el-table-column label="操作" width="400">
                <template slot-scope="scope">
                    <el-button @click="edit(scope.row)" size="medium" type="primary" style="margin-right:10px">修改</el-button>
                   
                    <el-dropdown trigger="click">
                       
                        <el-button class="el-dropdown-link" type="primary" size="medium">历史版本<i class="el-icon-arrow-down el-icon--right"></i></el-button>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item @click.native="viewRecord(item)" v-for="(item,index) in scope.row.update_records" :key="index">{{formateDate(item.update_time)}} {{item.user_name}}修改</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                    <el-button @click="removetSpeechArt(scope.row._id.$id)" size="medium" type="primary" style="margin-left:10px">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-dialog title="编辑" :visible.sync="show">
            <el-form>
                <el-form-item label="问题">
                    <el-input type="textarea" :rows="5" v-model="form.question"></el-input>
                </el-form-item>
                <el-form-item label="回复">
                    <el-input type="textarea" :rows="5" v-model="form.answer"></el-input>
                </el-form-item>
                <el-form-item label="解析/逻辑">
                    <el-input type="textarea" :rows="5" v-model="form.reason"></el-input>
                </el-form-item>
                <el-form-item label="标签">
                    <el-tag v-for="item in form.labels" closable @close="removeLabel(item)" :key="item" style="margin:2px">{{item}}</el-tag>
                    <el-button size="mini" type="primary" @click="addLabel">
                        <i class="el-icon-plus"></i>
                    </el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="updateSpeechArt">确认</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>

        <el-dialog title="查看" :visible.sync="show1">
            <el-form>
                <el-form-item label="问题">
                    <el-input type="textarea" :rows="5" v-model="form1.question" disabled></el-input>
                </el-form-item>
                <el-form-item label="回复">
                    <el-input type="textarea" :rows="5" v-model="form1.answer" disabled></el-input>
                </el-form-item>
                <el-form-item label="解析">
                    <el-input type="textarea" :rows="5" v-model="form1.reason" disabled></el-input>
                </el-form-item>
                <el-form-item label="标签">
                    <el-tag v-for="item in form1.labels" :key="item" style="margin:2px">{{item}}</el-tag>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog :visible.sync="showLabel">
            <el-input v-model="label" @keyup.enter.native="confirmAdd"></el-input>
            <el-button @click="confirmAdd" type="primary" style="margin-top:10px">确认</el-button>
        </el-dialog>
    </div>
</template>

<script>
import {getSpeechArts,updateSpeechArt,removetSpeechArt} from '../api/assistant'
import {getUserId} from '../utils/store'
export default {
    data(){
        return{
            speechs:[],
            id:'',
            show:false,
            show1:false,
            form:{
                question:'',
                answer:'',
                reason:'',
                labels:[]
            },
            form1:{
                question:'',
                answer:'',
                reason:'',
                labels:[]
            },
            showLabel:false,
            label:''
        }
    },
    mounted(){
        this.fetchData()
    },
    methods:{
        fetchData(){
            getSpeechArts().then(res=>{
                if(res.data.code===0){
                    this.speechs = res.data.data
                }
            })
        },
        initAdd(){
            this.show = true
            this.id = ''
            this.form = {
                question:'',
                answer:'',
                reason:'',
                labels:[]
            }
        },
        edit(row){
            this.form = JSON.parse(JSON.stringify(row))
            this.id = row._id.$id
            this.show = true
        },
        updateSpeechArt(){
            updateSpeechArt(this.id,this.form.question,this.form.answer,this.form.reason,this.form.labels,getUserId()).then(res=>{
                if(res.data.code===0){
                    this.$message({
                        message:'更新成功',
                        type:'success'
                    })
                    this.fetchData()
                    this.show = false
                }
            })
        },
        removetSpeechArt(id){
            this.$confirm('是否确认删除?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(()=>{
                    removetSpeechArt(id).then(res=>{
                        this.fetchData()
                    })
                })
        },
        addLabel(){
            this.label = ''
            this.showLabel = true
        },
        confirmAdd(){
            this.form.labels.push(this.label)
            this.showLabel = false
        },
        removeLabel(item){
            let index = this.form.labels.findIndex(x=>x===item)
            this.form.labels.splice(index,1)
        },
        formateDate(date){
            let time = new Date(date*1000)
            return `${time.getMonth()+1}月${time.getDate()}日`;
        },
        viewRecord(item){
            console.log(1111111111)
            this.form1 = item
            this.show1 = true
        }
    }
}
</script>

<style>

</style>