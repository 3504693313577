<template>
    <div class="container">
        <div @click="$router.back(-1)">
            <i class="el-icon-arrow-left" style="font-size:120%;cursor:pointer"></i>
        </div>
        <div style="width:90%;margin:0 auto">
            <h3>工作日志</h3>
            <p>工作日期{{$route.query.date}}</p>
            <p>工作日志详细信息</p>
            <el-form label-position="top" style="margin:0 auto">
                <!--公共填写部分-->
                <el-form-item label="工作班次">
                        <el-select v-model="workLogForm.sheet_schedule">
                            <el-option label="上午" value="上午"></el-option>
                            <el-option label="下午" value="下午"></el-option>
                            <el-option label="全天" value="全天"></el-option>
                            <el-option label="请假" value="请假"></el-option>
                            <el-option label="休息" value="休息"></el-option>
                        </el-select>
                </el-form-item>
                <el-form-item label="上班时间">
                    <el-input v-model="workLogForm.sheet_work_ontime"></el-input>
                </el-form-item>
                <el-form-item label="下班时间">
                    <el-input v-model="workLogForm.sheet_work_offtime"></el-input>
                </el-form-item>
                <el-form-item label="工作时长(单位为小时)">
                    <el-input-number v-model="workLogForm.sheet_work_timelen" :min="0"></el-input-number>
                </el-form-item>

                <el-form-item label="不同业务时间分配(必须加总100%)">
                    <div class="distributionStyle">教育(Education)
                        <el-input type="number" style="width:80px;" v-model="workLogForm.sheet_work_education" :min="0"></el-input>
                        %
                    </div>
                    <div class="distributionStyle">游戏(Game)
                        <el-input type="number" style="width:80px;" v-model="workLogForm.sheet_work_game" :min="0"></el-input>
                        %
                    </div>
                    <div class="distributionStyle1">电商(E-commerce)
                        <el-input type="number" style="width:80px;" v-model="workLogForm.sheet_work_business" :min="0"></el-input>
                        %
                    </div>
                    <div class="distributionStyle1">餐饮(Food & Beverage)
                        <el-input type="number" style="width:80px;" v-model="workLogForm.sheet_work_catering" :min="0"></el-input>
                        %
                    </div>

                    <div class="distributionStyle1">2B工具
                        <el-input type="number" style="width:80px;" v-model="workLogForm.sheet_work_twob" :min="0"></el-input>
                        %
                    </div>

                    <div class="distributionStyle2">数字出版(Digital Publishing)
                        <el-input type="number" style="width:80px;" v-model="workLogForm.sheet_work_dp" :min="0"></el-input>
                        %
                    </div>


                    <div class="distributionStyle1">人力资源服务(HR Service)
                        <el-input type="number" style="width:80px;" v-model="workLogForm.sheet_work_hr" :min="0"></el-input>
                        %
                    </div>

                    <div class="distributionStyle1">儿童教育(Kid Education)
                        <el-input type="number" style="width:80px;" v-model="workLogForm.sheet_work_kid" :min="0"></el-input>
                        %
                    </div>

                    <div class="distributionStyle2">媒体营销(Media & Marketing)
                        <el-input type="number" style="width:80px;" v-model="workLogForm.sheet_work_media" :min="0"></el-input>
                        %
                    </div>

                    <div class="distributionStyle2">会议与社区(Meetings & Clubs)
                        <el-input type="number" style="width:80px;" v-model="workLogForm.sheet_work_mc" :min="0"></el-input>
                        %
                    </div>

                </el-form-item>

                <!--工作模板部分-->
                <el-form-item label="上个工作日的“明日计划”（用做今日比对参考）">
                    <el-input disabled v-model="last_tomorrow_plan" type="textarea" :rows="5" style="width:80%"></el-input>
                </el-form-item>
                <el-form-item v-for="item in formItemList" :key="item.key" :label="item.label">
                    <el-input v-model="item.val" type="textarea" :rows="5" style="width:80%"></el-input>
                </el-form-item>

                <el-form-item label="今日完成事项（事项清单）">
                    <ul>
                        <li v-for="(item,index) in finishTODOs" :key="index">{{item.todo_content}}</li>
                    </ul>
                </el-form-item>
                <el-form-item label="今日完成品牌任务（关联品牌内容工作清单）">
                    <ul>
                        <li v-for="(item,index) in finishProjectTODOs" :key="index">{{item.content}}-{{item.article_name}}</li>
                    </ul>
                </el-form-item>

                <h3 style="margin-top:60px">{{user_type==='fulltime'?'加班部分':'兼职部分'}}</h3>
                <el-form-item :label="`${user_type==='fulltime'?'加班':'兼职'}上班时间`">
                    <el-input v-model="workLogForm.sheet_parttime_work_ontime"></el-input>
                </el-form-item>
                <el-form-item :label="`${user_type==='fulltime'?'加班':'兼职'}下班时间`">
                    <el-input v-model="workLogForm.sheet_parttime_work_offtime"></el-input>
                </el-form-item>
                <el-form-item :label="`${user_type==='fulltime'?'加班':'兼职'}工作时长(单位为小时)`">
                    <el-input-number v-model="workLogForm.sheet_parttime_work_timelen" :min="0"></el-input-number>
                </el-form-item>
                <el-form-item :label="`${user_type==='fulltime'?'加班':'兼职'}工资估算`">
                    <el-input-number v-model="workLogForm.sheet_work_comp" :min="0" :step="10"></el-input-number>
                </el-form-item>
                <el-form-item :label="`${user_type==='fulltime'?'加班':'兼职'}工资估算明细（若有，请罗列计算的方式）`">
                    <el-input v-model="workLogForm.sheet_work_comp_detail"></el-input>
                </el-form-item>
                <el-form-item :label="`${user_type==='fulltime'?'加班':'兼职'}工作内容`">
                    <el-input type="textarea" :rows="6" v-model="workLogForm.sheet_parttime_work_content" ></el-input>
                </el-form-item>
                <el-form-item v-if="type!=='admin'">
                    <el-button @click="submit" type="primary">提交</el-button>
                </el-form-item>
            </el-form>

        </div>
    </div>
</template>

<script>
import internal from 'stream';
import {getTemplateByEmployee,getTimeSheetById,updateWorkLog,getUserType,getFinishTODORecord,getUserFinishProjectTodoSubs,getLastTomorrowPlan} from '../../api/assistant'
export default {
    data(){
        return{
            workLogForm:{
                sheet_schedule:'',
                sheet_work_ontime:'',
                sheet_work_offtime:'',
                sheet_work_timelen:0,
                ///兼职部分
                sheet_parttime_work_ontime:'',
                sheet_parttime_work_offtime:'',
                sheet_work_comp:0,
                sheet_work_comp_detail:'',
                sheet_parttime_work_timelen:0,
                sheet_parttime_work_content:'',
                sheet_work_education:0,
                sheet_work_game:0,
                sheet_work_business:0,
                sheet_work_catering:0,
                sheet_work_twob:0,
                sheet_work_dp:0,
                sheet_work_hr:0,
                sheet_work_kid:0,
                sheet_work_media:0,
                sheet_work_mc:0


            },
            workLogExtraForm:{},
            formItemList:[],
            start_time:this.$route.query.start_day,
            end_time:this.$route.query.end_day,
            sheet_id:this.$route.query.sheet_id,
            user_id:this.$route.query.user_id,
            type:this.$route.query.type,
            user_type:'',
            finishTODOs:[],
            finishProjectTODOs:[],
            last_tomorrow_plan:''
        }
    },
    async created(){
        
        this.getUserType()
        ///如果没有写过的日报 就生成新的表单
        if(!this.sheet_id){
            const loading = this.$loading({lock: true,text: 'Loading',spinner: 'el-icon-loading',background: 'rgba(0, 0, 0, 0.7)'});
            await this.initNewWorkLogForm()
            loading.close()
        }else{
            const loading = this.$loading({lock: true,text: 'Loading',spinner: 'el-icon-loading',background: 'rgba(0, 0, 0, 0.7)'});
            //写过就给值
            await this.initNewWorkLogForm()
            let sheet = await this.getTimeSheetById()
            for(let i in sheet){
                for(let item of this.formItemList){
                    if(item.key===i){
                        item.val = sheet[i]
                    }
                }
            }
            this.workLogForm.sheet_schedule = sheet.sheet_schedule
            this.workLogForm.sheet_work_ontime = sheet.sheet_work_ontime
            this.workLogForm.sheet_work_offtime = sheet.sheet_work_offtime
            this.workLogForm.sheet_work_timelen = sheet.sheet_work_timelen
            ///兼职部分
            this.workLogForm.sheet_work_comp = sheet.sheet_work_comp
            this.workLogForm.sheet_work_comp_detail = sheet.sheet_work_comp_detail
            this.workLogForm.sheet_parttime_work_ontime = sheet.sheet_parttime_work_ontime?sheet.sheet_parttime_work_ontime:''
            this.workLogForm.sheet_parttime_work_offtime = sheet.sheet_parttime_work_offtime?sheet.sheet_parttime_work_offtime:''
            this.workLogForm.sheet_parttime_work_content = sheet.sheet_parttime_work_content?sheet.sheet_parttime_work_content:''
            this.workLogForm.sheet_parttime_work_timelen = sheet.sheet_parttime_work_timelen?sheet.sheet_parttime_work_timelen:''
        
            this.workLogForm.sheet_work_education = sheet.sheet_work_education?sheet.sheet_work_education:0
            this.workLogForm.sheet_work_game = sheet.sheet_work_game?sheet.sheet_work_game:0
            this.workLogForm.sheet_work_business = sheet.sheet_work_business?sheet.sheet_work_business:0
            this.workLogForm.sheet_work_catering = sheet.sheet_work_catering?sheet.sheet_work_catering:0
            this.workLogForm.sheet_work_twob = sheet.sheet_work_twob?sheet.sheet_work_twob:0

            this.workLogForm.sheet_work_dp = sheet.sheet_work_dp?sheet.sheet_work_dp:0
            this.workLogForm.sheet_work_hr = sheet.sheet_work_hr?sheet.sheet_work_hr:0
            this.workLogForm.sheet_work_kid = sheet.sheet_work_kid?sheet.sheet_work_kid:0
            this.workLogForm.sheet_work_media = sheet.sheet_work_media?sheet.sheet_work_media:0
            this.workLogForm.sheet_work_mc = sheet.sheet_work_mc?sheet.sheet_work_mc:0

            loading.close()
        }
        this.getFinishTODORecord()
        this.getUserFinishProjectTodoSubs()
        this.getLastTomorrowPlan()
    },
    methods:{
        getLastTomorrowPlan(){
            getLastTomorrowPlan(this.start_time,this.user_id).then(res=>{
                this.last_tomorrow_plan = res.data
            })
        },
        getUserType(){
            getUserType(this.user_id).then(res=>{
                this.user_type=res.data
            })
        },
        async getTemplateByEmployee(){
            return new Promise((resolve)=>{
                getTemplateByEmployee(this.user_id).then(res=>{
                    resolve(res.data)
                })
            })
        },
        async getTimeSheetById(){
            return new Promise((resolve)=>{
                getTimeSheetById(this.sheet_id).then(res=>{
                    resolve(res.data)
                })
            })
        },
        async initNewWorkLogForm(){
            let template =  await this.getTemplateByEmployee();
            if(typeof(template)=='string'){
                this.$message.error({
                    message:'数据请求错误'
                })
                this.$router.back(-1)
                return;
            }
            if(template){
                let formItemList = [];
                let paras = template.template_parameters_array;
                for(let i in paras){
                    let para = 'sheet_'+paras[i].replace(/ /g,'_');
                    let formItem = {};
                    formItem.key = para;
                    formItem.label = i;
                    formItem.val = '';

                    formItem.required = 'NO';
                    formItem.requiredNum = 0;

                    if (template['template_required_array']){
                        formItem.required = template['template_required_array'][i].required
                        formItem.requiredNum = template['template_required_array'][i].requiredNum
                    }

                    formItemList.push(formItem);
                }
                this.formItemList = formItemList;
            }else{
                let formItemList = [
                    {key:'sheet_work_content',label:'工作内容（不要输入英文引号，不然邮件发不出去；)',val:''},
                    {key:'sheet_work_plan',label:'明日计划（不要输入英文引号，不然邮件发不出去；)',val:''},
                    {key:'sheet_work_comment',label:'工作感想及建议（不要输入英文引号，不然邮件发不出去）',val:''},
                    {key:'sheet_work_questions',label:'想要和上级请教或探讨的问题（不要输入英文引号，不然邮件发不出去；)',val:''}
                ];
                this.formItemList = formItemList
            }
        },
        submit(){
            const loading = this.$loading({lock: true,text: 'Loading',spinner: 'el-icon-loading',background: 'rgba(0, 0, 0, 0.7)'});
            var showEdit = false;
            var showStr = '';
            for(let item of this.formItemList){
                this.workLogExtraForm[item.key] = item.val

                console.log(111,item.key,item.required,item.requiredNum)
                if (item.required == 'YES'){

                    var valueStr = item.val.replace(/\s+/g,"");

                    if (valueStr.length > item.requiredNum){
                        showEdit = false;
                    }else{
                        showEdit = true;
                        showStr = '请填写' + item.label + '(至少'+ item.requiredNum +'字)';
                        break
                    }


                }
                // if (item.key == 'sheet_Big_Picture'){
                //     if (item.val.length > 3){
                //         showEdit = false;
                //     }else{
                //         showEdit = true;
                //         showStr = '请填写客户情况';
                //     }
                // }
            }
            if (showEdit){
                loading.close()
                this.$message.warning(showStr)
                return
            }

            let totalNum = parseFloat(this.workLogForm.sheet_work_education) + parseFloat(this.workLogForm.sheet_work_game) 
            + parseFloat(this.workLogForm.sheet_work_business) + parseFloat(this.workLogForm.sheet_work_catering) 
            + parseFloat(this.workLogForm.sheet_work_twob) + parseFloat(this.workLogForm.sheet_work_dp) 
            + parseFloat(this.workLogForm.sheet_work_hr) + parseFloat(this.workLogForm.sheet_work_kid) 
            + parseFloat(this.workLogForm.sheet_work_media) + parseFloat(this.workLogForm.sheet_work_mc) 

            if(totalNum != 100 && this.user_type === 'fulltime'){
                loading.close()
                this.$message.warning('不同业务时间分配(必须加总100%)!')
                return
            }

            // console.log (1111,showEdit)
            let real_params = {...this.workLogForm,...this.workLogExtraForm};
            let real_params_values = Object.values(real_params);
            let total_txt = ''
            for(let item of real_params_values){
                total_txt += item;
            }
            if(this.user_id != '5e991290023091024e6a614d' && total_txt.length<100){
                loading.close()
                this.$message.warning('字数不足100字')
                return
            }
            updateWorkLog(this.user_id,this.sheet_id,this.start_time,real_params).then(res=>{
                console.log(res)
                if(res.data.code===0){
                    loading.close()
                    this.$message({
                        message:'提交成功',
                        type:'success'
                    })
                }else{
                    loading.close()
                    this.$message.error({
                        message:'提交失败'
                    })
                }
            })
        },
        getFinishTODORecord(){
            getFinishTODORecord(this.user_id,this.start_time).then(res=>{
                this.finishTODOs = res.data
            })
        },
        getUserFinishProjectTodoSubs(){
            getUserFinishProjectTodoSubs(this.user_id,this.start_time).then(res=>{
                this.finishProjectTODOs = res.data
            })
        }
    }
}
</script>

<style scoped>
.container{
    width: 100%;
    color: #606266;
}
.el-input{
     width: 80%;
}
.distributionStyle{
    width:230px;
    height:50px;
    float:left;
    margin-bottom:20px;
    text-align: left;

}
.distributionStyle1{
    width:280px;
    height:50px;
    float:left;
    margin-bottom:20px;
    text-align: left;

}
.distributionStyle2{
    width:300px;
    height:50px;
    float:left;
    margin-bottom:20px;
    text-align: left;

}
</style>