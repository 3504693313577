<template>
    <div class="container">
        <div style="position:absolute;cursor:pointer;font-size:18px" @click="$router.back(-1)"><i class="el-icon-arrow-left"></i></div>
        <div style="padding:30px">
        <el-table :data="workshops">
            <el-table-column label="名称" width="300" prop="name"></el-table-column>
            <el-table-column label="介绍" prop="intro"></el-table-column>
            <el-table-column label="模板名" prop="template.name" width="200"></el-table-column>
            <el-table-column label="完成情况" width="200">
                <template slot-scope="scope">
                    {{scope.row.status==='YES'?'已完成':'未完成'}}
                </template>
            </el-table-column>
            <el-table-column label="操作" width="200">
                <template slot-scope="scope">
                    <el-button type="primary" @click="toEntry(scope.row._id.$id)">去完成</el-button>
                </template>
            </el-table-column>
        </el-table>
        </div>
    </div>
</template>

<script>
import {getContentWorkShop} from '../../api/assistant'
import { getUserId } from '../../utils/store'

export default {
    data(){
        return{
            content_id:this.$route.query.content_id,
            workshops:[]
        }
    },
    mounted(){
        this.fetchData()
    },
    methods:{
        fetchData(){
            getContentWorkShop(this.content_id,getUserId()).then(res=>{
                if(res.data.code===0){
                    this.workshops = res.data.data.workshops
                }
            })
        },
        toEntry(id){
            this.$router.push({
                path:'/adminmanageuser/workShopEntry',
                query:{
                    id:id,
                    user_id:getUserId(),
                    type:'creater'
                }
            })
        }
    }
}
</script>

<style scoped>
.container{
    width: 100%;
}
</style>