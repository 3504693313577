<template>
    <div style="width:100%">
        <div style="padding:20px">
            <el-table :data="examList" v-loading="loading">
                <el-table-column label="试卷名" prop="template.template_name"></el-table-column>
                <!-- <el-table-column label="试卷介绍" prop="test_instruction"></el-table-column>
                <el-table-column label="学科" prop="test_subject"></el-table-column>
                <el-table-column label="考试时长" prop="test_duration" width="120px"></el-table-column> -->
                <el-table-column prop="test_scores" label="得分"></el-table-column>
                <el-table-column label="测试者">
                    <template slot-scope="scope">
                        {{scope.row.taker?scope.row.taker.user_lastName+scope.row.taker.user_givenName:''}}
                    </template>
                </el-table-column>
                <el-table-column label="完成情况" prop="test_status"></el-table-column>
                <el-table-column label="提交时间">
                    <template slot-scope="scope">
                        {{formateTime(scope.row.create_time)}}
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
import { getExerciseTests } from '../../api/assistant'
import Template from './Template.vue'
export default {
  components: { Template },
  data () {
    return {
      examList: [],
      loading: false
    }
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      this.loading = true
      getExerciseTests().then(res => {
        console.log(res)
        if (res.data.code === 0) {
          this.examList = res.data.data
          this.loading = false
        }
      })
    },
    formateTime (date) {
      const time = new Date(date * 1000)
      return `${this.formateTwoPath(time.getMonth() + 1)}-${this.formateTwoPath(time.getDate())} ${this.formateTwoPath(time.getHours())}:${this.formateTwoPath(time.getMinutes())}`
    },
    formateTwoPath (a) {
      return a < 10 ? '0' + a : a
    }
  }
}
</script>

<style>

</style>
