<template>
    <div>
        <el-button @click="addStandard" type="primary" style="margin-left:20px">添加标准</el-button>
        <el-table :data="standards" v-loading="loading">
            <el-table-column prop="format" label="形式" width="100"></el-table-column>
            <el-table-column prop="type" label="类型" width="100"></el-table-column>
            <el-table-column prop="content" label="标准名称" ></el-table-column>
            <el-table-column prop="note" label="注释" ></el-table-column>
            <el-table-column prop="priority" label="优先级" ></el-table-column>
            <el-table-column prop="valid_status" label="启用状态" width="100"></el-table-column>
            <el-table-column label="详细" width="150">
                <template slot-scope="scope">
                    <el-button type="primary" size="small" @click="view(scope.row)">编辑</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-dialog :visible.sync="showEdit" title="添加标准" width="80%">
            <el-form label-width="200px">
                <el-form-item label="形式">
                    <el-select v-model="form.format">
                        <el-option v-for="item in formats" :label="item" :value="item"></el-option>
                        
                    </el-select>
                </el-form-item>
                <el-form-item label="类型">
                    <el-select v-model="form.type">
                        <el-option v-for="item in type_arr" :label="item" :value="item"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="启用状态">
                    <el-select v-model="form.valid_status">
                        <el-option v-for="item in valid_statuses" :label="item" :value="item"></el-option>
                    </el-select>
                </el-form-item>
                

                <el-form-item label="名称">
                    <el-input v-model="form.content" style="width:80%"></el-input>
                </el-form-item>

                <el-form-item label="注释">
                    <el-input v-model="form.note" style="width:80%"></el-input>
                </el-form-item>
                <el-form-item label="优先级">
                    <el-input type="number" v-model="form.priority" style="width:80%"></el-input>
                </el-form-item>
                
                <el-form-item>
                    <el-button @click="updateTodoStandard" type="primary">确认</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import {getTodoStandards,getUser,updateTodoStandard} from '../../api/assistant'
import {getUserId} from '../../utils/store'
export default {
    data(){
        return{
            standards:[],
            form:{},
            loading:false,
            showEdit:false,
            standard_id:'',
            formats:['有用','内容','活动','讲座','投放','研发','装饰','课程','流程'],
            type_arr:['process','quality','design','publish'],
            valid_statuses:['YES','NO']
        }
    },
    mounted(){
        this.fetchData()
    },
    methods:{
        fetchData(){
            this.loading=true
            getTodoStandards().then(res=>{
                this.standards = res.data
                this.loading=false
            })
        },
        addStandard(){
            this.form={
                
            }
            this.standard_id = ''
            this.showEdit = true
        },
        updateTodoStandard(){
            updateTodoStandard(this.standard_id,this.form.format,this.form.type,this.form.content,this.form.valid_status,this.form.note,this.form.priority).then(res=>{
                this.fetchData()
                this.showEdit = false
            })
        },
        view(data){
            this.form = JSON.parse(JSON.stringify(data))
            this.standard_id = data._id.$id
            
            this.showEdit = true
        },

        
    }
}
</script>

<style>

</style>