<template>
    <div class="container">
        <el-button type="primary" size="mini" @click="lastWeek">上一周</el-button>
        <el-button type="primary" size="mini" @click="nextWeek">下一周</el-button>
        <div class="table">
            <div v-for="(item,index) in schedule" :key="index" class="column">
                <div :class="{'tableHead':true,'border':index===6,'today':item.isToday}">{{item.weekName}} {{item.name}}</div>
                <div :class="{'tableContent':true,'border':index===6}" @click="toForm(item)">
                    {{item.sheet?`${item.sheet.sheet_schedule}(${Number(item.sheet.sheet_work_timelen)+(item.sheet.sheet_parttime_work_timelen?item.sheet.sheet_parttime_work_timelen:0)}小时)`:''}}
                </div>
            </div>
        </div>
        <div style="margin-top:20px">
            <p>未完成工作日志的日期：</p>
            <span v-for="item in missing_logs" :key="item._id.$id" style="margin-right:20px;line-height:24px">{{item.year}}-{{item.month}}-{{item.day}}</span>
        </div>

    </div>
</template>

<script>
import { getWorkingSchedule, checkUserMissingLog, getUserMonthly } from '../../api/assistant'
import { getUserId } from '../../utils/store'
export default {
  data () {
    return {
      number: 0,
      schedule: [],
      mapWeek: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      loading: false,
      showMonthly: false,
      missing_logs: []
    }
  },
  created () {
    this.fetchData()
    this.checkUserMissingLog()
  },
  methods: {
    fetchData () {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      getWorkingSchedule(this.number, getUserId()).then(res => {
        for (let i = 0; i < 7; i++) {
          res.data[i].weekName = this.mapWeek[i]
        }
        this.schedule = res.data
        console.log(this.schedule)
        loading.close()
      })

      getUserMonthly(getUserId()).then(res => {
        this.showMonthly = !res.data
      })
    },
    lastWeek () {
      this.number -= 1
      this.fetchData()
    },
    nextWeek () {
      this.number += 1
      this.fetchData()
    },
    toForm (data) {
      if (this.showMonthly == true) {
        this.$message({
          message: '请先完成月度总结',
          type: 'error'
        })
        return
      }

      console.log(data)
      this.$router.push({
        path: '/adminmanageuser/dailyLogForm',
        query: {
          date: data.name,
          start_day: data.start_day,
          end_day: data.end_day,
          sheet_id: data.sheet ? data.sheet._id.$id : '',
          user_id: getUserId()
        }
      })
    },
    checkUserMissingLog () {
      checkUserMissingLog(getUserId()).then(res => {
        console.log(res)
        this.missing_logs = res.data
      })
    }
  }
}
</script>

<style scoped>
.container{
    width: 100%;
    color: #909399;
}
.table{
    width:100%;
    display: flex;
    margin-top:20px
}
.column{
    width: 14%;
}
.tableHead{
    text-align: center;
    border-top: 0.5px solid #E4E7ED;
    border-left: 0.5px solid #E4E7ED;
    height: 50px;
    line-height: 50px;
    font-weight: 600;
    font-size: 96%
}
.tableContent{
    height: 54px;
    line-height: 54px;
    border-top: 0.5px solid #E4E7ED;
    border-bottom: 0.5px solid #E4E7ED;
    border-left: 0.5px solid #E4E7ED;
    cursor: pointer;
    text-align: center;
}
.border{
    border-right: 0.5px solid #E4E7ED;
}
.today{
    background: #DCDFE6;
}
.tableContent:hover{
    background: #DCDFE6;
}
</style>
