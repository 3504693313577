<template>
    <div style="width:100%">
        <div style="padding:20px">
            <p>模板信息</p>
            <el-form class="templateForm" label-width="80px">
                <el-form-item label="模板名">
                    <el-input v-model="templateForm.template_name"></el-input>
                </el-form-item>
                <el-form-item label="所属业务" prop="test_BU">
                    <el-select class="w140" v-model="templateForm.test_BU" placeholder="请选择试卷所属业务" clearable>
                        <el-option
                        v-for="item in BUOptions"
                        :key="item.name"
                        :label="item.name"
                        :value="item.name"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="试卷类型" prop="test_type">
                    <el-select class="w140" v-model="templateForm.test_type" placeholder="请选择试卷类型" clearable>
                        <el-option
                        v-for="item in typeOptions"
                        :key="item.name"
                        :label="item.name"
                        :value="item.name"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="Banner">
                    <img :src="image" @click="uploadHeadImg" style="width:200px">
                    <input type="file" ref="file" accept="image/*" class="hiddenInput" @change="handleFile">
                    <div style="width:140px;text-align:center"><el-button type="warning" @click="uploadBanner" size="mini">提交</el-button></div>
                </el-form-item>
                <el-form-item label="模板主题">
                    <el-input v-model="templateForm.test_subject"></el-input>
                </el-form-item>
                <el-form-item label="模板介绍">
                    <el-input type="textarea" :rows="5" v-model="templateForm.template_instruction" style="width:80%"></el-input>
                </el-form-item>
                <el-form-item label="时长(分钟)">
                    <el-input-number v-model="templateForm.test_duration" :min="1"></el-input-number>
                </el-form-item>
                <el-form-item label="考试时间">
                    <el-input v-model="templateForm.test_time"></el-input>
                </el-form-item>
                <el-form-item label="参考资料">
                    <el-input type="textarea" :rows="6" v-model="templateForm.test_refer"></el-input>
                </el-form-item>
            </el-form>
            <el-button type="primary" @click="updateExerciseTemplate">修改信息</el-button>
            <p>模块列表</p>
            <el-button type="primary" @click="initAdd" size="medium">添加模块</el-button>
            <el-table :data="sections">
                <el-table-column label="标题" prop="section_title" width="200px"></el-table-column>
                <el-table-column label="说明" prop="section_instruction"></el-table-column>
                <el-table-column label="材料" prop="section_material"></el-table-column>
                <el-table-column>
                    <template slot-scope="scope">
                        <el-button type="primary" size="mini" @click="viewSection(scope.row)">编辑</el-button>
                        <el-button type="primary" size="mini" @click="viewQuestion(scope.row._id.$id)">查看题目列表</el-button>
                        <el-button type="danger" size="mini" @click="removeExerciseSection(scope.row._id.$id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <el-dialog title="添加Section" :visible.sync="showSection">
            <el-form>
                <el-form-item label="标题">
                    <el-input v-model="sectionForm.section_title"></el-input>
                </el-form-item>
                <el-form-item label="说明">
                    <el-input v-model="sectionForm.section_instruction"></el-input>
                </el-form-item>
                <el-form-item label="材料">
                    <el-input type="textarea" :rows="6" v-model="sectionForm.section_material"></el-input>
                </el-form-item>
            </el-form>
            <el-button type="primary" @click="updateExerciseTemplateSection">确定</el-button>
        </el-dialog>
    </div>
</template>

<script>
import {
    getExerciseTemplate,
    addExerciseQuestionToTemplate,
    getExerciseQuestions,
    removeExerciseQuestionInTemplate,
    resortQuestionsInTemplate,
    updateExerciseTemplate,
    uploadBanner,
    updateExerciseTemplateSection,
    removeExerciseSection
    } from '../../api/assistant'
import Template from './Template.vue'
export default {
  components: { Template },
    data(){
        return{
            template_id:this.$route.query.template_id,
            sections:[],
            questions_all:[],
            showAdd:false,
            showSection:false,
            sectionForm:{
                _id:{$id:''},
                section_title:'',
                section_instruction:'',
                section_material:''
            },
            currentRow:{},
            templateForm:{},
            image:'',
            searchForm:{

            },
            BUOptions: [
                {
                name: "ETA"
                },
                {
                name: "FBLA"
                }
            ],
            typeOptions: [
                {
                name: " formal_test"
                },
                {
                name: "mock_test"
                },
                {
                name: "sample_test"
                },
                {
                name: "special_exercise"
                }
            ]
        }
    },
    mounted(){
        this.fetchData()
    },
    methods:{
        searchQuestion(){
            if(!this.searchForm.question_question_string){
                    this.$message.warning("请先输入题目")
            }else{
                getExerciseQuestionsByName({search_word:this.searchForm.question_question_string}).then(res=>{
                    if(res.code===0){ 
                        this.questions = res.data
                    }
                })
            }
       
        },
        fetchData(){
            getExerciseTemplate(this.template_id).then(res=>{
                console.log(res)
                if(res.data.code===0){
                    this.templateForm = res.data.data
                    this.sections = res.data.data.sections_item
                    this.image = res.data.data.banner_address?'http://'+res.data.data.banner_address:'https://wpimg.wallstcn.com/577965b9-bb9e-4e02-9f0c-095b41417191'
                }
            })
        },
        updateExerciseTemplate(){
            updateExerciseTemplate(this.template_id,
                this.templateForm.template_name,
                this.templateForm.test_subject,
                this.templateForm.template_instruction,
                this.templateForm.test_duration,
                this.templateForm.test_type,
                this.templateForm.test_BU,
                this.templateForm.test_time,
                this.templateForm.test_refer).then(res=>{
                    if(res.data.code===0){
                        this.$message({
                            message:'修改成功',
                            type:'success'
                        })
                        this.fetchData()
                    }
                })
        },
        addExerciseQuestionToTemplate(question_id){
            addExerciseQuestionToTemplate(question_id,this.template_id).then(res=>{
                if(res.data && res.data.code===0){
                    this.$message({
                        message:'添加成功',
                        type:'success'
                    })
                    this.fetchData()
                }else{
                    this.$message('已添加')
                }
            })
        },
        removeExerciseQuestionInTemplate(question_id){
            removeExerciseQuestionInTemplate(question_id,this.template_id).then(res=>{
                if(res.data && res.data.code===0){
                    this.$message({
                        message:'删除成功',
                        type:'success'
                    })
                    this.fetchData()
                }
            })
        },
        viewSection(row){
            this.sectionForm=row
            this.showSection=true
        },
        uploadHeadImg() {
            document.querySelector('.hiddenInput').click()
        },
        // 将头像显示
        handleFile(e) {
            const $target = e.target || e.srcElement
            const file = $target.files[0]
            var reader = new FileReader()
            reader.onload = (data) => {
                const res = data.target || data.srcElement
                this.image = res.result
            }
            reader.readAsDataURL(file)
        },
        uploadBanner(){
            let myfile = this.$refs["file"];
            let file = myfile.files[0];
            if(!file){
                this.$message('请上传图片');
                return;
            }
            let param = new FormData();
            param.append("file", file);
            param.append("function", "uploadBanner");
            param.append('template_id',this.template_id)
            uploadBanner(param).then(res=>{
                if(res.data.status==='success'){
                    this.$message({
                        message:'上传成功',
                        type:'success'
                    })
                    this.fetchData()
                }
            })
        },
        initAdd(){
            this.sectionForm = {
                _id:{$id:''},
                section_title:'',
                section_instruction:'',
                section_material:''
            }
            this.showSection = true
        },
        updateExerciseTemplateSection(){
            updateExerciseTemplateSection(
                this.sectionForm._id.$id,
                this.template_id,
                this.sectionForm.section_title,
                this.sectionForm.section_instruction,
                this.sectionForm.section_material
                ).then(res=>{
                    if(res.data.code===0){
                        this.$message({
                            message:'更新成功',
                            type:'success'
                        })
                        this.showSection=false
                        this.fetchData()
                    }
                })
        },
        removeExerciseSection(section_id){
            removeExerciseSection(section_id,this.template_id).then(res=>{
                if(res.data.code===0){
                    this.$message({
                        message:'更新成功',
                        type:'success'
                    })
                    this.fetchData()
                }
            })
        },
        viewQuestion(section_id){
            this.$router.push({
                path:'/sectionInfo',
                query:{
                    section_id:section_id
                }
            })
        }
    }
}
</script>

<style scoped>
.questionForm .el-input{
    width: 500px;
}
.templateForm .el-input{
    width: 80%;
}

.hiddenInput{
    display: none;
}

</style>