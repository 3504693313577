<template>
    <div class="container">
        <div style="position:absolute;cursor:pointer;font-size:18px" @click="$router.back(-1)"><i class="el-icon-arrow-left"></i></div>
        <div style="padding:30px">
            <p class="reviewerP">基本信息</p>
            <section class="partI">
  <div v-for="item in template" :key="item.key">
                <custom-input
                    :formItem="item"
                    :value="keyCollector[item.key]"
                    @dataChange="onDataChange"
                    :disabled="type==='reviewer'?true:false">
                </custom-input>
            </div>
            <div style="margin-top:20px" v-if="type==='creater'">
                <el-button type="primary" @click="updateWorkshopEntry">提交</el-button>
            </div>
            </section>
           <p class="reviewerP">评价及评语</p>
           <div class="partI" >
        <div style="padding:30px" class="containerII">
            <div v-for="item in evaluation_dimensions" :key="item.key">
                <custom-input-two
                    :formItem="item"
                    :value="keyCollector[item.key]"
                    @dataChange="onDataChangeNew">
                </custom-input-two>
            </div>
            <div class="flex_critique">
                <p class="commonP">评语：</p>
                <el-input type="textarea" placeholder="请输入评语"  :rows="6" v-model="critique"></el-input>
                  <p class="commentBtn"  @click="updateWorkshopEntryCritique">提交</p>
            </div>
        </div>
    </div>
            <div style="margin-top:50px" v-if="type!=='creater'">
                <!-- <el-button style="margin-left:20px" type="primary" @click="add">添加评语</el-button> -->
                <p class="reviewerP">评语列表</p>
                <!-- <el-input type="textarea" v-model="critique" :rows="6" placeholder="请在此处填写评语" style="width:50%"></el-input> -->
                <el-table :data="critiques">
                    <el-table-column label="内容" prop="content"></el-table-column>
                    <el-table-column label="创建者">
                        <template slot-scope="scope">
                            {{scope.row.user.user_lastName+scope.row.user.user_givenName}}
                        </template>
                    </el-table-column>
                    <el-table-column label="编辑">
                        <template slot-scope="scope">
                            <el-button type="primary" @click="edit(scope.row._id.$id,scope.row.content)">编辑</el-button>
                            <el-button type="danger" @click="removeCritique(scope.row._id.$id)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div style="margin-top:50px" v-if="type!=='creater'">
                <!-- <el-button style="margin-left:20px" type="primary" @click="addEvaluations">添加评价</el-button> -->
                <p  class="reviewerP">评价列表</p>
                <!-- <el-input type="textarea" v-model="critique" :rows="6" placeholder="请在此处填写评语" style="width:50%"></el-input> -->
                <el-table :data="evaluations">
                    <el-table-column v-for="item in keys" :key="item" :label="item" :prop="`contents.${item}`"></el-table-column>
                    <el-table-column label="编辑">
                        <template slot-scope="scope">
                            <el-button type="primary" @click="editEvaluations(scope.row._id.$id)">编辑</el-button>
                            <el-button type="danger" @click="removeEvaluation(scope.row._id.$id)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <el-dialog title="编辑评语" :visible.sync="showEdit">
            <el-input type="textarea" :rows="6" v-model="critique"></el-input>
            <el-button type="primary" @click="updateWorkshopEntryCritique" style="margin-top:10px">提交</el-button>
        </el-dialog>
    </div>
</template>

<script>
import {getEvaluationFormByWorkshop,getWorkshopEntryEvaluation,updateWorkshopEntryEvaluation,getWorkshop,updateWorkshopEntry,getWorkshopEntryByUser,updateWorkshopEntryCritique,getWorkshopEntryCritiques,getWorkshopEntryEvaluations,removeEvaluation,removeCritique} from '../../api/assistant'
import CustomInput from '../../components/CustomInput.vue'
import customInputTwo from '../../components/customInputTwo.vue'
import evaluationInfo from './evaluationInfo.vue'
import { getUserId } from '../../utils/store'
import Template from '../ExamAdmin/Template.vue'
export default {
    components:{
        CustomInput,
        customInputTwo,
        Template,
        evaluationInfo
    },
    data(){
        return{
            id:this.$route.query.id,
            template:[],
            keyCollector:{},
            entry_id:'',
            user_id:this.$route.query.user_id,
            type:this.$route.query.type,
            critiques:[],
            critique:'',
            critique_id:'',
            showEdit:false,
            forms:[],
            evaluations:[],
            keys:[],
            entry_id:this.$route.query.entry_id,
            workshop_id:"",
            evaluation_dimensions:[],
            keyCollector:{},
            form_id:'',
            data_id:""
        }
    },
    mounted(){
        this.workshop_id =this.id;
        this.getEvaluationFormByWorkshop()
        this.fetchData()
    },
    methods:{
          getEvaluationFormByWorkshop(){
            getEvaluationFormByWorkshop(this.id).then(res=>{
                if(res.data.code===0){
                    
                    this.form_id = res.data.data._id.$id
                    let evaluation_dimensions = res.data.data.evaluation_dimensions;
                     this.keys = Object.keys(evaluation_dimensions)
                    let result = [];
                    for(let i in evaluation_dimensions){
                        let model = {key:i,...evaluation_dimensions[i]};
                        this.keyCollector[i] = '';
                        result.push(model);
                    }
                    this.evaluation_dimensions = result;
                        console.log(this.evaluation_dimensions)
                    if(this.data_id){
                        getWorkshopEntryEvaluation(this.data_id).then(res=>{
                            if(res.data.code===0){
                                let contents = res.data.data.contents;
                                let keyCollector = JSON.parse(JSON.stringify(this.keyCollector));
                                Object.keys(contents).map(item=>{
                                    keyCollector[item] = contents[item];
                                })
                                this.keyCollector = keyCollector;
                            }
                        })
                    }
                }
            })
        },
        onDataChangeNew(e){
            this.keyCollector[e.key] = e.data;
            updateWorkshopEntryEvaluation(this.data_id,this.entry_id,this.form_id,getUserId(),this.keyCollector).then(res=>{
                if(res.data.code===0){
                    this.data_id=res.data.data._id.$id;
                    this.getWorkshopEntryEvaluations();
                    this.$message({
                        message:'更新成功',
                        type:'success'
                    })
                }
            })
  
        },
        async fetchData(){
            let status = await this.getWorkshop()
            if(status){
                this.getWorkshopEntryByUser()
            }
        },
        async getWorkshop(){
            return new Promise((resolve)=>{
                getWorkshop(this.id).then(res=>{
                    if(res.data.code===0){
                        let template = res.data.data.template.dimensions;
                        let result = [];
                        for(let i in template){
                            let model = {key:i,...template[i]}
                            this.keyCollector[i] = '';
                            result.push(model)
                        }
                        this.template = result
                        resolve(true)
                    }
                })
            })
        },
        onDataChange(e){
            this.keyCollector[e.key] = e.data
            console.log(this.keyCollector)
        },
        getWorkshopEntryByUser(){
            getWorkshopEntryByUser(this.id,this.user_id).then(res=>{
                console.log(res)
                if(res.data.code===0){
                    this.entry_id = res.data.data._id.$id;
                    this.getWorkshopEntryCritiques()
                    this.getWorkshopEntryEvaluations()
                    let contents = res.data.data.entry_contents;
                    let keyCollector = JSON.parse(JSON.stringify(this.keyCollector));
                    Object.keys(contents).map(item=>{
                        keyCollector[item] = contents[item];
                    })
                    this.keyCollector = keyCollector
                }
            })
        },
        updateWorkshopEntry(){
            updateWorkshopEntry(this.entry_id,this.id,this.user_id,this.keyCollector).then(res=>{
                if(res.data.code===0){
                    this.$message({
                        message:'提交成功',
                        type:'success'
                    })
                }
            })
        },
        getWorkshopEntryCritiques(){
            getWorkshopEntryCritiques(this.entry_id).then(res=>{
                if(res.data.code===0){
                    this.critiques = res.data.data
                }
            })
        },
        updateWorkshopEntryCritique(){
            updateWorkshopEntryCritique(this.critique_id,this.entry_id,getUserId(),this.critique).then(res=>{
                this.showEdit = false;
                
                if(res.data.code===0){
                    this.critique="";
                    this.$message({
                        message:'更新成功',
                        type:'success'
                    })
                    this.fetchData()
                }else{
                    this.$message.error('更新失败')
                }
            })
        },
        add(){
            this.critique_id = ''
            this.critique = ''
            this.showEdit=true
        },
        edit(id,content){
            this.critique_id = id
            this.critique = content
            this.showEdit = true
        },
        addEvaluations(){
            this.$router.push({
                path:'evaluationInfo',
                query:{
                    workshop_id:this.id,
                    entry_id:this.entry_id,
                    id:''
                }
            })
        },
        editEvaluations(id){
            this.$router.push({
                path:'evaluationInfo',
                query:{
                    workshop_id:this.id,
                    entry_id:this.entry_id,
                    id:id
                }
            })
        },
        // getEvaluationFormByWorkshop(){
        //     getEvaluationFormByWorkshop(this.id).then(res=>{
        //         if(res.data.code===0){
        //             let evaluation_dimensions = res.data.data.evaluation_dimensions;
        //             this.keys = Object.keys(evaluation_dimensions)
        //         }
        //     })
        // },
        getWorkshopEntryEvaluations(){
            getWorkshopEntryEvaluations(this.entry_id).then(res=>{
                console.log(111111111111111111,res)
                if(res.data.code===0){
                    this.evaluations = res.data.data
                }
            })
        },
        removeEvaluation(id){
            removeEvaluation(id).then(res=>{
                this.fetchData()
            })
        },
        removeCritique(id){
            removeCritique(id).then(res=>{
                this.fetchData()
            })
        }
    }
}
</script>

<style scoped>
.container{
    width:100%
}
.partI{
    border: 1px solid #dedede;
    padding:20px;
    border-radius: 4px;
}
.reviewerP{
    height: 40px;
    line-height: 40px;
    color:#fff;
    font-size: 15px;
    padding-left: 20px;
    margin:0;
    margin-top:20px;
    background:-moz-linear-gradient(left,rgb(50, 115, 255),rgba(122, 50, 255, 0.2));

background:-ms-linear-gradient(left,rgb(50, 115, 255),rgba(122, 50, 255, 0.2));

background:-o-linear-gradient(left,rgb(50, 115, 255),rgba(122, 50, 255, 0.2));

background:-webkit-linear-gradient(left,rgb(50, 115, 255),rgba(122, 50, 255, 0.2));
}
.commonP{
    width: 90px;
    font-weight: bold;
}
.flex_critique{
    display: flex;
    position: relative;
}
.commentBtn{
position: absolute;
bottom: 5px;
right: 5px;
height: 20px;
line-height: 20px;
border-radius: 5px;
color: #FFF;
background-color: #409EFF;
border-color: #409EFF;
padding:0 5px;
cursor: pointer;
font-size: 10px;
margin:0;
}
</style>