<template>
  <div>
    <span>表达类型</span>
    <el-select
      v-model="expression_type"
      placeholder="请选择表达类型"
      style="margin-left:10px"
    >
      <el-option value="all" label="全部"></el-option>
      <el-option value="文字表达" label="文字表达"></el-option>
      <el-option value="视觉表达" label="视觉表达"></el-option>
      <el-option value="B端表达" label="B端表达"></el-option>
    </el-select>
    <span style="margin-left:10px">领域</span>
    <el-select
      v-model="domain"
      placeholder="请选择领域"
      style="margin-left:10px"
    >
      <el-option value="all" label="全部"></el-option>
      <el-option
        v-for="item in domains"
        :label="item"
        :value="item"
        :key="item"
      ></el-option>
    </el-select>
    <span style="margin-left:10px">提交人</span>
    <el-select
      v-model="user_id"
      placeholder="请选择提交人"
      style="margin-left:10px"
      v-loading="loading"
    >
      <el-option value="all" label="全部"></el-option>
      <el-option
        v-for="item in users"
        :label="item.label"
        :value="item.value"
        :key="item.value"
      ></el-option>
    </el-select>
    <el-button type="primary" @click="refreshTable" style="margin-left:10px"
      >查询</el-button
    >
    <el-table :data="compeletes" v-loading="loading" style="margin-top:10px">
      <!-- <el-table-column prop="sample.domain" label="领域" width="100px"></el-table-column> -->
      <el-table-column
        prop="sample.problem"
        label="原句"
        width="240"
      ></el-table-column>
      <!-- <el-table-column label="图片" width="120">
                <template slot-scope="scope">
                    <el-image
                        v-if="scope.row.sample && scope.row.sample.pic_url"
                        style="width: 100px; height: 100px"
                        :src="scope.row.sample.pic_url"
                        :preview-src-list="[scope.row.sample.pic_url]">
                    </el-image>
                </template>
            </el-table-column> -->
      <!-- <el-table-column prop="sample.answer" label="纠正" ></el-table-column> -->
      <!-- <el-table-column prop="sample.type" label="问题类型" width="100px"></el-table-column> -->
      <!-- <el-table-column prop="sample.expression_type" label="表达类型" width="100px"></el-table-column> -->
      <el-table-column prop="work" label="回答" width="500px"></el-table-column>
      <el-table-column label="作答人" width="100px">
        <template slot-scope="scope">
          {{
            scope.row.user
              ? scope.row.user.user_lastName + scope.row.user.user_givenName
              : ''
          }}
        </template>
      </el-table-column>
      <el-table-column
        prop="comment"
        label="评语"
        width="140"
      ></el-table-column>
      <el-table-column label="打分" width="200">
        <template slot-scope="scope">
          <div style="height:50px;display:flex;align-items:center">
            <el-rate
              v-model="scope.row.score"
              @change="
                e => {
                  submitChange(e, scope.row._id.$id)
                }
              "
            ></el-rate>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200">
        <template slot-scope="scope">
          <div></div>
          <el-button
            type="primary"
            size="small"
            style="margin-left:10px"
            @click="initComment(scope.row)"
            >评语</el-button
          >
          <el-button
            type="primary"
            size="small"
            @click="viewAudioRecord(scope.row)"
            >收听记录</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :visible.sync="showComment" title="编辑评语">
      <el-input type="textarea" v-model="comment" :rows="6"></el-input>
      <el-button
        type="primary"
        size="medium"
        @click="confirmComment"
        style="margin-top:10px"
        >确认</el-button
      >
    </el-dialog>
    <el-dialog :visible.sync="showRecord">
      <div>问题收听时长：{{ currentItem['problem'] }}</div>
      <div>答案收听时长：{{ currentItem['answer'] }}</div>
      <div>笔记收听时长：{{ currentItem['note'] }}</div>
      <el-table :data="currentArray" size="small">
        <el-table-column label="类型">
          <template slot-scope="scope">
            {{ keyMap[scope.row.type] }}
          </template>
        </el-table-column>
        <el-table-column label="时长">
          <template slot-scope="scope"> {{ scope.row.length }} </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import {
  getCollectionCompeletes,
  scoreCompelete,
  getCorrectionDomains,
  commentCompelete,
  viewAudioRecord
} from '../../api/assistant'
export default {
  data () {
    return {
      compeletes: [],
      compeletesAll: [],
      loading: false,
      showEdit: false,
      domain: 'all',
      domains: [],
      expression_type: 'all',
      user_id: 'all',
      users: [],
      showComment: false,
      comment: '',
      currentItem: {},
      currentArray: [],
      showRecord: false,
      keyMap: {
        problem: '问题',
        answer: '答案',
        note: '解析'
      }
    }
  },
  mounted () {
    this.getCorrectionDomains()
    this.fetchData()
  },
  methods: {
    fetchData () {
      this.loading = true
      getCollectionCompeletes().then(res => {
        this.compeletesAll = res.data
        this.compeletes = res.data
        const user_ids = {}
        res.data.map(item => {
          if (item.user_id) {
            user_ids[item.user_id] =
              item.user.user_lastName + item.user.user_givenName
          }
        })
        const users = []
        for (const key in user_ids) {
          users.push({
            value: key,
            label: user_ids[key]
          })
        }
        this.users = users
        this.loading = false
      })
    },
    submitChange (e, compelete_id) {
      scoreCompelete(compelete_id, e).then(res => {
        if (res.data.code === 0) {
          this.$message.success('打分成功')
        }
      })
    },
    getCorrectionDomains () {
      getCorrectionDomains().then(res => {
        this.domains = res.data
      })
    },
    refreshTable () {
      this.compeletes = this.compeletesAll.filter(item => {
        debugger
        if (
          ((item.sample && item.sample.domain === this.domain) ||
            this.domain === 'all') &&
          ((item.sample &&
            item.sample.expression_type === this.expression_type) ||
            this.expression_type === 'all') &&
          (item.user_id === this.user_id || this.user_id === 'all')
        ) {
          return true
        }
      })
    },
    initComment (row) {
      this.compelete_id = row._id.$id
      this.comment = row.comment ? row.comment : ''
      this.showComment = true
    },
    confirmComment () {
      commentCompelete(this.compelete_id, this.comment).then(res => {
        if (res.data.code === 0) {
          this.$message.success('评价成功')
          this.showComment = false
          this.fetchData()
          this.refreshTable()
        }
      })
    },
    viewAudioRecord (data) {
      this.showRecord = true
      viewAudioRecord(data.sample_id, data.user_id).then(res => {
        const obj = {
          problem: 0,
          answer: 0,
          note: 0
        }
        const array = res.data
        for (const item of array) {
          if (item.type === 'problem') {
            obj.problem += item.length
          }
          if (item.type === 'answer') {
            obj.answer += item.length
          }
          if (item.type === 'note') {
            obj.note += item.length
          }
          item.length = `${Math.floor(item.length / 60)}分${item.length % 60}秒`
        }
        obj.problem = `${Math.floor(obj.problem / 60)}分${obj.problem % 60}秒`
        obj.answer = `${Math.floor(obj.answer / 60)}分${obj.answer % 60}秒`
        obj.note = `${Math.floor(obj.note / 60)}分${obj.note % 60}秒`
        this.currentItem = obj
        this.currentArray = array
      })
    }
  }
}
</script>

<style></style>
