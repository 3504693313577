<template>
    <div style="padding: 20px;box-sizing: border-box;width: 100%;">
        <el-button type="primary" size="small" @click="editEmail(null)">Add Email</el-button>
        <el-table :data="dataList" style="margin-top: 20px;width: 100%;">
            <el-table-column prop="index" type="index" label="Index" width="100px"></el-table-column>
            <el-table-column prop="email_address" label="Email"></el-table-column>
            <el-table-column prop="email_name" label="Sender Name"></el-table-column>
            <el-table-column prop="count_pending_reply" label="# of Emails Pending Reply"></el-table-column>
            <el-table-column prop="" label="Operation">
                <template slot-scope="scope">
                    <el-button type="success" size="small" @click="editEmail(scope.row)">Edit</el-button>
                    <el-button type="danger" size="small" @click="doRemoveEmail(scope.row)">Delete</el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-dialog :visible.sync="showEmailDialog" title="Edit Email" width="45%">
            <div>
                <el-form>
                    <el-form-item label="Email">
                        <el-input v-model="companyEmailInfo.email_address"></el-input>
                    </el-form-item>
                    <el-form-item label="Sender Name">
                        <el-input v-model="companyEmailInfo.email_name"></el-input>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer" style="margin-top: 20px;">
                    <el-button @click="showEmailDialog = false">Cancel</el-button>
                    <el-button type="primary" @click="saveEmailInfo()">Save</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import {
    updateCompanyEmail,
    removeCompanyEmail,
    getCompanyEmails
} from '../../api/assistant'
export default ({
    name: 'index',
    data() {
        return {
            dataList: [],
            companyEmailInfo: {
                id: '',
                email_address: '',
                email_name: ''
            },
            showEmailDialog: false
        }
    },
    mounted() {
        this.fetchData()
    },
    methods: {
        fetchData() {
            getCompanyEmails().then((res) => {
                this.dataList = res.data
            })
        },
        doRemoveEmail(row) {
            this.$alert('你确定要删除该邮件吗？', '提示', {
                callback: action => {
                    if (action == 'confirm') {
                        removeCompanyEmail(row._id.$id).then((res) => {
                            this.$message.success('删除成功')
                            this.fetchData()
                            this.notifyEmailsChanged()
                        })
                    }
                }
            })
        },
        saveEmailInfo() {
            if (!this.companyEmailInfo.email_address) {
                this.$message.warning('请输入邮箱!')
                return
            }
            updateCompanyEmail(this.companyEmailInfo).then((res) => {
                this.$message.success('保存成功')
                this.showEmailDialog = false
                this.notifyEmailsChanged()
                this.fetchData()
            })
        },
        editEmail(row) {
            if (row) {
                this.companyEmailInfo = {
                    id: row._id.$id,
                    email_address: row.email_address,
                    email_name: row.email_name
                }
            } else {
                this.companyEmailInfo = {
                    id: '',
                    email_address: '',
                    email_name: ''
                }
            }
            this.showEmailDialog = true
        },
        notifyEmailsChanged() {
            this.$emit('handleEmailsChanged')
        }
    }
})
</script>
