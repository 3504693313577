<template>
    <div style="color:#606266">
        <div class="top-holiday">
            <div class="top-holiday-item">
                调休假
                <span v-if="user.lieu_holiday" class="holiday-item-txt">
                    {{types['调休']?user.lieu_holiday-types['调休']['hour_len']/8:user.lieu_holiday}}
                </span>
                <span v-else class="holiday-item-txt">0</span>
                天
            </div>
            <div class="top-holiday-item">
                年假
                <span v-if="user.year_holiday" class="holiday-item-txt">
                    {{types['年假']?user.year_holiday-types['年假']['hour_len']/8:user.year_holiday}}
                </span>
                <span v-else class="holiday-item-txt">0</span>
                天
            </div>
            <div class="top-holiday-item">
                特殊病假
                <span v-if="user.special_holiday" class="holiday-item-txt">
                    {{types['特殊病假']?user.special_holiday-types['特殊病假']['hour_len']/8:user.special_holiday}}
                </span>
                <span v-else class="holiday-item-txt">0</span>
                天
            </div>
        </div>
        <el-form style="margin-top:20px">
            <el-form-item label="类型">
                <el-select v-model="form.type">
                    <el-option label="事假" value="事假"></el-option>
                    <el-option label="一般病假" value="一般病假"></el-option>
                    <el-option label="特殊病假" value="特殊病假"></el-option>
                    <el-option label="调休" value="调休"></el-option>
                    <el-option label="年假" value="年假"></el-option>
                    <el-option label="婚假" value="婚假"></el-option>
                    <el-option label="丧假" value="丧假"></el-option>
                    <el-option label="产假" value="产假"></el-option>
                    <el-option label="陪产假" value="陪产假"></el-option>
                </el-select>
            </el-form-item>
            <div v-if="form.type==='一般病假' || form.type==='特殊病假'" class="Holidaytip">
                <div>
                    <h3>病假流程</h3>
                    <ul style="padding-inline-start:20px">
                        <li>凡请病假，最迟应在病假涉及第一个工作日当天或之前向公司人事线上提交病假申请（包括一般病假和特殊病假）；未及时提交病假申请，而擅自按照病假休息的，即使病假手续后续补交齐全、审批通过，病假申请前的休假日一律按照事假处理；</li>
                        <li>凡请病假，应在病假申请时，先向公司人事提供病假单原件照片（对应相应病假日期）和相应医院检查结果（如化验单、CT扫描影象等）的原件照片（须带有检查当日日期戳）；病假结束返岗当天应出具病假单原件，由公司人力资源部予以审核归档；</li>
                    </ul>
                    <h3>病假工资</h3>
                    <div style="font-weight:bold">员工申请一般病假或特殊病假，经公司审批准予休假的，未优先使用调休假而按照病假休息的，参照以下规定计算和发放病假工资：</div>
                    <ul style="padding-inline-start:20px">
                        <li>病假期间的基本工资按照《上海市企业工资支付办法（2016）》第九条第（二）点规定，按劳动者正常出勤月依照本办法第二条规定的工资(不包括加班工资)的70%确定；</li>
                        <li>病假期间基本工资的发放按照《劳动法》规定，参照员工自入职公司之日起连续工龄计算系数（员工离职又入职的，自最新一次入职日起计算）：
                            <ul style="padding-inline-start:20px">
                                <li>连续工龄不满2年的，按本人病假工资基数的60%计发;</li>
                                <li>连续工龄满2年不满4年的，按本人病假工资基数的70%计发;</li>
                                <li>连续工龄满4年不满6年的，按本人病假工资基数的80%计发;</li>
                                <li>连续工龄满6年不满8年的，按本人病假工资基数的90%计发;</li>
                                <li>连续工龄满8年及以上的，按本人病假工资基数的100%计发;</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div v-if="form.type==='一般病假'" class="Holidaytip">
                    <div style="font-weight:bold">员工因身体原因，获得医院开具的病假单的，则属于一般病假情形，按照以下规定处理：</div>
                    <ul style="padding-inline-start:20px">
                        <li>一般病假的申请，员工需要向公司提供上海市本市三级医院盖章开具的病假单，以及病假单开具当天在同一医院的就诊记录、病历记录和检查报告；只提供病假单，但无法提供对应就诊记录和检查报告的，视作无法证明病情，公司对病假单真实性不予采信，不接受按一般病假审批；由私人医院、私人诊所、上海市本市三级以下医院或外地医院开具的病假单，公司对病假单可靠性不予采信，不接受按一般病假审批；</li>
                        <li>员工向公司提供正规病假单和对应医院就诊记录、病历记录、检查报告的，须确保病假单上的病假事由与医院就诊记录、病历记录、检查报告上的病症诊断一致；病假事由与医院就诊检查诊断不一致的，或医院就诊检查诊断记录上缺少对病假足够医学支持的，公司对病假单真实性不予采信，不接受按一般病假审批；</li>
                        <li>公司有权对员工提交病假单的真实性向开具医院进行二次确认，经验证开具虚假病假单的，取消相应病假审批，并依照重大失信行为和严重违反纪律行为对员工进行后续处理；</li>
                        <li>员工提交的病假单中，病假长度明显超出对应病症合理需要的病假天数的，或存在其他明显不合理病假开具行为的，即使有相应病假单的支持，公司有权向病假开具医院提起病假开具调查和投诉，医院无法就病假开具的合理性做出充分解释的，不接受按一般病假审批，并同时向医院上级主管部门（上海市卫生局）反映情况和投诉；经公司核实发现员工进行不合理病假申请的，可将员工相应病假申请行为和实际请假行为按照“消极工作行为”记录在工作绩效考核中；</li>
                        <li>所有超过三天的一般病假申请，公司必须进行病假单的真实性核实与病假合理性核实；</li>
                        <li>由于病假单的核实需要相应周期，在核实完成之前员工选择先行休息的，但后经核实不符合一般病假批准要求的，则一律按照事假处理；若公司在病假开始之前完成核实的，但不予病假申请通过的，若员工还强行进行休假，则一律按照事假处理；</li>
                        <li>一般病假申请，经公司核实准予休假的，员工若有调休假未休的，则优先使用调休假；没有调休假未休的，则按照一般病假休假，并按照相关规定计算和发放病假期间工资；</li>
                    </ul>
                </div>
                <div v-if="form.type==='特殊病假'" class="Holidaytip">
                    <div style="font-weight:bold">员工因个人感觉不舒服前往医院检查、配药、换药等，但医院无法提供病假单的，则按照以下规定处理：</div>
                    <ul style="padding-inline-start:20px">
                        <li>员工因上述情形请假，必须提供请假当日的上海市本市三级医院的挂号和身体检查相关证明（包括但不限于病历描述、挂号记录、检查结果报告等）。请假时间仅限检查当日一天，检查当日以外时间不得以上述情形为理由请假。</li>
                        <li>员工因上述情形请假的，需按照公司特殊病假额度管理规定申请和批准：
                            <ul style="padding-inline-start:20px">
                                <li>自员工入职公司之日起，特殊病假额度以每满一个月（以到达每月同一入职日为准）可增加一天、累积未使用额度最多不超过三天进行管理；若额度已达三天，则后续每个月额度不会增加；若额度未达三天，则后续每个月额度增加一天；</li>
                                <li>员工在请特殊病假时，特殊病假额度有余额的，可进入特殊病假的审批和处理流程，并消除相应额度；特殊病假额度没有余额的，不准按特殊病假情形请假，直接按照事假进行处理；</li>
                                <li>特殊病假额度有余额的，请假时员工若有调休假未休，则优先使用调休假，并扣减特殊病假额度；若没有调休假未休，则按照一般病假休息并参照病假工资规定发放期间工资；</li>
                            </ul>
                        </li>
                        <li>员工仅以个人身体不舒服为由（包括但不限于生理期、感冒发烧、拉肚子、休息保胎等），但无法提供上海市本市三级医院就诊检查记录的，若有调休假未休的，优先休调休假；无调休假可休的，则或主动申请调班补上工作时间，或直接按照事假进行处理；一般病假的申请，若公司对员工提供病假单和医院诊断资料核实后，认为医院存在夸大病情、开具病假单不合理的，对一般病假申请不予准假的，亦可理解为参照本条情形进行处理；</li>
                        
                    </ul>
                </div>
            </div>

            <div v-if="form.type==='事假'" class="Holidaytip">
                <div style="font-weight:bold">事假：</div>
                <ul style="padding-inline-start:20px">
                    <li>事假申请的最小时间单位为小时，不足一天的按照实际小时数除以8小时计算对应请假天数的小数；</li>
                    <li>请事假会优先扣除调休假，调休假扣完优先扣除年假，若无可抵消假期，主管可选择要求员工安排调班在特定时间将工作时间补上（按照调休处理）；员工拒绝调班补足工作时间的，则最终按照事假处理；</li>
                    <li>所有事假均需要按照以下规定进行提前申请，未按照要求提前申请事假的，公司可有权拒绝事假申请：
                        <ul style="padding-inline-start:20px">
                            <li>事假请假数小时的，可于请假当天提出申请；</li>
                            <li>事假请假1天，需提前至少1个自然日申请；</li>
                            <li>事假请假1-3天，需提前至少7个自然日申请；</li>
                            <li>事假请假3-5天，需提前至少15个自然日申请；</li>
                            <li>事假请假5天以上，需提前至少30个自然日申请；</li>
                        </ul>
                    </li>
                    <li>员工在申请事假时，需在申请中说明具体请假事由，公司主管应对具体请假事由进行评估；员工不提供请假事由的，公司可拒绝员工事假申请；员工不提供具体请假事由，仅以个人私事等通用模糊说法作为理由的，公司可拒绝员工事假申请；员工提供具体请假事由，但经评估没有一定紧急性和必要性的，公司可拒绝员工事假申请；若公司对事假申请不予以批准，但员工执意请假的，公司可依据具体情况将其认定为“旷工行为”或“消极工作行为”进行处理；</li>
                    <li>员工休事假期间，应按照每月21.75天计算每日工资，然后扣除对应事假时间的工资；</li>
                </ul>
            </div>

            <div v-if="form.type==='年假'" class="Holidaytip">
                <div style="font-weight:bold">公司依照国家《职工带薪年休假条例（2008）》依法实行年假制度，且将部分规定收录如下：</div>
                <ul style="padding-inline-start:20px">
                    <li>第三条：职工累计工作已满1年不满10年的，年休假5天；已满10年不满20年的，年休假10天；已满20年的，年休假15天。国家法定休假日、休息日不计入年休假的假期。</li>
                    <li>第四条：职工有下列情形之一的，不享受当年的年休假：
                        <ol style="padding-inline-start:20px">
                            <li>职工依法享受寒暑假，其休假天数多于年休假天数的；</li>
                            <li>职工请事假累计20天以上且单位按照规定不扣工资的；</li>
                            <li>累计工作满1年不满10年的职工，请病假累计2个月以上的；</li>
                            <li>累计工作满10年不满20年的职工，请病假累计3个月以上的；</li>
                            <li>累计工作满20年以上的职工，请病假累计4个月以上的。</li>
                        </ol>
                    </li>
                </ul>
                <div style="font-weight:bold">依据国家《职工带薪年休假条例（2008）》并结合公司实际，制定以下规定：</div>
                <ul style="padding-inline-start:20px">
                    <li>年休假采取按年休假日累积制度，累积速度为“全年365天 除以 全年 年休假 额度 得出的 天数“；员工自进入公司工作之日起，每满足相应天数，获得年休假一天；不足相应天数的，年休假不按照小数进行累计；</li>
                    <li>年休假累计达到全年额度后：
                        <ul style="padding-inline-start:20px">
                            <li>公司应安排员工进行强行年休假休假；员工拒不休假的，将视为员工主动放弃休假，公司按照员工正常出勤工资计算年休假对应工资发放给员工后，年休假额度清零；</li>
                            <li>公司因实际工作需要，主动要求员工放弃年休假权益，经员工同意的，公司按照员工正常出勤工资的三倍计算年休假对应工资发放给员工后，年休假额度清零；</li>
                            <li>员工提前主动提出离职或告知劳动合同到期主动不续约的，有剩余年休假未休的，公司应强行安排员工在离职前安排年休假，员工拒不休假的，将视为员工主动放弃休假，公司按照员工正常出勤工资计算年休假对应工资发放给员工后，年休假额度清零；</li>
                            <li>员工提前主动提出离职或告知劳动合同到期主动不续约的，有剩余年休假未休的，但公司因实际工作需要，主动要求员工放弃年休假权益，经员工同意的，公司按照员工正常出勤工资的三倍计算年休假对应工资发放给员工后，年休假额度清零；</li>
                        </ul>
                    </li>
                    <li>员工因故申请事假的，若年休假有余额的，优先使用年休假额度；年休假没有余额的，则按照事假处理；具体优先顺序以事假部分规定为准；</li>
                    <li>年休假的申请，无论是员工主动申请或公司要求休假，均需提前一周通知到对方，并安排好</li>
                </ul>
            </div>

            <div v-if="form.type==='婚假'" class="Holidaytip">
                <div style="font-weight:bold">婚假：</div>
                <ol style="padding-inline-start:20px">
                    <li>符合法律规定结婚的员工可按照国家现通行做法享受3天婚假；</li>
                    <li>按照《上海市人口与计划生育条例（2016）》第三十一条规定，符合法律规定结婚的公民，除上述享受国家规定的婚假外，增加婚假七天；</li>
                    <li>婚假申请需一次性连续休假，员工不可将同一次结婚的婚假天数拆开进行多次婚假申请；员工主动申请婚假天数不足法律法规允许婚假天数的，则视为员工主动放弃休假，不得在其他时间另行申请补足；</li>
                    <li>婚假天数指自然日（包括工作日和双休日，不包括国家法定假日），遇法定节假日顺延；</li>
                    <li>员工申请婚假需提供有效结婚证件，婚假时间需在结婚证件登记日期的6个自然月内；超过6个月的婚假申请不予批准，视作员工主动放弃婚假权益；</li>
                    <li>员工申请婚假需至少提前30个自然日向公司提出婚假申请，婚假申请提前时间不足30个自然日的，公司应不予批准婚假申请，并要求员工更改婚假申请休息日期；员工未获公司婚假批准，强行进行婚假休息的，可按照事假处理，并可依据具体情况将其认定为“消极工作行为”进行处理；</li>
                    <li>婚假期间，按照《上海市人口与计划生育条例（2016）》第三十一条规定，按照本人正常出勤应得的工资发给；</li>
                </ol>
            </div>

            <div v-if="form.type==='产假' || form.type==='陪产假'" class="Holidaytip">
                <div style="font-weight:bold">产假：</div>
                <ol style="padding-inline-start:20px">
                    <li>按照《女职工劳动保护特别规定》第七条规定：
                        <ol style="padding-inline-start:20px">
                            <li>符合国家生育法律法规规定的进行生育的女性员工，享受98天产假，其中产前可以休假15天；难产的，应增加产假15天；生育多胞胎的，每多生育1个婴儿，可增加产假15天；</li>
                            <li>女职工怀孕未满4个月流产的，享受15天产假；怀孕满4个月流产的，享受42天产假；</li>
                        </ol>
                    </li>
                    <li>按照《上海市人口与计划生育条例（2016）》第三十一条规定，符合法律法规规定生育的夫妻，女方除享受国家规定的产假外，还可以再享受生育假三十天，男方享受配偶陪产假十天。生育假享受产假同等待遇，配偶陪产假期间的工资，按照本人正常出勤应得的工资发给；</li>
                    <li>所有产假均指自然日，包含周末及国家法定节假日；</li>
                    <li>男性员工申请配偶陪产假的，需提供配偶生育的预产期证明或实际生育证明，配偶生育日期须在所申请的配偶陪产假期间；男性员工无法提供配偶预产期证明或实际生育证明的，不得申请配偶陪产假；</li>
                </ol>
            </div>

            <div v-if="form.type==='丧假'" class="Holidaytip">
                <div style="font-weight:bold">丧假：</div>
                <ul style="padding-inline-start:20px">
                    <li>若有员工直系亲属（包括父母、配偶、子女）丧亡，公司将根据员工的司龄酌情给予丧假（连续天数，包含节假日），丧假期间涉及到正常工作日的，公司给付员工正常工资：
                        <ul style="padding-inline-start:20px">
                            <li>在公司连续工作时间累积超过36个月，准予丧假三天</li>
                            <li>在公司连续工作时间累积超过24个月但不满36个月，准予丧假两天</li>
                            <li>在公司连续工作时间累积超过12个月但不满24个月，准予丧假一天</li>
                            <li>在公司连续工作时间累积不超过12个月，不提过丧假额度</li>
                        </ul>
                    </li>
                    <li>员工经公司批准休丧假的，涉及到工作日的，按照《上海市企业工资支付办法（2016）》第九条第（二）点规定，按劳动者正常出勤月依照本办法第二条规定的工资(不包括加班工资)的70%确定；</li>
                    <li>若员工没有丧假额度，或有旁系亲属丧亡，员工因治丧请假的，一律按照事假处理；且单次因治丧请事假不得超过三天（包含节假日）；因此类治丧请事假超过三天的，公司可依据具体情况将其认定为“旷工行为”或“消极工作行为”进行处理；</li>
                </ul>
            </div>


            <el-form-item label="请假原因">
                <el-input type="textarea" :rows="6" v-model="form.details"></el-input>
            </el-form-item>
            <el-form-item label="补充图片">
                <div style="width:100%;display:flex;flex-wrap:wrap">
                    <img v-for="(item,index) in pic_arr" :key="index" :src="item" class="avatar" @click="removePic(index)">
                    <el-upload
                        class="avatar-uploader"
                        action="123"
                        :before-upload="beforUploadApplyPic">
                        <i class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </div>
            </el-form-item>
            <el-form-item label="请假时间">
                <el-date-picker
                    v-model="time"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="请假时长（天）">
                <el-input-number v-model="form.day_len" @change="changeHour"></el-input-number>
            </el-form-item>
            <el-form-item label="请假时长（小时）">
                <el-input-number v-model="form.hour_len" @change="changeDay"></el-input-number>
            </el-form-item>
            <el-form-item label="审批人">
                <el-tag v-for="item in check_users" :key="item._id.$id">{{item.user_lastName+item.user_givenName}}</el-tag>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="applyHoliday">确认</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import { applyHoliday,uploadApplyPic,getUser,checkUserAppliedHolidays } from '../../api/assistant'
import { getUserId } from '../../utils/store'
export default {
    data(){
        return{
            pic_arr:[],
            time:[],
            check_users:[],
            form:{
                type:'',
                details:'',
                day_len:'',
                hour_len:''
            },
            types:[],
            user:{}
        }
    },
    mounted(){
        this.getUser()
        this.checkUserAppliedHolidays()
    },
    methods:{
        getUser(){
            getUser(getUserId()).then(res=>{
                this.user = res.data
                this.check_users = res.data.supervisers
            })
        },
        applyHoliday(){
            let time = this.time.map(item=>item.getTime()/1000)
            let check_users = this.check_users.map(item=>item._id.$id)
            applyHoliday('',getUserId(),this.form.type,this.form.details,this.pic_arr,time,this.form.hour_len,check_users).then(res=>{
                if(res.data.code===0){
                    this.$message({
                        message:'申请成功',
                        type:'success'
                    })
                    this.showApply = false
                    this.getUser()
                }else{
                    this.$message.error(res.data.msg)
                }
            })
        },
        beforUploadApplyPic(file){
            const param = new FormData()
            param.append('function', 'uploadApplyPic')
            param.append('file', file)

            uploadApplyPic(param).then(res=>{
                if(res.data.code===0){
                    this.pic_arr.push(res.data.data)
                }
            })
        },
        removePic(index){
            this.$confirm('是否确认删除？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.pic_arr.splice(index,1)
            })
        },
        changeHour(){
            this.form.hour_len = this.form.day_len*8
        },
        changeDay(){
            this.form.day_len = this.form.hour_len/8
        },
        checkUserAppliedHolidays(){
            checkUserAppliedHolidays(getUserId()).then(res=>{
                console.log(res)
                this.types = res.data
            })
        }
    }
}
</script>

<style>
.Holidaytip{
    color: #606266;
}
.Holidaytip ul li{
    list-style-type: disc;
    font-size: 16px;
    line-height: 24px;
}
</style>