<template>
    <div class="adminContainer">
        <div class="header">
            <img src="../assets/loginTitle.png" style="width:90px;margin-left:50px">
            <div class="rightPart">
                <span>{{userName}}</span>
                <img src="../assets/exit.svg" style="margin-left:20px;cursor: pointer;" @click="logout">
            </div>
        </div>
        <div class="nav">
            <div class="contentContainer">
                <div @click="toManage('userAdmin')" class="item">
                    <img src="../assets/icon/员工账号管理.svg">
                    <div class="item-content">
                        <div class="item-title">员工账号管理</div>
                    </div>
                </div>
                <div @click="toManage('smartAdmin')" class="item">
                    <img src="../assets/icon/smart.svg">
                    <div class="item-content">
                        <div class="item-title">SmartWork培训管理</div>
                    </div>
                </div>
                <div @click="toManage('trackfeedbacknumAdmin')" class="item">
                    <img src="../assets/icon/追踪反馈数量.svg">
                    <div class="item-content">
                        <div class="item-title">追踪反馈数量</div>
                    </div>
                </div>
                <div @click="toManage('examAdmin')" class="item">
                    <img src="../assets/icon/考试管理.svg">
                    <div class="item-content">
                        <div class="item-title">考试管理</div>
                    </div>
                </div>
                <div @click="toManage('holiday')" class="item">
                    <img src="../assets/icon/假期管理1.svg">
                    <div class="item-content">
                        <div class="item-title">假期管理</div>
                    </div>
                </div>

                <div @click="toManage('recorder')" class="item">
                    <img src="../assets/icon/假期管理1.svg">
                    <div class="item-content">
                        <div class="item-title">录音测试</div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import {
  setBlock,
  getUserId,
  removeUserId,
  removeUserName,
  getUserName
} from '../utils/store'

export default {
  data () {
    return {
      userName: ''
    }
  },
  mounted () {
    this.userId = getUserId()
    this.userName = getUserName()
  },
  methods: {
    toManage (block) {
      setBlock(block)
      this.$router.push({
        path: '/adminmanage',
        query: {
          block: block
        }
      })
    },
    logout () {
      removeUserId()
      removeUserName()
      location.reload()
    }
  }
}
</script>

<style lang="scss" scoped>
.adminContainer{
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100vh;
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        flex-shrink: 0;
        background: #ffffff;
        box-shadow: 0px 3px 6px 1px rgba(0, 21, 41, 0.12);
        border-radius: 0px 0px 0px 0px;
        .rightPart{
            display: flex;
            align-items: center;
            margin-right: 30px;
            font-family: Alibaba PuHuiTi 2.0-65 Medium, Alibaba PuHuiTi 20;
            font-weight: normal;
            color: #999999;
            font-size: 16px;
        }
    }
    .nav {
        width: 100%;
        flex: 1;
        overflow-y: scroll;
        .intro{
            width: 1580px;
            height: 184px;
            margin: 20px auto;
            background: #FFFFFF;
            box-shadow: 0px 3px 30px 1px rgba(0,0,0,0.0800);
            border-radius: 2px 2px 2px 2px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .item{
                width: 250px;
                padding: 0 20px;
                box-sizing: border-box;
                text-align: center;
                border-right: 1px solid #EAEAEA;
            }
            .intro-title{
                font-family: Alibaba PuHuiTi 2.0-55 Regular, Alibaba PuHuiTi 20;
                font-weight: normal;
                color: #666666;
            }
            .intro-content{
                font-size: 18px;
                font-family: Alibaba PuHuiTi 2.0-75 SemiBold, Alibaba PuHuiTi 20;
                font-weight: bold;
                color: #333333;
                margin-top: 17px;
            }
        }
        .fastWay{
            width: 1580px;
            margin: 30px auto;
            height: 90px;
            background: #FFFFFF;
            box-shadow: 0px 3px 30px 1px rgba(0,0,0,0.0800);
            border-radius: 2px 2px 2px 2px;
            display: flex;
            align-items: center;
            .item{
               padding: 8px 16px;
                font-size: 14px;
                text-align: center;
                background: #E6F0FF;
                border-radius: 5px 5px 5px 5px;
                font-family: Alibaba PuHuiTi 2.0-75 SemiBold, Alibaba PuHuiTi 20;
                font-weight: normal;
                color: #4A8AF4;
                margin-left: 40px;
                cursor: pointer;
            }
        }
        .contentContainer{
            width: 1600px;
            margin: 40px auto;
            display: flex;
            flex-wrap: wrap;
            .item{
                width: 380px;
                height: 160px;
                background: #FFFFFF;
                box-shadow: 0px 3px 30px 1px rgba(0,0,0,0.0800);
                border-radius: 2px 2px 2px 2px;
                margin: 10px;
                display: flex;
                align-items: center;
                padding: 0 34px;
                box-sizing: border-box;
                cursor: pointer;
                img{
                    margin-right: 30px;
                }
                .item-content{
                    .item-title{
                        font-family: Alibaba PuHuiTi 2.0-75 SemiBold, Alibaba PuHuiTi 20;
                        font-weight: normal;
                        color: #333333;
                        font-size: 18px;
                    }
                    .item-title1{
                        font-family: Alibaba PuHuiTi 2.0-75 SemiBold, Alibaba PuHuiTi 20;
                        font-weight: normal;
                        color: #333333;
                        font-size: 16px;
                        margin-top: 8px;
                    }
                }
            }
        }
    }
}

</style>
