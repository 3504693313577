<template>
    <div>home1111111111
      <el-button type="primary" @click="open">打开摄像头</el-button>
      <video ref="video" autoplay></video>
    </div>
  </template>
  
  <script>
  export default {
      methods: {
          open() {
              console.log('打开摄像头')
              navigator.mediaDevices.getUserMedia({
                  video: true
              }).then(stream => {
                  console.log(stream)
                  this.$refs.video.srcObject = stream
              }).catch(err => {
                  console.log(err)
              })
          }
      }
  }
  </script>
  
  <style>
  
  </style>