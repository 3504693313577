<template>
  <div class="Home">
    <Head />
    <el-button @click="drawer = true" type="primary" style="position: absolute; top: 10px; left: 10px">
      本章目录
    </el-button>

    <!-- <el-button type="text" style="position: absolute; top: 60px; left: 10px;">调整字号</el-button>
    <el-button type="text" style="position: absolute; top:100px; left: 10px;">调整背景颜色</el-button> -->
    <el-button @click="$router.back(-1)" type="text" style="position: absolute; top: 60px; left: 10px">返回总目录</el-button>

    <el-container style="height: 100%; border: 1px solid #eee;height:calc(100% - 60px)" >
      <el-drawer
        custom-class="color"
        :visible.sync="drawer"
        direction="ltr"
        size="300px"
      >
        <el-aside width="100%" style="background-color: rgb(238, 241, 246)">
          <el-tree :data="menuItems" :props="defaultProps" @node-click="handleNodeClick" :render-content="renderContent"></el-tree>
        </el-aside>
      </el-drawer>
      <el-container class="main-container">
        <el-main style="font-family: 'Amiri',serif;overflow: unset;">
          <!-- <el-table :data="tableData">
            <el-table-column prop="date" label="日期" width="140">
            </el-table-column>
            <el-table-column prop="name" label="姓名" width="120">
            </el-table-column>
            <el-table-column prop="address" label="地址">
            </el-table-column>
          </el-table> -->
          <el-container class="title">
            {{content.content_name || '请选择content课程~'}}
            <span class="title-description" v-if="content_quiz.length!==0"> (本课程有{{content_quiz.length}}道随堂练习)</span>
            
            <!-- <el-button size="mini" type="success" style="margin-left: 10px" @click="resetQuiz" v-if="content_quiz.length!==0  " v-show="onDoing">{{'删除错题记录'}}</el-button> -->
          </el-container>

          <div v-if="content.content_name" class="content_instruction">{{content.content_instruction || ''}}</div>
          <el-collapse v-model="activeNames" >
            <el-collapse-item title="附件：" name="1" v-show="content_attach.length !== 0">
              <div v-for="item in content_attach" :key="item.attach_key"><a class="attach" target="_blank" :href="'//'+item.attach_address" :download="item.attach_title">{{item.attach_title}}</a></div>
              <!-- <div>在界面中一致：所有的元素和结构需保持一致，比如：设计样式、图标和文本、元素的位置等。</div> -->
            </el-collapse-item>
          </el-collapse>
          <div></div>
          <div v-if="content.content_type === 'AUDIO'" style="height:calc(100% - 60px)">
            <audio :src="content.audio_url" controls controlsList="nodownload" style="margin-top: 10px"></audio>
            <div v-if="content.html_text" v-html="content.html_text"></div>
          </div>
          <!-- <div v-if="content.content_type === 'HTML'" style="height:calc(100% - 60px)"> -->
          <div v-if="content.content_type === 'HTML'">
            <div v-if="content.html_text" v-html="content.html_text"></div>
            <!-- <iframe v-if="!content.html_text && content.pdf_url" :src="content.pdf_url" width="100%" height="100%">

            This browser does not support PDFs. Please download the PDF to view it: <a :href="content.pdf_url">Download PDF</a>

            </iframe> -->
            <div style="width: 100%;height:100%;" v-if="!content.html_text && content.pdf_url">
              <pdf  :src="content.pdf_url" width="100%" height="100%" 
                v-for="i in numPages"
                :key="i"
                :page="i"
                style="width:100%;"
                @page-loaded="pageloaded"
              />
            </div>

          <div v-if="!content.html_text && !content.pdf_url" style="margin-top:20px">Coming soon.</div>
          </div>

          <el-button size="mini" type="success" style="margin-left: 10px" @click="beginQuiz" v-if="content_quiz.length!==0" v-show="!onDoing" :disabled="content_quiz.length==0">去做题</el-button>
          <el-button size="mini" type="success" style="margin-left: 10px" @click="endQuiz" v-if="content_quiz.length!==0" v-show="onDoing">收起题目</el-button>
          <el-button size="mini" type="success" style="margin-left: 10px" @click="lastPage"  v-show="selectPage !=0">{{'上一章'}}</el-button>
          <el-button size="mini" type="success" style="margin-left: 10px" @click="nextPage" v-show="selectPage != menuItems.length-1">{{'下一章'}}</el-button>
          <el-button size="mini" type="success" style="margin-left: 10px" @click="toWorkshop" v-show="content.workshop_ids && content.workshop_ids.length!==0">完成workshop</el-button>

          <Quiz :studentId="studentId" :quizs="quizs" :onDone="onQuizDone" :onCI="onChangeDone"/>
         
          <div v-if="content.content_type === 'VIDEO'">
            <div>
              <video :src="content.video_url" id="videoPlay" controls="controls" class="video" oncontextmenu="return false;" v-show="!isBeginQuiz&&!onDoing" @timeupdate="timeupdate"  controlslist="nodownload" @ended="videoEnd">您的浏览器不支持 video 视屏播放。</video>
              
            </div>
          </div>

          <!-- <div v-if="content.content_name">
            <div class="addCommets">
                <el-input style="margin-top:10px;"   type="textarea" v-model="educator_comment" placeholder="请输入您的疑问……（如果您没有问题，可以不用在这里提交问题）"></el-input>
                <el-select v-model="now_index" placeholder="请选择问题归属">
                  <el-option label="课程内容" :value="0"></el-option>
                  <el-option v-for="(item,index) in content_quiz" :label="item.question_title" :key="item._id.$id" :value="index+1" class="option"></el-option>
                </el-select>
                <el-button style="margin-top:20px"  @click="submitComment">提交问题</el-button>

            </div>
            <div class="comments">
              
              课堂答疑：{{comments.length === 0 ? '无': ''}}
              <div v-for="item in comments" :key="item._id.$id" style="margin-top: 10px;    border-bottom: 1px solid #ccc;">
                {{item.account_name}}-{{item.sender_name}}: {{item.message_content}}
                <span style="color:#ccc;font-size:12px;padding:5px">{{new Date(item.create_time * 1000).toLocaleDateString()}}</span>
                <div style="font-size:12px;padding:16px;color:#999">{{item.reply_text}}</div>
              </div>
            </div>
          </div>
           -->
          


        </el-main>
        <!-- <div class="console">
            console
        </div> -->
      </el-container>
      
    </el-container>
    <el-dialog
  title=""
  :visible.sync="showBigImg"
  width="90%"
  @open="openBigImg"
    @close="closeBigImg">
  <div class="bigImg">
    <img :src="bigImgUrl" alt="">
  </div>
</el-dialog>
  </div>
</template>

<script>
import { getContents, getContent, netService,resetQuiz,checkLastWorkShopFinishStatus } from '../api/assistant'
import { getUserId } from '../utils/store'
import pdf from 'vue-pdf'
import Cookie from 'js-cookie'
import Quiz from '../components/Quiz'

export default {
    props: {
        msg: String
    },
    components: {
        Quiz,
        pdf
    },
    mounted() {
        this.session_id = this.$route.query.session_id
        this.user_id = getUserId()
        this.studentId = getUserId()
        getContents(this.session_id,
            this.user_id
        ).then((res) => {
            let data=res.data
            this.menuItems = this.handleList(data)
            this.class_id = this.menuItems[0].content_info.class_id
            this.handleNodeClick(
                this.menuItems[0]
            ) 
            console.log(this.menuItems,111111111111)
        })
    
    },
    methods: {
      getBigImg(imgUrl){
        console.log(imgUrl)
      },
        resetQuiz(){
            this.$confirm('是否删除做题记录?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                resetQuiz(this.user_id,this.class_id,this.session_id).then(res=>{
                    console.log(res)
                    if(res.code===0){
                        this.$message({
                            message:'删除成功',
                            type:'success'
                        })
                    }
                    location.reload();
                })
            })
        },
        pageloaded(){
            this.loading.close();
        },
        renderContent(h, { node, data }) {
            const isComplete = data.content_info.complete_status
            console.log(isComplete)
            return (
                <span class="el-tree-node__label">
                    <span title={node.label} >
                    {node.label}
                    
                    </span>
                    
                </span>);
            // {isComplete === 'yes'  ?(<i class="el-icon-check "></i>): ''}
        },
        async handleNodeClick(data) {
            let result = await this.checkLastWorkShopFinishStatus(data.content_info.id);
            if(result.code==-1){
                this.$message.warning('请先完成上一章节的workshop')
                return
            }
            
            this.onDoing = false
            for (var a=0;a < this.menuItems.length;a++){
                var slectname = this.menuItems[a].label
                if(data.label == slectname){
                    this.selectPage = a;
                }
            }
            this.now_content_id = data.content_info.id

            if( data.content_info.content_type === 'VIDEO'){
                getContent( data.content_info.id,
                    this.studentId
                ).then((data) => {
            
                    this.quizsByTimes = {}
                    this.isBeginQuiz = false
                    this.quizs = []
                    this.videoDone = false        
                    let quizsByTimes = this.quizsByTimes
                    let content_quiz = data.data.content_quiz
                    let reg = new RegExp( '\r\n' , "g" )
                    for(let item of content_quiz){
                        item.question_title = item.question_title.replace(reg,'</br>')
                    }
                    this.content_quiz = content_quiz

                    data.data.content_quiz.map((v) => {
                        if(quizsByTimes[+v.quiz_position]) {
                            quizsByTimes[v.quiz_position].push(v)
                        } else {
                            quizsByTimes[v.quiz_position] = [v]
                        }
                    })
                  
                    
                    this.question = res.data.data.question_title.replace(reg,'</br>');

                    this.content_attach = data.data.content_attach;
                        this.$nextTick(()=>{
          console.log($(".wscnph"))
          $(".wscnph").on('click',function(){
            console.log("ceshi")
          })
        })
                  
                })
            
            }else{
                getContent(data.content_info.id,
                    this.studentId
                ).then(data => {
                    
                    this.quizsByTimes = {}
                    this.isBeginQuiz = false
                    this.quizs = []
                    this.videoDone = false        
                    const quizsByTimes = this.quizsByTimes
                    
                    let content_quiz = data.data.content_quiz

                    let reg = new RegExp( '\r\n' , "g" )
                    for(let item of content_quiz){
                        item.question_title = item.question_title.replace(reg,'</br>')
                    }
                    this.content_quiz = content_quiz

                    data.data.content_quiz.map((v) => {
                        if(quizsByTimes[+v.quiz_position]) {
                            quizsByTimes[v.quiz_position].push(v)
                        } else {
                            quizsByTimes[v.quiz_position] = [v]
                        }
                    })
                    
                    this.content_attach = data.data.content_attach
                    if(!this.content.html_text && this.content.pdf_url){
                       
                        const loadingTask = pdf.createLoadingTask(this.content.pdf_url);
                        this.loading = this.$loading({
                            lock: true,
                            text: 'Loading',
                            spinner: 'el-icon-loading',
                            background: 'rgba(0, 0, 0, 0.7)'
                        });
                        
                        loadingTask.then(pdf => {
                            this.numPages = pdf.numPages;
                        });
                    }
                        this.$nextTick(()=>{
                          let that =this;
          console.log($(".wscnph"))
          $(".wscnph").on('click',function(){
           
           that.bigImgUrl=this.src;
           that.showBigImg=true;
          })
        })
                })
            }
            // getContent({content_id: data.content_info.content_id}).then((i) => {
            this.content = data.content_info
          
            //   this.getSB()
            // })
        },
        openBigImg(){
this.$nextTick(()=>{
 console.log(that.bigImgUrl)
})
        },
        closeBigImg(){
this.bigImgUr="";
  this.showBigImg=false;
        },
        handleList(list) {
            let result = []
            list.map(v => {
                this.dfs(result, v)
            })
            console.log(result)
            return result
        },
        dfs(result, v) {
            // console.log(v, result)
            if (+v.content_info.content_level === 1) {
                result.push({
                    label: v.content_info.content_name,
                    children: [],
                    content_info: {
                        ...v.content_info,
                        id: v._id.$id,
                        complete_status: v.complete_status
                    }
                })
            } else {
                const len = result.length
                const l = +v.content_info.content_level
                v.content_info.content_level = l - 1
                v.content_info.content_raw_level = l
                this.dfs(result[len-1].children, v)
            }
        },
        onQuizDone() {
            this.isBeginQuiz = false
            this.quizs = []
            this.onDoing = false
            
            if(this.videoDone){
                setTimeout(() => {
                    this.videoElement.play()
                    this.videoDone = false
                }, 300);
            }
        },
        onChangeDone(index){
            console.log(index)
            this.quizIndex = index
        },
        timeupdate(event) {
            const currentTime = event.target.currentTime;//获取当前播放时间
            this.videoElement = event.target
            // console.log(currentTime,this.quizsByTimes[~~currentTime],this.quizsByTimes);//在调试器中打印
            if(this.quizsByTimes[~~currentTime]){
                this.quizs = this.quizsByTimes[~~currentTime]
                this.onDoing = true
                setTimeout(() => {
                    this.videoElement.pause()
                }, 1000);
                this.isBeginQuiz = true
            }
        },
        videoEnd() {
            if(this.quizsByTimes[-1]){
                this.quizs = this.quizsByTimes[-1]
                
                this.isBeginQuiz = true
                this.videoDone = true
                this.onDoing = true
            }
        },
        submitComment(){
            if(this.educator_comment == '' ){
                this.$message({
                    message: '内容不能为空',
                    type: 'error',
                    duration: 1500
                });
                return
            }
            if( this.now_index == ''){
                this.$message({
                    message: '内容归属为空',
                    type: 'error',
                    duration: 1500
                });
                return
            }
            // let index = 0
            // if(this.onDoing){
            //   index = this.quizIndex + 1
            // }
            netService({
                functionName: 'sendMessage',
                student_id: this.studentId,
                question_id: this.now_content_id,
                text: this.educator_comment,
                index: this.now_index,
            }).then((data => {
                if(data == 1){
                    this.educator_comment = ''
                    this.$message({
                        message: '添加成功~',
                        type: 'success',
                        duration: 1500
                    });
                    this.getSB()
                }
            }))
        },
        handleClose(closeDrawer){
            closeDrawer()
        },
        //   getSB(){
            
        //     //  console.log(id,this.accountid)
        //      netService('findQuestionMessages',this.now_content_id).then(data=>{
        //           console.log(33333,data)
        //         console.log(JSON.parse(data))
        //         this.comments = JSON.parse(data)
        //         // this.update_block_content = data.block_content
        //         // this.comments = data.block_comments
        //         // const date = new Date(data.latest_save_time*1000)
        //         // this.latest_save_time = data.latest_save_time ? date.toLocaleDateString() + ' '+date.toLocaleTimeString() : '没有记录~'
        //         // this.isAnswer = data._id ? true: false
        //         // this.student_block_id = data._id.$id
        //       })
        //   },
        beginQuiz(){
            this.onDoing = true
            let test = []
            for(const index in this.quizsByTimes){
                test.push(...this.quizsByTimes[index])
            }
            this.quizs = test
            this.isBeginQuiz = !this.isBeginQuiz

        },
        endQuiz(){
            this.onDoing = false
            let test = []
            
            this.quizs = test
            this.isBeginQuiz = !this.isBeginQuiz
        },
        nextPage(){
            let now_content_id = this.content.id
            let index = this.menuItems.findIndex(item=>item.content_info.id===now_content_id)
            if(index!==this.menuItems.length-1){
                this.handleNodeClick(this.menuItems[index+1])
            }

            // if(this.selectPage + 1 < this.menuItems.length){
            //     let a =  this.handleNodeClick(this.menuItems[this.selectPage+1])
            //     console.log(222222222,a)
            //     this.selectPage += 1;
            // }
       

            let top = document.documentElement.scrollTop || document.body.scrollTop;
            // 实现滚动效果 
            const timeTop = setInterval(() => {
                document.body.scrollTop = document.documentElement.scrollTop = top -= 50;
                if (top <= 0) {
                    clearInterval(timeTop);
                }
            }, 10);
        },
        lastPage(){
            let now_content_id = this.content.id
            let index = this.menuItems.findIndex(item=>item.content_info.id===now_content_id)
            if(index!==0){
                this.handleNodeClick(this.menuItems[index-1])
            }
            // if(this.selectPage -1 >= 0){
            //     let a = this.handleNodeClick(this.menuItems[this.selectPage-1])
            //     console.log(222222222,a)
            //     this.selectPage -= 1;
            // }

            let top = document.documentElement.scrollTop || document.body.scrollTop;
            // 实现滚动效果 
            const timeTop = setInterval(() => {
                document.body.scrollTop = document.documentElement.scrollTop = top -= 50;
                if (top <= 0) {
                    clearInterval(timeTop);
                }
            }, 10);
        },
        toWorkshop(){
            this.$router.push({
                path:'/adminmanageuser/workShop',
                query:{
                    content_id:this.content.id
                }
            })
        },
        async checkLastWorkShopFinishStatus(content_id){
            return new Promise(resolve=>{
                checkLastWorkShopFinishStatus(this.user_id,this.session_id,content_id).then(res=>{
                    resolve(res.data)
                })
            })
        }
    },
    data() {
        return {
          showBigImg:false,
          bigImgUrl:"",
            drawer: true,
            now_index: '' ,
            educator_comment: '',
            comments: [],
            now_content_id: '',

            videoDone: false,
            videoElement: null,
            session_id: '',
            menuItems:[],
            defaultProps: {
                children: 'children',
                label: 'label',
                content_info: 'content_info'
            },
            content: {},
            studentId: '',
            quizs: [],
            isBeginQuiz: false,
            quizsByTimes: {},
            content_quiz: [],
            onDoing: false,
            activeNames: ['1'],
            content_attach: [],
            quizIndex: 0,
            numPages: 0,
            selectPage:0,
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main-container{
  max-width: 1028px;
  margin: 0 auto;
  line-height: 200%;
  font-size:24px;
}
.attach{
  color:#409EFF;
  display: block;
    text-decoration: wavy;
}
.comments{
  margin-top: 30px;
}
  .Home {
    width: 100%;
  }
  .el-header {
    background-color: #B3C0D1;
    color: #333;
    line-height: 60px;
  }
  
  .el-aside {
    color: #333;
    padding-right: 5px;
  }
  .console{
    position: absolute;
    bottom: 0;
    height: 300px;
    width: calc(100% - 201px);
    border-top: 1px solid #ccc;
  }
  .el-tree-node{
    padding: 15px 0;
  }
  .title{
    font-size: 20px;
  }
  #videoPlay{
    width: 100%;
  }
  .title-description{
    font-size: 14px;
    display: contents;
  }
  .content_instruction{
    display: block;
    font-size: 12px;
    line-height: 150%;
    margin-top:10px;
    margin-bottom:10px;
  }
  .option{
    width: 80%;
  }
</style>
<style>
 .el-popper{
  /* width: 95%!important; */
}
.color{
  background: #eef1f6;
  overflow: scroll;
}
.green{
  color: green
}
.el-tree-node{
  white-space: normal!important;
  background: rgb(238, 241, 246);
}
.el-tree-node__content{
    padding: 15px 0;
    
  }
  .el-tree-node__label{
    /* overflow: hidden;
    text-overflow: ellipsis; */
  }
  .el-tree-node__expand-icon + .el-tree-node__label{
    font-weight:bolder;
  }
  .el-tree-node__expand-icon.is-leaf + .el-tree-node__label{
    font-weight: normal;
  }
  video::-internal-media-controls-download-button { 
    display:none; 
  }

  video::-webkit-media-controls-enclosure { 
    overflow:hidden; 
  }
  video::-webkit-media-controls-panel { 
    width: calc(100% + 30px); 
  }
.addCommets .el-textarea__inner{
  height: 100px;
}
.main-container img{
    width: 100%;
}
.main-container td{
    font-size: 16px;
    padding-left:20px;
    padding-right:20px;
    line-height: 26px;
    height: 26px;
}
.bigImg{
  width: 100%;
  height: 550px;
  overflow-y: auto;
}
.bigImg img{
  width: 100%;
}
</style>
