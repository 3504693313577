<template>
  <div class="chat-info">
    <el-avatar shape="square" class="avatar" :size="48" :src="info.logo || squareUrl" fit="contain"></el-avatar>
    <div style="flex: 1; position: relative;">
      <div class="group-name">{{ info.project_code || info.project_product }}</div>
      <div class="group-desc">{{ info.latest_message || info.account_name }}</div>
      <div class="dot" v-if="info.latest_message && !info.have_latest_message"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChatGroup',
  props: {
    info: {
      type: Object
    }
  },
  data () {
    return {
      squareUrl: 'https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png'
    }
  }
}
</script>

<style lang="scss" scoped>
.chat-info {
  padding: 18px;
  border-top: 1px solid #F5F5F5;
  border-bottom: 1px solid #F5F5F5;
  display: flex;
  cursor: pointer;
  .avatar {
    background: white;
    img {
      width: 100% !important;
    }
  }
  .group-name {
    font-size: 14px;
    flex: 1;
    color: #333333;
    margin-left: 18px;
    margin-top: 4px;
  }
  .group-desc {
    font-size: 12px;
    color: #999;
    margin-left: 18px;
    margin-top: 10px;
  }
  .dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: absolute;
    right: 8px;
    background: #FF7600;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>
