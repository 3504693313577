<template>
  <div class="l-c">
    <!-- <el-input v-model="string" type="textarea" @change="look"></el-input> -->
    <el-form
      v-if="showDialog === 'password'"
      ref="ruleForm2"
      :model="ruleForm2"
      :rules="rules2"
      class="login-container"
      status-icon
    >
      <img src="../assets/loginTitle.png" class="title" />
      <el-form-item prop="employee_email">
        <img src="../assets/账号.svg" class="form-icon" />
        <el-input
          v-model="ruleForm2.employee_email"
          type="text"
          auto-complete="off"
          placeholder="请输入账号"
        />
      </el-form-item>
      <el-form-item prop="employee_pwd">
        <img src="../assets/密码.svg" class="form-icon" />
        <el-input
          v-model="ruleForm2.employee_pwd"
          auto-complete="off"
          placeholder="请输入密码"
          type="password"
        />
      </el-form-item>
      <el-form-item prop="employee_type">
        <img src="../assets/类型.svg" class="form-icon" />
        <el-select
          v-model="ruleForm2.employee_type"
          style="width: 100%"
          placeholder="请选择登录类型"
        >
          <el-option value="employee" label="员工"></el-option>
          <el-option value="admin" label="管理员"></el-option>
        </el-select>
      </el-form-item>
      <span v-if="!!error_text" style="color: red" class="error_text">{{
        error_text
      }}</span>
      <el-form-item style="width: 100%">
        <el-button
          :loading="logining"
          type="primary"
          style="
            width: 100%;
            background: #4a8af4;
            margin-top: 20px;
            height: 42px;
          "
          @click="submit"
          >登录</el-button
        >
      </el-form-item>
    </el-form>
    <el-form
      ref="ruleForm2"
      class="login-container"
      status-icon
      v-if="showDialog === 'email'"
    >
      <img src="../assets/loginTitle.png" class="title" />
      <el-form-item>
        <img src="../assets/账号.svg" class="form-icon" />
        <el-input
          v-model="form.user_email"
          type="text"
          auto-complete="off"
          disabled
          placeholder="请输入邮箱"
        />
      </el-form-item>
      <el-form-item>
        <img src="../assets/密码.svg" class="form-icon" />
        <el-input
          v-model="form.pin"
          auto-complete="off"
          placeholder="请输入验证码"
          type="password"
        >
          <template slot="append"
            ><div @click="sendEmailPin" style="cursor: pointer">
              发送验证码
            </div></template
          >
        </el-input>
      </el-form-item>
      <el-form-item style="width: 100%">
        <el-button
          type="primary"
          style="
            width: 100%;
            background: #4a8af4;
            margin-top: 20px;
            height: 42px;
          "
          @click="confirmEmail"
          >确认</el-button
        >
      </el-form-item>
    </el-form>
    <el-form
      ref="ruleForm2"
      class="login-container"
      status-icon
      v-if="showDialog === 'mobile'"
    >
      <img src="../assets/loginTitle.png" class="title" />
      <el-form-item>
        <img src="../assets/账号.svg" class="form-icon" />
        <el-input
          v-model="form1.show_mobile"
          type="text"
          auto-complete="off"
          disabled
          placeholder="请输入手机号"
        />
      </el-form-item>
      <el-form-item>
        <img src="../assets/密码.svg" class="form-icon" />
        <el-input
          v-model="form1.pin"
          auto-complete="off"
          placeholder="请输入验证码"
          type="password"
        >
          <template slot="append"
            ><div @click="sendPin" style="cursor: pointer">
              发送验证码
            </div></template
          >
        </el-input>
      </el-form-item>
      <el-form-item style="width: 100%">
        <el-button
          type="primary"
          style="
            width: 100%;
            background: #4a8af4;
            margin-top: 20px;
            height: 42px;
          "
          @click="confirmMobile"
          >确认</el-button
        >
      </el-form-item>
    </el-form>
    <!-- <el-button @click="test">test</el-button> -->
    <el-dialog :visible.sync="showChangePwd">
      <h3>您的密码仍为初始默认密码,请重置密码</h3>
      <el-form>
        <el-form-item label="请输入新密码">
          <el-input v-model="new_pwd" type="password"></el-input>
        </el-form-item>
        <el-form-item label="请再次输入新密码">
          <el-input v-model="new_pwd_again" type="password"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="updateNewUserPwd">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import {
  loginByEmail,
  checkUserPwdStatus,
  updateNewUserPwd,
  loginByAccount,
  test,
  verifyLoginPin,
  createLoginPin,
  verifyEmailLoginPin,
  createEmailLoginPin,
} from "../api/assistant";
import { setUserId, setUserName, setEmail } from "../utils/store";
export default {
  name: "Login",
  data() {
    return {
      error_text: "",
      logining: false,
      ruleForm2: {
        employee_email: "",
        employee_pwd: "",
        employee_type: "",
      },
      rules2: {
        employee_email: [
          { required: true, message: "请输入账号", trigger: "blur" },
        ],
        employee_pwd: [
          { required: true, message: "请输入密码", trigger: "blur" },
        ],
        employee_type: [
          { required: true, message: "请输入类型", trigger: "blur" },
        ],
      },
      showChangePwd: false,
      new_pwd: "",
      new_pwd_again: "",
      user_id: "",
      string: "",
      showDialog: "password",
      form: {
        user_email: "",
        pin: "",
      },
      form1: {
        show_mobile:'',
        mobile: "",
        pin: "",
      },
    };
  },
  mounted() {
    console.log("test jenkins9000000");
  },
  methods: {
    sendPin() {
      createLoginPin({
        user_email: this.ruleForm2.employee_email,
      }).then((res) => {
        if (res.data.code === 0) {
          this.$message.success("验证码已发送至手机");
        } else {
          this.$message.error("验证码发送失败，请间隔一分钟尝试重新发送");
        }
      });
    },
    confirmMobile() {
      verifyLoginPin({
        mobile: this.form1.mobile,
        pin: this.form1.pin,
      }).then((res) => {
        if (res.data.code === 0) {
          let result = res.data.data;
          const user_id = result._id.$id;
          const user_name = result.user_name;
          const user_email = result.user_email;
          setUserId(user_id);
          setUserName(user_name);
          setEmail(user_email);
          this.$router.push("/nav");
        } else {
          this.$message.error("验证失败");
        }
      });
    },
    test() {
      test({
        string: this.string,
      }).then((res) => {
        console.log(res);
      });
    },
    sendEmailPin() {
      createEmailLoginPin({
        user_email: this.form.user_email,
      }).then((res) => {
        if (res.data.code === 0) {
          this.$message.success("验证码已发送至邮箱");
        } else {
          this.$message.error("验证码发送失败");
        }
      });
    },
    confirmEmail() {
      verifyEmailLoginPin({
        user_email: this.form.user_email,
        pin: this.form.pin,
      }).then((res) => {
        if (res.data.code === 0) {
          if (res.data.data.user_mobile) {
            let mobile = res.data.data.user_mobile;
            this.form1.mobile = mobile;
            this.form1.show_mobile = '*******' + phone.substr(7) 
            this.showDialog = "mobile";
            this.sendPin();
          } else {
            this.$message.error("手机号未设置，请联系管理员设置手机号");
          }
        } else {
          this.$message.error("验证失败");
        }
      });
    },
    submit() {
      this.logining = false;
      if (this.ruleForm2.employee_type === "employee") {
        checkUserPwdStatus(
          this.ruleForm2.employee_email,
          this.ruleForm2.employee_pwd
        ).then((res) => {
          if (res.data.code === 0) {
            const result = res.data.data;
            if (result) {
              loginByEmail(
                this.ruleForm2.employee_email,
                this.ruleForm2.employee_pwd
              )
                .then((res) => {
                  const result = res.data
                  if(result.status_code === 0){
                    const user_id = result.user_id
                    const user_name = result.user_name
                    const user_email = result.user_email
                    setUserId(user_id)
                    setUserName(user_name)
                    setEmail(user_email)
                    this.$router.push("/nav")
                  }else if (result.status_code === 1) {
                      // 手机号登录
                      if (result.user_mobile) {
                        this.showDialog = "mobile";
                        this.form1 = {
                          mobile: result.user_mobile,
                          show_mobile: '*******' + result.user_mobile.substr(7),
                          pin: "",
                        };
                        this.sendPin();
                      } else {
                        this.$message.error(
                          "手机号未设置，请联系管理员设置手机号"
                        );
                      }
                  
                  } else if (result.status_code === 3) {
                    this.error_text = "密码不对";
                  } else if (result.status_code === 4) {
                    this.error_text = "找不到账户";
                  }
                })
                .finally(() => {
                  this.logining = false;
                });
            } else {
              this.user_id = res.data.user_id;
              this.showChangePwd = true;
            }
          } else {
            this.error_text = res.data.msg;
          }
        });
      } else if (this.ruleForm2.employee_type === "admin") {
        loginByAccount(
          this.ruleForm2.employee_email,
          this.ruleForm2.employee_pwd
        )
          .then((res) => {
            const result = res.data;
            if (result.status_code === 1) {
              setUserId(result.user_id);
              setUserName(result.user_name);
              setEmail(result.user_email);
              this.$router.push("/admin");
            } else if (result.status_code === 3) {
              this.error_text = "密码不对";
            } else if (result.status_code === 4) {
              this.error_text = "找不到账户";
            }
          })
          .finally(() => {
            this.logining = false;
          });
      }
    },
    updateNewUserPwd() {
      if (this.new_pwd !== this.new_pwd_again) {
        this.$message.error("两次输入的密码不一致");
        return;
      }
      this.$confirm("是否确认重置密码?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        updateNewUserPwd(this.user_id, this.new_pwd).then((res) => {
          if (res.data.code === 0) {
            this.$message.success("修改成功");
            this.showChangePwd = false;
          } else {
            this.$message.error("密码修改错误");
          }
        });
      });
    },
    look(e) {
      console.log(JSON.stringify(e).split("&&&"));
    },
  },
};
</script>

<style scoped lang="scss">
.l-c {
  width: 100%;
  height: 100vh;
  position: relative;
  background: url("../assets/background.png");
  background-position: 0 0;
  .login-container {
    position: absolute;
    top: 43%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    padding: 35px 35px 15px 35px;
    .title {
      width: 300px;
      height: 125px;
      margin: 0 auto;
      display: block;
      margin-bottom: 60px;
    }

    .remember {
      margin: 0px 0px 35px 0px;
    }
    .el-radio {
      margin-bottom: 10px;
      margin-top: 10px;
    }
  }
}
.error_text {
  color: red;
}
</style>
<style lang="scss">
.l-c {
  .el-input {
    .el-input__inner {
      padding-left: 60px;
    }
  }
  .form-icon {
    position: absolute;
    left: 20px;
    z-index: 999;
    top: 12px;
  }
  .el-form-item {
    margin-bottom: 30px;
  }
}
</style>
