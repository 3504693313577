<template>
    <div>
        <el-table :data="users">
            <el-table-column label="姓名">
                <template slot-scope="scope">
                    {{scope.row.user_lastName+scope.row.user_givenName}}
                </template>
            </el-table-column>
            <el-table-column label="调休假">
                <template slot-scope="scope">
                    {{scope.row.lieu_holiday?scope.row.lieu_holiday:0}}
                </template>
            </el-table-column>
            <el-table-column label="年假">
                <template slot-scope="scope">
                    {{scope.row.year_holiday?scope.row.year_holiday:0}}
                </template>
            </el-table-column>
            <el-table-column label="特殊病假">
                <template slot-scope="scope">
                    {{scope.row.special_holiday?scope.row.special_holiday:0}}
                </template>
            </el-table-column>
            <el-table-column label="修改">
                <template slot-scope="scope">
                    <el-button @click="edit(scope.row)" type="primary" size="medium">修改余额</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-dialog title="修改余额" :visible.sync="showEdit">
            <el-form>
                <el-form-item label="调休假">
                    <el-input-number v-model="form.lieu_holiday"></el-input-number>
                </el-form-item>
                <el-form-item label="年假">
                    <el-input-number v-model="form.year_holiday"></el-input-number>
                </el-form-item>
                <el-form-item label="特殊病假">
                    <el-input-number v-model="form.special_holiday"></el-input-number>
                </el-form-item>
                <el-form-item>
                    <el-button @click="updateUserHoliday" type="primary">确定</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import {updateUserHoliday,getUserList} from '../../api/assistant'
export default {
    data(){
        return{
            users:[],
            user_id:'',
            form:{
                lieu_holiday:0,
                year_holiday:0,
                special_holiday:0
            },
            showEdit:false
        }
    },
    mounted(){
        this.fetchData()
    },
    methods:{
        fetchData(){
            getUserList().then(res=>{
                this.users = res.data
            })
        },
        updateUserHoliday(){
            updateUserHoliday(this.user_id,this.form.lieu_holiday,this.form.year_holiday,this.form.special_holiday).then(res=>{
                this.$message({
                    message:'修改成功',
                    type:'success'
                })
                this.fetchData()
                this.showEdit = false
            })
        },
        edit(row){
            this.user_id = row._id.$id
            this.form = JSON.parse(JSON.stringify(row))
            this.showEdit = true
        }
    }
}
</script>

<style>

</style>