<template>
    <div>
        <el-form>
            <el-form-item label="年份">
                <el-input v-model="year" disabled></el-input>
            </el-form-item>
            <el-form-item label="季度">
                <el-input v-model="quarter" disabled></el-input>
            </el-form-item>
            <el-form-item label="季度工作回顾">
                <el-input v-model="review" type="textarea" :rows="6" :disabled="$route.path.includes('workLog')"></el-input>
            </el-form-item>
            <el-form-item label="下一季度工作重点">
                <el-input v-model="point" type="textarea" :rows="6" :disabled="$route.path.includes('workLog')"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button @click="submit" type="primary" v-if="!$route.path.includes('workLog')">提交</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import {getQuarterTimeSheet,updateQuarterLog} from '../../api/assistant'
export default {
    data(){
        return{
            year:this.$route.query.year,
            quarter:this.$route.query.quarter,
            user_id:this.$route.query.user_id,
            review:'',
            point:''
        }
    },
    mounted(){
        this.fetchData()
    },
    methods:{
        submit(){
            updateQuarterLog(this.quarter,this.year,this.review,this.point,this.user_id).then(res=>{
                this.$message({
                    message:'更新成功',
                    type:'success'
                })
            })
        },
        fetchData(){
            getQuarterTimeSheet(this.quarter,this.year,this.user_id).then(res=>{
                if(res.data){
                    this.review = res.data.review
                    this.point = res.data.point
                }
            })
        }
    }
}
</script>

<style>

</style>