<template>
  <div style="width: 100%" class="sectionInfo">
    <div style="padding: 20px">
      <el-button type="primary" icon="el-icon-plus" @click="showType = true" size="small">添加题目</el-button>
      <div class="btnInpput">
        <input class="file" type="file" id="files" ref="refFile" accept="*" @change="getFile" />
        <p v-loading="excelLoading" class="el-icon-upload uploadBtn">上传题目模板</p>
      </div>
      <el-button type="primary" @click="initSort" size="small" icon="el-icon-sort">排序</el-button>
      <el-button
        type="danger"
        @click="BatchDelExerciseQuestionInTemplateSection"
        size="small"
        icon="el-icon-delete"
      >批量删除</el-button>
      <el-table
        :data="question_list"
        v-loading="excelLoading"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column label="序号">
          <template slot-scope="scope">{{ scope.$index + 1 }}</template>
        </el-table-column>
        <el-table-column label="主题" prop="question_subject"></el-table-column>
        <el-table-column label="题目" prop="question_question_string" width="500"></el-table-column>
        <el-table-column label="年份" prop="question_year"></el-table-column>
        <el-table-column label="等级" prop="question_level"></el-table-column>
        <el-table-column label="考点" prop="question_competency"></el-table-column>
        <el-table-column label="操作" width="200px">
          <template slot-scope="scope">
            <el-button type="primary" size="small" @click="viewQuestion(scope.row)">查看</el-button>
            <el-button
              type="danger"
              size="small"
              @click="
                removeExerciseQuestionInTemplateSection(scope.row._id.$id)
              "
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-dialog :visible.sync="showType" width="400px">
        <div style="width: 100%; text-align: center">
          <el-button type="primary" size="mini" @click="toNew">直接创建新题</el-button>
          <el-button type="primary" size="mini" @click="add">从题库中选择</el-button>
        </div>
      </el-dialog>

      <el-dialog title="添加题目" :visible.sync="showAdd">
        <el-table :data="questions_all" height="60vh" v-loading="loading">
          <el-table-column label="题目" prop="question_question_string"></el-table-column>
          <el-table-column label="主题" prop="question_subject"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                type="primary"
                size="small"
                @click="addExerciseQuestionToTemplateSection(scope.row._id.$id)"
              >添加</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-dialog>
      <el-dialog title="查看题目信息" :visible.sync="showQuestion">
        <el-form :model="currentRow" class="questionForm" label-width="70px">
          <el-form-item label="问题">
            <el-input
              type="textarea"
              :rows="5"
              style="width: 98%"
              v-model="currentRow.question_question_string"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="学科">
            <el-input v-model="currentRow.question_subject" disabled></el-input>
          </el-form-item>
          <el-form-item label="年份">
            <el-input v-model="currentRow.question_year" disabled></el-input>
          </el-form-item>
          <el-form-item label="等级">
            <el-input v-model="currentRow.question_level" disabled></el-input>
          </el-form-item>
          <el-form-item label="考点">
            <el-input v-model="currentRow.question_competency" disabled></el-input>
          </el-form-item>
          <el-form-item label="选项A">
            <el-input v-model="currentRow.question_optionA_string" disabled></el-input>
          </el-form-item>
          <el-form-item label="选项B">
            <el-input v-model="currentRow.question_optionB_string" disabled></el-input>
          </el-form-item>
          <el-form-item label="选项C">
            <el-input v-model="currentRow.question_optionC_string" disabled></el-input>
          </el-form-item>
          <el-form-item label="选项D">
            <el-input v-model="currentRow.question_optionD_string" disabled></el-input>
          </el-form-item>
          <el-form-item label="选项E">
            <el-input v-model="currentRow.question_optionE_string" disabled></el-input>
          </el-form-item>
          <el-form-item label="答案">
            <el-input v-model="currentRow.question_answer" disabled></el-input>
          </el-form-item>
        </el-form>
      </el-dialog>
      <el-dialog :visible.sync="showSort" :close-on-click-modal="false" @opened="initRowSort">
        <el-table :data="sortedArray" class="sort" height="56vh">
          <el-table-column label="拖拽" width="50px">
            <div class="handle">
              <i class="el-icon-s-unfold" style="font-size: 120%"></i>
            </div>
          </el-table-column>
          <el-table-column label="题目" prop="question_question_string"></el-table-column>
        </el-table>
        <el-button type="primary" size="medium" @click="submit">确认</el-button>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  getExerciseSection,
  addExerciseQuestionToTemplateSection,
  getExerciseQuestions,
  removeExerciseQuestionInTemplateSection,
  resortQuestionsInTemplateSection,
  BatchDelExerciseQuestionInTemplateSection,
  importCsvInTemplate
} from "../../api/assistant";
import Template from "./Template.vue";
import Papa from "papaparse";
import Sortable from "sortablejs";
export default {
  components: { Template },
  data() {
    return {
      section_id: this.$route.query.section_id,
      question_list: [],
      questions_all: [],
      showAdd: false,
      showType: false,
      showQuestion: false,
      currentRow: {},
      loading: false,
      sortedArray: [],
      showSort: false,
      csvData: [],
      excelLoading: false,
      multipleSelection: []
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log(val);
    },
    batchDele() {},
    getFile: function(e) {
      this.excelLoading = true;
      let selectedFile = null;
      selectedFile = this.$refs.refFile.files[0];
      if (selectedFile === undefined) {
        return;
      }
      var reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onload = evt => {
        // 检查编码
        // let encoding = this.checkEncoding(evt.target.result);
        // 将csv转换成二维数组
        Papa.parse(selectedFile, {
          encoding: "ANSI",
          complete: res => {
            // UTF8 \r\n与\n混用时有可能会出问题
            let data = res.data;
            if (data[data.length - 1] == "") {
              //去除最后的空行
              data.pop();
            }
            data.splice(0, 1);
            if (data.length > 0) {
              data.forEach((item, index) => {
                debugger;
                let excelItem = {
                  question_question: "<p>" + item[2] + "</p>",
                  question_question_string: item[2],
                  question_optionA_string: item[3],
                  question_optionA: "<p>" + item[3] + "</p>",
                  question_optionB_string: item[4],
                  question_optionB: "<p>" + item[4] + "</p>",
                  question_optionC_string: item[5],
                  question_optionC: "<p>" + item[5] + "</p>",
                  question_optionD_string: item[6],
                  question_optionD: "<p>" + item[6] + "</p>",
                  question_answer: item[7]
                };

                this.csvData.push(excelItem);
              });
              //开始上传
              console.log({ questions: this.csvData });
              //解析数据收开始上传至数据库
              importCsvInTemplate({
                questions: this.csvData,
                section_id: this.section_id
              }).then(resdata => {
                if (resdata.data.code == 0) {
                  //
                  this.excelLoading = false;
                  this.fetchData();
                }
              });
            }
          }
        });
      };
    },
    fetchData() {
      this.excelLoading = true;
      getExerciseSection(this.section_id).then(res => {
        this.excelLoading = false;

        if (res.data.code === 0) {
          this.question_list = res.data.data.questions_item;
        }
      });
    },
    toNew() {
      this.$router.push({
        path: "/addTemplateQuestion",
        query: {
          section_id: this.section_id
        }
      });
    },
    add() {
      this.showAdd = true;
      this.loading = true;
      getExerciseQuestions().then(res => {
        if (res.data.code === 0) {
          this.questions_all = res.data.data;
          this.loading = false;
        }
      });
    },
    addExerciseQuestionToTemplateSection(question_id) {
      addExerciseQuestionToTemplateSection(question_id, this.section_id).then(
        res => {
          if (res.data && res.data.code === 0) {
            this.$message({
              message: "添加成功",
              type: "success"
            });
            this.fetchData();
          } else {
            this.$message("已添加");
          }
        }
      );
    },
    viewQuestion(row) {
      this.currentRow = row;
      this.showQuestion = true;
    },
    removeExerciseQuestionInTemplateSection(question_id) {
      removeExerciseQuestionInTemplateSection(
        question_id,
        this.section_id
      ).then(res => {
        if (res.data.code === 0) {
          this.$message({
            message: "删除成功",
            type: "success"
          });
          this.fetchData();
        }
      });
    },
    BatchDelExerciseQuestionInTemplateSection() {
      
      if(this.multipleSelection.length==0){
this.$message.warning("请选择需要删除的题目")
      }else{
  this.$confirm('确定要批量删除吗？','警告',{
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(()=>{
  this.multipleSelection = this.multipleSelection.map(m => {
        let param = {
          question_id: m._id.$id,
          section_id: this.section_id
        };
        return param;
      });

      BatchDelExerciseQuestionInTemplateSection({
        templateSection: this.multipleSelection
      }).then(res => {
        if (res.data.code === 0) {
          this.$message({
            message: "删除成功",
            type: "success"
          });
          this.fetchData();
        }
      });
        })
    
      }
            
    },
    initRowSort() {
      const sortDiv = document.querySelector(
        ".sort .el-table__body-wrapper tbody"
      );
      Sortable.create(sortDiv, {
        handle: ".handle",
        animation: 150,
        onEnd: ({ oldIndex, newIndex }) => {
          let newArr = JSON.parse(JSON.stringify(this.sortedArray));
          const currRow = newArr.splice(oldIndex, 1)[0];
          newArr.splice(newIndex, 0, currRow);
          // 重新排序完的表格数据
          this.sortedArray = [];
          this.$nextTick(function() {
            this.sortedArray = newArr;
          });
        }
      });
    },
    initSort() {
      this.showSort = true;
      this.sortedArray = JSON.parse(JSON.stringify(this.question_list));
    },
    submit() {
      let questions = this.sortedArray.map(item => item._id.$id);
      resortQuestionsInTemplateSection(this.section_id, questions).then(res => {
        console.log(res);
        if (res.data.code === 0) {
          this.$message({
            message: "排序成功",
            type: "success"
          });
          this.showSort = false;
          this.fetchData();
        }
      });
    }
  }
};
</script>

<style scoped>
.handle {
  text-align: center;
  cursor: pointer;
}
.uploadBtn {
  color: #fff;
  background-color: #409eff;
  border-color: #409eff;
  padding: 10px 10px;
  border-radius: 2px;
  cursor: pointer;
  margin: 0 10px;

  font-size: 12px;
}
.btnInpput {
  position: relative;
  display: inline-block;
  margin-left: 10px;
}
.file {
  position: absolute;
  width: 89px;
  right: 0;
  height: 33px;
  background: red;
  opacity: 0;
  z-index: 999;
  cursor: pointer;
}
</style>