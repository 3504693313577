import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Nav from '../views/Nav.vue'
import Meeting from '../views/MeetingNote/Meeting.vue'
import ITRequestList from '../views/it_request/ITRequestList.vue'
import Comment from '../views/workfeedback/Comment.vue'
import SubordiNate from '../views/workfeedback/SubordiNate.vue'
import WorkQuestions from '../views/workfeedback/WorkQuestions.vue'
import UpdateMeetingNote from '../views/MeetingNote/UpdateMeetingNote.vue'
import checkTask from '../views/checkTask.vue'
import checkType from '../views/checkType.vue'
import checkList from '../views/checkList.vue'
import checkItem from '../views/checkItem.vue'
import ReviewsList from '../views/ReviewsList.vue'
import toReview from '../views/toReview.vue'
import reviewDetail from '../views/reviewDetail.vue'
import Admin from '../views/admin.vue'
import adminManage from '../views/adminManage.vue'
import TrackFeedbackNum from '../views/TrackFeedbackNum.vue'
import adminManageUser from '../views/adminManageUser.vue'
import userManage from '../views/UserAdmin/userManage.vue'
import userOkr from '../views/UserAdmin/userOkr.vue'
import setBUs from '../views/UserAdmin/setBUs.vue'
import userOkrUpdate from '../views/UserAdmin/userOkrUpdate.vue'
import userInfo from '../views/UserAdmin/userInfo.vue'
import classlist from '../views/classlist.vue'
import classlistUser from '../views/classlistUser.vue'
import classInfo from '../views/classInfo.vue'
import classInfoUser from '../views/classInfoUser.vue'
import sessionInfo from '../views/sessionInfo.vue'
import sessionInfoUser from '../views/sessionInfoUser.vue'
import contentInfo from '../views/contentInfo.vue'
import contentInfoUser from '../views/contentInfoUser.vue'
import quizInfo from '../views/quizInfo.vue'
import workShopList from '../views/workShopAdmin/workShopList.vue'
import workShopInfo from '../views/workShopAdmin/workShopInfo.vue'
import workShopEvaluation from '../views/workShopAdmin/workShopEvaluation'
import workShop from '../views/workShopUser/workShop'
import WorkshopTemplate from '../views/workShopAdmin/workShopTemplate.vue'
import WorkshopTemplateInfo from '../views/workShopAdmin/workShopTemplateInfo.vue'
import workShopEntry from '../views/workShopUser/workShopEntry.vue'
import workShopReview from '@/views/workShopUser/workShopReview'
import workShopEntryList from '@/views/workShopUser/workShopEntryList'
import dailyLogList from '@/views/workLog/dailyLogList'
import dailyLogForm from '@/views/workLog/dailyLogForm'
import monthLogList from '@/views/workLog/monthLogList'
import monthLogForm from '@/views/workLog/monthLogForm'
import workLogTemplate from '@/views/UserAdmin/workLogTemplate'
import workTemplateForm from '@/views/UserAdmin/workTemplateForm'
import ProjectTodo from '@/views/ProjectTodo/ProjectTodo'
import workLogTable from '@/views/UserAdmin/workLogTable'
import performanceStatistic from '@/views/UserAdmin/performanceStatistic'
import monthLogTable from '@/views/UserAdmin/monthLogTable'
import standardList from '@/views/UserAdmin/standardList'
import standard from '@/views/UserAdmin/standard'
import Exam from '@/views/ExamAdmin/Exam'
import Template from '@/views/ExamAdmin/Template'
import Question from '@/views/ExamAdmin/Question'
import QuestionInfo from '@/views/ExamAdmin/QuestionInfo'
import TemplateInfo from '@/views/ExamAdmin/TemplateInfo'
import addTemplateQuestion from '@/views/ExamAdmin/addTemplateQuestion'
import sectionInfo from '@/views/ExamAdmin/SectionInfo'
import ExamList from '@/views/Exam/ExamList'
import ExamView from '@/views/Exam/ExamView'
import EvaluationInfo from '@/views/workShopUser/evaluationInfo'
import Bank from '@/views/UserAdmin/bank.vue'
import ApplyHoliday from '@/views/Holiday/applyHoliday.vue'
import newApply from '@/views/Holiday/newApply.vue'
import CheckHoliday from '@/views/Holiday/checkHoliday.vue'
import HolidaySetting from '@/views/Holiday/HolidaySetting.vue'
import QuarterSchedule from '@/views/UserAdmin/QuarterSchedule.vue'
import QuarterSheet from '@/views/UserAdmin/QuarterSheet.vue'
import Regulations from '@/views/Regulation/regulations.vue'
import userRegulations from '@/views/Regulation/userRegulations.vue'
import Regulation from '@/views/Regulation/regulation.vue'
import QuarterUserSchedule from '@/views/UserAdmin/QuarterScheduleUser.vue'
import Speech from '@/views/Speech.vue'
import Correction from '@/views/Correction.vue'
import todoStandardList from '@/views/ProjectTodo/standardList'
import todoTypeList from '@/views/ProjectTodo/typeList'
import correctionSampleList from '@/views/ProjectTodo/correctionSampleList'
import brandList from '@/views/ProjectTodo/brandList'
import specialWorkDayList from '@/views/ProjectTodo/specialWorkDayList'
import tipList from '@/views/ProjectTodo/tipList'
import scoreCompelete from '@/views/ProjectTodo/scoreCompelete'
import brandPolicy from '@/views/ProjectTodo/brandPolicy.vue'
import ProjectTimePoint from '@/views/timePoint/ProjectTimePoint.vue'

import Music from '@/views/Music/Music'
import Game from '@/views/Game/Game'

import recorder from '@/views/recorder/recording.vue'
import Chat from '@/views/Chat/index.vue'
import Price from '@/views/price.vue'
import ChatAI from '@/views/ChatAI/index.vue'
import audioToText from '@/views/Transfer/AudioToText.vue'
import textToAudio from '@/views/Transfer/TextToAudio.vue'

import employeeList from '../views/employeeList.vue'
import ziliao from '../views/ziliao.vue'
import result from '../views/result.vue'
import Law from '../views/law.vue'
import totext from '../views/toText.vue'
import wechat from '../views/wechatA/index.vue'
import test from '../views/test.vue'

import AIContents from '../views/AIContents.vue'

//email
import Emails from '../views/EmailBox/inbox.vue'

import EmailSign from '../components/Email/signDetail.vue'

import { getUserId } from '../utils/store'
Vue.use(VueRouter)

const routes = [{
        path: '/',
        redirect: '/login',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/home',
        name: 'Home',
        component: Home,
        meta: {
            requiresAuth: true
        }
    }, {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/nav',
        name: 'Nav',
        component: Nav,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/admin',
        component: Admin,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/emails',
        component: Emails,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/email-sign',
        component: EmailSign,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/test',
        component: test
    },
    {
        path: '/adminmanage',
        name: 'adminManage',
        component: adminManage,
        meta: {
            requiresAuth: true,
            bread: '首页'
        },
        children: [{
                path: 'usermanage',
                component: userManage,
                meta: {
                    requiresAuth: true,
                    bread: '首页 / 员工信息管理'
                }
            },
            {
                path: 'setBUs',
                component: setBUs,
                meta: {
                    requiresAuth: true,
                    bread: '首页 / 员工所属BU'
                }
            },
            {
                path: 'userokr',
                component: userOkr,
                meta: {
                    requiresAuth: true,
                    bread: '首页 / 员工OKR'
                }
            },
            {
                path: 'userokrupdate',
                component: userOkrUpdate,
                meta: {
                    requiresAuth: true,
                    bread: ''
                }
            },
            {
                path: 'userinfo',
                component: userInfo,
                meta: {
                    requiresAuth: true,
                    bread: ''
                }
            },
            {
                path: 'performanceStatistic',
                component: performanceStatistic,
                meta: {
                    requiresAuth: true,
                    bread: '首页 / 绩效统计'
                }
            },
            {
                path: 'classlist',
                component: classlist,
                meta: {
                    requiresAuth: true,
                    bread: '首页 / 课程列表'
                }
            },
            {
                path: 'classinfo',
                component: classInfo,
                meta: {
                    requiresAuth: true,
                    bread: ''
                }
            },
            {
                path: 'sessioninfo',
                component: sessionInfo,
                meta: {
                    requiresAuth: true,
                    bread: ''
                }
            },
            {
                path: 'contentinfo',
                component: contentInfo,
                meta: {
                    requiresAuth: true,
                    bread: ''
                }
            },
            {
                path: 'quizInfo',
                component: quizInfo,
                meta: {
                    requiresAuth: true,
                    bread: ''
                }
            },
            {
                path: 'workShopList',
                component: workShopList,
                meta: {
                    requiresAuth: true,
                    bread: '首页 / WorkShop列表'
                }
            },
            {
                path: 'workShopInfo',
                component: workShopInfo,
                meta: {
                    requiresAuth: true,
                    bread: ''
                }
            },
            {
                path: 'workShopEvaluation',
                component: workShopEvaluation,
                meta: {
                    requiresAuth: true,
                    bread: ''
                }
            },
            {
                path: 'workshopTemplate',
                component: WorkshopTemplate,
                meta: {
                    requiresAuth: true,
                    bread: '首页 / WorkShop模板列表'
                }
            },
            {
                path: 'workshopTemplateInfo',
                component: WorkshopTemplateInfo,
                meta: {
                    requiresAuth: true,
                    bread: ''
                }
            },
            {
                path: 'workLogTemplate',
                component: workLogTemplate,
                meta: {
                    requiresAuth: true,
                    bread: '首页 / 工作日志模板管理'
                }
            },
            {
                path: 'workTemplateForm',
                component: workTemplateForm,
                meta: {
                    requiresAuth: true,
                    bread: ''
                }
            },
            {
                path: 'workLogTable',
                component: workLogTable,
                meta: {
                    requiresAuth: true,
                    bread: '首页 / 排班管理'
                }
            },
            {
                path: 'monthLogTable',
                component: monthLogTable,
                meta: {
                    requiresAuth: true,
                    bread: '首页 / 月度日志'
                }
            },
            {
                path: 'trackfeedbacknum',
                component: TrackFeedbackNum,
                meta: {
                    requiresAuth: true,
                    bread: '首页 / 追踪反馈数量'
                }
            },
            {
                path: 'dailyLogForm',
                component: dailyLogForm,
                meta: {
                    requiresAuth: true,
                    bread: ''
                }
            },
            {
                path: 'monthLogForm',
                component: monthLogForm,
                meta: {
                    requiresAuth: true,
                    bread: ''
                }
            },
            {
                path: 'standardList',
                component: standardList,
                meta: {
                    requiresAuth: true,
                    bread: ''
                }
            },
            {
                path: 'standard',
                component: standard,
                meta: {
                    requiresAuth: true,
                    bread: ''
                }
            },
            {
                path: '/exam',
                name: 'Exam',
                component: Exam,
                meta: {
                    requiresAuth: true,
                    bread: ''
                }
            },
            {
                path: '/template',
                name: 'Template',
                component: Template,
                meta: {
                    requiresAuth: true,
                    bread: ''
                }
            },
            {
                path: '/questionInfo',
                name: 'QuestionInfo',
                component: QuestionInfo,
                meta: {
                    requiresAuth: true,
                    bread: ''
                }
            },
            {
                path: '/templateInfo',
                name: 'TemplateInfo',
                component: TemplateInfo,
                meta: {
                    requiresAuth: true,
                    bread: ''
                }
            },
            {
                path: '/addTemplateQuestion',
                name: 'addTemplateQuestion',
                component: addTemplateQuestion,
                meta: {
                    requiresAuth: true,
                    bread: ''
                }
            },
            {
                path: '/sectionInfo',
                name: 'sectionInfo',
                component: sectionInfo,
                meta: {
                    requiresAuth: true,
                    bread: ''
                }
            },
            {
                path: '/question',
                name: 'question',
                component: Question,
                meta: {
                    requiresAuth: true,
                    bread: ''
                }
            },
            {
                path: 'bank',
                name: 'bank',
                component: Bank,
                meta: {
                    requiresAuth: true,
                    bread: ''
                }
            },
            {
                path: 'checkholiday',
                name: 'CheckHoliday',
                component: CheckHoliday,
                meta: {
                    requiresAuth: true,
                    bread: ''
                }
            },
            {
                path: 'holidaysetting',
                name: 'HolidaySetting',
                component: HolidaySetting,
                meta: {
                    requiresAuth: true,
                    bread: ''
                }
            },
            {
                path: 'recorder',
                name: 'recorder',
                component: recorder,
                meta: {
                    requiresAuth: true,
                    bread: ''
                }
            },

            {
                path: 'quarterschedule',
                name: 'QuarterSchedule',
                component: QuarterSchedule,
                meta: {
                    requiresAuth: true,
                    bread: ''
                }
            },
            {
                path: 'quartersheet',
                name: 'QuarterSheet',
                component: QuarterSheet,
                meta: {
                    requiresAuth: true,
                    bread: ''
                }
            },
            {
                path: 'regulations',
                component: Regulations,
                meta: {
                    requiresAuth: true,
                    bread: ''
                }
            },
            {
                path: 'todoStandardList',
                component: todoStandardList,
                meta: {
                    requiresAuth: true,
                    bread: ''
                }
            },
            {
                path: 'todoTypeList',
                component: todoTypeList,
                meta: {
                    requiresAuth: true,
                    bread: ''
                }
            },
            {
                path: 'correctionSampleList',
                component: correctionSampleList,
                meta: {
                    requiresAuth: true,
                    bread: ''
                }
            },
            {
                path: 'brandList',
                component: brandList,
                meta: {
                    requiresAuth: true,
                    bread: ''
                }
            },
            {
                path: 'brandpolicy',
                component: brandPolicy,
                meta: {
                    requiresAuth: true,
                    bread: ''
                }
            },
            {
                path: 'specialWorkDayList',
                component: specialWorkDayList,
                meta: {
                    requiresAuth: true,
                    bread: ''
                }
            },
            {
                path: 'tipList',
                component: tipList,
                meta: {
                    requiresAuth: true,
                    bread: ''
                }
            },
            {
                path: 'scoreCompelete',
                component: scoreCompelete,
                meta: {
                    requiresAuth: true,
                    bread: ''
                }
            }
        ]
    },
    {
        path: '/adminmanageuser',
        name: 'adminManageUser',
        component: adminManageUser,
        meta: {
            requiresAuth: true
        },
        children: [{
                path: 'projectTimePoint',
                component: ProjectTimePoint,
                meta: {
                    requiresAuth: true,
                    bread: ''
                }
            },
            {
                path: 'dailyLogList',
                component: dailyLogList,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'dailyLogForm',
                component: dailyLogForm,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'monthLogList',
                component: monthLogList,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'monthLogForm',
                component: monthLogForm,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'quarterschedule',
                component: QuarterUserSchedule,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'quartersheet',
                component: QuarterSheet,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'comment',
                component: Comment,
                meta: {
                    requiresAuth: true,
                    bread: '首页 / 我的评语'
                }
            },
            {
                path: 'subordinate',
                component: SubordiNate,
                meta: {
                    requiresAuth: true,
                    bread: '首页 / 查看下属'
                }
            },
            {
                path: 'workquestions',
                component: WorkQuestions,
                meta: {
                    requiresAuth: true,
                    bread: '首页 / 工作提问'
                }
            },
            {
                path: 'checktask',
                component: checkTask,
                meta: {
                    requiresAuth: true,
                    bread: '首页 / 审核任务'
                }
            },
            {
                path: 'checktype',
                component: checkType,
                meta: {
                    requiresAuth: true,
                    bread: '首页 / 审核类型'
                }
            },
            {
                path: 'checklist',
                component: checkList,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'checkitem',
                component: checkItem,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'reviewslist',
                component: ReviewsList,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'toreview',
                component: toReview,
                meta: {
                    requiresAuth: true,
                    bread: '首页 / 待审核'
                }
            }, {
                path: 'reviewdetail',
                component: reviewDetail,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'requestList',
                component: ITRequestList,
                meta: {
                    requiresAuth: true,
                    bread: '首页 / IT需求'
                }
            }, {
                path: 'classlistuser',
                component: classlistUser,
                meta: {
                    requiresAuth: true,
                    bread: '首页 / 我的课程'
                }
            },
            {
                path: 'employeeList',
                component: employeeList,
                meta: {
                    requiresAuth: true,
                    bread: '首页 / 员工列表'
                }
            },
            {
                path: 'classInfouser',
                component: classInfoUser,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'contentinfouser',
                component: contentInfoUser,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'workShop',
                component: workShop,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'workShopEntry',
                component: workShopEntry,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'evaluationInfo',
                component: EvaluationInfo,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'workShopReview',
                component: workShopReview,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'workShopEntryList',
                component: workShopEntryList,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/meeting',
                name: 'Meeting',
                component: Meeting,
                meta: {
                    requiresAuth: true,
                    bread: '首页 / 会议记录'
                }
            },
            {
                path: '/updatemeetingnote',
                name: 'UpdateMeetingNote',
                component: UpdateMeetingNote,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'ProjectTodo',
                name: 'ProjectTodo',
                component: ProjectTodo,
                meta: {
                    requiresAuth: true,
                    bread: '首页 / 品牌内容工作清单'
                }
            },
            {
                path: 'standardList',
                component: standardList,
                meta: {
                    requiresAuth: true,
                    bread: '首页 / 工作标准'
                }
            },
            {
                path: 'standard',
                component: standard,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/examlist',
                name: 'ExamList',
                component: ExamList,
                meta: {
                    requiresAuth: true,
                    bread: '首页 / 考试模板'
                }
            },
            {
                path: 'applyHoliday',
                name: 'ApplyHoliday',
                component: ApplyHoliday,
                meta: {
                    requiresAuth: true,
                    bread: '首页 / 假期管理'
                }
            },
            {
                path: 'checkApply',
                name: 'checkApply',
                component: CheckHoliday,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'regulations',
                component: userRegulations,
                meta: {
                    requiresAuth: true,
                    bread: '首页 / 规章制度'
                }
            },
            {
                path: 'law',
                component: Law,
                meta: {
                    requiresAuth: true,
                    bread: '首页 / 法律法规'
                }
            },
            {
                path: 'regulation',
                component: Regulation,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'newapply',
                component: newApply,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'speech',
                component: Speech,
                meta: {
                    requiresAuth: true,
                    bread: '首页 / 话术字典'
                }
            },
            {
                path: 'correction',
                component: Correction,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'audio-to-text',
                component: audioToText,
                meta: {
                    requiresAuth: true,
                    bread: '首页 / 音频转文字'
                }
            },
            {
                path: 'totext',
                name: 'totext',
                component: totext,
                meta: {
                    requiresAuth: true,
                    bread: '首页 / 语音转文字'
                }
            },
            {
                path: 'text-to-audio',
                component: textToAudio,
                meta: {
                    requiresAuth: true,
                    bread: '首页 / 文字转音频'
                }
            }

        ]
    },
    {
        path: '/sessioninfouser',
        component: sessionInfoUser,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/examview',
        name: 'ExamView',
        component: ExamView,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/music',
        name: 'Music',
        component: Music,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/game',
        name: 'Game',
        component: Game,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/chat',
        name: 'Chat',
        component: Chat,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/price',
        name: 'Price',
        component: Price,
        meta: {
            requiresAuth: true,
            bread: '首页 / 定价管理'
        }
    },
    {
        path: '/aicontents',
        name: 'AI Contents',
        component: AIContents,
        meta: {
            requiresAuth: true,
            bread: '首页 / AI内容创作'
        }
    },
    {
        path: '/chatai',
        name: 'ChatAI',
        component: ChatAI,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/ziliao',
        name: 'ziliao',
        component: ziliao,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/result',
        name: 'result',
        component: result,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/wechat',
        name: 'wechat',
        component: wechat,
        meta: {
            requiresAuth: true
        }
    },

]

const router = new VueRouter({
    linkActiveClass: 'active',
    routes
})
router.beforeEach((to, from, next) => {
    if (to.path === '/login') {
        next()
    } else {
        var userId = getUserId()
        console.log('userId:' + userId)
        if (to.meta.requiresAuth && !userId) {
            next({
                path: '/login'
            })
        } else {
            next()
        }
    }
})
export default router