<template>
  <div class="priceContainer">
    <div class="leftSide">
      <img class="titleLogo" src="../assets/loginTitle.png" />
      <el-menu :default-active="currentProject">
        <el-menu-item :index="item" v-for="item in projectList" :key="item" @click="chooseItem(item)">{{ item
          }}</el-menu-item>
      </el-menu>
    </div>
    <div class="rightSide">
      <div class="header">
        <div class="goBack" @click="$router.push('/nav')">
          <i class="el-icon-arrow-left" style="font-size: 120%"></i><span
            style="font-size: 100%; margin-left: 4px">返回首页</span>
        </div>
        <div class="rightPart">
          <span>{{ userName }}</span>
          <img src="../assets/exit.svg" style="margin-left: 20px; cursor: pointer" @click="logout" />
        </div>
      </div>
      <div class="tagView">
        {{ $route.meta.bread }}
      </div>
      <div style="padding: 0 20px ;height: 80vh;">
        <div style="margin-bottom: 10px">{{ currentProject }}</div>
        <el-button v-if="showType !== 0" @click="add" type="primary">添加</el-button>
        <!-- <el-upload class="avatar-uploader" action="#" :show-file-list="false" :before-upload="beforeAvatarUpload">
          <i class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload> -->
        <el-table style="height: 100%;overflow: hidden;overflow-y: auto;" v-if="showType == 0" :data="tableData">
          <el-table-column label="项目" prop="project_code" width="200px">
          </el-table-column>

          <el-table-column label="币种">
            <template slot-scope="scope">
              {{ scope.row.price_type }}
            </template>

          </el-table-column>
          <el-table-column v-for="item in 8" :key="item" :label="item">
            <template slot-scope="scope">


              <span style="">{{ 'standard : ' + scope.row[`price${item}`] }}
              </span>

              <br>

              <span style="">{{ 'early bird : ' + scope.row[`earlyBirdPrice${item}`] }}
              </span>


              <!-- <br>
              <span style="margin-right: 10px"
                >{{'2: ' +  scope.row.price2 }}
                {{ scope.row.price_type }}</span
              >
              <br>
              <span style="margin-right: 10px" 
                >{{'3: ' +  scope.row.price3 }}
                {{ scope.row.price_type }}</span
              >
              <br>
              <span style="margin-right: 10px" 
                >{{'4: ' +  scope.row.price4 }}
                {{ scope.row.price_type }}</span
              >
              <br>
              <span style="margin-right: 10px" 
                >{{'5: ' +  scope.row.price5 }}
                {{ scope.row.price_type }}</span
              >
              <br>
              <span style="margin-right: 10px" 
                >{{'6: ' +  scope.row.price6 }}
                {{ scope.row.price_type }}</span
              >
              <br>
              <span style="margin-right: 10px" 
                >{{'7: ' +  scope.row.price7 }}
                {{ scope.row.price_type }}</span
              >
              <br>
              <span style="margin-right: 10px" 
                >{{'8: ' +  scope.row.price8 }}
                {{ scope.row.price_type }}</span
              > -->

              <!-- <el-button
                v-if="scope.row[`${item}`]"
                type="primary"
                size="small"
                @click="edit(scope.row[`${item}`])"
                >编辑</el-button
              >

              <el-button
                v-if="scope.row[`price${item}`]"
                type="danger"
                size="small"
                @click="remove(scope.row[`price${item}`])"
                >删除</el-button
              > -->
            </template>
          </el-table-column>
        </el-table>

        <el-table style="height: 100%;overflow: hidden;overflow-y: auto;" v-if="showType == 1" :data="countryData">
          <el-table-column label="国家" prop="country"></el-table-column>
          <el-table-column label="国家英文" prop="">
            <template slot-scope="scope">
              {{  scope.row.country_en ? scope.row.country_en  : ''}}
            </template>
          </el-table-column>
          <el-table-column label="国家中文" prop="">
            <template slot-scope="scope">
              {{  scope.row.country_cn ? scope.row.country_cn  : ''}}
            </template>
          </el-table-column>
          <el-table-column label="级别" prop="">
            <template slot-scope="scope">
              <span style="margin-right: 10px">{{ scope.row.level }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Target Offer" prop="">
            <template slot-scope="scope">
              <span style="margin-right: 10px">{{ scope.row.target_offer }}</span>
            </template>
          </el-table-column>
          <el-table-column label="">
            <template slot-scope="scope">
              <!-- <span style="margin-right: 10px"
                >{{ scope.row.level }}</span> -->


              <!-- <el-button type="primary" size="small" @click="editCountry(scope.row)">编辑</el-button> -->

              <el-button type="danger" size="small" @click="removeCountry(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>


        <el-table style="height: 100%;overflow: hidden;overflow-y: auto;" v-if="showType == 2" :data="projectData">
          <el-table-column label="项目" prop="project_code"></el-table-column>
          <el-table-column label="年份">
            <template slot-scope="scope">
              {{ scope.row.year }}
            </template>
          </el-table-column>
          <el-table-column label="币种">
            <template slot-scope="scope">
              {{ scope.row.price_type }}
            </template>
          </el-table-column>
          <el-table-column label="价格1" width="120px">
            <template slot-scope="scope">
              {{ 'standard : ' + scope.row.price1 }}

              <br>

              <span style="">{{ 'early bird : ' + scope.row.earlyBirdPrice1 }}
              </span>

            </template>
          </el-table-column>
          <el-table-column label="价格2" width="120px">
            <template slot-scope="scope">
              {{ 'standard : ' + scope.row.price2 }}
              <br>

              <span style="">{{ 'early bird : ' + scope.row.earlyBirdPrice2 }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="价格3" width="120px">
            <template slot-scope="scope">
              {{ 'standard : ' + scope.row.price3 }}
              <br>

              <span style="">{{ 'early bird : ' + scope.row.earlyBirdPrice3 }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="价格4" width="120px">
            <template slot-scope="scope">
              {{ 'standard : ' + scope.row.price4 }}
              <br>

              <span style="">{{ 'early bird : ' + scope.row.earlyBirdPrice4 }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="价格5" width="120px">
            <template slot-scope="scope">
              {{ 'standard : ' + scope.row.price5 }}
              <br>

              <span style="">{{ 'early bird : ' + scope.row.earlyBirdPrice5 }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="价格6" width="120px">
            <template slot-scope="scope">
              {{ 'standard : ' + scope.row.price6 }}
              <br>

              <span style="">{{ 'early bird : ' + scope.row.earlyBirdPrice6 }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="价格7" width="120px">
            <template slot-scope="scope">
              {{ 'standard : ' + scope.row.price7 }}
              <br>

              <span style="">{{ 'early bird : ' + scope.row.earlyBirdPrice7 }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="价格8" width="120px">
            <template slot-scope="scope">
              {{ 'standard : ' + scope.row.price8 }}
              <br>

              <span style="">{{ 'early bird : ' + scope.row.earlyBirdPrice8 }}
              </span>
            </template>
          </el-table-column>

          <el-table-column>
            <template slot-scope="scope">

              <el-button type="primary" size="small" @click="editProjectPrice(scope.row)">编辑</el-button>

              <el-button type="danger" size="small" @click="removeProjectPrice(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>

      </div>
    </div>
    <el-dialog :visible.sync="showDialog">
      <el-form>
        <el-form-item label="国家">
          <el-select filterable v-model="form.country" placeholder="请选择">
            <el-option v-for="item in countryList" :key="item.name" :label="item.name" :value="item.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="币种">
          <el-select v-model="form.price_type" placeholder="请选择">
            <el-option value="RMB"></el-option>
            <el-option value="SGD"></el-option>
            <el-option value="USD"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="金额">
          <el-input v-model="form.num" style="width: 200px"></el-input>
        </el-form-item>
        <el-form-item label="年份">
          <el-select v-model="form.year" placeholder="请选择">
            <el-option :value="item" v-for="item in yearList" :key="item"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="showDialog = false">取消</el-button>
          <el-button type="primary" @click="confirm">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>


    <el-dialog :visible.sync="showCountryDialog">
      <el-form>
        <el-form-item label="国家">
          <el-select filterable v-model="countryForm.country" placeholder="请选择">
            <el-option v-for="item in countryList" :key="item.name" :label="item.name" :value="item.name"></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="币种">
          <el-select v-model="form.price_type" placeholder="请选择">
            <el-option value="RMB"></el-option>
            <el-option value="SGD"></el-option>
            <el-option value="USD"></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="级别">
          <el-select v-model="countryForm.level" placeholder="请选择">
            <el-option :value="item" v-for="item in level" :key="item"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="Target Offer">
          <el-select v-model="countryForm.target_offer" placeholder="请选择">
            <el-option :value="item" v-for="item in target_offer_arr" :key="item"></el-option>
          </el-select>
        </el-form-item>


        <!-- <el-form-item label="年份">
          <el-select v-model="form.year" placeholder="请选择">
            <el-option
              :value="item"
              v-for="item in yearList"
              :key="item"
            ></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item>
          <el-button @click="showCountryDialog = false">取消</el-button>
          <el-button type="primary" @click="confirmCountry()">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog :visible.sync="showProjectDialog">
      <el-form>
        <el-form-item label="项目">
          <el-select filterable v-model="projectForm.project_code" placeholder="请选择">
            <el-option v-for="item in project_codes" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </el-form-item>


        <!-- <el-form-item label="年份">
          <el-select v-model="projectForm.year" placeholder="请选择">
            <el-option
              v-for="item in yearList"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item> -->

        <el-form-item label="币种">
          <el-select v-model="projectForm.price_type" placeholder="请选择">
            <el-option value="RMB"></el-option>
            <el-option value="SGD"></el-option>
            <el-option value="USD"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="定价1">
          <el-input v-model="projectForm.price1" style="width: 200px"></el-input>
          <el-input v-model="projectForm.earlyBirdPrice1" style="width: 200px;margin-left: 30px;"></el-input>

        </el-form-item>
        <el-form-item label="定价2">
          <el-input v-model="projectForm.price2" style="width: 200px"></el-input>
          <el-input v-model="projectForm.earlyBirdPrice2" style="width: 200px;margin-left: 30px;"></el-input>
        </el-form-item>
        <el-form-item label="定价3">
          <el-input v-model="projectForm.price3" style="width: 200px"></el-input>
          <el-input v-model="projectForm.earlyBirdPrice3" style="width: 200px;margin-left: 30px;"></el-input>
        </el-form-item>
        <el-form-item label="定价4">
          <el-input v-model="projectForm.price4" style="width: 200px"></el-input>
          <el-input v-model="projectForm.earlyBirdPrice4" style="width: 200px;margin-left: 30px;"></el-input>

        </el-form-item>
        <el-form-item label="定价5">
          <el-input v-model="projectForm.price5" style="width: 200px"></el-input>
          <el-input v-model="projectForm.earlyBirdPrice5" style="width: 200px;margin-left: 30px;"></el-input>
        </el-form-item>
        <el-form-item label="定价6">
          <el-input v-model="projectForm.price6" style="width: 200px"></el-input>
          <el-input v-model="projectForm.earlyBirdPrice6" style="width: 200px;margin-left: 30px;"></el-input>
        </el-form-item>
        <el-form-item label="定价7">
          <el-input v-model="projectForm.price7" style="width: 200px"></el-input>
          <el-input v-model="projectForm.earlyBirdPrice7" style="width: 200px;margin-left: 30px;"></el-input>
        </el-form-item>
        <el-form-item label="定价8">
          <el-input v-model="projectForm.price8" style="width: 200px"></el-input>
          <el-input v-model="projectForm.earlyBirdPrice8" style="width: 200px;margin-left: 30px;"></el-input>

        </el-form-item>

        <el-form-item>
          <el-button @click="showProjectDialog = false">取消</el-button>
          <el-button type="primary" @click="confirmProject()">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

  </div>
</template>

<script>
import { updatePrice, removePrice, getPrices, removeCountry, getCountrys, updateCountry, removeProjectPrice, updateProjectPrice, getProjectPrices, getAdminProjects, getProjectPricesByProject } from "../api/assistant";
import { removeUserId, removeUserName, getUserName } from "../utils/store";
import * as XLSX from "xlsx";
export default {
  data() {
    return {
      projectList: ["国家", "产品定价", "EAE", "IBO", "AEO", "WEC", "AM", "NIM", "IPsyO"],
      currentProject: "产品定价",
      tableData: [],
      countryData: [],
      projectData: [],
      project_codes: [],
      showDialog: false,
      showCountryDialog: false,
      showProjectDialog: false,
      form: {},
      countryForm: {},
      projectForm: {},
      userName: "",
      yearList: [
        "2024",
        "2025",
        "2026",
        "2027",
        "2028",
        "2029",
        "2030",
        "2031",
        "2032",
        "2033",
        "2034",
        "2035",
        "2036",
        "2037",
        "2038",
        "2039",
        "2040",
        "2041",
        "2042",
        "2043",
        "2044",
        "2045",
        "2046",
        "2047",
        "2048",
        "2049",
        "2050",
      ],
      countryList: [
        { name: "中国 (China)", areaCode: "86" },
        { name: "中国香港 (Hong Kong)", areaCode: "852" },
        { name: "中国澳門 (Macau)", areaCode: "853" },
        { name: "中国台湾 (Taiwan)", areaCode: "886" },
        { name: "日本 (Japan)", areaCode: "81" },
        { name: "韩国 (South Korea)", areaCode: "82" },
        { name: "美国 (USA)", areaCode: "1" },
        { name: "加拿大 (Canada)", areaCode: "1" },
        { name: "阿富汗 (Afghanistan)", areaCode: "93" },
        { name: "阿尔巴尼亚 (Albania)", areaCode: "355" },
        { name: "阿尔及利亚 (Algeria)", areaCode: "213" },
        { name: "萨摩亚 (American Samoa)", areaCode: "684" },
        { name: "安道尔共和国 (Andorra)", areaCode: "376" },
        { name: "安哥拉 (Angola)", areaCode: "244" },
        { name: "安圭拉岛 (Anguilla)", areaCode: "1264" },
        { name: "南极洲 (Antarctica)", areaCode: "672" },
        { name: "安提瓜和巴布达 (Antigua and Barbuda)", areaCode: "1268" },
        { name: "阿根廷 (Argentina)", areaCode: "54" },
        { name: "亚美尼亚 (Armenia)", areaCode: "374" },
        { name: "阿鲁巴 (Aruba)", areaCode: "297" },
        { name: "澳大利亚 (Australia)", areaCode: "61" },
        { name: "奥地利 (Austria)", areaCode: "43" },
        { name: "阿塞拜疆 (Azerbaijan)", areaCode: "994" },
        { name: "巴哈马 (Bahamas)", areaCode: "1242" },
        { name: "巴林 (Bahrain)", areaCode: "973" },
        { name: "孟加拉国 (Bangladesh)", areaCode: "880" },
        { name: "巴巴多斯 (Barbados)", areaCode: "1246" },
        { name: "白俄罗斯 (Belarus)", areaCode: "375" },
        { name: "比利时 (Belgium)", areaCode: "32" },
        { name: "伯利兹城 (Belize)", areaCode: "501" },
        { name: "贝宁 (Benin)", areaCode: "229" },
        { name: "百慕大 (Bermuda)", areaCode: "1441" },
        { name: "不丹 (Bhutan)", areaCode: "975" },
        { name: "玻利维亚 (Bolivia)", areaCode: "591" },
        {
          name: "波斯尼亚和黑塞哥维那 (Bosnia and Herzegovina)",
          areaCode: "387",
        },
        { name: "博茨瓦纳 (Botswana)", areaCode: "267" },
        { name: "巴西 (Brazil)", areaCode: "55" },
        {
          name: "英属印度洋领地 (British Indian Ocean Territory)",
          areaCode: "246",
        },
        { name: "文莱达鲁萨兰国 (Brunei Darussalam)", areaCode: "673" },
        { name: "保加利亚 (Bulgaria)", areaCode: "359" },
        { name: "布基纳法索 (Burkina Faso)", areaCode: "226" },
        { name: "布隆迪 (Burundi)", areaCode: "257" },
        { name: "柬埔寨 (Cambodia)", areaCode: "855" },
        { name: "喀麦隆 (Cameroon)", areaCode: "237" },
        { name: "佛得角 (Cape Verde)", areaCode: "238" },
        { name: "开曼群岛 (Cayman Islands)", areaCode: "1345" },
        { name: "中非共和国 (Central African Republic)", areaCode: "236" },
        { name: "乍得 (Chad)", areaCode: "235" },
        { name: "智利 (Chile)", areaCode: "56" },
        { name: "圣延岛 (Christmas Island)", areaCode: "61" },
        { name: "科科斯群岛 (Cocos (Keeling) Islands)", areaCode: "61" },
        { name: "哥伦比亚(Colombia)", areaCode: "57" },
        { name: "科摩罗 (Comoros)", areaCode: "269" },
        { name: "刚果 (Congo)", areaCode: "242" },
        {
          name: "刚果民主共和国(Congo,The Democratic Republic Of The)",
          areaCode: "243",
        },
        { name: "库克群岛 (Cook Islands)", areaCode: "682" },
        { name: "哥斯达黎加 (Costa Rica)", areaCode: "506" },
        { name: "科特迪瓦 (Cote D Ivoire)", areaCode: "225" },
        { name: "克罗地亚 (Croatia)", areaCode: "385" },
        { name: "古巴 (Cuba)", areaCode: "53" },
        { name: "塞浦路斯 (Cyprus)", areaCode: "357" },
        { name: "捷克 (Czech Republic)", areaCode: "420" },
        { name: "丹麦 (Denmark)", areaCode: "45" },
        { name: "吉布提 (Djibouti)", areaCode: "253" },
        { name: "多米尼克国 (Dominica)", areaCode: "1767" },
        { name: "多米尼加共和国 (Dominican Republic)", areaCode: "1849" },
        { name: "东帝汶 (East Timor)", areaCode: "670" },
        { name: "厄瓜多尔 (Ecuador)", areaCode: "593" },
        { name: "埃及 (Egypt)", areaCode: "20" },
        { name: "萨尔瓦多 (El Salvador)", areaCode: "503" },
        { name: "赤道几内亚 (Equatorial Guinea)", areaCode: "240" },
        { name: "爱沙尼亚 (Estonia)", areaCode: "372" },
        { name: "埃塞俄比亚 (Ethiopia)", areaCode: "251" },
        { name: "福克兰群岛(Falkland Islands (Malvinas))", areaCode: "500" },
        { name: "法罗群岛 (Faroe Islands)", areaCode: "298" },
        { name: "斐济 (Fiji)", areaCode: "679" },
        { name: "芬兰 (Finland)", areaCode: "358" },
        { name: "法国 (France)", areaCode: "33" },
        { name: "法国大都会 (France Metropolitan)", areaCode: "33" },
        { name: "法属圭亚那 (French Guiana)", areaCode: "594" },
        { name: "法属玻里尼西亚 (French Polynesia)", areaCode: "689" },
        { name: "加蓬 (Gabon)", areaCode: "241" },
        { name: "冈比亚 (Gambia)", areaCode: "220" },
        { name: "格鲁吉亚 (Georgia)", areaCode: "995" },
        { name: "德国 (Germany)", areaCode: "49" },
        { name: "加纳 (Ghana)", areaCode: "233" },
        { name: "直布罗陀 (Gibraltar)", areaCode: "350" },
        { name: "希腊 (Greece)", areaCode: "30" },
        { name: "格陵兰 (Greenland)", areaCode: "45" },
        { name: "格林纳达 (Grenada)", areaCode: "1473" },
        { name: "瓜德罗普岛 (Guadeloupe)", areaCode: "590" },
        { name: "关岛 (Guam)", areaCode: "1671" },
        { name: "危地马拉 (Guatemala)", areaCode: "502" },
        { name: "几内亚 (Guinea)", areaCode: "224" },
        { name: "几内亚比绍 (Guinea-Bissau)", areaCode: "245" },
        { name: "圭亚那 (Guyana)", areaCode: "592" },
        { name: "海地 (Haiti)", areaCode: "509" },
        { name: "洪都拉斯 (Honduras)", areaCode: "504" },
        { name: "匈牙利 (Hungary)", areaCode: "36" },
        { name: "冰岛 (Iceland)", areaCode: "354" },
        { name: "印度 (India)", areaCode: "91" },
        { name: "印度尼西亚 (Indonesia)", areaCode: "62" },
        { name: "伊朗 (Iran (Islamic Republic of))", areaCode: "98" },
        { name: "伊拉克 (Iraq)", areaCode: "964" },
        { name: "爱尔兰 (Ireland)", areaCode: "353" },
        { name: "以色列 (Israel)", areaCode: "972" },
        { name: "意大利 (Italy)", areaCode: "39" },
        { name: "牙买加 (Jamaica)", areaCode: "1876" },
        { name: "约旦 (Jordan)", areaCode: "962" },
        { name: "哈萨克 (Kazakhstan)", areaCode: "7" },
        { name: "肯尼亚 (Kenya)", areaCode: "254" },
        { name: "科威特 (Kuwait)", areaCode: "965" },
        { name: "吉尔吉斯 (Kyrgyzstan)", areaCode: "996" },
        { name: "拉脱维亚 (Latvia)", areaCode: "371" },
        { name: "黎巴嫩 (Lebanon)", areaCode: "961" },
        { name: "莱索托 (Lesotho)", areaCode: "266" },
        { name: "利比里亚 (Liberia)", areaCode: "231" },
        { name: "利比亚 (Libyan Arab Jamahiriya)", areaCode: "218" },
        { name: "列支敦士登 (Liechtenstein)", areaCode: "423" },
        { name: "立陶宛 (Lithuania)", areaCode: "370" },
        { name: "卢森堡 (Luxembourg)", areaCode: "352" },
        { name: "马达加斯加 (Madagascar)", areaCode: "261" },
        { name: "马拉维 (Malawi)", areaCode: "265" },
        { name: "马来西亚 (Malaysia)", areaCode: "60" },
        { name: "马尔代夫 (Maldives)", areaCode: "960" },
        { name: "马里 (Mali)", areaCode: "223" },
        { name: "马尔他 (Malta)", areaCode: "356" },
        { name: "马提尼克岛 (Martinique)", areaCode: "596" },
        { name: "毛里塔尼亚 (Mauritania)", areaCode: "222" },
        { name: "毛里求斯(Mauritius)", areaCode: "230" },
        { name: "马约特 (Mayotte)", areaCode: "262" },
        { name: "墨西哥 (Mexico)", areaCode: "52" },
        { name: "密克罗尼西亚 (Micronesia)", areaCode: "691" },
        { name: "摩尔多瓦 (Moldova)", areaCode: "373" },
        { name: "摩纳哥 (Monaco)", areaCode: "377" },
        { name: "外蒙古 (Mongolia)", areaCode: "976" },
        { name: "黑山共和国 (Montenegro)", areaCode: "382" },
        { name: "蒙特塞拉特 (Montserrat)", areaCode: "1664" },
        { name: "摩洛哥 (Morocco)", areaCode: "212" },
        { name: "莫桑比克 (Mozambique)", areaCode: "258" },
        { name: "缅甸 (Myanmar)", areaCode: "95" },
        { name: "那米比亚 (Namibia)", areaCode: "264" },
        { name: "瑙鲁 (Nauru)", areaCode: "674" },
        { name: "尼泊尔 (Nepal)", areaCode: "977" },
        { name: "荷兰 (Netherlands)", areaCode: "31" },
        { name: "荷兰安的列斯群岛 (Netherlands Antilles)", areaCode: "599" },
        { name: "新喀里多尼亚 (New Caledonia)", areaCode: "687" },
        { name: "新西兰 (New Zealand)", areaCode: "64" },
        { name: "尼加拉瓜 (Nicaragua)", areaCode: "505" },
        { name: "尼日尔 (Niger)", areaCode: "227" },
        { name: "尼日利亚 (Nigeria)", areaCode: "234" },
        { name: "诺福克岛 (Norfolk Island)", areaCode: "6723" },
        { name: "朝鲜 (North Korea)", areaCode: "850" },
        { name: "北马里亚纳群岛 (Northern Mariana Islands)", areaCode: "1670" },
        { name: "挪威 (Norway)", areaCode: "47" },
        { name: "阿曼 (Oman)", areaCode: "968" },
        { name: "巴基斯坦 (Pakistan)", areaCode: "92" },
        { name: "帛琉 (Palau)", areaCode: "680" },
        { name: "巴勒斯坦 (Palestine)", areaCode: "970" },
        { name: "巴拿马 (Panama)", areaCode: "507" },
        { name: "巴布亚新几内亚 (Papua New Guinea)", areaCode: "675" },
        { name: "巴拉圭 (Paraguay)", areaCode: "595" },
        { name: "秘鲁 (Peru)", areaCode: "51" },
        { name: "菲律宾共和国 (Philippines)", areaCode: "63" },
        { name: "皮特凯恩岛 (Pitcairn)", areaCode: "64" },
        { name: "波兰 (Poland)", areaCode: "48" },
        { name: "葡萄牙 (Portugal)", areaCode: "351" },
        { name: "波多黎各 (Puerto Rico)", areaCode: "1787" },
        { name: "卡塔尔 (Qatar)", areaCode: "974" },
        { name: "留尼汪岛 (Reunion)", areaCode: "262" },
        { name: "罗马尼亚 (Romania)", areaCode: "40" },
        { name: "俄罗斯联邦 (Russian Federation)", areaCode: "7" },
        { name: "卢旺达 (Rwanda)", areaCode: "250" },
        { name: "美属萨摩亚 (Samoa)", areaCode: "685" },
        { name: "圣马力诺共和国 (San Marino)", areaCode: "378" },
        { name: "沙特阿拉伯 (Saudi Arabia)", areaCode: "966" },
        { name: "塞内加尔 (Senegal)", areaCode: "221" },
        { name: "塞尔维亚共和国 (Serbia)", areaCode: "381" },
        { name: "塞舌尔 (Seychelles)", areaCode: "248" },
        { name: "塞拉利昂 (Sierra Leone)", areaCode: "232" },
        { name: "新加坡 (Singapore)", areaCode: "65" },
        { name: "斯洛伐克 (Slovakia (Slovak Republic))", areaCode: "421" },
        { name: "斯洛文尼亚 (Slovenia)", areaCode: "386" },
        { name: "索罗门群岛 (Solomon Islands)", areaCode: "677" },
        { name: "索马里 (Somalia)", areaCode: "252" },
        { name: "南非 (South Africa)", areaCode: "27" },
        { name: "西班牙 (Spain)", areaCode: "34" },
        { name: "斯里兰卡 (Sri Lanka)", areaCode: "94" },
        { name: "苏丹 (Sudan)", areaCode: "249" },
        { name: "苏里南 (Suriname)", areaCode: "597" },
        { name: "斯威士兰 (Swaziland)", areaCode: "268" },
        { name: "瑞典 (Sweden)", areaCode: "46" },
        { name: "瑞士 (Switzerland)", areaCode: "41" },
        { name: "叙利亚 (Syrian Arab Republic)", areaCode: "963" },
        { name: "塔吉克 (Tajikistan)", areaCode: "992" },
        { name: "坦桑尼亚 (Tanzania)", areaCode: "255" },
        { name: "泰国 (Thailand)", areaCode: "66" },
        { name: "多哥 (Togo)", areaCode: "228" },
        { name: "汤加 (Tonga)", areaCode: "676" },
        { name: "特立尼达和多巴哥 (Trinidad and Tobago)", areaCode: "1868" },
        { name: "突尼斯 (Tunisia)", areaCode: "216" },
        { name: "土耳其 (Turkey)", areaCode: "90" },
        { name: "土库曼 (Turkmenistan)", areaCode: "993" },
        {
          name: "土克斯及开科斯群岛 (Turks and Caicos Islands)",
          areaCode: "1809",
        },
        { name: "乌干达 (Uganda)", areaCode: "256" },
        { name: "乌克兰 (Ukraine)", areaCode: "380" },
        { name: "阿拉伯联合酋长国 (United Arab Emirates)", areaCode: "971" },
        { name: "英国 (United Kingdom)", areaCode: "44" },
        { name: "乌拉圭 (Uruguay)", areaCode: "598" },
        { name: "乌兹别克斯坦 (Uzbekistan)", areaCode: "998" },
        { name: "瓦努阿图 (Vanuatu)", areaCode: "678" },
        { name: "梵蒂冈 (Vatican City State (Holy See))", areaCode: "39" },
        { name: "委内瑞拉 (Venezuela)", areaCode: "58" },
        { name: "越南 (Vietnam)", areaCode: "84" },
        {
          name: "维尔京群岛(英国) (Virgin Islands (British))",
          areaCode: "1284",
        },
        { name: "维尔京群岛(美国) (Virgin Islands (U.S.))", areaCode: "1340" },
        { name: "西撒哈拉 (Western Sahara)", areaCode: "685" },
        { name: "也门 (Yemen)", areaCode: "967" },
        { name: "南斯拉夫 (Yugoslavia)", areaCode: "381" },
        { name: "赞比亚 (Zambia)", areaCode: "260" },
        { name: "津巴布韦 (Zimbabwe)", areaCode: "263" },
        { name: "阿布哈兹 (the Republic of Abkhazia)", areaCode: "7" },
        { name: "南奥赛梯 (the Republic of South Ossetia)", areaCode: "7" },
        { name: "泽西岛 (Bailiwick of Jersey)", areaCode: "44" },
        { name: "老挝 (Lao People s Democratic Republic)", areaCode: "856" },
        { name: "马其顿 (The Republic of Macedonia)", areaCode: "389" },
        {
          name: "圣基茨和尼维斯(The Federation of Saint Kitts and Nevis)",
          areaCode: "1869",
        },
        { name: "圣卢西亚岛 (Santa Luzia Island)", areaCode: "1758" },
        {
          name: "圣文森特和格林纳丁斯(Saint Vincent and the Grenadines)",
          areaCode: "1784",
        },
        { name: "圣多美和普林西比 (Sao Tome and Principe)", areaCode: "239" },
        { name: "南苏丹共和国 (The Republic of South Sudan)", areaCode: "211" },
      ],
      level: [
        1, 2, 3, 4, 5, 6, 7, 8
      ],
      target_offer_arr: [
        0, 1
      ],
      showType: 2 // 0是项目 1是国家 2是产品定价
    };
  },
  mounted() {
    this.userName = getUserName();
    this.fetchProjectData();
    this.getAdminProjects()
  },
  methods: {

    async beforeAvatarUpload(file) {
      const reader = new FileReader()
      let that = this
      reader.onload = e => {
        const data = e.target.result
        const workbook = XLSX.read(data, { type: 'array' })
        const secondSheet = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[secondSheet]
        const results = XLSX.utils.sheet_to_json(worksheet)
        let dataList = []
        for (let i = 0; i < results.length; i++) {
          let content = results[i]
          let CountryEn = content['CountryEn']
          let level = content['Level']
          let offer = content['Targeted Offer']
          let Country = content['Country']
          let CountryCN = content['CountryCN']
          let params = {
            id: '',
            country: Country,
            level: level,
            target_offer: offer,
            country_en: CountryEn,
            country_cn: CountryCN
          }
          updateCountry(params).then((res) => {
            let result = res.data
            if (result.code != 0) {
              console.log('country info :' + JSON.stringify(params))
            }
          })
          dataList.push(params)
        }
        console.log('size :' + dataList.length)
      }
      reader.readAsArrayBuffer(file)
    },

    getAdminProjects() {
      getAdminProjects({ project: this.currentProject }).then((res) => {
        this.project_codes = res.data;

      });
    },
    reshapeData(data) {
      let countryList = [];
      let tableData = [];
      for (let item of data) {
        if (
          item.year === "2021" ||
          item.year === "2022" ||
          item.year === "2023" ||
          item.year === "2024"
        ) {
          if (!countryList.includes(item.country)) {
            countryList.push(item.country);
          }
        }
      }
      for (let country of countryList) {
        let obj = {
          country: country,
        };
        for (let item of data) {
          if (item.country === country) {
            obj["price" + item.year] = JSON.parse(JSON.stringify(item));
          }
        }
        tableData.push(obj);
      }
      return tableData;
    },
    chooseItem(item) {

      this.currentProject = item;

      if (item == '国家') {

        this.showType = 1

        this.fetchCountryData();

        return
      }
      if (item == '产品定价') {

        this.showType = 2

        this.fetchProjectData();

        return
      }

      this.showType = 0

      this.fetchData();
    },
    fetchData() {
      // getPrices({ project: this.currentProject }).then((res) => {
      //   if (res.data.code === 0) {
      //     this.tableData = this.reshapeData(res.data.data);
      //   }
      // });


      getProjectPricesByProject({ project: this.currentProject }).then((res) => {
        if (res.data.code === 0) {
          console.log(123, res.data.data)
          this.tableData = res.data.data;
        }
      });
    },
    fetchCountryData() {
      getCountrys({}).then((res) => {
        if (res.data.code === 0) {
          this.countryData = res.data.data;
        }
      });
    },
    fetchProjectData() {
      getProjectPrices({ project: '' }).then((res) => {
        if (res.data.code === 0) {
          this.projectData = res.data.data;
        }
      });
    },

    editProjectPrice(item) {
      this.projectForm = {
        project_code: item.project_code,
        year: item.year,
        price_type: item.price_type,
        price1: item.price1,
        price2: item.price2,
        price3: item.price3,
        price4: item.price4,
        price5: item.price5,
        price6: item.price6,
        price7: item.price7,
        price8: item.price8,
        earlyBirdPrice1: item.earlyBirdPrice1,
        earlyBirdPrice2: item.earlyBirdPrice2,
        earlyBirdPrice3: item.earlyBirdPrice3,
        earlyBirdPrice4: item.earlyBirdPrice4,
        earlyBirdPrice5: item.earlyBirdPrice5,
        earlyBirdPrice6: item.earlyBirdPrice6,
        earlyBirdPrice7: item.earlyBirdPrice7,
        earlyBirdPrice8: item.earlyBirdPrice8,
        id: item._id.$id
      };
      this.showProjectDialog = true;


    },
    editCountry(item) {
      this.countryForm = {
        country: item.country,
        level: item.level,
        target_offer: item.target_offer,
        id: item._id.$id,
        country_en: item.country_en,
        country_cn: item.country_cn
      };
      this.showCountryDialog = true;
    },
    add() {


      if (this.showType == 1) {
        this.$message.warning('暂不不支持添加国家')
        // this.countryForm = {
        //   country: "",
        //   level: "",
        //   target_offer: "",
        //   id: "",
            // country_en: '',
            // country_cn: ''
        // };
        // this.showCountryDialog = true;


        return
      }
      if (this.showType == 2) {

        this.projectForm = {
          project_code: "",
          price_type: "",
          price1: "",
          price2: "",
          price3: "",
          price4: "",
          price5: "",
          price6: "",
          price7: "",
          price8: "",
          earlyBirdPrice1: "",
          earlyBirdPrice2: "",
          earlyBirdPrice3: "",
          earlyBirdPrice4: "",
          earlyBirdPrice5: "",
          earlyBirdPrice6: "",
          earlyBirdPrice7: "",
          earlyBirdPrice8: "",
          id: ""
        };
        this.showProjectDialog = true;

        return
      }

      this.form = {
        project: this.currentProject,
        country: "",
        price_type: "",
        num: "",
        year: "",
        id: "",
      };
      this.showDialog = true;
    },
    edit(data) {
      this.form = {
        project: this.currentProject,
        country: data.country,
        price_type: data.price_type,
        num: data.num,
        year: data.year,
        id: data._id.$id,
      };
      this.showDialog = true;
    },
    remove(data) {
      this.$confirm("确认删除该条数据吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        removePrice({ id: data._id.$id }).then((res) => {
          if (res.data.code === 0) {
            this.fetchData();
          }
        });
      });
    },

    removeProjectPrice(data) {
      this.$confirm("确认删除该条数据吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        removeProjectPrice({ id: data._id.$id }).then((res) => {
          if (res.data.code === 0) {
            this.fetchProjectData();
          }
        });
      });
    },

    confirm() {
      updatePrice(this.form).then((res) => {
        if (res.data.code === 0) {
          this.showDialog = false;
          this.fetchData();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    removeCountry(data) {
      this.$confirm("确认删除该条数据吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        removeCountry({ id: data._id.$id }).then((res) => {
          if (res.data.code === 0) {
            this.fetchCountryData();
          }
        });
      });
    },
    confirmCountry() {


      updateCountry(this.countryForm).then((res) => {
        if (res.data.code === 0) {
          this.showCountryDialog = false;
          this.fetchCountryData();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    confirmProject() {


      updateProjectPrice(this.projectForm).then((res) => {
        if (res.data.code === 0) {
          this.showProjectDialog = false;
          this.fetchProjectData();
        } else {
          this.$message.error(res.data.msg);
        }
      });

    },
    myrange(start, end) {
      let arr = [];
      for (let i = start; i <= end; i++) {
        arr.unshift(i);
      }
      return arr;
    },
    logout() {
      removeUserId();
      removeUserName();
      location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.priceContainer {
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100vh;

  .leftSide {
    width: 220px;
    flex-shrink: 0;
    height: 100%;
    background: #fff;
    position: absolute;
    left: 0;
    z-index: 3;
    box-shadow: 2px 0px 6px 1px rgba(0, 21, 41, 0.12);

    .titleLogo {
      width: 120px;
      display: block;
      margin: 16px auto;
    }

    .el-menu-item.is-active {
      background: #e5efff;
      border-right: 4px solid #4a8af4;
    }
  }

  .rightSide {
    margin-left: 220px;
    flex: 1;
    height: 100%;
    overflow: hidden;
    position: relative;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
      height: 50px;
      width: 100%;
      background: #fff;
      position: absolute;
      top: 0;
      box-shadow: 0px 3px 6px 1px rgba(0, 21, 41, 0.12);
      z-index: 2;

      .rightPart {
        display: flex;
        align-items: center;
        margin-right: 30px;
        font-family: Alibaba PuHuiTi 2-65 Medium, Alibaba PuHuiTi 20;
        font-weight: normal;
        color: #999999;
        font-size: 16px;
      }

      .goBack {
        font-size: 16px;
        margin-left: 10px;
        cursor: pointer;
      }
    }

    .tagView {
      margin-top: 50px;
      background: #fff;
      height: 50px;
      line-height: 50px;
      padding-left: 30px;
    }

    .myMain {
      height: calc(100vh - 120px);
      padding: 16px;
      overflow-y: scroll;
    }
  }
}
</style>