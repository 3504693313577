<template>
    <div class="homeContainer">
        <div>
            <el-button type="primary" @click="add">创建新任务</el-button>
        </div>
        <el-table :data="tableData">
            <el-table-column prop="audio_url" label="音频地址"></el-table-column>
            <el-table-column prop="status" label="状态" width="300px"></el-table-column>
            <el-table-column prop="result" label="转化结果" width="300px"></el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button @click="getResult(scope.row)" type="primary" size="medium">手动获取转化结果</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-dialog :visible.sync="showAdd">
            <el-form>
                <el-form-item label="上传音频">
                    <el-upload
                        action="#"
                        :show-file-list="true"
                        :limit="1"
                        :file-list="fileList"
                        :before-upload="beforeUpload">
                        <el-button type="primary" :loading="uploadLoading">上传音频文件</el-button>
                    </el-upload>
                </el-form-item>
                <el-form-item>
                    <el-button style="margin-top:20px" @click="submit" type="primary">确认新建</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>

    </div>
</template>

<script>
import { getUserId } from '../../utils/store.js'
import { getUserAudioToTextTaskList, initAudioToTextTask, getQiniuToken, getTaskResult } from '../../api/assistant'
import * as qiniu from 'qiniu-js'
export default {
  data () {
    return {
      tableData: [],
      form: {},
      showAdd: false,
      id: '',
      fileList: []
    }
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      getUserAudioToTextTaskList({
        user_id: getUserId()
      }).then(res => {
        if (res.data.code === 0) {
          this.tableData = res.data.data
        }
      })
    },
    add () {
      this.id = ''
      this.fileList = []
      this.showAdd = true
    },
    submit () {
      const loading = this.$loading({
        lock: true,
        text: '任务生成中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      initAudioToTextTask({
        audio_url: this.fileList[0].url,
        audio_key: this.fileList[0].key,
        user_id: getUserId()
      }).then(res => {
        if (res.data.code === 0) {
          this.$message({
            message: '创建成功',
            type: 'success'
          })
          this.fetchData()
          this.showAdd = false
        }
      }).finally(() => {
        loading.close()
      })
    },
    async beforeUpload (file) {
      this.uploadLoading = true
      const result = await getQiniuToken()
      if (result.data.code === 0) {
        const token = result.data.data
        const fileName = file.name
        this.observable = qiniu.upload(
          file,
          new Date().getTime() + fileName,
          token
        )
        const observer = {
          complete: (res) => {
            this.uploadLoading = false
            this.fileList = [{
              name: fileName,
              key: res.key,
              url: 'http://mainbucket.reachable-edu.com/' + res.key
            }]
          }
        }
        this.observable.subscribe(observer)
      }
    },
    getResult (data) {
      getTaskResult({
        order_id: data.order_id
      }).then(res => {
        if (res.data.code === 0) {
          this.$message.success('获取成功')
        } else {
          this.$message.error(res.data.msg)
        }
        this.fetchData()
      })
    }
  }
}
</script>

<style scoped>
.el-header{
    background-color: #409EFF;
    color: #333;
    line-height: 60px;
    text-align: right;
}
.cardItem{
    width: 1000px;
    border-radius: 10px;
    border: 1px solid #DCDFE6;
    height: 400px;
    margin: 20px auto;
    text-align: left;
}
.title{
    margin-top:20px;
}
.content{
    margin-top:20px;
    height: 50%;
}
.label{
    margin-top:20px;
    margin-left:30px
}

.title .el-input__inner{
    padding-left:30px;
    border: none;
    font-size: 24px;
    font-weight: 600;
}

.content .el-textarea__inner{
    padding-left:30px;
    border: none;
    height: 200px;
    font-size: 16px;
    width: 970px;
    resize: none;
}

</style>
