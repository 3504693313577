<template>
    <div class="container">
        <div style="position:absolute;cursor:pointer;font-size:18px" @click="$router.back(-1)"><i class="el-icon-arrow-left"></i></div>
        <div style="padding:20px">
            <p>评价标准</p>
            <el-button type="primary" size="small" @click="addDimension">添加维度</el-button>
            <el-table :data="dimensions_list" v-loading="loading" style="margin-top:20px">
                <el-table-column label="字段" prop="key"></el-table-column>
                <el-table-column label="介绍" prop="instruction"></el-table-column>
                <el-table-column label="表单类型" prop="form_type"></el-table-column>
                <el-table-column label="表单值" prop="form_value"></el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <!-- <el-button type="primary" @click="edit(scope.row)" size="small">修改</el-button> -->
                        <el-button type="primary" @click="remove(scope.$index)" size="mini">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div style="margin-top:20px">
                <el-button type="primary" size="medium" @click="updateWorkshopEvaluationForm">提交</el-button>
            </div>
        </div>
        <el-dialog title="编辑维度" :visible.sync="showMension">
            <p>字段名(英文)</p>
            <el-input v-model="key"></el-input>
            <p>介绍</p>
            <el-input v-model="instruction"></el-input>
            <p>表单类型</p>
            <el-select v-model="form_type">
                <el-option v-for="(item,index) in forms" :key="index" :label="item.label" :value="item.value"></el-option>
            </el-select>
            <div v-if="form_type==='select'" style="margin-top:20px">
                <el-button size="mini" @click="addSelect" type="primary">添加选项</el-button>
                <p>选项模板</p>
                <el-tag v-for="(item,index) in selectValue" :key="index">{{item}}</el-tag>
            </div>
            <div style="margin-top:20px"><el-button type="primary" size="small" @click="confirmAdd">确定</el-button></div>
        </el-dialog>
        <el-dialog title="编辑选项" :visible.sync="showSelect">
            <p>选项名</p>
            <el-input type="text" v-model="newSelect"></el-input>
            <div style="margin-top:20px">
                <el-button type="primary" size="small" @click="confirmAddSelect">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {updateWorkshopEvaluationForm,getWorkshopEvaluationForm} from '../../api/assistant'
export default {
    data(){
        return{
            id:'',
            workshop_id:'',
            activeNames:[],
            dimensions_list:[],
            key:'',
            instruction:'',
            form_type:'',
            showMension:false,
            showSelect:false,
            loading:false,
            forms:[
                {value:'input',label:'文字输入框'},
                {value:'textarea',label:'多行文字输入框'},
                {value:'select',label:'选择框'},
                {value:'file',label:'文件上传框'}
            ],
            selectValue:[],
            newSelect:''
        }
    },
    mounted(){
        this.id = this.$route.query.id
        this.workshop_id = this.$route.query.workshop_id
        if(this.id){
            this.fetchData()
        }
    },
    methods:{
        fetchData(){
            this.loading=true
            getWorkshopEvaluationForm(this.id).then(res=>{
                if(res.data.code===0){
                    let evaluationForm = res.data.data.evaluation_dimensions
                    for(let i in evaluationForm){
                        this.dimensions_list.push({
                            key:i,
                            instruction:evaluationForm[i].instruction,
                            form_type:evaluationForm[i].form_type,
                            form_value:evaluationForm[i].form_value?evaluationForm[i].form_value:''
                        });
                    }
                    this.loading=false
                }
            })
        },
        addDimension(){
            this.key=''
            this.instruction=''
            this.form_type=''
            this.selectValue = []
            this.showMension = true
        },
        confirmAdd(){
            let model = {
                key:this.key,
                instruction:this.instruction,
                form_type:this.form_type,
                form_value:this.form_type==='select'?this.selectValue:[]
            }
            this.dimensions_list.push(model)
            this.showMension = false
        },
        updateWorkshopEvaluationForm(){
            let evaluation_dimensions = {}
            this.dimensions_list.map(item=>{
                evaluation_dimensions[item.key] = {
                    instruction:item.instruction,
                    form_type:item.form_type,
                    form_value:item.form_value?item.form_value:[]
                };
            })
            updateWorkshopEvaluationForm(this.id,this.workshop_id,evaluation_dimensions).then(res=>{
                if(res.data.code===0){
                    this.$message({
                        message:'更新成功',
                        type:'success'
                    })
                    this.$router.back(-1)
                }
            })
        },
        remove(index){
            this.dimensions_list.splice(index,1);
        },
        addSelect(){
            this.newSelect = ''
            this.showSelect = true
        },
        confirmAddSelect(){
            this.selectValue.push(this.newSelect)
            this.showSelect = false
        },
        edit(row){
            this.key= row.key
            this.instruction=row.instruction
            this.form_type=row.form_type
            this.selectValue = row.form_value
            this.showMension = true
        }
    }
}
</script>

<style>

</style>