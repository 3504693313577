<template>
    <div>
        <div style="margin-top:20px;margin-bottom:20px;font-family:'Microsoft YaHei'">审核列表</div>
        <el-button @click="add" type="primary" style="margin-top:20px;padding:10px">添加IT需求</el-button>
        <el-table :data="requestList">
            <el-table-column prop="demand" label="需求内容"></el-table-column>
            <el-table-column label="处理状态" width="100px">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.solve_status==='待处理'" type="warning">{{scope.row.solve_status}}</el-tag>
                    <el-tag v-if="scope.row.solve_status==='已解决'" type="success">{{scope.row.solve_status}}</el-tag>
                    <el-tag v-if="scope.row.solve_status==='暂缓处理'" type="primary">{{scope.row.solve_status}}</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="user_name" label="创建人" width="100px"></el-table-column>
            <el-table-column prop="solver_name" label="处理人" width="100px"></el-table-column>
            <el-table-column prop="solve_note" label="处理反馈" width="200"></el-table-column>
            <el-table-column prop="create_date" label="创建日期" width="150px"></el-table-column>
            <el-table-column label="操作" width="300px">
                <template slot-scope="scope">
                    <el-button @click="toDetail(scope.row)" type="primary" size="mini">查看</el-button>
                    <el-button @click="toSolve(scope.row)" type="primary" size="mini">修改状态</el-button>
                    <el-button @click="toDelete(scope.row._id.$id)" type="danger" size="mini">删除</el-button>
                    <!-- <el-button @click="submit(scope.row.)" type="text" size="mini">重新审核</el-button> -->
                    <!-- <el-button @click="approve(scope.row)" type="text" size="mini">通过</el-button>
                    <el-button @click="reject(scope.row)" type="text" size="mini">拒绝</el-button> -->
                </template>
            </el-table-column>
        </el-table>
        <el-dialog :visible.sync="showRequestDetail">
            <div>
                <p>开发需求</p>
                <el-input type="textarea" :rows="7" v-model="request_info.demand" style="width:80%"></el-input>
                <p>开发原因</p>
                <el-input type="textarea" :rows="7" v-model="request_info.reason" style="width:80%"></el-input>
            </div>
            <div>
                <el-button @click="initSubmit" type="primary" style="margin-top:20px" v-if="!request_info.user_id || request_info.user_id===user_id">提交</el-button>
            </div>
        </el-dialog>

        <el-dialog :visible.sync="showStatus">
            <el-select v-model="request_info.solve_status">
                <el-option value="已解决" label="已解决"></el-option>
                <el-option value="暂缓处理" label="暂缓处理"></el-option>
            </el-select>
            <el-input type="textarea" v-model="request_info.solve_note" :rows="6" style="margin-top:10px"></el-input>
            <el-button type="primary" @click="confirm" style="margin-top:20px">确定</el-button>
        </el-dialog>
    </div>
</template>

<script>
import { getITRequest, getITRequests,updateITRequest, updateITRequestStatus,solveITRequest,deleteITRequest} from '../../api/assistant.js'
import { getUserId} from '../../utils/store.js'
export default {
  data () {
    return {
        user_id:'',
        task_id: '',
        requestList: [],
        request_info:{
            id:'',
            solve_status:'',
            solve_note:''
        },
        showRequestDetail:false,
        showStatus:false
    }
  },
  mounted () {
    // this.task_id = this.$route.query.task_id
    this.user_id = getUserId()

    this.getRequestList('all')
  },
  methods: {
    getRequestList (status) {
      getITRequests(status).then(res => {
        console.log(res)
        if (res.data.code === 0) {
          this.requestList = res.data.data
        }
      })
    },
    add() {
      this.request_info = {
            id:'',
          demand:'',
          reason:''
      }
      this.showRequestDetail = true
    },
    toDetail (row) {
      this.request_info = JSON.parse(JSON.stringify(row))
      this.showRequestDetail = true
    },
    confirm(){
        solveITRequest(this.request_info._id.$id,getUserId(),this.request_info.solve_status,this.request_info.solve_note).then(res => {
            if (res.data.code === 0) {
                this.getRequestList('all')
                this.$message({
                    message:'更新成功',
                    type:'success'
                })
                this.showStatus = false
            }
        })
    },
    toSolve(row){
        this.request_info = JSON.parse(JSON.stringify(row))
        console.log(this.request_info)
        this.showStatus = true
    },
    toDelete (id) {
        this.$confirm('是否确认删除', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(()=>{
            deleteITRequest(id).then(res => {
                if (res.data.code === 0) {
                this.getRequestList('all')
                    this.$message({
                        message:'删除成功',
                        type:'success'
                    })

                }
            })
        })
    },

    initSubmit () {
      updateITRequest(this.request_info.id,this.user_id, this.request_info.demand,this.request_info.reason).then(res => {
        console.log(res)
        if (res.data.code === 0) {
          this.$message({
            message: '更新成功！',
            type: 'success'
          })
          this.showRequestDetail = false
          this.getRequestList('all')
        }
      })
    }

  }
}
</script>

<style>

</style>
