<template>
    <div>
        <el-select v-model="select_status" @change="getHolidayAppliesByStatus">
            <el-option value="all" label="全部"></el-option>
            <el-option value="pending" label="待处理"></el-option>
            <el-option value="approve" label="已批准"></el-option>
            <el-option value="reject" label="已拒绝"></el-option>
            <el-option value="cancel" label="已撤销"></el-option>
        </el-select>
        <el-table :data="applies">
            <el-table-column label="申请人" width="120">
                <template slot-scope="scope">
                    {{scope.row.user_name}}
                </template>
            </el-table-column>
            <el-table-column label="申请类型" prop="type" width="120"></el-table-column>
             <el-table-column label="请假时间" width="210">
                <template slot-scope="scope">
                    {{`${formateTime(scope.row.time[0])} - ${formateTime(scope.row.time[1])}`}}
                </template>
            </el-table-column>
            <el-table-column label="请假时长（天）" width="140">
                <template slot-scope="scope">
                    {{scope.row.hour_len/8}}
                </template>
            </el-table-column>
            <el-table-column label="请假时长（小时）" prop="hour_len" width="140"></el-table-column>
            <el-table-column label="请假原因" prop="details" width="260"></el-table-column>
            <el-table-column label="补充图片" width="300">
                <template slot-scope="scope">
                    <div style="display:flex">
                        <img v-for="(item,index) in scope.row.pic_arr" :key="index" :src="item"  style="width:100px;margin-left:10px"/>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="审批状态" width="180">
                <template slot-scope="scope">
                    <ul style="padding-inline-start:0px;">
                        <li style="margin-top:6px;display:flex;align-items:center" slot="reference" v-for="(item,index) in scope.row.check_status" :key="index">
                            <div>{{item.checker_name}}：</div>
                            <el-popover
                                v-if="item.check_note"
                                placement="top-start"
                                width="200"
                                trigger="hover"
                                :content="item.check_note">
                                <div slot="reference">
                                    <el-tag :type="statusMap[item.check_status].type">{{statusMap[item.check_status].name}}</el-tag>
                                </div>
                            </el-popover>
                            <div v-else>
                                <el-tag :type="statusMap[item.check_status].type">{{statusMap[item.check_status].name}}</el-tag>
                            </div>
                        </li>
                    </ul>
                </template>
            </el-table-column>
            <el-table-column label="审核状态" width="120">
                <template slot-scope="scope">
                    <el-tag :type="statusMap[scope.row.status].type">{{statusMap[scope.row.status].name}}</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="查看">
                <template slot-scope="scope">
                    <el-button type="primary" size="small" @click="view(scope.row)">查看</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-dialog title="查看申请" :visible.sync="showApply">
            <el-form label-width="110px">
                <el-form-item label="申请人：">
                    <el-input v-model="form.user_name" disabled style="width:80%"></el-input>
                </el-form-item>
                <el-form-item label="状态：">
                    <el-input v-model="form.status" disabled style="width:80%"></el-input>
                </el-form-item>
                <el-form-item label="类型：">
                    <el-input v-model="form.type" disabled style="width:80%"></el-input>
                </el-form-item>
                <el-form-item label="请假原因：">
                    <el-input v-model="form.details" type="textarea" :rows="5" disabled style="width:80%"></el-input>
                </el-form-item>
                <el-form-item label="补充图片：">
                    <img :src="item" v-for="(item,index) in form.pic_arr" :key="index" style="width:100px" />
                </el-form-item>
                <el-form-item label="请假时间">
                    <el-date-picker
                        v-model="time"
                        type="datetimerange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        disabled>
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="请假时长(天)">
                    <el-input-number :value="form.hour_len/8" disabled></el-input-number>
                </el-form-item>
                <el-form-item label="请假时长(小时)">
                    <el-input-number v-model="form.hour_len" disabled></el-input-number>
                </el-form-item>
                <el-form-item label="是否通过：">
                    <el-select v-model="status" >
                        <el-option value="approve" label="通过"></el-option>
                        <el-option value="reject" label="不通过"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="审核理由：">
                    <el-input type="textarea" :rows="6" v-model="check_note" ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="checkApply">提交</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import { checkApply,getHolidayAppliesByStatus } from '../../api/assistant'
import { getUserId } from '../../utils/store'
export default {
    data(){
        return{
            select_status:'pending',
            apply_id:'',
            applies:[],
            form:{
                pic_arr:[],
                status:'',
                time_len:0
            },
            time:[],
            status:'',
            check_note:'',
            showApply:false,
            flag:false,
            statusMap:{
                pending:{name:'审核中',type:'warning'},
                approve:{name:'通过',type:'success'},
                reject:{name:'不通过',type:'danger'},
                cancel:{name:'已撤销',type:'info'}
            }
        }
    },
    mounted(){
        this.getHolidayAppliesByStatus()
    },
    methods:{
        getHolidayAppliesByStatus(){
            getHolidayAppliesByStatus(this.select_status,getUserId()).then(res=>{
                if(res.data.code===0){
                    this.applies = res.data.data
                }
            })
        },
        checkApply(){
            checkApply(this.apply_id,getUserId(),this.status,this.check_note).then(res=>{
                if(res.data.code===0){
                    this.$message({
                        message:'审批成功',
                        type:'success'
                    })
                    this.getHolidayAppliesByStatus()
                    this.showApply = false
                }
            })
        },
        view(row){
            this.status = ''
            this.check_note = ''
            this.apply_id = row._id.$id
            this.form = JSON.parse(JSON.stringify(row))
            this.time = row.time.map(item=>new Date(item*1000))
            this.showApply = true
        },
        formateDate(date){
            let time = new Date(date*1000)
            return `${time.getMonth()+1}月${time.getDate()}日`;
        },
        formateTime(date){
            let time = new Date(date*1000)
            return `${time.getMonth()+1}月${time.getDate()}日 ${time.getHours()}:${time.getMinutes()<10?'0'+time.getMinutes():time.getMinutes()}`;
        }
    }
}
</script>

<style>

</style>