<template>
    <div class="container">
        <div @click="$router.back(-1)">
            <i class="el-icon-arrow-left" style="font-size:120%;cursor:pointer"></i>
        </div>
        <el-form style="margin-top:20px">
            <el-form-item label="所属业务">
                <el-input v-model="form.Standard_business" :disabled="isUser"></el-input>
            </el-form-item>
            <el-form-item label="所属部门">
                <!-- <el-input v-model="form.Standard_department"></el-input> -->
                <el-select v-model="form.Standard_department" placeholder="请选择" multiple :disabled="isUser">
                    <el-option label="总办" value="总办"></el-option>
                    <el-option label="策划部" value="策划部"></el-option>
                    <el-option label="品牌部" value="品牌部"></el-option>
                    <el-option label="研发部" value="研发部"></el-option>
                    <el-option label="执行部" value="执行部"></el-option>
                    <el-option label="财务部" value="财务部"></el-option>
                    <el-option label="设计部" value="设计部"></el-option>
                    <el-option label="人事部" value="人事部"></el-option>
                    <el-option label="前端部" value="前端部"></el-option>
                    <el-option label="技术部" value="技术部"></el-option>
                    <el-option label="渠道开发部" value="渠道开发部"></el-option>
                    <el-option label="全员" value="全员"></el-option>
                    <el-option label="其他" value="其他"></el-option>

                    <!-- <el-option label="行政人事部" value="行政人事部"></el-option>
                    <el-option label="财务部" value="财务部"></el-option>
                    <el-option label="信息管理部" value="信息管理部"></el-option>
                    <el-option label="市场营销部" value="市场营销部"></el-option>
                    <el-option label="渠道沟通部" value="渠道沟通部"></el-option>
                    <el-option label="客户运营部" value="客户运营部"></el-option>
                    <el-option label="教研部" value="教研部"></el-option>
                    <el-option label="业务开发部" value="业务开发部"></el-option>
                    <el-option label="技术研发中心" value="技术研发中心"></el-option>
                    <el-option label="项目运营部" value="项目运营部"></el-option>
                    <el-option label="品牌市场" value="品牌市场"></el-option>
                    <el-option label="设计部" value="设计部"></el-option> -->


                    
                </el-select>
            </el-form-item>
            <el-form-item label="标准名称">
                <el-input v-model="form.Standard_name" :disabled="isUser"></el-input>
            </el-form-item>
            <el-form-item label="标准内容">
                
            </el-form-item>

            <div v-if="$route.path.includes('adminmanageuser') && this.id" v-html="form.Standard_description"> </div>
            <tinymce v-if="!$route.path.includes('adminmanageuser') && this.id" :height="200" v-model="form.Standard_description"/>


            <el-form-item label="这个文件适用于该部门下" v-if="!$route.path.includes('adminmanageuser') && this.id">
                <el-input v-model="employee" :disabled="true" type="textarea"></el-input>
            </el-form-item>

            <el-form-item label="版本">
                <el-input v-model="form.version" :disabled="isUser"></el-input>
            </el-form-item>
            <el-form-item label="版本更新内容">
                <el-input v-model="form.update_note" :disabled="isUser"></el-input>
            </el-form-item>
            

            <el-form-item label="是否生效" v-if="!$route.path.includes('adminmanageuser')">
                <el-switch
                v-model="form.is_open"
                active-color="#13ce66"
                inactive-color="#ff4949">
                </el-switch>
            </el-form-item>
                

            <el-form-item label="" v-if="$route.path.includes('adminmanageuser')">
                <el-button v-if="!form.check_status" type="primary" style="margin-left:20px" @click="checkStandard">我已知晓</el-button>
                <span v-if="form.check_status" style="margin-left:20px">已知晓</span>
            </el-form-item>

            <el-form-item >
                <el-button type="primary" v-if="!$route.path.includes('adminmanageuser')" @click="updateStandard">提交</el-button>
            </el-form-item>



            

            
        </el-form>
    </div>
</template>

<script>
import {updateStandard,getStandard,checkStandard,getStandardEmployee} from '../../api/assistant'
import Tinymce from '../../components/Tinymce'
import {getUserId} from '../../utils/store'

export default {
    components:{
        tinymce: Tinymce,
    },
    data(){
        return{
            id:this.$route.query.id,
            version_id:'',
            employee:'',
            form:{},
            isUser:false

        }
    },
    mounted(){
        if(this.id){
            this.fetchData()
        }


    },
    methods:{
        fetchData(){
            if(this.$route.path.includes('adminmanageuser')){

                this.isUser = true
                getStandard(this.id,getUserId()).then(res=>{
                    if(res.data.code===0){
                        this.form = res.data.data
                        this.version_id = res.data.data.version
                    }
                })
            }else{
                getStandard(this.id,'admin').then(res=>{
                    if(res.data.code===0){
                        this.form = res.data.data
                    }
                })

                getStandardEmployee(this.id).then(res=>{
                    if(res.data.code===0){
                        this.employee = res.data.data.join(",");
                    }
                })
            }
            
        },
        updateStandard(){
            updateStandard(this.id,this.form.Standard_business,this.form.Standard_name,this.form.Standard_description,this.form.Standard_department,this.form.version,this.form.update_note,this.form.is_open).then(res=>{
                this.$router.back(-1)
            })
        },
        checkStandard(){
            checkStandard(this.id,this.version_id,getUserId()).then(res=>{
                if(res.data.code===0){
                    this.fetchData()
                }
            })
        }
    }
}
</script>

<style>

</style>