<template>
  <div>
    <!-- <el-button type="primary" @click="initAdd">添加</el-button> -->
    <el-table :data="corrections">
      <el-table-column prop="problem" label="原句"></el-table-column>
      <el-table-column width="100px" label="图片">
        <template slot-scope="scope">
          <el-link
            v-if="scope.row.pic_url"
            :href="scope.row.pic_url"
            type="primary"
            target="_blank"
            >图片链接</el-link
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="domain"
        label="领域"
        width="100px"
      ></el-table-column>
      <!-- <el-table-column prop="answer" label="回答"></el-table-column> -->
      <el-table-column label="状态" width="100px">
        <template slot-scope="scope">
          <el-tag
            v-if="scope.row.complete_status"
            style="margin:2px;background-color:green;border:none"
            effect="dark"
            >已完成</el-tag
          >
          <el-tag
            v-if="!scope.row.complete_status"
            style="margin:2px;color:red;border:1px solid red;background-color:white"
            effect="light"
            >未完成</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column prop="score" label="分数" width="100"></el-table-column>
      <el-table-column prop="comment" label="评语"></el-table-column>
      <el-table-column label="操作" width="400">
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.complete_status"
            @click="view(scope.row)"
            size="medium"
            type="primary"
            style="margin-right:10px;"
            >查看</el-button
          >

          <el-button
            v-if="!scope.row.complete_status"
            @click="answer(scope.row)"
            size="medium"
            type="primary"
            style="margin-left:10px"
            >作答</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      title="作答"
      :visible.sync="show"
      :close-on-click-modal="false"
      @opened="startAudioTimer('dialog1')"
      @close="endAudioTimer"
    >
      <el-form>
        <el-form-item label="原句">
          <el-input
            type="textarea"
            :rows="5"
            v-model="form.problem"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item
          label="原句(音频)"
          v-if="
            form.problem_audio_url_list && form.problem_audio_url_list.length
          "
        >
          <div v-for="item in form.problem_audio_url_list" :key="item.address">
            <audio
              v-if="show"
              :src="item.address"
              controls
              controlsList="nodownload"
              id="problemAudio"
              @ratechange="e => ratechange(e, 'problemRate', 'form')"
            ></audio>
          </div>
        </el-form-item>
        <el-form-item label="纠正">
          <el-input type="textarea" :rows="5" v-model="form.work"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="finishCorrection">提交</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog
      title="查看"
      :visible.sync="show1"
      :close-on-click-modal="false"
      @opened="startAudioTimer('dialog2')"
      @close="endAudioTimer"
    >
      <el-form>
        <el-form-item label="原句">
          <el-input
            type="textarea"
            :rows="5"
            v-model="form1.problem"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item
          label="原句(音频)"
          v-if="
            form1.problem_audio_url_list && form1.problem_audio_url_list.length
          "
        >
          <div v-for="item in form1.problem_audio_url_list" :key="item.address">
            <audio
              v-if="show1"
              :src="item.address"
              controls
              controlsList="nodownload"
              id="problemAudio1"
              @ratechange="e => ratechange(e, 'problemRate', 'form1')"
            ></audio>
          </div>
        </el-form-item>
        <el-form-item label="你的纠正">
          <el-input
            type="textarea"
            :rows="5"
            v-model="form1.work"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="参考答案">
          <el-input
            type="textarea"
            :rows="5"
            v-model="form1.answer"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item
          label="参考答案(音频)"
          v-if="
            form1.answer_audio_url_list && form1.answer_audio_url_list.length
          "
        >
          <div v-for="item in form1.answer_audio_url_list" :key="item.address">
            <audio
              v-if="show1"
              :src="item.address"
              controls
              controlsList="nodownload"
              id="answerAudio"
              @ratechange="e => ratechange(e, 'answerRate', 'form1')"
            ></audio>
          </div>
        </el-form-item>
        <el-form-item label="错误类型">
          <el-input
            type="textarea"
            :rows="1"
            v-model="form1.type"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="参考解释">
          <div>
            此答案仅为参考，不代表你的纠正就一定是无效的。原句可能存在多种语病，以下答案仅为出题人希望你能注意的点
          </div>
          <el-input
            type="textarea"
            :rows="5"
            v-model="form1.note"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item
          label="参考解释(音频)"
          v-if="form1.note_audio_url_list && form1.note_audio_url_list.length"
        >
          <div v-for="item in form1.note_audio_url_list" :key="item.address">
            <audio
              v-if="show1"
              :src="item.address"
              controls
              controlsList="nodownload"
              id="noteAudio"
              @ratechange="e => ratechange(e, 'noteRate', 'form1')"
            ></audio>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { getCorrections, finishCorrection, audioRecord } from '../api/assistant'
import { getUserId } from '../utils/store'
export default {
  data () {
    return {
      corrections: [],
      id: '',
      show: false,
      show1: false,
      form: {
        work: ''
      },
      form1: {
        problem: '',
        work: '',
        answer: '',
        note: ''
      },
      timer: '',
      problemListenTimeLength: 0,
      answerListenTimeLength: 0,
      noteListenTimeLength: 0
    }
  },
  mounted () {
    this.fetchData()
  },
  destroyed () {
    window.clearInterval(this.timer)
  },
  methods: {
    fetchData () {
      getCorrections(getUserId()).then(res => {
        this.corrections = res.data
      })
    },
    answer (row) {
      this.show = true
      this.show1 = false
      this.id = row._id.$id
      this.form.problem = row.problem
      this.form.work = ''
      this.form.problem_audio_url_list =
        row.problem_audio_url_list && row.problem_audio_url_list.length
          ? row.problem_audio_url_list
          : []
      this.form1 = row
    },
    view (row) {
      this.show = false
      this.show1 = true
      this.id = row._id.$id
      this.form1 = row
    },
    finishCorrection () {
      if (this.form.work.length < this.form.problem.length - 10) {
        this.$message({
          message: '作答字数不够要求',
          type: 'error'
        })
        return
      }
      if (this.form.train_mode === '笔记' && this.form.work.length < 100) {
        this.$message({
          message: '作答字数不够100字',
          type: 'error'
        })
        return
      }
      finishCorrection(getUserId(), this.id, this.form.work).then(res => {
        this.$message({
          message: '作答成功',
          type: 'success'
        })
        this.fetchData()
        this.form1.work = this.form.work
        this.show = false
        this.show1 = true
      })
    },

    formateDate (date) {
      const time = new Date(date * 1000)
      return `${time.getMonth() + 1}月${time.getDate()}日`
    },
    ratechange (e, type, dialog) {
      this[dialog][type] = e.target.defaultPlaybackRate
    },
    startAudioTimer (dialog) {
      this.problemListenTimeLength = 0
      this.answerListenTimeLength = 0
      this.noteListenTimeLength = 0
      if (dialog === 'dialog1') {
        const audio = document.getElementById('problemAudio')
        let timeFlag = 0
        this.timer = setInterval(() => {
          const currentTime = audio.currentTime
          if (
            Math.abs(timeFlag - currentTime) <
              2 * (this.form.problemRate ? this.form.problemRate : 1) &&
            timeFlag !== currentTime
          ) {
            console.log('问题收听了一秒')
            this.problemListenTimeLength++
          }
          timeFlag = currentTime
        }, 1000)
      } else if (dialog === 'dialog2') {
        const problemAudio = document.getElementById('problemAudio1')
        const answerAudio = document.getElementById('answerAudio')
        const noteAudio = document.getElementById('noteAudio')
        let problemTimeFlag = 0
        let answerTimeFlag = 0
        let noteTimeFlag = 0
        this.timer = setInterval(() => {
          if (problemAudio) {
            const problemCurrentTime = problemAudio.currentTime
            if (
              Math.abs(problemTimeFlag - problemCurrentTime) <
                2 * (this.form1.problemRate ? this.form1.problemRate : 1) &&
              problemTimeFlag !== problemCurrentTime
            ) {
              console.log('问题收听了一秒')
              this.problemListenTimeLength++
            }
            problemTimeFlag = problemCurrentTime
          }

          if (answerAudio) {
            const answerCurrentTime = answerAudio.currentTime
            if (
              Math.abs(answerTimeFlag - answerCurrentTime) <
                2 * (this.form1.answerRate ? this.form1.answerRate : 1) &&
              answerTimeFlag !== answerCurrentTime
            ) {
              console.log('答案收听了一秒')
              this.answerListenTimeLength++
            }
            answerTimeFlag = answerCurrentTime
          }
          if (noteAudio) {
            const noteCurrentTime = noteAudio.currentTime
            if (
              Math.abs(noteTimeFlag - noteCurrentTime) <
                2 * (this.form1.noteRate ? this.form1.noteRate : 1) &&
              noteTimeFlag !== noteCurrentTime
            ) {
              console.log('解析收听了一秒')
              this.noteListenTimeLength++
            }
            noteTimeFlag = noteCurrentTime
          }
        }, 1000)
      }
    },
    endAudioTimer () {
      window.clearInterval(this.timer)
      if (this.problemListenTimeLength) {
        audioRecord(
          getUserId(),
          this.id,
          'problem',
          this.problemListenTimeLength
        )
      }
      if (this.answerListenTimeLength) {
        audioRecord(getUserId(), this.id, 'answer', this.answerListenTimeLength)
      }
      if (this.noteListenTimeLength) {
        audioRecord(getUserId(), this.id, 'note', this.noteListenTimeLength)
      }
    }
  }
}
</script>

<style></style>
