<template>
    <div class="container">
        <span>年份选择</span>
        <el-select v-model="year" @change="fetchData" style="margin-left:20px">
            <el-option :value="2022" label="2022"></el-option>
            <el-option :value="2021" label="2021"></el-option>
            <el-option :value="2020" label="2020"></el-option>
            <el-option :value="2019" label="2019"></el-option>
        </el-select>
        <div style="margin-top:30px">
            <div class="table">
                <div v-for="(item,index) in schedule" :key="index" class="column">
                    <div :class="{'tableHead':true,'border':index===3}">第{{item.quarter}}季度</div>
                    <div :class="{'tableContent':true,'border':index===3}" @click="toForm(item)">
                        {{item.sheet?'已完成':''}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getQuarterWorkingScheduleByUser } from '../../api/assistant'
import { getUserId } from '../../utils/store'

export default {
  data () {
    return {
      schedule: [],
      loading: false,
      year: 2021
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })

      getQuarterWorkingScheduleByUser(this.year, getUserId()).then(res => {
        this.schedule = res.data
        loading.close()
      })
    },
    toForm (data) {
      this.$router.push({
        path: '/adminmanageuser/quartersheet',
        query: {
          quarter: data.quarter,
          year: this.year,
          user_id: getUserId()
        }
      })
    }
  }
}
</script>

<style scoped>
.container{
    width: 100%;
    color: #909399;
}
.table{
    width:100%;
    display: flex;
}
.column{
    width: 24%;
}
.tableHead{
    text-align: center;
    border-top: 0.5px solid #E4E7ED;
    border-left: 0.5px solid #E4E7ED;
    height: 50px;
    line-height: 50px;
    font-weight: 600;
    font-size: 96%
}
.tableContent{
    height: 54px;
    line-height: 54px;
    border-top: 0.5px solid #E4E7ED;
    border-left: 0.5px solid #E4E7ED;
    border-bottom: 0.5px solid #E4E7ED;
    cursor: pointer;
    text-align: center;
}
.border{
    border-right: 0.5px solid #E4E7ED;
}
.border-bottom{
    border-bottom: 0.5px solid #E4E7ED;
}
.today{
    background: #DCDFE6;
}
.tableContent:hover{
    background: #DCDFE6;
}
</style>
