<template>
    <div>
        <el-button @click="addBrand" type="primary" style="margin-left:20px">添加时间</el-button>
        <el-table :data="specials" v-loading="loading">
            <el-table-column label="编号" width="100px">
                <template slot-scope="scope">
                    {{scope.$index + 1}}
                </template>
            </el-table-column>
            
            <el-table-column label="时间" width="200px">
                <template slot-scope="scope">
                    {{formateDate(scope.row.time)}}
                </template>
            </el-table-column>

            <el-table-column label="性质" width="200px">
                <template slot-scope="scope">
                    {{scope.row.dayType == 'wordDay' ? '工作日' : '休息日' }}
                </template>
            </el-table-column>

            <el-table-column label="操作" width="150">
                <template slot-scope="scope">
                    <el-button type="primary" size="small" @click="removeSpecialWorkDay(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-dialog :visible.sync="showEdit" title="添加时间" width="80%">
            <el-form label-width="200px">
                <el-form-item label="时间">
                    <el-date-picker
                        v-model="selectTime"
                        type="date"
                        placeholder="选择日期"
                        :picker-options="pickerOptions">
                    </el-date-picker>
                </el-form-item>

                <el-form-item label="性质">
                    <el-select v-model="selectType">
                        <el-option value="wordDay" label="工作日"></el-option>
                        <el-option value="restDay" label="休息日"></el-option>
                    </el-select>
                </el-form-item>
                
                <el-form-item>
                    <el-button @click="addSpecialWorkDay" type="primary">确认</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        
    </div>
</template>

<script>
import {getSpecialWorkDay,getUser,addSpecialWorkDay,removeSpecialWorkDay} from '../../api/assistant'
import {getUserId} from '../../utils/store'
import uploadItem from '../../components/UploadItem'
export default {
    components:{
        uploadItem
    },
    data(){
        return{
            specials:[],
            form:{
                
            },
            loading:false,
            showEdit:false,
            showAttach:false,
            brand_id:'',
            groups:['商科经济','项目制','自然环境','社区','学术','少儿','科技','教具','内容'],
            selectTime:'',
            selectType:'',
            attach_url:'',
            attach_key:'',
            old_attach_key:'',
            pickerOptions: {
                
                shortcuts: [{
                    text: '今天',
                    onClick(picker) {
                    picker.$emit('pick', new Date().setHours(0,0,0,0));
                    }
                }]
            }
        }
    },
    mounted(){
        this.fetchData()
    },
    methods:{
        fetchData(){
            this.loading=true
            getSpecialWorkDay().then(res=>{
                this.specials = res.data
                this.loading=false
            })
        },
        addBrand(){
            this.form={
                
            }
            this.selectTime = ''
            this.selectType = ''
            this.showEdit = true
        },
        addSpecialWorkDay(){
            addSpecialWorkDay(this.selectTime/1000,this.selectType).then(res=>{
                this.fetchData()
                this.showEdit = false
            })
        },
        removeSpecialWorkDay(id){
            
            removeSpecialWorkDay(id._id.$id).then(res=>{
                this.fetchData()
                this.showEdit = false
            })
        },
        view(data){
            this.form = JSON.parse(JSON.stringify(data))
            if(data.attach_key){
                this.old_attach_key = data.attach_key
                this.attach_key = data.attach_key
                this.attach_name = data.attach_name
                this.attach_url = data.attach_url
            }else{
                this.old_attach_key = ''
                this.attach_key = ''
                this.attach_name = ''
                this.attach_url = ''
            }
            this.brand_id = data._id.$id
            
            this.showEdit = true
        },
        initUpload(){
            this.attach_name = ''
            this.attach_url = ''
            this.attach_key = ''
            this.showAttach = true
        },
        onConfirmUpload(e){
            if(this.old_attach_key!==''){
                removeRemoteFile(this.old_attach_key).then(res=>{
                    this.old_attach_key = e.key
                    this.attach_key = e.key
                    this.attach_name = e.key
                })
            }else{
                this.old_attach_key = e.key
                this.attach_key = e.key
                this.attach_name = e.key
            }
        },
        confirmRemoveFile(){
            this.attach_key = ''
            this.attach_url = ''
        },
        formateDate(date){
            if(date==='nodate'){
                return '无确定时间'
            }
            let time = new Date(date*1000)
            return `${time.getFullYear()}年${time.getMonth()+1}月${time.getDate()}日`;
        },
        
    }
}
</script>

<style>

</style>