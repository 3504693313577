<template>
  <div class="todo">
    <div style="display: flex; justify-content: space-between">
      <div>
        <div @click="finishTask" @click.stop="" style="display: inline-block">
          <el-checkbox
            v-model="todoData.finish_status"
            size="medium"
          ></el-checkbox>
        </div>
        <span
          :class="todoData.finish_status ? 'deleteline' : ''"
          v-for="(label, index) in todoData.todo_labels"
          :key="index"
          style="color: #44bbbb; margin-left: 10px"
          @click="selectTag(label, todoData.finish_status)"
          @click.stop=""
          >{{ "#" + label }}</span
        >
        <span
          :class="todoData.finish_status ? 'deleteline' : ''"
          style="margin-left: 10px"
          >{{ todoData.todo_content }}</span
        >
        <span
          v-if="todoData.assign_users && todoData.assign_users.length > 0"
          style="font-size: 13px; color: #b3b3b3; margin-left: 10px"
        >
          {{ formatassign_users(todoData.assign_users) }}
        </span>
        <span
          v-if="
            todoData.cooperation_users && todoData.cooperation_users.length > 0
          "
          style="font-size: 13px; color: #b3b3b3; margin-left: 10px"
          >{{ formatcooperation_users(todoData.cooperation_users) }}</span
        >
        <i
          v-if="
            (todoData.assign_users && todoData.assign_users.length > 0) ||
            (todoData.cooperation_users &&
              todoData.cooperation_users.length > 0)
          "
          class="el-icon-message-solid"
          style="cursor: pointer; font-size: 18px; margin-left: 10px"
          @click.stop="toRemind"
        ></i>
        <span
          v-if="todoData.remind_list && todoData.remind_list.length > 0"
          style="font-size: 13px; color: #666"
          >（提醒了{{ todoData.remind_list.length }}次）</span
        >
        <span
          v-if="todoData.assign_label"
          style="font-size: 13px; color: #cccccc; margin-left: 10px"
          >{{ todoData.assign_label }}</span
        >
        <span
          v-if="todoData.time_label"
          style="font-size: 13px; color: red; margin-left: 10px"
          >{{ todoData.time_label }}</span
        >
        <span
          v-if="
            todoData.time_type === '时间段' &&
            todoData.start_time &&
            todoData.end_time
          "
          style="font-size: 13px; color: red; margin-left: 10px"
          >时间段：{{
            $dayjs(todoData.start_time).format("YYYY/MM/DD") +
            " 至 " +
            $dayjs(todoData.end_time).format("YYYY/MM/DD")
          }}</span
        >
        <span
          v-if="todoData.time_type === '截止时间' && todoData.end_time"
          style="font-size: 13px; color: red; margin-left: 10px"
          >截止时间：{{ $dayjs(todoData.end_time).format("YYYY/MM/DD") }}</span
        >
        <span
          v-if="todoData.sub_todos && todoData.sub_todos.length"
          style="margin-left: 10px; font-size: 13px; color: #666"
          >子任务：{{
            todoData.sub_todos.filter((item) => item.status).length +
            "/" +
            todoData.sub_todos.length
          }}</span
        >
      </div>

      <div
        class="special"
        style="
          display: flex;
          flex-direction: row;
          justify-items: center;
          align-items: center;
          flex-shrink: 0;
        "
      >
        <!-- <el-select style="display:block;margin-left:10px;margin-right:15px;width:140px;flex-shrink:0" v-model="todoData.level" size="small" @change="updateTodoLevel" placeholder="任务等级">
                <el-option value="1" label="级别1">
                    <div>
                    <i class="el-icon-medal" style="font-size:20px"></i>

                    </div>
                </el-option>
                <el-option value="2" label="级别2">
                    <i class="el-icon-medal" style="font-size:20px"></i>
                    <i class="el-icon-medal" style="font-size:20px"></i>

                </el-option>
                <el-option value="3" label="级别3">
                    <i class="el-icon-medal" style="font-size:20px"></i>
                    <i class="el-icon-medal" style="font-size:20px"></i>
                    <i class="el-icon-medal" style="font-size:20px"></i>

                </el-option>
            </el-select> -->
        <div
          style="
            flex-shrink: 0;
            font-size: 13px;
            margin-right: 12px;
            margin-left: 10px;
            color: #999;
          "
        >
          创建时间：{{ formateTime(todoData.create_time) }}
        </div>
        <star
          :score.sync="todoData.level"
          @mark="markGuide"
          style="margin-right: 40px"
        ></star>
        <img
          v-if="todoData.tentative_status"
          src="../assets/icon1/03.png"
          style="width: 30px; height: 30px; margin-right: 15px"
          @click="tentativeChanged"
          @click.stop=""
        />
        <img
          v-if="!todoData.tentative_status"
          src="../assets/icon1/04.png"
          style="width: 30px; height: 30px; margin-right: 15px"
          @click="tentativeChanged"
          @click.stop=""
        />
        <img
          v-if="todoData.flag_status"
          src="../assets/icon1/05.png"
          style="width: 30px; height: 30px; margin-right: 15px"
          @click="qizhiChanged"
          @click.stop=""
        />
        <img
          v-if="!todoData.flag_status"
          src="../assets/icon1/06.png"
          style="width: 30px; height: 30px; margin-right: 15px"
          @click="qizhiChanged"
          @click.stop=""
        />
        <div @click="starChanged" @click.stop="">
          <img
            v-if="todoData.star_status"
            src="../assets/icon1/07.png"
            style="width: 30px; height: 30px; margin-right: 15px"
          />
          <img
            v-if="!todoData.star_status"
            src="../assets/icon1/08.png"
            style="width: 30px; height: 30px; margin-right: 15px"
          />
        </div>
        <img
          v-if="todoData.fast_status"
          src="../assets/icon1/09.png"
          style="width: 30px; height: 26px"
          @click="fastChanged"
          @click.stop=""
        />
        <img
          v-if="!todoData.fast_status"
          src="../assets/icon1/10.png"
          style="width: 30px; height: 26px"
          @click="fastChanged"
          @click.stop=""
        />
      </div>
    </div>
  </div>
</template>
<script>
import {
  tentativeTODO,
  untentativeTODO,
  unflagTODO,
  flagTODO,
  starTODO,
  unstarTODO,
  finishTODO,
  unfinishTODO,
  unfastTODO,
  fastTODO,
  updateTodoLevel,
  sendTodoRemind,
} from "../api/assistant";
import { getUserId } from "../utils/store";
import star from "./Award";

export default {
  components: {
    star,
  },
  name: "Todo",
  data() {
    return {
      isChange: false,
    };
  },
  props: {
    todoData: {
      default() {
        return {};
      },
      required: false,
      type: Object,
    },
    selectedList: {
      default() {
        return {};
      },
      required: false,
      type: Object,
    },
  },
  methods: {
    formateTime(date) {
      let time = new Date(date * 1000);
      return `${time.getFullYear()}/${time.getMonth() + 1}/${time.getDate()}`;
    },
    formatassign_users(assign_users) {
      var result = "已分配给 ";
      var length = assign_users.length;
      for (var index = 0; index < length; index++) {
        result =
          result +
          assign_users[index].user_lastName +
          assign_users[index].user_givenName;
        if (index < length - 1) {
          result = result + "、";
        }
      }
      return result;
    },
    formatcooperation_users(cooperation_users) {
      var result = "协作人 ";
      var length = cooperation_users.length;
      for (var index = 0; index < length; index++) {
        result =
          result +
          cooperation_users[index].user_lastName +
          cooperation_users[index].user_givenName;
        if (index < length - 1) {
          result = result + "、";
        }
      }
      return result;
    },
    tentativeChanged() {
      var id = this.todoData._id.$id;
      const flag_status = this.todoData.tentative_status;
      this.todoData.tentative_status = flag_status ? false : true;
      if (flag_status) {
        untentativeTODO(id).then((res) => {
          // this.refreshData();
        });
      } else {
        tentativeTODO(id).then((res) => {
          // this.refreshData();
        });
      }
    },
    qizhiChanged() {
      var id = this.todoData._id.$id;
      const flag_status = this.todoData.flag_status;
      if (flag_status) {
        unflagTODO(id).then((res) => {
          this.refreshData();
        });
      } else {
        flagTODO(id).then((res) => {
          this.refreshData();
        });
      }
    },
    fastChanged() {
      var id = this.todoData._id.$id;
      const fast_status = this.todoData.fast_status;
      if (fast_status) {
        unfastTODO(id, getUserId()).then((res) => {
          this.refreshData();
        });
      } else {
        fastTODO(id, getUserId()).then((res) => {
          this.refreshData();
        });
      }
    },
    starChanged() {
      var id = this.todoData._id.$id;
      const star_status = this.todoData.star_status;
      if (star_status) {
        unstarTODO(id, getUserId()).then((res) => {
          this.refreshData();
        });
      } else {
        starTODO(id, getUserId()).then((res) => {
          this.refreshData();
        });
      }
    },
    finishTask() {
      if (this.isChange) {
        return;
      }
      this.isChange = true;
      const id = this.todoData._id.$id;
      const finish_status = this.todoData.finish_status;
      if (finish_status) {
        unfinishTODO(id, getUserId()).then((res) => {
          this.isChange = false;
          this.$emit("callback", "finishTaskChanged");
        });
      } else {
        finishTODO(id, getUserId()).then((res) => {
          this.isChange = false;
          this.$emit("callback", "finishTaskChanged");
        });
      }
    },
    selectTag(label, status) {
      this.$emit("selectTag", label, status);
    },
    refreshData() {
      this.$emit("refresh");
    },
    markGuide(e) {
      this.todoData.level = e;
      updateTodoLevel(this.todoData._id.$id, e).then((res) => {
        this.refreshData();
      });
    },
    toRemind() {
      sendTodoRemind({
        wechat_ids: [
          ...this.todoData.assign_users.map((item) => item.wechat_work_id),
          ...this.todoData.cooperation_users.map((item) => item.wechat_work_id),
        ],
        todo_id: this.todoData._id.$id,
      }).then((res) => {
        if (res.data.code === 0) {
          this.$message.success("提醒已通过企业微信发送");
        }
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.todo {
  width: 95%;
  padding: 20px;
  border: 16px #d9d9d9;
  border-radius: 10px;
  background: #eeeeee;
  margin-top: 3px;
  margin-bottom: 3px;
}
h3 {
  margin: 40px 0 0;
}
/* .todo ul {
  list-style-type: none;
  padding: 0;
} */
li {
  margin: 0 10px;
}

a {
  color: #42b983;
}
.deleteline {
  text-decoration: line-through;
}
.el-icon-star-on {
  font-size: 32px;
}
.el-icon-star-off {
  font-size: 24px;
}
/deep/ .el-checkbox__inner {
  width: 20px;
  height: 20px;
}
/deep/ .el-checkbox__input .el-checkbox__inner::after {
  box-sizing: content-box;
  content: "";
  border-left: 0;
  border-top: 0;
  height: 10px;
  left: 6px;
  position: absolute;
  top: 1px;
  width: 5px;
  transform: rotate(45deg) scaleY(1);
}
</style>
