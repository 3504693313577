<template>
  <div class="userAdminContainer">
    <div class="leftSide" v-if="block !== 'ETATodo'">
      <img class="titleLogo" src="../assets/loginTitle.png" />
      <el-aside width="220px">
        <el-menu :default-active="$route.path" :router="true">
          <el-menu-item index="/meeting" v-if="block === 'meeting'"
            >会议记录</el-menu-item
          >
          <el-menu-item
            v-if="block === 'smartwork'"
            index="/adminmanageuser/classlistuser"
            >我的课程</el-menu-item
          >
          <el-menu-item
            index="/adminmanageuser/workShopReview"
            v-if="
              block === 'smartwork' &&
              user.isSmartAdmin &&
              user.isSmartAdmin === 'yes'
            "
            >WorkShop评价模块</el-menu-item
          >
          <el-menu-item
            v-if="block === 'standard'"
            index="/adminmanageuser/standardList"
            >工作标准</el-menu-item
          >
          <el-menu-item v-if="block === 'exam'" index="/examlist"
            >考试模板</el-menu-item
          >
          <el-menu-item
            index="/adminmanageuser/applyHoliday"
            v-if="block === 'holiday'"
            >我的申请</el-menu-item
          >
          <el-menu-item
            v-if="block === 'holiday'"
            index="/adminmanageuser/checkApply"
            >我的审批</el-menu-item
          >
          <el-menu-item
            v-if="block === 'regulation'"
            index="/adminmanageuser/regulations"
            >规章制度</el-menu-item
          >
          <el-menu-item
            v-if="block === 'regulation'"
            index="/adminmanageuser/law"
            >法律法规</el-menu-item
          >
          <el-menu-item
            v-if="block === 'speech'"
            index="/adminmanageuser/speech"
            >话术字典</el-menu-item
          >
          <el-menu-item
            v-if="block === 'IT'"
            index="/adminmanageuser/requestList"
            >IT需求</el-menu-item
          >
          <el-menu-item
            v-if="block === 'check'"
            index="/adminmanageuser/checktask"
            >审核任务</el-menu-item
          >
          <el-menu-item
            v-if="block === 'check'"
            index="/adminmanageuser/toreview"
            >待审核</el-menu-item
          >
          <el-menu-item
            v-if="block === 'check'"
            index="/adminmanageuser/checktype"
            >审核类型</el-menu-item
          >
          <el-menu-item
            v-if="block === 'feedback'"
            index="/adminmanageuser/comment"
            >我的评语</el-menu-item
          >
          <el-menu-item
            v-if="block === 'feedback'"
            index="/adminmanageuser/subordinate"
            >查看下属</el-menu-item
          >
          <el-menu-item
            v-if="block === 'feedback'"
            index="/adminmanageuser/workquestions"
            >工作提问</el-menu-item
          >
          <el-menu-item
            v-if="block === 'log'"
            index="/adminmanageuser/dailyLogList"
            >工作日志</el-menu-item
          >
          <el-menu-item
            v-if="block === 'log'"
            index="/adminmanageuser/monthLogList"
            >月度总结</el-menu-item
          >
          <el-menu-item
            v-if="block === 'log'"
            index="/adminmanageuser/quarterschedule"
            >季度总结</el-menu-item
          >
          <el-menu-item
            v-if="block === 'projectTimePoint'"
            index="/adminmanageuser/projectTimePoint"
            >项目时间点</el-menu-item
          >
          <el-menu-item
            v-if="block === 'transfer'"
            index="/adminmanageuser/audio-to-text"
            >音频转文字</el-menu-item
          >
          <el-menu-item
            v-if="block === 'transfer'"
            index="/adminmanageuser/text-to-audio"
            >文字转音频</el-menu-item
          >
          <el-menu-item
            v-if="block === 'transfer'"
            index="/adminmanageuser/totext"
            >实时语音转文字</el-menu-item
          >
          <el-menu-item
            v-if="block === 'employeeList'"
            index="/adminmanageuser/employeeList"
            >员工列表</el-menu-item
          >
        </el-menu>
      </el-aside>
    </div>
    <div
      class="rightSide"
      :style="block !== 'ETATodo' ? { marginLeft: '220px' } : {}"
    >
      <div class="header">
        <div class="goBack" @click="$router.push('/nav')">
          <i class="el-icon-arrow-left" style="font-size: 120%"></i
          ><span style="font-size: 100%; margin-left: 4px">返回首页</span>
        </div>
        <div class="rightPart">
          <span>{{ userName }}</span>
          <img
            src="../assets/exit.svg"
            style="margin-left: 20px; cursor: pointer"
            @click="logout"
          />
        </div>
      </div>
      <div class="tagView">
        {{ $route.meta.bread }}
      </div>
      <div class="myMain">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import { getUser, getUserMonthly } from "../api/assistant.js";

import {
  getUserId,
  getBlock,
  removeUserId,
  removeUserName,
  getUserName,
} from "../utils/store";
export default {
  data() {
    return {
      userId: "",
      userName: "",
      block: "",
      user: {},
      showMonthly: false,
    };
  },
  mounted() {
    this.getUser();
    this.block = getBlock();
    this.userId = getUserId();
    this.userName = getUserName();
    this.checkMonth();
  },
  methods: {
    getUser() {
      getUser(getUserId()).then((res) => {
        this.user = res.data;
      });
    },
    logout() {
      removeUserId();
      removeUserName();
      location.reload();
    },
    checkMonth() {
      console.log(111);
      getUserMonthly(getUserId()).then((res) => {
        this.showMonthly = !res.data;
      });
    },
    toDaily() {
      if (this.showMonthly) {
        this.$message({
          message: "请先完成月度总结",
          type: "error",
        });
        return;
      }

      this.$router.push({
        path: "/workLog/dailyLogList",
      });
    },
    toMonth() {
      this.$router.push({
        path: "/workLog/monthLogList",
      });
    },
    toQuarter() {
      if (this.showMonthly) {
        this.$message({
          message: "请先完成月度总结",
          type: "error",
        });
        return;
      }

      this.$router.push({
        path: "/workLog/quarterschedule",
      });
    },
  },
};
</script>

<style lang="scss">
.userAdminContainer {
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  .leftSide {
    width: 220px;
    flex-shrink: 0;
    height: 100%;
    background: #fff;
    position: absolute;
    left: 0;
    z-index: 3;
    box-shadow: 2px 0px 6px 1px rgba(0, 21, 41, 0.12);
    .titleLogo {
      width: 120px;
      display: block;
      margin: 16px auto;
    }
    .el-menu-item.is-active {
      background: #e5efff;
      border-right: 4px solid #4a8af4;
    }
  }
  .rightSide {
    flex: 1;
    height: 100%;
    overflow: hidden;
    position: relative;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
      height: 50px;
      width: 100%;
      background: #fff;
      position: absolute;
      top: 0;
      box-shadow: 0px 3px 6px 1px rgba(0, 21, 41, 0.12);
      z-index: 2;
      .rightPart {
        display: flex;
        align-items: center;
        margin-right: 30px;
        font-family: Alibaba PuHuiTi 2-65 Medium, Alibaba PuHuiTi 20;
        font-weight: normal;
        color: #999999;
        font-size: 16px;
      }
      .goBack {
        font-size: 16px;
        margin-left: 10px;
        cursor: pointer;
      }
    }
    .tagView {
      margin-top: 50px;
      background: #fff;
      height: 50px;
      line-height: 50px;
      padding-left: 30px;
    }
    .myMain {
      height: calc(100vh - 120px);
      padding: 16px;
      overflow-y: scroll;
    }
  }
}
</style>
