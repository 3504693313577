<template>
    <div>
        <el-button @click="addTip" type="primary" style="margin-left:20px">添加提示</el-button>
        <el-table :data="tips" v-loading="loading">
            <el-table-column prop="department" label="部门" width="100px"></el-table-column>
            <el-table-column prop="category" label="类别" width="100px"></el-table-column>
            <el-table-column prop="content" label="内容"></el-table-column>
            
            <el-table-column label="详细" width="150">
                <template slot-scope="scope">
                    <el-button type="primary" size="small" @click="view(scope.row)">编辑</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-dialog :visible.sync="showEdit" title="添加提示" width="80%">
            <el-form label-width="200px">
                <el-form-item label="部门">
                    <el-select v-model="form.department">
                        <el-option v-for="item in departments" :label="item" :value="item" :key="item"></el-option>
                        
                    </el-select>
                </el-form-item>
                <el-form-item label="类别">
                    <el-select v-model="form.category">
                        <el-option v-for="item in categories" :label="item" :value="item" :key="item"></el-option>
                        
                    </el-select>
                </el-form-item>
                <el-form-item label="内容">
                    <el-input v-model="form.content" style="width:80%"></el-input>
                </el-form-item>
                
                
                <el-form-item>
                    <el-button @click="updateTip" type="primary">确认</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        
    </div>
</template>

<script>
import {getTips,getUser,updateTip,removeRemoteFile} from '../../api/assistant'
import {getUserId} from '../../utils/store'
import uploadItem from '../../components/UploadItem'
export default {
    components:{
        uploadItem
    },
    data(){
        return{
            tips:[],
            form:{
                
            },
            loading:false,
            showEdit:false,
            showAttach:false,
            tip_id:'',
            departments:['总办',"策划部","品牌部","研发部","执行部","财务部","设计部","人事部","前端部","技术部"],
            categories:['常规'],
        }
    },
    mounted(){
        this.fetchData()
    },
    methods:{
        fetchData(){
            this.loading=true
            getTips().then(res=>{
                this.tips = res.data
                this.loading=false
            })
        },
        addTip(){
            this.form={
                
            }
            this.tip_id = ''
            this.showEdit = true
        },
        updateTip(){
            updateTip(this.tip_id,this.form.department,this.form.category,this.form.content).then(res=>{
                this.fetchData()
                this.showEdit = false
            })
        },
        view(data){
            this.form = JSON.parse(JSON.stringify(data))
            
            this.tip_id = data._id.$id
            
            this.showEdit = true
        },
        
    }
}
</script>

<style>

</style>