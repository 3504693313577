<template>
    <div style="width:100%">
        <div style="padding:20px">
            <p>问题详情</p>
            <div class="item">
                <span>主题</span>
                <el-input type="text" v-model="editForm.question_subject"></el-input>
            </div>
            <div class="item">
                <span>年份</span>
                <el-input type="text" v-model="editForm.question_year"></el-input>
            </div>
            <div class="item">
                <span>等级</span>
                <el-input type="text" v-model="editForm.question_level"></el-input>
            </div>
            <div class="item">
                <span>考点</span>
                <el-input type="text" v-model="editForm.question_competency"></el-input>
            </div>
            <div class="item">
                <p>题目</p>
                <Tinymce v-model="editForm.question_question" :height="240"></Tinymce>
            </div>
            <div class="item">
                <p>选项A</p>
                <Tinymce v-model="editForm.question_optionA" :height="240"></Tinymce>
            </div>
            <div class="item">
                <p>选项B</p>
                <Tinymce v-model="editForm.question_optionB" :height="240"></Tinymce>
            </div>
            <div class="item">
                <p>选项C</p>
                <Tinymce v-model="editForm.question_optionC" :height="240"></Tinymce>
            </div>
            <div class="item">
                <p>选项D</p>
                <Tinymce v-model="editForm.question_optionD" :height="240"></Tinymce>
            </div>
            <div class="item">
                <p>选项E</p>
                <Tinymce v-model="editForm.question_optionE" :height="240"></Tinymce>
            </div>
            <div class="item">
                <span>答案</span>
                <el-select v-model="editForm.question_answer" style="margin-left:10px">
                  <el-option label="A" value="A"></el-option>
                  <el-option label="B" value="B"></el-option>
                  <el-option label="C" value="C"></el-option>
                  <el-option label="D" value="D"></el-option>
                  <el-option label="E" value="E"></el-option>
                </el-select>
            </div>
            <div style="margin-top:20px;margin-bottom:20px">
                <el-button type="primary" @click="submit">提交</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import {getExerciseQuestion,updateExerciseQuestion,addExerciseQuestionToTemplateSection} from '../../api/assistant'
import Tinymce from '../../components/Tinymce' 
export default {
    components:{
        Tinymce
    },
    data(){
        return{
            question_id:'',
            section_id:this.$route.query.section_id,
            editForm:{
                _id:{
                    $id:''
                },
                question_subject:'',
                question_year:'',
                question_level:'',
                question_competency:'',
                question_question:'',
                question_optionA:'',
                question_optionB:'',
                question_optionC:'',
                question_optionD:'',
                question_optionE:'',
                question_answer:''
            }
        }
    },
    mounted(){
        
    },
    methods:{
        async updateExerciseQuestion(){
            return new Promise((resolve,reject)=>{
                updateExerciseQuestion(this.question_id,
                    this.editForm.question_subject,
                    this.editForm.question_year,
                    this.editForm.question_level,
                    this.editForm.question_competency,
                    this.editForm.question_question,
                    this.editForm.question_optionA,
                    this.editForm.question_optionB,
                    this.editForm.question_optionC,
                    this.editForm.question_optionD,
                    this.editForm.question_optionE,
                    this.editForm.question_answer
                    ).then(res=>{
                        resolve(res);
                })
            })
        },
        addExerciseQuestionToTemplateSection(question_id){
            addExerciseQuestionToTemplateSection(question_id,this.section_id).then(res=>{
                if(res.data && res.data.code===0){
                    this.$message({
                        message:'添加成功',
                        type:'success'
                    })
                }else{
                    this.$message('已添加')
                }
            })
        },
        async submit(){
            let result = await this.updateExerciseQuestion();
            if(result.data.code===0){
                this.addExerciseQuestionToTemplateSection(result.data.data._id.$id,this.section_id);
            }
        }
    }
    
}
</script>

<style scoped>
.item{
    margin-top:20px
}
.item .el-input{
    width: 80%;
    margin-left: 10px;
}
</style>