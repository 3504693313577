<template>
    <div>
        <div style="position:absolute;cursor:pointer;font-size:18px" @click="$router.back(-1)"><i class="el-icon-arrow-left"></i></div>
        <div style="padding:30px">
            <el-form v-if="readonly == false">
                <el-form-item label="课程名称"  >
                    <el-input :readonly="readonly" v-model="class_info.class_info.class_name"></el-input>
                </el-form-item>
                <el-form-item label="课程所属BU">
                    <el-input :readonly="readonly" v-model="class_info.class_info.class_BU"></el-input>
                </el-form-item>
                <el-form-item label="课程介绍">
                    <el-input :readonly="readonly" v-model="class_info.class_info.class_description" type="textarea" :rows="6"></el-input>
                </el-form-item>
                <el-form-item label="课程头像链接">
                    <el-input :readonly="readonly" v-model="class_info.class_info.class_avatar_url"></el-input>
                </el-form-item>
                <el-form-item label="是否要求完成前一节才能进入下一节？">
                    <div style="width:100%">
                        <el-select :disabled="readonly" v-model="class_info.class_info.session_completion_required" style="width:80px">
                            <el-option value="是" label="是"></el-option>
                            <el-option value="否" label="否"></el-option>
                        </el-select>
                    </div>
                </el-form-item>
            </el-form>
        
        <!-- <el-button type="primary" @click="submit">提交</el-button> -->
            <section>
                <el-table :data="session_list">
                    <el-table-column label="编号" width="200px" align="center">
                        <template slot-scope="scope">
                            {{scope.$index}}
                        </template>
                    </el-table-column>
                    <el-table-column label="课时名称" prop="session_info.session_name" align="center"></el-table-column>
                    <el-table-column label="操作" align="center">
                        <template slot-scope="scope">
                            <el-button type="primary" @click="viewSession(scope.row)">查看</el-button>
                            <!-- <el-button type="primary" @click="viewWorkShop(scope.row._id.$id)">查看workshop</el-button> -->
                        </template>
                    </el-table-column>
                </el-table>
            </section>
        </div>
    </div>
</template>

<script>
import { getClass,updateClass } from '../api/assistant'
export default {
    data(){
        return{
            id:'',
            class_info:{
                class_info:{

                }
            },
            session_list:[],
            readonly: true
        }
    },
    mounted(){
        this.id = this.$route.query.id
        this.fetchData()
    },
    methods:{
        fetchData(){
            getClass(this.id).then(res=>{
                console.log(res)
                if(res.data){
                    this.class_info = res.data
                    this.session_list = res.data.sessions
                }  
            })
        },
        submit(){
            updateClass(this.id,this.class_info.class_info.class_name,this.class_info.class_info.class_BU,this.class_info.class_info.class_description,this.class_info.class_info.class_avatar_url,this.class_info.class_info.session_completion_required).then(res=>{
                this.$message({
                    message:'更新成功',
                    type:'success'
                })
            })
        },
        viewSession(row){
            this.$router.push({
                path:'/sessioninfouser',
                query:{
                    session_id:row._id.$id
                }
            })
        },
        viewWorkShop(id){
            this.$router.push({
                path:'/adminmanageuser/workShop',
                query:{
                    session_id:id
                }
            })
        }
    }
}
</script>

<style>

</style>