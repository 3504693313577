<template>
    <div style="color:#606266">
        <div>
            <p>提交的Entries</p>
            <el-table :data="entry_list">
                <el-table-column label="workshop名" prop="work_shop_name"></el-table-column>
                <el-table-column label="员工名" prop="user_name"></el-table-column>
                <el-table-column label="查看">
                    <template slot-scope="scope">
                        <el-button type="primary" @click="toEntry(scope.row)">查看</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
import {getWorkshopEntries} from '../../api/assistant'
export default {
    data(){
        return{
            id:this.$route.query.id,
            entry_list:[]
        }
    },
    mounted(){
        this.fetchData()
    },
    methods:{
        fetchData(){
            getWorkshopEntries(this.id).then(res=>{
                console.log(res)
                if(res.data.code===0){
                    this.entry_list = res.data.data
                }
            })
        },
        toEntry(data){
            this.$router.push({
                path:'/adminmanageuser/workShopEntry',
                query:{
                    id:data.workshop_id,
                    user_id:data.user_id,
                    type:'reviewer'
                }
            })
        }
    }
}
</script>

<style>

</style>