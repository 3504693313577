<template>
    <div>
        <div style="margin-top:20px;margin-bottom:20px;font-family:'Microsoft YaHei'">审核列表</div>
        <el-table :data="reviewsList">
            <el-table-column  label="审查状态">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.review_status==='approved'" effect="dark" type="success" size="medium">通过</el-tag>
                    <el-tag v-if="scope.row.review_status==='pending'" effect="dark" type="warning" size="medium">审核中</el-tag>
                    <el-tag v-if="scope.row.review_status==='reject'" effect="dark" type="danger" size="medium">拒绝</el-tag>
                </template>

            </el-table-column>
            <el-table-column prop="task" label="任务名称"></el-table-column>
            <el-table-column prop="type" label="任务类型"></el-table-column>
            <el-table-column prop="checklist" label="审核清单"></el-table-column>
            <el-table-column prop="creater" label="创建者"></el-table-column>
            <el-table-column prop="reviewer" label="审核者"></el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button @click="toDetail(scope.row)" type="text" size="mini">查看</el-button>
                    <el-button @click="submit(scope.row)" type="text" size="mini">重新审核</el-button>
                    <!-- <el-button @click="approve(scope.row)" type="text" size="mini">通过</el-button>
                    <el-button @click="reject(scope.row)" type="text" size="mini">拒绝</el-button> -->
                </template>
            </el-table-column>
        </el-table>
        <el-button @click="$router.back(-1)" style="margin-top:20px" size="medium">返回</el-button>
    </div>
</template>

<script>
import { getUserId } from '../utils/store'
import { getMyCheckTasksToReview, refreshReviewerStatus, getReviewsByTask, changeReviewStatus } from '../api/assistant.js'
export default {
  data () {
    return {
      task_id: '',
      reviewsList: []
    }
  },
  mounted () {
    this.task_id = this.$route.query.task_id
    this.getMyCheckTasksToReview()
  },
  methods: {
    getMyCheckTasksToReview () {
      const my_id = getUserId()
      getMyCheckTasksToReview(my_id, 'false').then(res => {
        if (res.data.code === 0) {
          this.reviewsList = res.data.data
        }
      })
    },
    getReviewsByTask () {
      getReviewsByTask(this.task_id).then(res => {
        console.log(res)
        if (res.data.code === 0) {
          this.reviewsList = res.data.data
        }
      })
    },
    toDetail (row) {
      this.$router.push({
        path: '/adminmanageuser/reviewDetail',
        query: {
          id: row._id.$id,
          task_id: this.task_id,
          type: 'creater'
        }
      })
    },
    approve (row) {
      changeReviewStatus(row._id.$id, 'approved').then(res => {
        console.log(res)
        if (res.data.code === 0) {
          this.$message({
            message: '更新成功！',
            type: 'success'
          })
          this.getReviewsByTask()
        }
      })
    },
    reject (row) {
      changeReviewStatus(row._id.$id, 'reject').then(res => {
        console.log(res)
        if (res.data.code === 0) {
          this.$message({
            message: '更新成功！',
            type: 'success'
          })
          this.getReviewsByTask()
        }
      })
    },
    submit (row) {
      // changeReviewStatus(row._id.$id, 'pending').then(res => {
      //   console.log(res)
      //   if (res.data.code === 0) {
      //     this.$message({
      //       message: '更新成功！',
      //       type: 'success'
      //     })
      //     this.getReviewsByTask()
      //   }
      // })
      refreshReviewerStatus({ task_id: this.task_id }).then(res => {
        console.log(res)
        if (res.data.code === 0) {
          this.$message({
            message: '更新成功！',
            type: 'success'
          })
          this.getReviewsByTask()
        }
      })
    }

  }
}
</script>

<style>

</style>
