<template>
    <div>
        <el-button @click="add" type="primary">新增</el-button>
        <el-table :data="banks">
            <el-table-column label="公司名称" prop="bank_name"></el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button @click="edit(scope.row)" type="primary" size="medium">编辑</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-dialog title="编辑公司" :visible.sync="showBank">
            <el-form>
                <el-form-item label="公司名称">
                    <el-input v-model="form.bank_name"></el-input>
                </el-form-item>
            </el-form>
            <el-button @click="submit" type="primary">确认</el-button>
        </el-dialog>
    </div>
</template>

<script>
import { getBanks,updateBank } from '../../api/assistant'
export default {
    data(){
        return{
            banks:[],
            bank_id:'',
            showBank:false,
            form:{
                bank_name:''
            }
        }
    },
    mounted(){
        this.fetchData()
    },
    methods:{
        fetchData(){
            getBanks().then(res=>{
                if(res.data.code===0){
                    this.banks = res.data.data
                }
            })
        },
        add(){
            this.bank_id = ''
            this.form.bank_name = ''
            this.showBank = true
        },
        submit(){
            updateBank(this.bank_id,this.form.bank_name).then(res=>{
                if(res.data.code===0){
                    this.$message({
                        message:'更新成功',
                        type:'success'
                    })
                    this.showBank = false
                    this.fetchData()
                }
            })
        },
        edit(row){
            this.bank_id = row._id.$id
            this.form = JSON.parse(JSON.stringify(row))
            this.showBank = true
        }
    }
}
</script>

<style>

</style>