<template>
    <div class="container">
        <div style="position:absolute;cursor:pointer;font-size:18px" @click="$router.back(-1)"><i class="el-icon-arrow-left"></i></div>
        <div style="padding:30px">
            <div v-for="item in evaluation_dimensions" :key="item.key">
                <custom-input
                    :formItem="item"
                    :value="keyCollector[item.key]"
                    @dataChange="onDataChange">
                </custom-input>
            </div>
            <div style="margin-top:30px">
                <el-button @click="updateWorkshopEntryEvaluation" type="primary">提交</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import {getEvaluationFormByWorkshop,getWorkshopEntryEvaluation,updateWorkshopEntryEvaluation} from '../../api/assistant'
import CustomInput from '../../components/CustomInput.vue'
import {getUserId} from '../../utils/store'
export default {
    components:{
        CustomInput,
    },
    data(){
        return{
            id:this.$route.query.id,
            entry_id:this.$route.query.entry_id,
            workshop_id:this.$route.query.workshop_id,
            evaluation_dimensions:[],
            keyCollector:{},
            form_id:''
        }
    },
    mounted(){
        this.getEvaluationFormByWorkshop()
    },
    methods:{
        getEvaluationFormByWorkshop(){
            getEvaluationFormByWorkshop(this.workshop_id).then(res=>{
                if(res.data.code===0){
                    this.form_id = res.data.data._id.$id
                    let evaluation_dimensions = res.data.data.evaluation_dimensions;
                    let result = [];
                    for(let i in evaluation_dimensions){
                        let model = {key:i,...evaluation_dimensions[i]};
                        this.keyCollector[i] = '';
                        result.push(model);
                    }
                    this.evaluation_dimensions = result;

                    if(this.id){
                        getWorkshopEntryEvaluation(this.id).then(res=>{
                            if(res.data.code===0){
                                let contents = res.data.data.contents;
                                let keyCollector = JSON.parse(JSON.stringify(this.keyCollector));
                                Object.keys(contents).map(item=>{
                                    keyCollector[item] = contents[item];
                                })
                                this.keyCollector = keyCollector;
                            }
                        })
                    }
                }
            })
        },
        onDataChange(e){
            this.keyCollector[e.key] = e.data;
            
        },
        updateWorkshopEntryEvaluation(){
            updateWorkshopEntryEvaluation(this.id,this.entry_id,this.form_id,getUserId(),this.keyCollector).then(res=>{
                if(res.data.code===0){
                    this.$message({
                        message:'更新成功',
                        type:'success'
                    })
                }
            })
        }
    }
}
</script>

<style>

</style>