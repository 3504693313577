<template>
  <div>
    <el-button type="primary" @click="initAdd">添加</el-button>
    <el-table :data="regulations">
      <el-table-column prop="title" label="标题"></el-table-column>
      <!-- <el-table-column prop="content" label="内容"></el-table-column> -->
      <el-table-column
        prop="version"
        label="版本"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="update_note"
        label="更新内容"
        width="300"
      ></el-table-column>
      <el-table-column label="操作" width="200">
        <template slot-scope="scope">
          <el-button @click="edit(scope.row)" size="medium" type="primary"
            >修改</el-button
          >
          <el-button
            @click="removeRegulation(scope.row._id.$id)"
            size="medium"
            type="primary"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="编辑" :visible.sync="show" :close-on-click-modal="false">
      <el-form>
        <el-form-item label="标题">
          <el-input v-model="form.title"></el-input>
        </el-form-item>
        <el-form-item label="标题英文">
          <el-input v-model="form.title_en"></el-input>
        </el-form-item>
        <el-form-item label="内容">
          <el-input type="textarea" :rows="5" v-model="form.content"></el-input>
        </el-form-item>
        <el-form-item label="内容英文">
          <el-input
            type="textarea"
            :rows="5"
            v-model="form.content_en"
          ></el-input>
        </el-form-item>
        <el-form-item label="版本">
          <el-input v-model="form.version"></el-input>
        </el-form-item>
        <el-form-item label="版本更新内容">
          <el-input
            v-model="form.update_note"
            type="textarea"
            :rows="5"
          ></el-input>
        </el-form-item>
        <el-form-item label="版本更新内容英文">
          <el-input
            v-model="form.update_note_en"
            type="textarea"
            :rows="5"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="updateRegulation">确认</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  getRegulations,
  updateRegulation,
  removeRegulation,
} from "../../api/assistant";
import { getUserId } from '../../utils/store.js'
export default {
  data() {
    return {
      regulations: [],
      id: "",
      show: false,
      form: {
        title: "",
        content: "",
        version: "",
        update_note: "",
        title_en: "",
        content_en: "",
        update_note_en: "",
      },
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      getRegulations({
        account_id:getUserId()
      }).then((res) => {
        if (res.data.code === 0) {
          this.regulations = res.data.data;
        }
      });
    },
    initAdd() {
      this.show = true;
      this.id = "";
      this.form = {
        title: "",
        content: "",
        version: "",
        update_note: "",
        title_en: "",
        content_en: "",
        update_note_en: "",
      };
    },
    edit(row) {
      this.form = JSON.parse(JSON.stringify(row));
      this.id = row._id.$id;
      this.show = true;
    },
    updateRegulation() {
      updateRegulation({
        id: this.id,
        title: this.form.title,
        content: this.form.content,
        version: this.form.version,
        update_note: this.form.update_note,
        title_en: this.form.title_en,
        content_en: this.form.content_en,
        update_note_en: this.form.update_note_en,
        account_id:getUserId()
      }).then((res) => {
        if (res.data.code === 0) {
          this.$message({
            message: "更新成功",
            type: "success",
          });
          this.fetchData();
        }
        this.show = false;
      });
    },
    removeRegulation(id) {
      removeRegulation(id).then((res) => {
        this.fetchData();
      });
    },
  },
};
</script>

<style>
</style>