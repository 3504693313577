<template>
  <div class="container">
    <div class="leftLine">
      <div
        class="leftItem"
        :class="{ selected: tab === 0 }"
        @click="changeTab(0)"
      >
        <div class="leftIcon">
          <i class="el-icon-chat-round"></i>
        </div>
        <div class="txt">消息</div>
      </div>
      <div
        class="leftItem"
        :class="{ selected: tab === 1 }"
        @click="changeTab(1)"
      >
        <div class="leftIcon">
          <i class="el-icon-chat-dot-square"></i>
        </div>
        <div class="txt">通讯录</div>
      </div>
    </div>
    <div class="rightContent">
      <Content v-show="tab === 0" ref="content"></Content>
      <Book v-show="tab === 1" @message="talkToSomeone"></Book>
    </div>
  </div>
</template>

<script>
import Book from "./book.vue";
import Content from "./content.vue";
export default {
  components: {
    Book,
    Content,
  },
  data() {
    return {
      tab: 0,
    };
  },
  mounted() {},
  methods: {
    changeTab(index) {
      this.tab = index;
    },
    talkToSomeone(e) {
      this.tab = 0;
      this.$nextTick(() => {
        this.$refs.content.talkToSomeone(e);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  position: relative;
  .leftLine {
    width: 64px;
    height: 100%;
    background: rgb(57, 117, 198);
    .leftItem {
      width: 54px;
      height: 54px;
      color: #ddd;
      margin: 0 auto;
      margin-top: 10px;
      cursor: pointer;
      border-radius: 4px;
      overflow: hidden;
      .leftIcon {
        text-align: center;
        font-size: 26px;
        margin-top: 4px;
      }
      .txt {
        text-align: center;
        font-size: 12px;
        margin-top: 2px;
      }
    }
    .leftItem:hover {
      background: rgba(255, 255, 255, 0.2);
    }
    .leftItem.selected {
      color: #fff;
      background: rgba(255, 255, 255, 0.2);
    }
  }
  .rightContent {
    width: calc(100vw - 64px);
    height: 100%;
  }
}
</style>