<template>
    <div>
        <el-button @click="$router.back(-1)" size="medium">返回</el-button>
        <el-form>
            <el-form-item label="课时名称">
                <el-input v-model="sessionData.session_info.session_name"></el-input>
            </el-form-item>
            <el-form-item label="课时介绍">
                <el-input v-model="sessionData.session_info.session_description" type="textarea" :rows="6"></el-input>
            </el-form-item>
        </el-form>
        <el-button @click="submit" type="primary" >更新</el-button>
        <section style="margin-top:50px">
            <el-button @click="initAdd" type="primary">添加</el-button>
            <el-button @click="startOrder" type="primary">排序</el-button>
        <el-table :data="contents">
            <el-table-column label="编号" width="150px">
                <template slot-scope="scope">
                    {{scope.$index+1}}
                </template>
            </el-table-column>
            <el-table-column label="内容级别" prop="content_info.content_level" width="150px"></el-table-column>
            <el-table-column label="内容类型" prop="content_info.content_type"></el-table-column>
            <el-table-column label="内容长度" prop="content_info.content_length"></el-table-column>
            <el-table-column label="内容名称" prop="content_info.content_name"></el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button @click="viewContent(scope.row)" type="primary" size="small">查看</el-button>
                    <el-button @click="removeContent(scope.row._id.$id)" type="danger" size="small">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        </section>
        <el-dialog :visible.sync="showAdd">
            <span>内容名称</span>
            <el-input v-model="content_name" type="text"> </el-input>
            <el-button style="margin-top:20px" @click="addConfirm">确定</el-button>
        </el-dialog>
        <el-dialog
          title="更改排序"
          :visible.sync="ordershow"
          :close-on-click-modal="false"
          width="80%"
          @open="openOrder"
          @close="orderClose"
        >
          <p class="orderP">注：自由拖拽可进行排序</p>
          <el-table
            v-loading="orderLoading"
            row-key="id"
            class="orderTable"
            stripe
            :data="orderData" 
           
            style="width: 100%"
          >
            <el-table-column
              align="center"
              prop="content_info.content_name"
              label="Content"
              width="300px"
            ></el-table-column>
           
            
          </el-table>
          <span slot="footer" class="dialog-footer">
            <el-button @click="orderClose">取 消</el-button>
            <el-button type="primary" @click="orderTask">确 定</el-button>
          </span>
        </el-dialog>
    </div>
</template>

<script>
import { getSession,updateSession,updateContent,addContentToSession,updateContentOrderInSession,removeContent} from '../api/assistant'
import Sortable from "sortablejs";
export default {
    data(){
        return{
            id:'',
            class_id:this.$route.query.class_id,
            sessionData:{
                session_info:{

                }
            },
            contents:[],
            content_name:'',
            showAdd:false,
            ordershow:false,
            orderLoading: false,
            orderData:[],
        }
    },
    mounted(){
        this.id = this.$route.query.id;
        this.fetchData()
    },
    methods:{
        fetchData(){
            getSession(this.id).then(res=>{
                if(res.data){
                    this.sessionData = res.data
                    this.contents = res.data.contents
                    this.orderData = res.data.contents.map(m=>{
                      m.id=m._id.$id;
                      return m;
                    })
                }
            })
        },
        submit(){
            updateSession(this.id,this.sessionData.session_info.session_name,this.sessionData.session_info.session_description,this.class_id).then(res=>{
                this.$message({
                    message:'更新成功',
                    type:'success'
                })
            })
        },
        initAdd(){
            this.showAdd=true
            this.content_name = ''
        },
        viewContent(row){
            this.$router.push({
                path:'contentinfo',
                query:{
                    id:row._id.$id,
                    class_id:this.class_id,
                    session_id:this.id
                }
            })
        },
        addConfirm(){
            updateContent('',this.content_name,'','','','','','','','',this.class_id,this.id,[]).then(res=>{
                console.log(res)
                addContentToSession(res.data._id.$id,this.id).then(
                    this.$message({
                        message:'更新成功',
                        type:'success'
                    })
                )
                this.fetchData()
                this.showAdd=false
            })
        },
        orderTask() {
          //确定排序
          let child_ids = [];
          this.orderData.forEach((f) => {
            child_ids.push(f._id.$id);
          });
          this.orderLoading = true;
          updateContentOrderInSession(this.id, child_ids).then((res) => {
            this.orderLoading = false;
            if (res.data.code == 0) {
              this.$message.success("更新成功！");
              this.orderClose();
            }
          });
        },
        //行拖拽
        rowDrop() {
          const tbody = document.querySelector(
            ".orderTable .el-table__body-wrapper tbody"
          );
          console.log(tbody);
          const _this = this;
          Sortable.create(tbody, {
            onEnd({ newIndex, oldIndex }) {
              const currRow = _this.orderData.splice(oldIndex, 1)[0];
              _this.orderData.splice(newIndex, 0, currRow);
              console.log(_this.orderData);
            },
          });
        },
        // taskOrder(item) {
        //   this.orderData = item.children.map((m) => {
        //     if (m.children && m.children.length > 0) {
        //       delete m.children;
        //     }
        //     return m;
        //   });
        //   console.log(item);
        //   this.item_id = item.id;
        //   this.ordershow = true;
        // },
        openOrder() {
          this.$nextTick(() => {
            // 阻止默认行为
            document.body.ondrop = function (event) {
              event.preventDefault();
              event.stopPropagation();
            };
            this.rowDrop();
          });
        },
        orderClose() {
          this.ordershow = false;
          this.fetchData();
        },
        startOrder(){
            this.ordershow = true;
        },
        removeContent(content_id){
            this.$confirm('是否确认删除?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(res=>{
                removeContent(content_id,this.id).then(res=>{
                    this.fetchData()
                })
            })
        }
    }
}
</script>

<style>

</style>