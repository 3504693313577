<template>
    <div class="container">
        <div @click="$router.back(-1)">
            <i class="el-icon-arrow-left" style="font-size:120%;cursor:pointer"></i>
        </div>
        <div style="width:90%;margin:0 auto">
            <h3>月度总结</h3>
            <p>{{year}}年{{month}}</p>
            <p>月度总结详细信息</p>
            <el-form label-position="top" style="margin:0 auto">
                <el-form-item v-for="item in formList" :key="item.key" :label="item.label">
                    <p v-if="item.key==='month_value_boost'">注：这将作为你升职加薪和职业发展评估时的参考信息，须尽可能具体和详细；请避免笼统的说法（比如XXX能力得到了提升），这对公司的评估并没有太大帮助和价值；</p>
                    <p v-if="item.key==='month_value_boost'">请注意：提供本部分信息能够给公司提供参考价值，但提供这部分信息本身不会成为你升职加薪的保证</p>
                    <el-input type="textarea" v-model="item.val" :rows="6"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button @click="submit" type="primary">提交</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import {updateMonthLog,getMonthTimeSheetById,getUser} from '../../api/assistant'
import {getUserId} from '../../utils/store'
export default {
    data(){
        return{
            year:this.$route.query.year,
            month:this.$route.query.month,
            sheet_id:this.$route.query.sheet_id,
            formList:[
                {key:'month_content',label:'本月工作成果',val:''},
                {key:'month_get',label:'本月心得与收获',val:''},
                {key:'month_analysis',label:'本月分析（不足、改进、教训）',val:''},
                {key:'month_value_boost',label:'本月个人能力、技能、经验、价值创造等工作相关方面的成长/提升，以证明你比上月能为公司创造更多的价值/你具有更高的职场价值',val:''},
                {key:'month_problem',label:'工作难题及所需支持',val:''},
                {key:'month_suggest',label:'业务建议',val:''},
                {key:'month_plan',label:'下月计划',val:''},
                {key:'month_goal',label:'到下月底的成长目标和业务目标',val:''},
                {key:'month_need',label:'IT需求（此部分将自动提交至IT需求模块，若无IT需求此项请勿填写,空着即可）',val:''},
                {key:'month_time_assigned',label:'本月工作时间分配（一项工作一行，后面标明时间百分比，如XXX工作-20%）',val:''},
                {key:'month_extra_10',label:'这个月的Extra 10%',val:''},
                {key:'month_upward_feedback',label:'本月对你直属汇报人（上级）在管理、工作、带人方面的建议（这不会影响对你个人的评价）',val:''},
                {key:'month_skill_upgrading',label:'本月新的工作技能学习与提升（任何和自身岗位相关或跨岗位的新工作技能学习，填写目的与本月价值提升相同）',val:''},
                {key:'month_new_discovery',label:'本月自己在工作和生活中可以与他人分享的新发现、新启发（玩了新的游戏、看了新的书、吃了新的美食、发现了新的化妆品牌、看了新的剧等等等等,此部分将在企业微信通知内公开分享）',val:''}
            ],
            form:{},
            userInfo:{}
        }
    },
    created(){
        if(this.sheet_id){
            this.getMonthTimeSheetById()
        }

        getUser(getUserId()).then(res=>{

            console.log(788,res.data)
            this.userInfo = res.data
            if (res.data.employee_management_territory != null &&  res.data.employee_management_territory.indexOf("经理") != -1 ){

                var item = {key:'month_technology_administration',label:'通过信息技术加强管理(对公司内部使用的IT基础设施、业务系统、协作工具等提出改进和优化的意见,将原本未通过IT系统进行管理的流程，放入到系统中进行实现,提高业务处理效率和可靠性，降低出错率)',val:''}
                var item1 = {key:'month_meeting_administration',label:'通过安排会议实现工作计划管理(能够对负责业务工作在未来一个月内设立重要的对齐节点，并“提前”“主动”安排会议，组织和邀请相关涉及的跨部门同事进行参与，并撰写会议记录,在会议安排和会议议程中能够“布置”和“校准”自身以及团队的工作计划安排，计划拥有足够的提前量)',val:''}
                var item2 = {key:'month_feedback_administration',label:'通过工作反馈实现管理(在日常工作中，能够对团队中下辖的员工提供及时的工作反馈，包括正面的、负面的,能够使用系统的工作反馈工具，将工作反馈录入系统通知员工)',val:''}
                var item3 = {key:'month_standard_administration',label:'通过工作标准进行管理(能够将工作中遇到的问题、教训、改进方法等落实到工作标准（SOP）中，能够创建新的保护和维护升级已有的标准,对于新开展的工作，能够系统地规划新的标准，起草和撰写新的SOP文档)',val:''}

                this.formList.push(item)
                this.formList.push(item1)
                this.formList.push(item2)
                this.formList.push(item3)

            } 
        })

    },
    methods:{
        submit(){
            let count = 0

            var month_technology_administration_str = ''
            var month_meeting_administration_str = ''
            var month_feedback_administration_str = ''
            var month_standard_administration_str = ''

            var month_skill_upgrading_str = ''


            for(let item of this.formList){
                this.form[item.key] = item.val
                count = count + item.val.length

                if (this.userInfo.employee_management_territory != null &&  this.userInfo.employee_management_territory.indexOf("经理") != -1 ){
                    if (item.key == 'month_technology_administration'){
                        month_technology_administration_str = item.val
                    }
                    if (item.key == 'month_meeting_administration'){
                        month_meeting_administration_str = item.val
                    }
                    if (item.key == 'month_feedback_administration'){
                        month_feedback_administration_str = item.val
                    }
                    if (item.key == 'month_standard_administration'){
                        month_standard_administration_str = item.val
                    }
                }
                
                if (item.key == 'month_skill_upgrading'){
                    month_skill_upgrading_str = item.val
                }

            }

            if (month_skill_upgrading_str.length <= 0){
                this.$message.error('请填写本月新的工作技能学习与提升')
                return
            }

            
            if (this.userInfo.employee_management_territory != null &&  this.userInfo.employee_management_territory.indexOf("经理") != -1 ){
                if (month_technology_administration_str.length < 20){
                    this.$message.error('通过信息技术加强管理填写不能少于20字')
                    return
                }
                if (month_meeting_administration_str.length < 20){
                    this.$message.error('通过安排会议实现工作计划管理填写不能少于20字')
                    return
                }
                if (month_feedback_administration_str.length < 20){
                    this.$message.error('通过工作反馈实现管理填写不能少于20字')
                    return
                }
                if (month_standard_administration_str.length < 20){
                    this.$message.error('通过工作标准进行管理填写不能少于20字')
                    return
                }
            }
            

            if(count<500){
                this.$message.error('总字数不足500字无法提交')
                return
            }
            const loading = this.$loading({lock: true,text: 'Loading',spinner: 'el-icon-loading',background: 'rgba(0, 0, 0, 0.7)'});
            updateMonthLog(
                getUserId(),
                parseInt(this.month),
                this.year,
                this.form.month_content,
                this.form.month_get,
                this.form.month_analysis,
                this.form.month_value_boost,
                this.form.month_problem,
                this.form.month_suggest,
                this.form.month_plan,
                this.form.month_goal,
                this.form.month_need,
                this.form.month_time_assigned,
                this.form.month_extra_10,
                this.form.month_upward_feedback,
                this.form.month_new_discovery,
                month_technology_administration_str,
                month_meeting_administration_str,
                month_feedback_administration_str,
                month_standard_administration_str,
                month_skill_upgrading_str

            ).then(res=>{
                if(res.data.code===0){
                    loading.close()
                    this.$message({
                        message:'提交成功',
                        type:'success'
                    })
                }else{
                    loading.close()
                    this.$message.error({
                        message:'提交失败'
                    })
                }
            })
        },
        getMonthTimeSheetById(){
            getMonthTimeSheetById(this.sheet_id).then(res=>{
                for(let item of this.formList){
                    item.val = res.data[item.key]
                }
            })
        }
    }
}
</script>

<style>
.container{
    width: 100%;
    color: #606266;
}
</style>